import classNames from 'classnames';
import React from 'react';

import styles from './Well.css';

const Well: React.FC<Gemsby.ISimpleWrapperProps> = ({
  className,
  children,
}) => (
  <div className={classNames('well', styles.well, className)}>{children}</div>
);

export default Well;
