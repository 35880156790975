const store = window.localStorage;

export default {
  get(key: string): any {
    const val = store.getItem(key);
    try {
      return JSON.parse(val);
    } catch (e) {
      return null;
    }
  },
  set(key: string, val: any): Storage {
    store.setItem(key, JSON.stringify(val));
    return store;
  },
  remove(key: string) {
    store.removeItem(key);
  }
};
