import React from 'react';

import Button from 'common/components/common/Button';
import DeleteButton from 'seller/app/components/common/DeleteButton';
import InventoryDetailsCard from 'seller/app/components/inventory/form/InventoryDetailsCard';
import InventoryGalleryCard from 'seller/app/components/inventory/form/InventoryGalleryCard';
import InventoryOptionalDetailsCard from 'seller/app/components/inventory/form/InventoryOptionalDetailsCard';
import InventorySettingCard from 'seller/app/components/inventory/form/InventorySettingCard';

import styles from './Form.css';

const FormMobile = (props: any) => (
  <>
    <InventorySettingCard {...props} />
    <InventoryGalleryCard {...props} />
    <InventoryDetailsCard {...props} />
    <InventoryOptionalDetailsCard {...props} />
  </>
);

const FormDesktop = (props: any) => (
  <div className={styles.formContent}>
    <div className={styles.formContentLeftColumn}>
      <InventorySettingCard {...props} />
      <InventoryGalleryCard {...props} />
    </div>
    <div className={styles.formContentRightColumn}>
      <InventoryDetailsCard {...props} />
      <InventoryOptionalDetailsCard {...props} />
    </div>
  </div>
);

interface IForm extends Gemsby.IComponentProps {
  isBigScreen: boolean;
}

const Form = ({ isBigScreen, onCancel, onSubmit, onDelete, ...props }: IForm) => (

  <form className={styles.form}>
    {isBigScreen ? <FormDesktop {...props} /> : <FormMobile {...props} />}
    <div className={styles.buttons}>
      <Button className={styles.cancelButton} onClick={onCancel}>
        Cancel
      </Button>
      <Button className={styles.submitButton} onClick={onSubmit}>
        Submit
      </Button>
    </div>
    <div className={styles.deleteButtonContainer}>
      <DeleteButton onClick={onDelete} />
    </div>
  </form>
);

export default Form;
