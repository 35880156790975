import classNames from 'classnames';
import React from 'react';

import Field, { IFieldProps } from 'common/components/common/form/Field';
import GemsbyIcon from 'common/components/common/GemsbyIcon';
import { NA_OPTION, NA_TEXT } from 'common/utils/form';

import styles from './Form.css';

interface IRadioGroupField extends IFieldProps {
  listClassName?: string;
  itemClassName?: string;
  rowLayout?: boolean;
  radioGroupName: string;
  options: Record<string, string | JSX.Element>;
  selectedOption: string;
  disabled?: boolean;
  error?: string;
  onChange: React.ChangeEventHandler;
}

const RadioGroupField: React.FC<IRadioGroupField> = ({
  className,
  listClassName,
  itemClassName,
  rowLayout,
  label,
  radioGroupName,
  options,
  selectedOption,
  naOption = false,
  disabled,
  error,
  onChange,
  children,
}) => {
  listClassName = classNames(
    rowLayout ? styles.radioGroupRowList : styles.radioGroupList,
    listClassName
  );
  itemClassName = classNames(
    rowLayout ? styles.radioGroupRowItem : styles.radioGroupItem,
    itemClassName
  );
  if (naOption) {
    options = Object.assign({}, options, {
      [NA_OPTION]: NA_TEXT.toUpperCase()
    });
  }
  return (
    <Field
      containerClassName={classNames(styles.radioGroupField, className)}
      label={label}
      error={error}
    >
      <ul className={listClassName}>
        {Object.entries(options).map(([value, name]) => (
          <li className={itemClassName} key={value}>
            <label className={classNames(styles.radioGroupItemContainer, { disabled })}>
              <input
                type="radio"
                name={radioGroupName}
                value={value}
                checked={value === selectedOption}
                disabled={disabled}
                onChange={onChange}
              />
              {value !== selectedOption && (
                <GemsbyIcon icon="radio-unchecked" />
              )}
              {value === selectedOption && <GemsbyIcon icon="radio-checked" />}
              <div className={styles.radioGroupItemLabel}>
                <span>{name}</span>
              </div>
            </label>
          </li>
        ))}
      </ul>
      {children}
    </Field>
  );
};

export default RadioGroupField;
