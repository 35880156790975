import classNames from 'classnames';
import React from 'react';

import Fa from 'common/components/common/Fa';

import styles from './DataTable.css';

export enum HeaderSort {
  ASC,
  DESC,
}

const renderSort = (sort: HeaderSort) => {
  switch (sort) {
    case HeaderSort.ASC:
      return <Fa className={styles.faIcon} icon="chevron-up" />;
    case HeaderSort.DESC:
      return <Fa className={styles.faIcon} icon="chevron-down" />;
    default:
      return null;
  }
};

export interface ITableHeader {
  label: string;
  className?: string;
  thCls?: string;
  sort?: HeaderSort;
}

interface IDataTableHeaderProps {
  headers: ITableHeader[];
}

const DataTableHeader: React.FC<IDataTableHeaderProps> = ({ headers }) => (
  <thead className={styles.tableHeader}>
    <tr>
      {headers.map(({ label, className, sort, thCls }, i) => (
        <th key={i} className={classNames(thCls)}>
          <div className={classNames(styles.headerItem, className)}>
            <span className={styles.headerText}>{label}</span>
            {renderSort(sort)}
          </div>
        </th>
      ))}
    </tr>
  </thead>
);

export default DataTableHeader;
