import { randomString } from 'common/services/device';
import imageUrl, { isImageUrl } from 'common/utils/imageUrl';
import { deleteObject, getRegionFromUrl, putObject } from 'common/utils/s3';

const createObjectKey = (type: string, entityId: string, filename: string) => {
  const isDev = process.env.NODE_ENV === 'development';
  const random = randomString();
  entityId = entityId.replace(/\//g, '_');
  return `${type}${isDev ? '-dev' : ''}-${entityId}-${random}-${filename}`;
};

const getObjectKeyFromUrl = (url: string) =>
  url.substr(url.lastIndexOf('/') + 1);

export const uploadImage = (type: string, file: File, entityId: string) => {
  entityId = entityId || randomString();
  return putObject({
    Body: file,
    Key: createObjectKey(type, entityId, file.name),
    ContentType: file.type,
  });
};

export const deleteImage = (url: string) => {
  const objectKey = getObjectKeyFromUrl(url);
  if (!objectKey) {
    return Promise.reject('cannot get object key from url');
  }
  return deleteObject({ Key: objectKey }, getRegionFromUrl(url));
};

export const uploadImages = (
  type: string,
  images,
  entityId: string,
  progressCallback
) => {
  entityId = entityId || randomString();
  const results = [];

  return images
    .reduce(
      (task: Promise<any>, { file, url }: { file: File; url: string }) =>
        task.then(() =>
          (isImageUrl(url)
            ? Promise.resolve(url)
            : uploadImage(type, file, entityId)
          )
            .then(
              // tslint:disable-next-line:no-shadowed-variable
              (url: string) => {
                results.push({ file, url });
              },
              (err: Error) => {
                console.log(err);
                results.push(null);
              }
            )
            .then(() => {
              if (progressCallback) {
                progressCallback(results);
              }
            })
        ),
      Promise.resolve()
    )
    .then(() => results);
};

export const uploadInventoryImage = uploadImage.bind(null, 'inventory');
export const uploadInventoryImages = uploadImages.bind(null, 'inventory');
export const uploadRetailerImage = uploadImage.bind(null, 'retailer');
export const uploadRetailerImages = uploadImages.bind(null, 'retailer');

const getImageFile = (type: string, imageEntity: Gemsby.IJSONAPIEntity) => {
  const imageFile = Object.assign(imageEntity.toJSON().attributes, {
    id: imageEntity.id,
    type: imageEntity.type,
  });
  if (!imageFile.url && imageFile.path) {
    imageFile.url = imageUrl(type, imageFile.path);
  }
  return imageFile;
};

export const getInventoryImageFile = getImageFile.bind(null, 'inventory');
export const getRetailerImageFile = getImageFile.bind(null, 'retailer');

export default {
  uploadInventoryImage,
  uploadInventoryImages,
  uploadRetailerImage,
  uploadRetailerImages,
  deleteImage,
};
