import React from 'react';

import Header from 'seller/app/components/common/header/Header';
// import HeaderButtonLogout from 'seller/app/components/common/header/HeaderButtonLogout';

import styles from 'seller/app/components/common/header/HeaderNav.css';

const HeaderNavMobile = () => (
  <Header>{/* <div className={styles.header}></div> */}</Header>
);

export default HeaderNavMobile;
