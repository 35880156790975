import React from 'react';

import Well from 'common/components/common/Well';

import styles from './PromoteNowView.css';

import { useHistory } from 'react-router';

const PromoteNowView: React.FC = () => {

  const history = useHistory();

  const goToLink = (e) => {
    e.preventDefault();
    history.push('/inventory');
  }

  return (
    <Well className={styles.lastWeekViews}>
      Your Item has been added! Want to promote it now? Scroll down to learn more. Or{' '}
      <a onClick={goToLink} style={{cursor: 'pointer'}}>
        Go back to your inventory list
      </a>
      .
    </Well>
  );
};

export default PromoteNowView;
