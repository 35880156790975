import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import FormErrorMessage from 'common/components/common/form/FormErrorMessage';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import OutLink from 'common/components/common/OutLink';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import Well from 'common/components/common/Well';
import { SUPPORT_EMAIL } from 'common/constants';
import { scrollToFormError, useFormState } from 'common/utils/form';
import {
  getRetailerAccountData,
  prepareRetailerAccountData,
} from 'common/utils/retailer';
import {
  validateRetailerAccount,
  VALIDATION_ERROR_TYPE,
} from 'common/utils/validators';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from 'seller/app/actions/form';
import {
  setRetailerError,
  updateRetailerAccount,
} from 'seller/app/actions/retailer';
import AccountForm from 'seller/app/components/account/form/Form';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import { Routes } from 'seller/app/constants';
import onboarding from 'seller/app/services/onboarding';
import Modal from 'common/components/common/mobile/MobileModal';

const formErrorRef = React.createRef();

const PageTitle = () => <PageHeader>Account Settings</PageHeader>;
const ChangeEmailInstruction = ({ retailer }) => (
  <Well>
    Your connected email is <strong>{retailer.get('email')}</strong>. Please
    contact <OutLink to={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</OutLink> to
    change it.
  </Well>
);

const AccountPageMobile = props => (
  <>
  <Modal />
  <Screen>
    <PageMobile>
      <PageContent heightReducedBy={60}>
        <HeaderNavMobile />

        <PageTitle />

        <PageSection>
          <BannerAd />
        </PageSection>

        <PageSection>
          <ChangeEmailInstruction retailer={props.retailer} />
        </PageSection>

        <PageSection>
          <FormErrorMessage error={props.error} ref={formErrorRef} />
          <AccountForm {...props} />
        </PageSection>
      </PageContent>

      <TabBar activeItem={Routes.account} />
    </PageMobile>
  </Screen>
  </>
);

const AccountPageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.account} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <ChangeEmailInstruction retailer={props.retailer} />
    </PageSection>

    <PageSection>
      <PageTitle />

      <FormErrorMessage error={props.error} ref={formErrorRef} />
      <AccountForm {...props} />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const AccountPage = props => {
  // tslint:disable:no-shadowed-variable
  const {
    history,
    isBigScreen,
    retailer,
    error,
    showFullscreenLoader,
    hideFullscreenLoader,
    setRetailerError,
    updateRetailerAccount,
  } = props;
  // tslint:enable:no-shadowed-variable

  const [state, handleFieldChange] = useFormState(
    getRetailerAccountData(retailer)
  );

  useEffect(() => {
    if (!error || error.type !== VALIDATION_ERROR_TYPE) {
      return;
    }
    const { error: validationError } = validateRetailerAccount(
      prepareRetailerAccountData(state)
    );
    setRetailerError(validationError);
  }, [state]);

  const onCancel = event => {
    event.preventDefault();
    history.goBack();
  };

  const onSubmit = event => {
    event.preventDefault();
    showFullscreenLoader();

    const attributes = prepareRetailerAccountData(state);

    updateRetailerAccount({
      type: 'retailer',
      id: retailer.id,
      attributes,
    })
      .then(
        () => {
          onboarding.setAccountUpdated();
        },
        () => {
          scrollToFormError(formErrorRef);
        }
      )
      .finally(hideFullscreenLoader);
  };

  props = {
    ...props,
    state,
    handleFieldChange,
    onCancel,
    onSubmit,
  };

  return isBigScreen ? (
    <AccountPageDesktop {...props} />
  ) : (
    <AccountPageMobile {...props} />
  );
};

const mapState = ({ device, login, retailer }) => ({
  isBigScreen: device.isBigScreen,
  retailer: login.currentUser,
  error: retailer.error,
});

const mapDispatch = {
  updateRetailerAccount,
  setRetailerError,
  showFullscreenLoader,
  hideFullscreenLoader,
};

export default connect(
  mapState,
  mapDispatch
)(AccountPage);
