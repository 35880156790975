import algoliasearch from 'algoliasearch';

import { ALGOLIA_INDICES } from 'common/constants';
import inventoryUtils from 'common/utils/inventory';

const client = algoliasearch(ALGOLIA_APPID, ALGOLIA_APIKEY);
const inventoryIndex = client.initIndex(ALGOLIA_INDICES.inventory);
const inventoryPriceAscIndex = client.initIndex(ALGOLIA_INDICES.inventoryPriceAsc);
const inventoryPriceDescIndex = client.initIndex(ALGOLIA_INDICES.inventoryPriceDesc);
const inventoryRecentUpdate = client.initIndex(ALGOLIA_INDICES.inventoryRecentUpdate);
const inventorySortToIndexMap = {
  distance: inventoryIndex,
  priceAsc: inventoryPriceAscIndex,
  priceDesc: inventoryPriceDescIndex,
  recentUpdate: inventoryRecentUpdate,
};

export const shapes = [
  'round-brilliant',
  'cushion',
  'princess',
  'pear',
  'heart',
  'asscher',
  'emerald',
  'marquise',
  'oval',
  'trilliant',
  'baguette',
  'tapered-baguette',
  'radiant',
];
export const cuts = ['ideal', 'excellent', 'very-good', 'good', 'fair', 'poor'];
export const clarities = [
  'f',
  'if',
  'vvs1',
  'vvs2',
  'vs1',
  'vs2',
  'si1',
  'si2',
  'si3',
  'i1',
  'i2',
];
export const colors = [
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o-s',
  't-',
];
export const fancyColors = [
  'yellow',
  'brown',
  'pink',
  'orange',
  'black',
  'canary',
  'champagne',
  'cognac',
  'green',
  'purple',
  'blue',
  'grey',
  'red',
];
export const certs = [
  'gia',
  'ags',
  'igi',
  'gemscan',
  'hrd',
  'egl',
  'ugl',
  'igl',
];

export const search = ({
  index,
  filters,
  facets = '*',
  page = 0,
  geo,
  hitsPerPage,
  query,
}) => {
  if (page < 0) {
    index.clearCache();
    page = 0;
  }
  const params = {
    getRankingInfo: true,
    hitsPerPage,
    query: query || '',
    filters: filters || '',
    facets: facets || '*',
    page,
  };
  if (geo) {
    const { position, distance } = geo;
    if (position) {
      params.aroundLatLng = `${position.lat},${position.lng}`;
    } else {
      params.aroundLatLngViaIP = true;
    }
    params.aroundRadius = distance ? distance * 1000 : 'all';
  }
  return index.search(params).then(response => {
    response.hits = response.hits.map(hit =>
      Object.assign(hit, {
        id: encodeURIComponent(`${hit.createdDay}/${hit.objectID}`),
      })
    );
    return response;
  });
};

export const searchWithText = ({ index, query, page = 0, hitsPerPage }) => {
  const params = {
    getRankingInfo: true,
    hitsPerPage,
    query,
    page,
  };
  return index.search(params);
};

export const searchInventory = (filters, page, geo, sortBy, query) => {
  return search({
    index: inventorySortToIndexMap[sortBy],
    filters,
    page,
    geo,
    hitsPerPage: 40,
    query,
  });
};

export const searchRecentDiamonds = recentDiamondType => {
  return search({
    index: inventorySortToIndexMap.recentUpdate,
    filters: `statusCode:1 TO 2 AND ${recentDiamondType}`,
    hitsPerPage: 50,
  });
};

export function searchSimilarItems(category) {
  return search({
    index: inventorySortToIndexMap.recentUpdate,
    filters: `statusCode:1 TO 2 AND ${category}`,
    hitsPerPage: 50,
  });
}

export function searchBar(query, page) {
  return searchWithText({
    index: inventorySortToIndexMap.recentUpdate,
    query,
    page,
    hitsPerPage: 5,
  });
}

export const searchInventoryByRetailerId = ({
  retailerId,
  isPrivateRetailer = false,
  query = '',
  setting = '',
  jewelryType = '',
  gemstone = '',
  sortBy,
  page = 0,
  pageSize = 0,
}) => {
  let filters = `retailerId:${retailerId}`;

  const statusCodes = [
    inventoryUtils.statusCodes.AVAILABLE,
    inventoryUtils.statusCodes.RESERVED,
    inventoryUtils.statusCodes.HIDDEN,
  ];
  if (isPrivateRetailer) {
    statusCodes.push(inventoryUtils.statusCodes.PRIVATE);
  }
  filters = `${filters} AND (${statusCodes
    .map(code => `statusCode = ${code}`)
    .join(' OR ')})`;

  if (setting) {
    filters = `${filters} AND setting:${setting}`;
  }

  const jewelryTypeFilter = buildJewelryTypeClause(jewelryType);
  if (jewelryTypeFilter) {
    filters = `${filters} AND ${jewelryTypeFilter}`;
  }

  const gemstoneFilter = buildGemstoneClause(gemstone);
  if (gemstoneFilter) {
    filters = `${filters} AND ${gemstoneFilter}`;
  }

  return search({
    index: inventorySortToIndexMap[sortBy],
    filters,
    query,
    page,
    geo: {},
    hitsPerPage: pageSize,
  });
};

function normalizeShape(shape) {
  if (shape === 'round') {
    return 'round-brilliant';
  }
  return shape;
}

export function buildFilters(search) {
  let colorClause;
  if (search.get('fancyColorEnabled')) {
    colorClause = buildSelectionClause(
      tryIncludeEqualFancyColors(search.get('fancyColors')),
      fancyColors.slice(9),
      'color'
    );
  } else {
    colorClause = buildValueRangeClause(search.get('color'), colors, 'color');
  }
  const clauses = [
    buildSelectionClause(
      search.get('shapes').map(normalizeShape),
      shapes.slice(5),
      'shape'
    ),
    buildSelectionClause(search.get('certs'), certs.slice(5), 'certificate'),
    buildNumericalRangeClause(search.get('budget'), 'price'),
    buildNumericalRangeClause(search.get('carat'), 'carat'),
    buildValueRangeClause(search.get('cut'), cuts, 'cut'),
    colorClause,
    buildValueRangeClause(
      tryIncludeSi3(search.get('clarity')),
      clarities,
      'clarity'
    ),
    'statusCode:1 TO 2',
    buildJewelryTypeClause(search.get('jewelryType')),
    buildGemstoneClause(search.get('gemstone')),
    search.get('callForPriceEnabled') ? null : 'NOT hidePrice:true',
    search.get('buyOnlineOnlyEnabled') ? 'buy:true' : null,
  ];
  return clauses.filter(clause => clause).join(' AND ');
}

function buildSelectionClause(selection, others, type) {
  if (selection.size < 1) {
    return null;
  }
  return selection
    .reduce((result, item) => {
      if (item === 'other') {
        return result.concat(others);
      }
      return result.concat(item);
    }, [])
    .map(item => `${type}:${item}`)
    .join(' OR ');
}

function buildNumericalRangeClause(range, type) {
  if (range.length !== 2) {
    return null;
  }
  const [low, high] = range;
  if (high === Infinity) {
    return `${type}>${low}`;
  }
  if (low === high) {
    return `${type}=${low}`;
  }
  return `${type}:${low} TO ${high}`;
}

function buildValueRangeClause(range, fullRange, type) {
  if (range.length !== 2) {
    return null;
  }
  let [low, high] = range;
  if (low === 0 && high === Infinity) {
    return null;
  }
  if (low === high) {
    high++;
  }
  return fullRange
    .slice(low, high)
    .map(item => `${type}:${item}`)
    .join(' OR ');
}

function tryIncludeEqualFancyColors(colors) {
  if (colors.has('yellow')) {
    colors = colors.add('canary').add('green');
  }
  if (colors.has('brown')) {
    colors = colors.add('champagne').add('cognac');
  }
  return colors;
}

function tryIncludeSi3(clarity) {
  if (clarity.length !== 2) {
    return clarity;
  }
  // include SI3 if low is I1
  if (clarity[0] === 9) {
    clarity[0]--;
  }
  return clarity;
}

const buildJewelryTypeClause = jewelryType => {
  if (!jewelryType) {
    return null;
  }
  if (jewelryType === 'loose' || jewelryType === 'gemstone') {
    return `setting:${jewelryType}`;
  }
  return `jewelryType:${jewelryType}`;
};

const buildGemstoneClause = gemstone => {
  return gemstone && `gemstone:${gemstone}`;
};

export const clearCache = () => {
  inventoryRecentUpdate.clearCache();
};
