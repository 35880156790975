// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-common-header-HeaderButton__headerButton{padding:10px 20px;font-weight:300;border-width:1px}@media (max-width:768px){.projects-seller-app-components-common-header-HeaderButton__headerButton{padding:5px 15px}}", ""]);
// Exports
exports.locals = {
	"headerButton": "projects-seller-app-components-common-header-HeaderButton__headerButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["ghostButton"] + ""
};
module.exports = exports;
