import _ from 'lodash'
import request from 'fetch.jsonapi'
import constants from '../constants'

function buildUrl(url, params) {
  const qs = _.map(
    params,
    (v, k) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  ).join('&')
  if (qs) {
    return `${url}?${qs}`
  }
  return url
}

const loginUrl = `${constants.API_ROOT}/${constants.API_LOGIN_PATH}`
const retailerUrl = `${constants.API_ROOT}/${constants.API_RETAILER_PATH}`
const inventoryUrl = `${constants.API_ROOT}/${constants.API_INVENTORY_PATH}`
const bookingUrl = `${constants.API_ROOT}/${constants.API_BOOKING_PATH}`
const adsUrl = `${constants.API_ROOT}/${constants.API_ADS_PATH}`
const statsUrl = `${constants.API_ROOT}/${constants.API_STATS_PATH}`
const ordersUrl = `${constants.API_ROOT}/${constants.API_ORDERS_PATH}`

const defaultHeaders = {
  'content-type': 'application/vnd.api+json',
  accept: 'application/vnd.api+json'
}

let _token

export default {
  login(email, password) {
    return request.post(loginUrl, { username: email, password }, true)
  },
  register(data) {},
  setToken(token) {
    if (token !== _token) {
      _token = token
      if (_token) {
        request.headers(
          _.extend({}, defaultHeaders, {
            authorization: _token
          })
        )
      } else {
        request.headers(defaultHeaders)
      }
    }
    return this
  },
  verfityToken() {
    return Promise.resolve(true)
  },
  refreshToken() {
    return Promise.resolve(_token)
  },
  getRetailers(pageNumber) {
    return request.get(
      buildUrl(retailerUrl, {
        'page[number]': pageNumber
      })
    )
  },
  getRetailer(retailerId) {
    return request.get(`${retailerUrl}/${retailerId}`)
  },
  getRetailerByCustomUrl(customUrl) {
    return request.get(`${retailerUrl}/custom_url/${customUrl}`)
  },
  getDiamonds(pageNumber) {
    return request.get(
      buildUrl(inventoryUrl, {
        'page[number]': pageNumber
      })
    )
  },
  getDiamond(diamondId) {
    return request.get(`${inventoryUrl}/${diamondId}`)
  },
  createOrder(order) {
    return request.post(`${inventoryUrl}/buy`, order)
  },
  updateOrder(order) {
    return request.post(`${ordersUrl}/update_status/${order.id}`, order)
  },
  saveBooking(data) {
    return request.post(bookingUrl, data)
  },
  postPromotedItem(promoteItemUrl,data) {
    return request.post(promoteItemUrl,data)
  },
  getCuratedAds() {
    return request.get(adsUrl)
  },
  increaseRetailerProfileViewCount(retailerId) {
    return request.post(`${statsUrl}/profile/view/${retailerId}`, {})
  },
  increaseInventoryViewCount(diamondId) {
    return request.post(`${statsUrl}/inventory/view/${diamondId}`, {})
  },
  getRetailerProfileViewCount(retailerId) {
    return request.get(`${statsUrl}/profile/view/${retailerId}`)
  },
  authorizePrivateRetailerAccess(retailerId, retailerPassword) {
    return request
      .post(`${constants.API_ROOT}/auth_priv_retailer_access`, {
        retailerId,
        retailerPassword
      })
      .then(login => {
        this.setToken(login.get('token'))
        return login
      })
  },
  verifyPrivateRetailerAccessToken(token) {
    this.setToken(token)
    return request.get(`${retailerUrl}/verify_token`).catch(error => {
      this.setToken(constants.APP_TOKEN)
      throw error
    })
  }
}
