import React from 'react';

import Field from 'common/components/common/form/Field';
import ImagePicker from 'common/components/common/form/ImagePicker';
import SectionCard from 'common/components/common/SectionCard';
import { SubSectionTitle } from 'common/components/common/SubSection';

import styles from './Form.css';

const InventoryGalleryCard = ({ state, error, handleFieldChange, onImagesChange }) => {
  return (
    <SectionCard title="Item Gallery">
      <SubSectionTitle title="Images" />
      <p className={styles.sectionIntro}>
        Upload up to 5 images of the item. The individual size limit is 10MB an
        image.
      </p>
      <ImagePicker
        images={state.imageFiles}
        error={error.images}
        onChange={onImagesChange}
      />

      <SubSectionTitle className={styles.videoSectionTitle} title="Video" />
      <p className={styles.sectionIntro}>
        Enter a link to your YouTube video, and we will display it on your item's page.
      </p>
      <Field label="YouTube Video Url">
        <input type="text" value={state.youtubeUrl} onChange={handleFieldChange('youtubeUrl')} />
      </Field>
    </SectionCard>
  );
};

export default InventoryGalleryCard;
