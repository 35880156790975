import { Dispatch } from 'redux';

import { validateInventory as inventoryValidator } from 'common//utils/validators';
import { readFileAsDataUrl, splitDataUrl } from 'common/utils/file';
import { isNaOption } from 'common/utils/form';
import { deleteImage, uploadInventoryImages } from 'common/utils/image';
import { isImageUrl } from 'common/utils/imageUrl';
import { firstOption } from 'common/utils/misc';
import { currencies } from 'common/utils/retailer';
import { searchInventory } from 'common/utils/search';
import { setFullscreenLoaderMessage } from 'seller/app/actions/form';
import {
  deleteImageError,
  deleteImageRequest,
  deleteImageSuccess,
  uploadImageError,
  uploadImageRequest,
  uploadImageSuccess,
} from 'seller/app/actions/image';
import api from 'seller/app/services/api';
import { createNewInventory } from 'seller/app/utils/inventory';

export const FETCH_INVENTORY_LIST_REQUEST = 'FETCH_INVENTORY_LIST_REQUEST';
function fetchInventoryListRequest() {
  return { type: FETCH_INVENTORY_LIST_REQUEST };
}

export const FETCH_INVENTORY_LIST_SUCCESS = 'FETCH_INVENTORY_LIST_SUCCESS';
function fetchInventoryListSuccess(inventoryList, callback) {
  return { type: FETCH_INVENTORY_LIST_SUCCESS, inventoryList, callback };
}

export const FETCH_INVENTORY_LIST_ERROR = 'FETCH_INVENTORY_LIST_ERROR';
function fetchInventoryListError(error) {
  return { type: FETCH_INVENTORY_LIST_ERROR, error };
}

function fetchAllInventoryList(dispatch, getState, query, callback) {
  const pageNumber = query.start / query.length + 1;
  let inventoryList = getState().inventory.get('inventoryList');
  const currentQuery = inventoryList && inventoryList.getMeta('query');
  if (
    !inventoryList ||
    inventoryList.getPageNumber() !== pageNumber ||
    (currentQuery && currentQuery !== query.search.value)
  ) {
    inventoryList = api.getInventoryList(pageNumber);
  }
  return Promise.resolve(inventoryList).then(
    inventoryList => {
      dispatch(fetchInventoryListSuccess(inventoryList, callback));
      return inventoryList;
    },
    error => {
      dispatch(fetchInventoryListError(error));
      throw error;
    }
  );
}

function searchInventoryList(dispatch, getState, query, callback) {
  const currentUser = getState().login.get('currentUser');
  let retailerId;
  if (currentUser.type === 'retailer') {
    retailerId = currentUser.id;
  }
  return searchInventory(
    query.search.value,
    query.start / query.length,
    retailerId,
    query.setting,
    query.jewelryType
  ).then(
    inventoryList => {
      dispatch(fetchInventoryListSuccess(inventoryList, callback));
    },
    error => {
      dispatch(fetchInventoryListError(error));
    }
  );
}

export const fetchInventoryList = (query, callback) => (dispatch, getState) => {
  dispatch(fetchInventoryListRequest());
  if (query.search.value || query.setting || query.jewelryType) {
    return searchInventoryList(dispatch, getState, query, callback);
  }
  return fetchAllInventoryList(dispatch, getState, query, callback);
};

export const GET_INVENTORY_REQUEST = 'GET_INVENTORY_REQUEST';
const getInventoryRequest = () => ({ type: GET_INVENTORY_REQUEST });

export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
const getInventorySuccess = (inventory) => ({ type: GET_INVENTORY_SUCCESS, inventory });

export const GET_INVENTORY_ERROR = 'GET_INVENTORY_ERROR';
const getInventoryError = (error: Error) => ({ type: GET_INVENTORY_ERROR, error });

export const getInventory = (inventoryId: string) => (dispatch: Dispatch, getState: () => Record<string, any>) => {
  dispatch(getInventoryRequest());
  let inventory;
  if (!inventoryId) {
    inventory = Promise.resolve(createNewInventory());
  } else {
    const { inventoryList } = getState().inventory;
    inventory = inventoryList && inventoryList.get(inventoryId);
    if (!inventory || inventory.source === 'search') {
      inventory = api.getInventory(inventoryId);
    }
  }
  return Promise.resolve(inventory).then(
    inventory => {
      dispatch(getInventorySuccess(inventory));
    },
    error => {
      dispatch(getInventoryError(error));
    }
  );
};

export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const createInventory = () => ({ type: CREATE_INVENTORY });

export const SAVE_INVENTORY_REQUEST = 'SAVE_INVENTORY_REQUEST';
const saveInventoryRequest = () => ({ type: SAVE_INVENTORY_REQUEST });

export const SAVE_INVENTORY_SUCCESS = 'SAVE_INVENTORY_SUCCESS';
const saveInventorySuccess = inventory => ({
  type: SAVE_INVENTORY_SUCCESS,
  inventory,
});

export const SAVE_INVENTORY_ERROR = 'SAVE_INVENTORY_ERROR';
const saveInventoryError = error => ({ type: SAVE_INVENTORY_ERROR, error });

export const saveInventory = (data, images) => (dispatch, getState) => {
  const state = getState();
  const { currentUser } = state.login;
  const { retailerId, currency } = data.attributes;
  if (!retailerId && currentUser.type === 'retailer') {
    data.attributes.retailerId = currentUser.id;
    data.attributes.retailerName = currentUser.get('name');
  }
  if (!currency) {
    data.attributes.currency = firstOption(currencies);
  }
  const removedImages = (images || []).filter(({ removed }) => removed);
  images = (images || []).filter(({ removed }) => !removed);
  const { valid, error } = dispatch(validateInventory(data.attributes, images));
  if (!valid) {
    return Promise.reject(error);
  }
  if (isNaOption(`${data.attributes.carat}`)) {
    data.attributes.carat = -1;
  }

  // delete removed images
  if (data.id) {
    // tslint:disable-next-line:no-console
    api.deleteInventoryImage(data.id, removedImages).catch(error => console.log(error));
    removedImages.forEach(image => {
      // tslint:disable-next-line:no-console
      deleteImage(image.url).catch(error => console.log(error));
    });
  }

  dispatch(saveInventoryRequest());

  const progress = (images, results) => {
    if (!images || images.length < 1) {
      dispatch(
        setFullscreenLoaderMessage(
          'Please wait while we are saving the data...'
        )
      );
      return;
    }
    const uploaded = (results || []).length + 1;
    const total = images.length;
    const msg = `Uploading gallery images... (${uploaded}/${total})`;
    dispatch(setFullscreenLoaderMessage(msg));
  };

  progress(images);

  return uploadInventoryImages(images, null, progress.bind(null, images))
    .then(images => {
      progress();
      data.attributes.imageUrls = images
        .map(({ url }) => url)
        .filter(url => url);
      data.attributes.firstImagePath = images[0].url;
      return data.id
        ? api.updateInventory(data.id, data)
        : api.addInventory(data);
    })
    .then(inventory => {
      dispatch(saveInventorySuccess(inventory));
      return inventory;
    })
    .catch(error => {
      // XXX: Error 504 is gateway timeout error from cloudflare, we
      // don't know the real status of our backend, so we consider
      // this as success.
      if (error.status === 504) {
        dispatch(saveInventorySuccess());
        return;
      }

      dispatch(saveInventoryError(error));
      throw error;
    });
};

export const SET_INVENTORY_ERROR = 'SET_INVENTORY_ERROR';
const setInventoryError = error => ({ type: SET_INVENTORY_ERROR, error });

export const validateInventory = (data, images) => dispatch => {
  const result = inventoryValidator(data, images);
  if (result.valid) {
    dispatch(setInventoryError({}));
  } else {
    result.error.type = 'validation';
    dispatch(setInventoryError(result.error));
  }
  return result;
};

export const DELETE_INVENTORY_REQUEST = 'DELETE_INVENTORY_REQUEST';
const deleteInventoryRequest = () => ({ type: DELETE_INVENTORY_REQUEST });

export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS';
const deleteInventorySuccess = () => ({ type: DELETE_INVENTORY_SUCCESS });

export const DELETE_INVENTORY_ERROR = 'DELETE_INVENTORY_ERROR';
const deleteInventoryError = (error) => ({ type: DELETE_INVENTORY_ERROR, error });

export const deleteInventory = (inventory: Gemsby.IJSONAPIEntity) => (dispatch: Dispatch) => {
  dispatch(deleteInventoryRequest());
  return api.deleteInventory(inventory.id).then(
    () => {
      dispatch(deleteInventorySuccess());
    },
    error => {
      dispatch(deleteInventoryError(error));
      throw error;
    }
  );
};

export const DELETE_INVENTORY_IMAGE_SUCCESS = 'DELETE_INVENTORY_IMAGE_SUCCESS';
function deleteInventoryImageSuccess(image) {
  return { type: DELETE_INVENTORY_IMAGE_SUCCESS, image };
}

export function deleteInventoryImage(retailer, image) {
  return dispatch => {
    dispatch(deleteImageRequest());
    return api.deleteInventoryImage(retailer.id, image).then(
      () => {
        dispatch(deleteImageSuccess());
        dispatch(deleteInventoryImageSuccess(image));
      },
      error => {
        dispatch(deleteImageError(error));
        throw error;
      }
    );
  };
}

export const CSV_UPLOAD_REQUEST = 'CSV_UPLOAD_REQUEST';
function csvUploadRequest() {
  return { type: CSV_UPLOAD_REQUEST };
}

export const CSV_UPLOAD_SUCCESS = 'CSV_UPLOAD_SUCCESS';
function csvUploadSuccess() {
  return { type: CSV_UPLOAD_SUCCESS };
}

export const CSV_UPLOAD_ERROR = 'CSV_UPLOAD_ERROR';
function csvUploadError(error) {
  return { type: CSV_UPLOAD_ERROR, error };
}

export function csvUpload({ file, retailerId }) {
  return (dispatch, getState) => {
    const currentUser = getState().login.get('currentUser');
    if (!retailerId && currentUser.type === 'retailer') {
      retailerId = currentUser.id;
    }
    dispatch(csvUploadRequest());
    return readFileAsDataUrl(file).then(dataUrl => {
      const { data } = splitDataUrl(dataUrl);
      return api
        .csvUpload(retailerId, {
          mimeType: 'text/csv',
          fileContent: data,
        })
        .then(
          () => {
            dispatch(csvUploadSuccess());
          },
          error => {
            dispatch(csvUploadError(error));
          }
        );
    });
  };
}

export const CLEAR_INVENTORYLIST = 'CLEAR_INVENTORY_LIST';
export const clearInventoryList = () => ({ type: CLEAR_INVENTORY_LIST });

export const CLEAR_SELECTED_INVENTORY = 'CLEAR_SELECTED_INVENTORY';
export const clearSelectedInventory = () => ({
  type: CLEAR_SELECTED_INVENTORY,
});
