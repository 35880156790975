// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-promote-priceCards-PriceCards__priceCards{display:flex;justify-content:space-evenly;margin-top:5rem;flex-grow:1;flex-wrap:wrap}@media (max-width:992px){.projects-seller-app-components-inventory-promote-priceCards-PriceCards__priceCards{flex-direction:column;align-items:center}}", ""]);
// Exports
exports.locals = {
	"priceCards": "projects-seller-app-components-inventory-promote-priceCards-PriceCards__priceCards"
};
module.exports = exports;
