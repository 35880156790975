import _ from 'lodash';

import { isNaOption } from 'common/utils/form';
import * as inventory from 'common/utils/inventory';
import sprintf from 'common/utils/sprintf';

const currencySymbolMap = {
  cad: '$',
  usd: '$',
  gbp: '£'
};

export const NA = 'N/A';

const formatNA = nextFormatter => (value, ...rest) => {
  if (!value || isNaOption(value)) {
    return NA;
  }
  return nextFormatter.apply(null, [value, ...rest]);
};

export const formatSetting = (setting: inventory.Settings) => inventory.settings[setting];

export const formatCut = formatNA((cut: string, shortText = false) => {
  cut = shortText ? inventory.cutsShort[cut] : inventory.cuts[cut];
  return cut || NA;
})

export const formatCarat = (carat: number, shortText = false) => {
  if (carat <= 0) {
    return NA;
  }
  carat = parseFloat(carat);
  if (shortText && carat > 10) {
    return '10+';
  }
  if (Math.ceil(carat) === Math.floor(carat)) {
    return carat;
  }
  return sprintf('%.2f', carat);
}

export const formatColor = formatNA((color: string): string =>
  inventory.colors[inventory.ColorTypes.REGULAR][color] ||
  inventory.colors[inventory.ColorTypes.FANCY][color]);

export const formatClarity = formatNA((clarity: string, limit: number = 0): string => {
  clarity = inventory.clarities[clarity];
  return limit > 0 ? clarity.substring(0, limit - 1) : clarity;
});

export const formatCurrencySymbol = (currency = 'cad'): string =>
  currencySymbolMap[currency] || '$';

export const formatPrice = (price: number, currency = 'cad'): string => {
  const sym = formatCurrencySymbol(currency);
  price = parseFloat(price);
  if (_.isInteger(price)) {
    return sym + price;
  }
  if (Math.ceil(price) === Math.floor(price)) {
    return sym + price;
  }
  return sym + sprintf('%.2f', price);
};

export const formatDistance = (distance: number): string => {
  if (distance >= 1000) {
    return `${Math.floor(distance / 1000)} KM`;
  }
  return `${Math.floor(distance)} M`;
}

export const formatProportions = (length: number, width: number, depth: number): string => {
  if (length && width && depth) {
    return `${length} x ${width} x ${depth}`;
  }
  return null;
};

export const formatBrand = formatNA(brand => brand.toUpperCase());
export const formatCert = formatNA(cert => inventory.certificates[cert]);
export const formatEnhancement = formatNA(enhancement => inventory.enhancements[enhancement]);
export const formatFluorescence = formatNA(fluorescence => inventory.fluorescences[fluorescence]);
export const formatGemstone = formatNA(gemstone => inventory.gemstoneTypes[gemstone]);
export const formatHue = formatNA(hue => inventory.hues[hue]);
export const formatJewelryCondition = formatNA(condition => inventory.jewelryConditions[condition]);
export const formatJewelryType = formatNA(type => inventory.jewelryTypes[type]);
export const formatMetalPurity = formatNA(purity => inventory.metalPurities[purity]);
export const formatMetalType = formatNA(type => inventory.metalTypes[type]);
export const formatMetalWeight = formatNA(weight => weight ? `${weight}g` : NA);
export const formatPolish = formatNA(polish => inventory.polishes[polish]);
export const formatShape = formatNA(shape => inventory.shapes[shape]);
export const formatSymmetry = formatNA(symmetry => inventory.symmetries[symmetry]);

export default {
  brand: formatBrand,
  carat: formatCarat,
  cert: formatCert,
  clarity: formatClarity,
  color: formatColor,
  currencySymbol: formatCurrencySymbol,
  cut: formatCut,
  distance: formatDistance,
  enhancement: formatEnhancement,
  fluorescence: formatFluorescence,
  gemstone: formatGemstone,
  hue: formatHue,
  jewelryCondition: formatJewelryCondition,
  jewelryType: formatJewelryType,
  metalPurity: formatMetalPurity,
  metalType: formatMetalType,
  metalWeight: formatMetalWeight,
  polish: formatPolish,
  price: formatPrice,
  proportions: formatProportions,
  shape: formatShape,
  symmetry: formatSymmetry,
  setting: formatSetting,
};
