import produce from 'immer';
import {
  SET_CHAT_ROOM,
  GET_ALL_MESSAGES,
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
} from 'seller/app/actions/chat';

const initialState = {
  chatRoom: [],
  messages: [],
  notifications: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_CHAT_ROOM:
        draft.chatRoom = action.payload;
        break;
      case GET_ALL_MESSAGES:
        draft.messages = action.payload;
        break;
      case SET_NOTIFICATIONS:
        draft.notifications = action.payload;
        break;
      case CLEAR_NOTIFICATIONS:
        draft.notifications = [];
        break;
    }
  });
