import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './Form.css';

class SelectFileButton extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }
  render() {
    const {
      className,
      text,
      accept,
      disabled
    } = this.props;
    return (
      <label className={classNames(
        !disabled && styles.selectFileButton,
        disabled && styles.selectFileButtonDisabled,
        className
      )}>
        <input type="file"
          ref={this.fileInput}
          accept={accept}
          disabled={disabled}
          onChange={this.handleFileSelect}
        />
        {text}
      </label>
    );
  }
  handleFileSelect = (event) => {
    event.preventDefault();
    this.props.onSelect(this.fileInput.current.files[0]);
    this.fileInput.current.value = '';
  }
}

export default SelectFileButton;
