import _ from 'lodash';

import { firstOption, parseFormNumberField } from 'common/utils/misc';
import statusCode from 'common/utils/statusCode';
import { MARKETPLACE_URL } from 'common/constants';

export const { statusCodes, statusCodeToText } = statusCode.retailer;

export const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const marshalStoreHours = data => {
  weekdays.forEach(day => {
    const dayShort = day.substring(0, 3);
    const field = `${dayShort}Hours`;
    const openHour = data[`${day}OpenHour`] || '12';
    const openMinute = data[`${day}OpenMinute`] || '00';
    const openAm = data[`${day}OpenAm`] || 'AM';
    const closeHour = data[`${day}CloseHour`] || '12';
    const closeMinute = data[`${day}CloseMinute`] || '00';
    const closeAm = data[`${day}CloseAm`] || 'AM';
    data[
      field
    ] = `${openHour},${openMinute},${openAm},${closeHour},${closeMinute},${closeAm}`;
  });
};

export function unmarshalStoreHours(data) {
  weekdays.forEach(day => {
    const dayShort = day.substring(0, 3);
    const fields = (data[`${dayShort}Hours`] || '').split(',');
    const [
      openHour = '12',
      openMinute = '00',
      openAm = 'AM',
      closeHour = '12',
      closeMinute = '00',
      closeAm = 'PM',
    ] = fields.length === 6 ? fields : [];
    data[`${day}OpenHour`] = openHour;
    data[`${day}OpenMinute`] = openMinute;
    data[`${day}OpenAm`] = openAm;
    data[`${day}CloseHour`] = closeHour;
    data[`${day}CloseMinute`] = closeMinute;
    data[`${day}CloseAm`] = closeAm;
  });
}

export const isStandardRetailer = retailer => {
  const type = _.isFunction(retailer.get)
    ? retailer.get('retailerType')
    : retailer.attributes.retailerType;
  return type === '' || type === 'standard';
};

export const isPrivateRetailer = retailer =>
  retailer.get('statusCode') === statusCodes.PRIVATE;

export const isDeletedRetailer = retailer =>
  retailer.get('statusCode') === statusCodes.DELETED;

export enum RepTitles {
  JEWELER = 'jeweler',
  JEWELRY_DESIGNER = 'jewelryDesigner',
  JEWELRY_EXPERT = 'jewelryExpert',
  GEMSTONE_VENDOR = 'gemstoneVendor',
}

export const repTitles = {
  [RepTitles.JEWELER]: 'Your Jeweler',
  [RepTitles.JEWELRY_DESIGNER]: 'Your Jewelry Designer',
  [RepTitles.JEWELRY_EXPERT]: 'Your Jewelry Expert',
  [RepTitles.GEMSTONE_VENDOR]: 'Gemstone Vendor',
};

export const services = {
  ringSetting: 'Ring Setting',
  customJewelry: 'Custom Jewelry',
  jewelryRepair: 'Jewelry Repair',
  appraisals: 'Appraisals',
  insurance: 'Insurance',
  consultation: 'Consultation',
  brandRings: 'Brand Rings',
  purchasing: 'Purchasing',
};

export enum Currencies {
  USD = 'usd',
  CAD = 'cad',
  GBP = 'gbp',
}

export const currencies = {
  [Currencies.USD]: 'USD (US Dollar)',
  [Currencies.CAD]: 'CAD (Canadian Dollar)',
  [Currencies.GBP]: 'GBP (British Pound)',
};

export enum ShippingDestinations {
  LOCAL = 'local',
  INTERNATIONAL = 'international',
}

export const shippingDestinations = {
  [ShippingDestinations.LOCAL]: 'Local Shipping Only (Country)',
  [ShippingDestinations.INTERNATIONAL]: 'International Shipping',
};

export enum ReturnPolicies {
  RETURN_ACCEPTED = 'return-accepted',
  NO_RETURNS = 'no-returns',
}

export const returnPolicies = {
  [ReturnPolicies.RETURN_ACCEPTED]:
    'Returns Accepted (full refund with item in good condition)',
  [ReturnPolicies.NO_RETURNS]: 'No Returns/Refunds',
};

const passwordFields = {
  oldPassword: '',
  password: '',
  verifyPassword: '',
};

const accountFields = {
  currency: firstOption(currencies),
  taxRate: '',
  shippingDestination: firstOption(shippingDestinations),
  shippingFee: '',
  shippingTime: '',
  returnPolicy: firstOption(returnPolicies),
  returnPeriod: '',
  returnPostageRefunded: false,
  ...passwordFields,
};

export const getRetailerAccountData = (retailer: Gemsby.IJSONAPIEntity) => {
  const retailerData = retailer.toJSON().attributes;
  return Object.entries(accountFields).reduce(
    (result, [key, defaultValue]) =>
      Object.assign(result, {
        [key]: retailerData[key] || defaultValue,
      }),
    {}
  );
};

export const prepareRetailerAccountData = data => {
  data = Object.keys(accountFields).reduce(
    (result, key) => {
      const value = data[key];
      result[key] = _.isString(value) ? value.trim() : value;
      return result;
    },
    {} as Record<string, any>
  );
  Object.assign(data, {
    taxRate: parseFormNumberField(data.taxRate),
    shippingFee: parseFormNumberField(data.shippingFee),
    shippingTime: parseFormNumberField(data.shippingTime),
    returnPeriod:
      data.returnPolicy === ReturnPolicies.RETURN_ACCEPTED
        ? parseFormNumberField(data.returnPeriod)
        : 0,
  });
  Object.keys(passwordFields).forEach(field => {
    if (!data[field]) {
      delete data[field];
    }
  });
  return data;
};

export const getMarketplaceUrl = (retailer: Gemsby.IJSONAPIEntity) =>
  `${MARKETPLACE_URL}/store/${retailer.id}`;
