// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-Button__button{padding:12px 30px;font-family:Montserrat;font-size:14px;font-weight:400;text-transform:uppercase;background-color:#e8e8e8;color:#fff;border:none;border-radius:6px;outline:none;cursor:pointer}.projects-common-components-common-Button__primaryButton{background-color:var(--mainColor);border-radius:6px;box-shadow:0 3px 0 0 #0da07a}.projects-common-components-common-Button__plainButton{color:var(--textColor)}.projects-common-components-common-Button__plainPrimaryButton{background-color:var(--mainColor)}.projects-common-components-common-Button__ghostButton{color:var(--mainColor);background:none;border-radius:6px;border:2px solid var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"button": "projects-common-components-common-Button__button",
	"primaryButton": "projects-common-components-common-Button__primaryButton projects-common-components-common-Button__button",
	"plainButton": "projects-common-components-common-Button__plainButton projects-common-components-common-Button__button",
	"plainPrimaryButton": "projects-common-components-common-Button__plainPrimaryButton projects-common-components-common-Button__button",
	"ghostButton": "projects-common-components-common-Button__ghostButton projects-common-components-common-Button__button"
};
module.exports = exports;
