import React from 'react';
import styles from './Modal.css';

const Modal = props => {
  const onClick = e => {
    e.persist();
    if (e.target.className.includes('modalOverlay')) {
      props.onClose();
    } else if (e.target.className.includes(props.overlayClass)) {
      props.onClose();
    }
  };

  return (
    <>
      {props.open && (
        <div
          className={
            props.overlayClass ? props.overlayClass : styles.modalOverlay
          }
          onClick={e => onClick(e)}
        >
          <div className={props.modalClass ? props.modalClass : styles.modal}>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
