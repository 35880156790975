import React from 'react';

import styles from './Toggle.css';

const Toggle = ({ checked, onChange }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onChange({
      target: {
        type: 'checkbox',
        checked: !checked
      }
    });
  };
  
  return (
    <label className={`toggle ${styles.toggleContainer}`}>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <div className={styles.toggle} onClick={handleClick}>
        <i className={styles.slider} />
      </div>
    </label>
  );
};

export default Toggle;
