import React from 'react';
import { connect } from 'react-redux';

import Card from 'common/components/common/Card';
import Well from 'common/components/common/Well';
import OutLink from 'common/components/common/OutLink';
import { MARKETPLACE_URL } from 'common/constants';
import { login, loginError } from 'seller/app/actions/login';
import SignInForm from 'seller/app/components/landing/SignInForm';

import styles from './Landing.css';

const SignIn = ({ inProgress, error, login, loginError, goToDashboard }) => {
  const doLogin = (data: any) => {
    login(data).then(
      goToDashboard,
      (error: any) => {
        if (error.status === 401) {
          loginError({
            loginErrorMessage: 'Wrong email/password, please try again.',
          });
        }
      }
    );
  };
  return (
    <div className={styles.login}>
      <Card className={styles.loginFormContainer}>
        <h3>Sign In</h3>
        <Well className={styles.loginInfo}>
          Access your store and items listed on the{' '}
          <OutLink to={MARKETPLACE_URL}>Gemsby marketplace</OutLink>.
        </Well>
        {error.loginErrorMessage && (
          <div className={styles.loginErrorMsg}>{error.loginErrorMessage}</div>
        )}
        <SignInForm inProgress={inProgress} error={error} onSubmit={doLogin} />
      </Card>
    </div>
  );
};

const mapState = ({ login }) => ({
  inProgress: login.inProgress,
  error: login.error || {},
});

const mapDispatch = dispatch => ({
  login({ email, password, saveLogin }) {
    return dispatch(login(email, password, saveLogin));
  },
  loginError(error) {
    return dispatch(loginError(error));
  },
});

export default connect(
  mapState,
  mapDispatch
)(SignIn);
