import React from 'react';
import styles from './Form.css';

const Sent = ({ userEmail, message, images }) => {
  return (
    <div>
      <p className={styles.sent}>{userEmail}</p>
      <p className={styles.message}>{message}</p>
      {!!images.length &&
        images.map(item => {
          return (
            <div className={styles.imageContainer}>
              <img src={item} width="100%" />
            </div>
          );
        })}
    </div>
  );
};

export default Sent;
