import { Dispatch } from 'redux';

import styles from 'common/components/common/Dialog.css';

export enum DialogAction {
  SHOW_DIALOG = 'SHOW_DIALOG',
  HIDE_DIALOG = 'HIDE_DIALOG',
}

export const showDialog = (
  message: string,
  buttons: Gemsby.IDialogButton[]
) => ({
  type: DialogAction.SHOW_DIALOG,
  message,
  buttons,
});

export const hideDialog = () => ({ type: DialogAction.HIDE_DIALOG });

const handleButtonClick = (
  dispatch: Dispatch,
  callback: React.MouseEventHandler
) => (event: React.MouseEvent) => {
  event.preventDefault();
  dispatch(hideDialog());
  callback(event);
};

export const showInfo = (message: string) => (dispatch: Dispatch) =>
  new Promise(resolve => {
    showDialog(message, [
      {
        className: styles.okButton,
        label: 'OK',
        onClick: handleButtonClick(dispatch, resolve),
      },
    ]);
  });

export const showPrompt = (message: string) => (dispatch: Dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      showDialog(message, [
        {
          label: 'Cancel',
          onClick: handleButtonClick(dispatch, reject),
        },
        {
          className: styles.okButton,
          label: 'OK',
          onClick: handleButtonClick(dispatch, resolve),
        },
      ])
    );
  });
