import classNames from 'classnames';
import React from 'react';

import styles from './Card.css';

const Card: React.FC<Gemsby.ISimpleWrapperProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames(styles.card, className)} {...props}>
    {children}
  </div>
);

export default Card;
