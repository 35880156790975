import React from 'react';

import styles from './Page.css';

const Page = ({ className = styles.page, children }) => (
  <div className={className}>
    {children}
  </div>
);

export default Page;
