// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-promote-priceCards-PriceCard__priceCard{max-width:15.5rem;width:100%;padding:3rem 1rem 1rem;border-radius:30px;background:#fff;margin:1rem;display:grid;grid-template-rows:auto auto 1fr auto}@media (max-width:480px){.projects-seller-app-components-inventory-promote-priceCards-PriceCard__priceCard{border:.5px solid #c4c4c4;margin-bottom:2rem}}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardHeader{display:flex;justify-content:space-between;align-items:center}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardTitle{font-family:Montserrat,Arial,sans-serif;font-size:16px;font-weight:700}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__pricePerWeek{display:flex;justify-content:center;align-items:center}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__price{font-family:Montserrat,Arial,sans-serif;color:var(--mainColor);font-weight:700;font-size:20px}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__perWeek{font-size:16px;margin-left:.3rem}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardContent{margin-top:2rem}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__textWithTickContainer{margin-top:1rem}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardFooter{padding:1rem;margin-top:2rem;text-align:center}.projects-seller-app-components-inventory-promote-priceCards-PriceCard__footerButton{}", ""]);
// Exports
exports.locals = {
	"priceCard": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__priceCard",
	"cardHeader": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardHeader",
	"cardTitle": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardTitle",
	"pricePerWeek": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__pricePerWeek",
	"price": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__price",
	"perWeek": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__perWeek",
	"cardContent": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardContent",
	"textWithTickContainer": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__textWithTickContainer",
	"cardFooter": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__cardFooter",
	"footerButton": "projects-seller-app-components-inventory-promote-priceCards-PriceCard__footerButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + ""
};
module.exports = exports;
