import classNames from 'classnames';
import React, { Component } from 'react';

import device from 'common/services/device';

import styles from './PageContent.css';

const setHeight = (heightReducedBy, node) => {
  if (!node || !heightReducedBy) {
    return;
  }
  let padding = 0;
  if (device.isIos()) {
    padding = 15;
    if (device.hasNotch()) {
      padding = 64;
    }
  }
  const height = document.body.offsetHeight - heightReducedBy - padding;
  node.style.height = `${height}px`;
};

const setScrollTop = (scrollTop, node) => {
  if (!node || !scrollTop) {
    return;
  }
  node.scrollTop = scrollTop;
};

interface IPageContentProps extends Gemsby.ISimpleWrapperProps {
  heightReducedBy?: number;
  initialScrollTop?: number;
  onRef?: (node: HTMLElement) => void;
  onScroll?: React.EventHandler<React.UIEvent<HTMLElement>>;
}

class PageContent extends Component<IPageContentProps> {
  private initialized: boolean;
  private node: HTMLElement;

  constructor(props: IPageContentProps) {
    super(props);
    this.initialized = false;
    this.node = null;
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize, false);
  }
  public render() {
    const {
      className,
      heightReducedBy = 0,
      initialScrollTop = 0,
      onRef = () => {},
      onScroll = () => {},
      children
    } = this.props;
    return (
      <section
        className={classNames(styles.pageContent, className)}
        onScroll={onScroll}
        ref={node => {
          this.node = node
          onRef(node)
          if (!node || this.initialized) {
            return
          }
          setHeight(heightReducedBy, node)
          setScrollTop(initialScrollTop, node)
          this.initialized = true
          window.addEventListener('resize', this.handleWindowResize, false)
        }}
      >
        {children}
      </section>
    );
  }
  handleWindowResize = () => {
    if (!this.node) {
      return;
    }
    setHeight(this.props.heightReducedBy, this.node);
  };
}

export default PageContent;
