import { db } from 'seller/app/services/firebase';

export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const createChatRoom = ({ orderId, consumerEmail, retailerEmail,retailerId }) => {
  return dispatch => {
    let chatExist = false;
    db.collection('chatrooms')
      .where('orderId', '==', orderId)
      .get()
      .then(snap => {
        snap.forEach(item => {
          chatExist = { data: item.data(), roomId: item.id };
        });
        if (!chatExist) {
          const obj = {
            createdAt: Date.now(),
            orderId: orderId,
            retailerId,
            users: {
              consumerEmail,
              retailerEmail,
            },
          };

          db.collection('chatrooms')
            .add(obj)
            .then(snap => {
              dispatch({
                type: SET_CHAT_ROOM,
                payload: { data: obj, roomId: snap.id },
              });
            });
        } else {
          dispatch({ type: SET_CHAT_ROOM, payload: chatExist });
        }
      })
      .catch(e => {
        console.log(e.message);
      });
  };
};

export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const getAllMessages = roomId => {
  return dispatch => {
    db.collection('chatrooms')
      .doc(roomId)
      .collection('messages')
      .orderBy('timestamp')
      .onSnapshot(snap => {
        const messages = [];
        snap.forEach(item => {
          messages.push({ data: item.data() });
        });
        dispatch({ type: GET_ALL_MESSAGES, payload: messages });
      });
  };
};

const getDocId = email => {
  let id = '';
  return new Promise((resolve, reject) => {
    db.collection('notifications')
      .where('email', '==', email)
      .get()
      .then(doc => {
        doc.forEach(item => {
          id = item.id;
        });
        resolve(id);
      })
      .catch(e => reject({ message: e.message }));
  });
};

let unsubscribe;
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const getNotifications = email => {
  return async dispatch => {
    try {
      const id = await getDocId(email);
      if (id) {
        unsubscribe = db
          .collection('notifications')
          .doc(id)
          .collection('orderId')
          .onSnapshot(doc => {
            const docs = [];
            doc.forEach(item => {
              docs.push({ ...item.data() });
            });
            const orders = docs.map(item => {
              for (let key in item) {
                return key;
              }
            });
            const data = { data: orders, id };
            dispatch({ type: SET_NOTIFICATIONS, payload: data });
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const unsubsribeNotifications = () => {
  return dispatch => {
    unsubscribe();
    dispatch({ type: CLEAR_NOTIFICATIONS });
  };
};
