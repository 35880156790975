import React from 'react';

import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SectionCard, {
  SectionIntro,
} from 'common/components/common/SectionCard';
import { firstOption } from 'common/utils/misc';
import { currencies } from 'common/utils/retailer';

const CurrencyCard = ({ state: { currency }, handleFieldChange }) => {
  return (
    <SectionCard title="Currency">
      <SectionIntro>
        Select the currency you use for all of your item prices, along with
        account settings such as your shipping fee.
      </SectionIntro>
      <RadioGroupField
        label="Currency"
        radioGroupName="currencies"
        options={currencies}
        selectedOption={currency || firstOption(currencies)}
        onChange={handleFieldChange('currency')}
      />
    </SectionCard>
  );
};

export default CurrencyCard;
