// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-order-form-TrackingDetails__trackingDetails{margin-bottom:20px;font-size:12px}.projects-seller-app-components-order-form-TrackingDetails__trackingDetails h4{font-size:12px}.projects-seller-app-components-order-form-TrackingDetails__trackingDetailsForm{}.projects-seller-app-components-order-form-TrackingDetails__trackingDetailsForm h4{color:#b10000}.projects-seller-app-components-order-form-TrackingDetails__button{}@media (max-width:768px){.projects-seller-app-components-order-form-TrackingDetails__button{width:100%}}", ""]);
// Exports
exports.locals = {
	"trackingDetails": "projects-seller-app-components-order-form-TrackingDetails__trackingDetails",
	"trackingDetailsForm": "projects-seller-app-components-order-form-TrackingDetails__trackingDetailsForm projects-seller-app-components-order-form-TrackingDetails__trackingDetails",
	"button": "projects-seller-app-components-order-form-TrackingDetails__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + ""
};
module.exports = exports;
