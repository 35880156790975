import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import logoTextImage from 'common/assets/images/logo-gemsby-text.png';
import logoImage from 'common/assets/images/logo-gemsby.png';
import styles from './Header.css';

interface IHeaderProps extends Gemsby.ISimpleWrapperProps {
  isBigScreen: boolean;
}

const Header: React.FC<IHeaderProps> = ({ className, isBigScreen, children }) => (
  <div className={classNames(styles.header, className)}>
    <Link to="/">
      <div className={styles.branding}>
        <div className={styles.logo}>
          <img src={isBigScreen ? logoTextImage : logoImage} alt="logo" />
        </div>
        <div className={styles.title}>
          <h1>Seller</h1>
        </div>
      </div>
    </Link>
    <div className={styles.nav}>
      {children}
    </div>
  </div>
);

const mapState = ({ device }) => ({
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(Header);
