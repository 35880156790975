import _ from 'lodash';
import React from 'react';

import styles from './Form.css';

const FormErrorMessage = React.forwardRef((props, ref) => {
  const { error, errorListClassName } = props;
  const { errors } = error || {};

  if (!error || _.isEmpty(error)) {
    return null;
  }

  const errorResponse = errors ? (
    <ul className={errorListClassName}>
      {errors.map(msg => (
        <li key={msg}>{msg}</li>
      ))}
    </ul>
  ) : null;

  return (
    <div className={styles.formErrorMessage} ref={ref}>
      {error.type === 'validation' ? (
        <span>Please correct the invalid field(s) and submit again.</span>
      ) : (
        <div>
          Something went wrong, please try again.
          {errorResponse}
        </div>
      )}
    </div>
  );
});

export default FormErrorMessage;
