// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-SubSection__title{color:var(--mainColor);font-size:14px;font-weight:400;margin-bottom:15px;text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"title": "projects-common-components-common-SubSection__title"
};
module.exports = exports;
