import produce from 'immer';

import {
  SAVE_RETAILER_ERROR,
  SAVE_RETAILER_REQUEST,
  SAVE_RETAILER_SUCCESS,
  SET_RETAILER_ERROR,
} from 'seller/app/actions/retailer';

const initialState = {
  inProgress: false,
  error: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SAVE_RETAILER_REQUEST:
        draft.inProgress = true;
        draft.error = {};
        break;
      case SAVE_RETAILER_SUCCESS:
        draft.inProgress = false;
        draft.error = {};
        break;
      case SAVE_RETAILER_ERROR:
        draft.inProgress = false;
        draft.error = action.error;
        break;
      case SET_RETAILER_ERROR:
        draft.error = action.error;
        break;
    }
  });
