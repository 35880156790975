import classNames from 'classnames';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { toggleModal } from 'seller/app/actions/modal';
import { Routes } from 'seller/app/constants';
import auth from 'seller/app/services/auth';
import Modal from './Modal';

import styles from './MobileModal.css';

interface IMobileModalView {
  history: History;
  open: boolean;
  store: string;
  handleToggle: () => void;
}

const MobileModalView = ({
  history,
  open,
  store,
  handleToggle,
}: IMobileModalView) => (
  <Modal
    open={open}
    onClose={handleToggle}
    overlayClass={styles.overlay}
    modalClass={styles.customModal}
  >
    <p className={classNames(styles.item, styles.borderBottom)}>
      <Link to={Routes.account} onClick={handleToggle}>
        Account
      </Link>
    </p>
    <p className={styles.item} onClick={(event) => {
      event.preventDefault();
      auth.logout().then(() => {
        handleToggle();
        history.push('/')
      });
    }}>
      Logout ({store})
    </p>
  </Modal>
);

interface IMobileModal {
  history: History;
  user: any;
  modalData: boolean;
  toggleModal: (open: boolean) => void;
}

const MobileModal = ({
  history,
  user,
  modalData,
  toggleModal
}: IMobileModal) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open !== modalData) {
      setOpen(modalData);
    }
  });

  const handleToggle = () => {
    toggleModal(!open);
  };

  return <MobileModalView
    history={history}
    open={open}
    store={user.get('name')}
    handleToggle={handleToggle}
  />;
};

const mapStateToProps = ({ login, modal }) => ({
  user: login.currentUser,
  modalData: modal.modalData,
});
const mapDispatchToProps = () => ({
  toggleModal
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileModal));
