// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-PoweredByGoogle__poweredByGoogle{display:flex;align-items:center;justify-content:flex-end;line-height:normal}.projects-seller-app-components-common-PoweredByGoogle__text{font-size:10px;text-transform:none;margin-right:5px}.projects-seller-app-components-common-PoweredByGoogle__logo{width:50px}", ""]);
// Exports
exports.locals = {
	"poweredByGoogle": "projects-seller-app-components-common-PoweredByGoogle__poweredByGoogle",
	"text": "projects-seller-app-components-common-PoweredByGoogle__text",
	"logo": "projects-seller-app-components-common-PoweredByGoogle__logo"
};
module.exports = exports;
