// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-order-form-Form__buttons{display:flex;justify-content:space-between;margin-top:30px}.projects-seller-app-components-order-form-Form__button{}@media (max-width:768px){.projects-seller-app-components-order-form-Form__button{width:100%}}.projects-seller-app-components-order-form-Form__backButton{width:49%}.projects-seller-app-components-order-form-Form__linkButton{width:49%;text-align:center}", ""]);
// Exports
exports.locals = {
	"buttons": "projects-seller-app-components-order-form-Form__buttons",
	"button": "projects-seller-app-components-order-form-Form__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"backButton": "projects-seller-app-components-order-form-Form__backButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainButton"] + "",
	"linkButton": "projects-seller-app-components-order-form-Form__linkButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + ""
};
module.exports = exports;
