import React from 'react';

import styles from './Button.css';

const Button = ({
  className = styles.button,
  children,
  ...otherProps
}) => (
  <button className={className} {...otherProps}>
    {children}
  </button>
);

export default Button;
