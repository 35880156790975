import React from 'react';

import Fa from 'common/components/common/Fa';

import styles from './Pagination.css';

export interface IPaginationProps {
  currentPage: number,
  totalPages: number,
  onPageChange: (page: number) => void
}

const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange
}) => {
  if (totalPages < 1) {
    return null;
  }
  const prev = () => onPageChange(currentPage - 1);
  const next = () => onPageChange(currentPage + 1);
  return (
    <div className={styles.pagination}>
      <button
        className={styles.pageButton}
        onClick={prev}
        disabled={currentPage <= 0}
      >
        <Fa icon="chevron-left" />
      </button>
      <div className={styles.pageInfo}>
        Page {currentPage + 1} of {totalPages}
      </div>
      <button
        className={styles.pageButton}
        onClick={next}
        disabled={currentPage >= totalPages - 1}
      >
        <Fa icon="chevron-right" />
      </button>
    </div>
  );
};

export default Pagination;
