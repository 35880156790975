import React from 'react';
import { Link } from 'react-router-dom';

import Fa from 'common/components/common/Fa';

import styles from 'seller/app/components/common/Button.css';

const AddButton = () => (
  <Link className={styles.mainActionButton} to="/inventory/new">
    <Fa icon="plus-circle" />
    <span>Add an Item</span>
  </Link>
);

export default AddButton;
