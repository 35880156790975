import React from 'react';
import styles from './PromoteBannerMobile.css';

import mobileImage from '../../../../../assets/images/Promote/iphone12-min.png';
import Button from 'common/components/common/Button';

const PromoteBannerMobile = () => {
  return (
    <div>
      <div>

        <div className={styles.banner}>
          <div className={styles.bannerTextWithImage}>
            {/* left heading */}
            <div className={styles.leftHeading}>
              <h1 className={styles.mainHeading}>
                Promote
              </h1>
              <h3 className={styles.subHeading}>
                Why need promotion your items
              </h3>
            </div>

            {/*  image container*/}
            <div className={styles.imageContainer}>
              <img className={styles.mobileImage} src={mobileImage} />
            </div>
          </div>

          <div className={styles.buttons}>

            <Button className={styles.promoteButton}>
              Promote
            </Button>

            <Button className={styles.learnMoreButton}>
              Learn More
            </Button>

          </div>
        </div>

        <div className={styles.bottomCardContainer}>
          {/* Bottom card left*/}
          <div className={styles.bottomCardLeft} >
            <h1 className={styles.bottomCardLeftHeading}>
              WHY ME?
            </h1>
          </div>
          {/* Bottom card right*/}
          <div className={styles.bottomCardRight}>

            <h2 className={styles.bottomCardRightHeading}>
              Your <br/> promotion <br/> Value
            </h2>
            <h2 className={styles.bottomCardRightSubHeading}>
              Promotion is great for growing a new business, promotion helps new seller to get more sales.
            </h2>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PromoteBannerMobile;
