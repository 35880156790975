import React from 'react';
import { connect } from 'react-redux';

import SpinngDiamond from 'common/components/common/SpinningDiamond';

import styles from './FullscreenLoader.css';

const FullscreenLoader = ({ isShown, message }) => {
  if (!isShown) {
    return null;
  }
  return (
    <div className={styles.fullscreenLoader}>
      <div className={styles.content}>
        <SpinngDiamond smallIcon />
        {message &&
         <p className={styles.message}>
           {message}
         </p>}
      </div>
    </div>
  );
};

const mapState = ({ form }) => ({
  isShown: form.isFullscreenLoaderShown,
  message: form.fullscreenLoaderMessage,
});

export default connect(mapState)(FullscreenLoader);
