import { combineReducers } from 'redux';

import chat from './chat';
import device from './device';
import dialog from './dialog';
import form from './form';
import inventory from './inventory';
import login from './login';
import modal from './modal';
import retailer from './retailer';

export default combineReducers({
  chat,
  device,
  dialog,
  form,
  inventory,
  login,
  modal,
  retailer,
});
