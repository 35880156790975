import * as firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

const sendMessageToDb = ({ roomId, userEmail, message = '', images = [] }) => {
  return new Promise((resolve, reject) => {
    const obj = {
      message,
      userEmail,
      images,
      timestamp: Date.now(),
    };

    db.collection('chatrooms')
      .doc(roomId)
      .collection('messages')
      .add(obj)
      .then(success => {
        resolve(success);
      })
      .catch(e => {
        console.log(e);
        reject({ message: e.message });
      });
  });
};

const deleteNotifiction = ({ roomId, orderId }) => {
  return new Promise((resolve, reject) => {
    db.collection('notifications')
      .doc(roomId)
      .collection('orderId')
      .where(orderId, '==', true)
      .get()
      .then(snap => {
        snap.forEach(item => {
          item.ref
            .delete()
            .then(() => resolve('deleted'))
            .catch(e => reject({ message: e.message }));
        });
      })
      .catch(e => reject({ message: e.message }));
  });
};

export const uploadImages = (image, id, i) => {
  return new Promise((resolve, reject) => {
    storage
      .ref(`${id}/${image.name}-Gem-${i}`)
      .put(image)
      .then(success => {
        storage
          .ref(`${id}/${image.name}-Gem-${i}`)
          .getDownloadURL()
          .then(url => {
            resolve(url);
          })
          .catch(e => reject({ message: e.message }));
      })
      .catch(e => reject({ message: e.message }));
  });
};

export const setImagesToDb = ({ images, roomId, userEmail }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resArray = images.map((item, index) =>
        uploadImages(item.file, roomId, index)
      );
      const res = await Promise.all(resArray);
      let obj = {
        images: res,
        roomId,
        userEmail,
      };
      const messageRes = await sendMessageToDb(obj);
      resolve(messageRes);
    } catch (e) {
      console.log(e);
      reject({ message: e.message });
    }
  });
};

export const signInAnonymously = () => auth.signInAnonymously();

export default firebase;
export { db, storage, sendMessageToDb, deleteNotifiction };
