import S3 from 'aws-sdk/clients/s3';

const S3_ENDPOINTS = {
  asia: {
    hostname: S3_ENDPOINT_ASIA,
    cdn: `https://${S3_BUCKET}.${S3_CDN_ASIA}`
  },
  default: {
    hostname: S3_ENDPOINT_ASIA,
    cdn: `https://${S3_BUCKET}.${S3_CDN_ASIA}`
  }
};

const s3 = new S3({
  accessKeyId: S3_KEYID,
  secretAccessKey: S3_ACCESSKEY
});

const setS3Endpoint = (region) => {
  region = region || 'default'; //TODO geoloation lookup
  const endpoint = S3_ENDPOINTS[region];
  s3.endpoint.hostname = endpoint.hostname;
  return Promise.resolve(endpoint);
};

export const putObject = (params) => {
  params = Object.assign({ Bucket: S3_BUCKET, ACL: 'public-read' }, params);
  return setS3Endpoint()
    .then(({ cdn }) => {
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(`${cdn}/${params.Key}`);
        });
      });
    });
};

export const deleteObject = (params, region = 'default') => {
  params = Object.assign({ Bucket: S3_BUCKET }, params);
  return setS3Endpoint(region)
    .then(() => {
      return new Promise((resolve, reject) => {
        s3.deleteObject(params, (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(params.Key);
        });
      });
    });
};

export const getRegionFromUrl = (url) =>
  Object.keys(S3_ENDPOINTS)
    .filter(region => region !== 'default')
    .find(region => url.startsWith(S3_ENDPOINTS[region].cdn));

export default {
  putObject,
  deleteObject,
  getRegionFromUrl
};
