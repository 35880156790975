import classNames from 'classnames';
import React from 'react';

import styles from './PageHeader.css';

const PageHeader = ({ className, children }: Gemsby.ISimpleWrapperProps) => (
  <section className={classNames(styles.pageHeader, className)}>
    <h3>{children}</h3>
  </section>
);

export default PageHeader;
