// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/form/Form.css");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-landing-Form__form{}.projects-seller-app-components-landing-Form__form input{width:100%}.projects-seller-app-components-landing-Form__checkboxText{margin-left:5px;line-height:15px;text-transform:uppercase}.projects-seller-app-components-landing-Form__footer{margin-top:10px;text-align:center}.projects-seller-app-components-landing-Form__footer .projects-seller-app-components-landing-Form__note{margin-top:20px;font-size:12px;line-height:18px}.projects-seller-app-components-landing-Form__footer .projects-seller-app-components-landing-Form__note p{margin-bottom:0}.projects-seller-app-components-landing-Form__submitButton{width:100%}.projects-seller-app-components-landing-Form__secondaryAction{margin-top:5px;font-size:12px}@media (max-width:480px){.projects-seller-app-components-landing-Form__locationFieldLabel{flex-direction:column;align-items:flex-start}}", ""]);
// Exports
exports.locals = {
	"form": "projects-seller-app-components-landing-Form__form " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["form"] + "",
	"checkboxText": "projects-seller-app-components-landing-Form__checkboxText",
	"footer": "projects-seller-app-components-landing-Form__footer",
	"note": "projects-seller-app-components-landing-Form__note",
	"submitButton": "projects-seller-app-components-landing-Form__submitButton " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["plainPrimaryButton"] + "",
	"secondaryAction": "projects-seller-app-components-landing-Form__secondaryAction",
	"locationFieldLabel": "projects-seller-app-components-landing-Form__locationFieldLabel"
};
module.exports = exports;
