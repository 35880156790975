import localstore from 'common/utils/localstore';
import { getRetailerSuccess } from 'seller/app/actions/retailer';
import api from 'seller/app/services/api';
import store from 'seller/app/store';

let loggedIn = false;

const setToken = (token) => {
  localstore.set('token', token);
  api.setToken(token);
  return token;
};

const saveCurrentUser = (user) => {
  const data = user
    ? {
        type: user.type,
        id: user.id,
        username: user.get('username')
      }
    : null;
  localstore.set('currentUser', data);
  return user;
}

export default {
  init() {
    api.setErrorHandler(this.handleTokenExpired.bind(this));

    const currentUser = localstore.get('currentUser');
    const token = localstore.get('token');
    if (!currentUser || !currentUser.id || !currentUser.username || !token) {
      return Promise.resolve();
    }
    api.setToken(token);
    return this.refreshToken(currentUser.username)
      .then(() => {
        if (currentUser.type === 'retailer') {
          return api
            .getRetailer(currentUser.id)
            .then(retailer => {
              store.dispatch(getRetailerSuccess(retailer))
              return retailer
            })
            .catch(error => {
              if (error.status === 401) {
                return
              }
            });
        }
        return currentUser;
      })
      .then(
        currentUser => {
          if (currentUser) {
            loggedIn = true;
          }
          return currentUser;
        },
        () => {}
      );
  },
  loggedIn() {
    return loggedIn;
  },
  refreshToken(username) {
    return api.refreshToken(username).then(token => {
      loggedIn = true;
      return setToken(token);
    });
  },
  login(email, password) {
    return api.login(email, password).then(login => {
      loggedIn = true;
      return {
        token: setToken(login.get('token')),
        currentUser: saveCurrentUser(login.getRelated('current_user'))
      };
    });
  },
  logout() {
    setToken('');
    saveCurrentUser();
    loggedIn = false;
    return Promise.resolve();
  },
  register(data) {
    data.username = data.email;
    data.verifyPassword = data.password;
    return api.addRetailer({
      type: 'retailer',
      id: '',
      attributes: data
    });
  },
  handleTokenExpired(fn) {
    return (...args) => {
      return fn(...args).catch(error => {
        const currentUser = store.getState().login.currentUser;
        if (error.status === 401 && currentUser) {
          return this.refreshToken(currentUser.get('username')).then(
            () => {
              return fn(...args);
            },
            error => {
              this.logout();
              window.location = '/';
            }
          )
        }
        throw error;
      });
    };
  }
};
