import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Button from 'common/components/common/Button';
import Field from 'common/components/common/form/Field';
import FieldNote from 'common/components/common/form/FieldNote';
import SectionCard from 'common/components/common/SectionCard';
import { MARKETPLACE_URL } from 'common/constants';
import { validateCustomUrl } from 'common/utils/validators';
import api from 'seller/app/services/api';

import styles from './Form.css';

const CustomUrlCard = ({ state, handleFieldChange }) => {
  const { publicLink } = state;
  const [focused, setFocused] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [verified, setVerified] = useState(false);
  const [result, setResult] = useState(false);

  useEffect(() => {
    setVerified(false);
    setResult(false);
  }, [publicLink]);

  const validate = () => {
    const { valid } = validateCustomUrl(state);
    return valid;
  };

  const verifyCustomUrl = event => {
    event.preventDefault();
    setInProgress(true);
    api.checkCustomUrl(publicLink).then(result => {
      setInProgress(false);
      setResult(result);
      setVerified(true);
    }, error => {
      setInProgress(false);
      // TODO: show error
    });
  };

  const renderAvailability = () => {
    if (!verified) {
      return null;
    }
    const customUrl = `${MARKETPLACE_URL}/${publicLink}`;
    if (result) {
      return (
        <div className={styles.publicLinkAvailable}>
          Success! {customUrl} is available to take.
        </div>
      );
    }
    return (
      <div className={styles.availability}>
        Sorry, {customUrl} is not available, please try another one.
      </div>
    );
  };

  return (
    <SectionCard title="Customize Your Profile Link">
      <p className={styles.sectionIntro}>
        Direct anyone to view your seller profile at this unique link.
      </p>
      <Field
        className={styles.customUrlField}
        label="Choose Your Custom Profile Link"
      >
        <div className={classNames(styles.customUrlInput, { [styles.customUrlInputFocused]: focused })}>
          <span className={styles.customUrlPrefix}>{MARKETPLACE_URL}/</span>
          <input
            type="text"
            placeholder="profile-name"
            value={publicLink}
            disabled={inProgress}
            onChange={handleFieldChange('publicLink')}
            onFocus={event => {
              setFocused(true);
            }}
            onBlur={event => {
              setFocused(false);
            }}
          />
        </div>
        <FieldNote>
          Do not enter any spaces or special characters (i.e. #, $, @), Hyphens (-)
          are allowed.
        </FieldNote>
      </Field>
      {renderAvailability()}
      <Button
        className={styles.checkAvailabilityButton}
        disabled={inProgress || !validate()}
        onClick={verifyCustomUrl}
      >
        {inProgress ? 'Checking...' : 'Check Availability'}
      </Button>
    </SectionCard>
  );
};

export default CustomUrlCard;
