import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';

import device from 'common/services/device';
import { getNotifications } from 'seller/app/actions/chat';
import { setIsBigScreen } from 'seller/app/actions/device';
import { setCurrentUser } from 'seller/app/actions/login';
import App from 'seller/app/App';
import { VERSION } from 'seller/app/constants';
import auth from 'seller/app/services/auth';
import { signInAnonymously } from 'seller/app/services/firebase';
import store from 'seller/app/store';

import '@fortawesome/fontawesome-free/css/all.css';
import 'common/assets/fonts.css';
import 'common/assets/gemsby-icons.css';
import 'common/assets/mydiamond-icons.css';
import 'react-geosuggest/module/geosuggest.css';
import './main.css';

// tslint:disable-next-line:no-console
console.log(`Gemsby Seller v${VERSION}`);

const renderApp = () =>
  ReactDom.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.querySelector('#root')
  );

const main = () => {
  auth.init().then(async (currentUser) => {
    store.dispatch(setCurrentUser(currentUser));

    try {
      if (!sessionStorage.getItem('user')) {
        const user = await signInAnonymously();
        sessionStorage.setItem('user',JSON.stringify(user));
      }
    } catch(e) {
      // tslint:disable-next-line:no-console
      console.log(e)
    }

    if (currentUser) {
      store.dispatch(getNotifications(currentUser.get('email')));
    }
    renderApp();
  });

  device.listenWindowResize(() => {
    store.dispatch(setIsBigScreen(device.isBigScreen()));
  });

  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: 'tlydstcc',
    });
  }
};

if (window.cordova) {
  document.addEventListener('deviceready', main, false);
} else {
  main();
}
