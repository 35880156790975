// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-onboarding-Onboarding__onboarding{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"onboarding": "projects-seller-app-components-onboarding-Onboarding__onboarding"
};
module.exports = exports;
