import _ from 'lodash';

import { readFileAsDataUrl } from 'common/utils/file';
import { isImageUrl } from 'common/utils/imageUrl';
import { searchRetailers } from 'common/utils/search';
import {
  validatePassword,
  validatePayPalClientId as paypalClientIdValidator,
  validateRetailer,
  validateRetailerAccount,
} from 'common/utils/validators';
import { setFullscreenLoaderMessage } from 'seller/app/actions/form';
import {
  deleteImageError,
  deleteImageRequest,
  deleteImageSuccess,
  uploadImage,
  uploadMastheadImage,
  uploadRepImage,
} from 'seller/app/actions/image';
import { setCurrentUser } from 'seller/app/actions/login';
import api from 'seller/app/services/api';

export const FETCH_RETAILERS_REQUEST = 'FETCH_RETAILERS_REQUEST';
const fetchRetailersRequest = () => ({ type: FETCH_RETAILERS_REQUEST });

export const FETCH_RETAILERS_SUCCESS = 'FETCH_RETAILERS_SUCCESS';
const fetchRetailersSuccess = (retailers, callback) => ({
  type: FETCH_RETAILERS_SUCCESS,
  retailers,
  callback,
});

export const FETCH_RETAILERS_ERROR = 'FETCH_RETAILERS_ERROR';
const fetchRetailersError = error => ({ type: FETCH_RETAILERS_ERROR, error });

export const fetchAllRetailers = (dispatch, getState, query, callback) => {
  const pageNumber = query.start / query.length + 1;
  let retailers = getState().retailer.get('retailers');
  const currentQuery = retailers && retailers.getMeta('query');
  if (
    !retailers ||
    retailers.getPageNumber() !== pageNumber ||
    (currentQuery && currentQuery !== query.search.value)
  ) {
    retailers = api.getRetailers(pageNumber);
  }
  return Promise.resolve(retailers).then(
    retailers => {
      dispatch(fetchRetailersSuccess(retailers, callback));
      return retailers;
    },
    error => {
      dispatch(fetchRetailersError(error));
      throw error;
    }
  );
};

export const searchForRetailers = (dispatch, query, callback) => {
  return searchRetailers(query.search.value, query.start / query.length).then(
    retailers => {
      dispatch(fetchRetailersSuccess(retailers, callback));
      return retailers;
    },
    error => {
      dispatch(fetchRetailersError(error));
      throw error;
    }
  );
};

export const fetchRetailers = (query, callback) => {
  return (dispatch, getState) => {
    dispatch(fetchRetailersRequest());
    if (query.search.value) {
      return searchForRetailers(dispatch, query, callback);
    }
    return fetchAllRetailers(dispatch, getState, query, callback);
  };
};

export const GET_RETAILER_REQUEST = 'GET_RETAILER_REQUEST';
function getRetailerRequest() {
  return { type: GET_RETAILER_REQUEST };
}

export const GET_RETAILER_SUCCESS = 'GET_RETAILER_SUCCESS';
export const getRetailerSuccess = retailer => {
  return { type: GET_RETAILER_SUCCESS, retailer };
};

export const GET_RETAILER_ERROR = 'GET_RETAILER_ERROR';
function getRetailerError(error) {
  return { type: GET_RETAILER_ERROR, error };
}

export const getRetailer = retailerId => {
  return (dispatch, getState) => {
    dispatch(getRetailerRequest());
    const retailers = getState().retailer.get('retailers');
    let retailer = retailers && retailers.get(retailerId);
    if (!retailer || retailer.source === 'search') {
      retailer = api.getRetailer(retailerId);
    }
    return Promise.resolve(retailer).then(
      retailer => {
        dispatch(getRetailerSuccess(retailer));
      },
      error => {
        dispatch(getRetailerError(error));
      }
    );
  };
};

export const CREATE_RETAILER = 'CREATE_RETAILER';
export const createRetailer = () => {
  return { type: CREATE_RETAILER };
};

export const SAVE_RETAILER_REQUEST = 'SAVE_RETAILER_REQUEST';
const saveRetailerRequest = () => ({ type: SAVE_RETAILER_REQUEST });

export const SAVE_RETAILER_SUCCESS = 'SAVE_RETAILER_SUCCESS';
const saveRetailerSuccess = retailer => ({
  type: SAVE_RETAILER_SUCCESS,
  retailer,
});

export const SAVE_RETAILER_ERROR = 'SAVE_RETAILER_ERROR';
const saveRetailerError = error => ({ type: SAVE_RETAILER_ERROR, error });

export const saveRetailer = (
  data,
  galleryImages,
  repImage,
  mastheadImage
) => dispatch => {
  dispatch(saveRetailerRequest());
  const { valid, error } = validateRetailer(data.attributes);
  if (!valid) {
    dispatch(saveRetailerError(error));
    return Promise.reject(error);
  }

  // delete removed gallery images
  api.deleteRetailerImage(
    data.id,
    (galleryImages || []).filter(({ removed }) => removed)
  );
  galleryImages = (galleryImages || []).filter(({ removed }) => !removed);

  // delete removed rep image
  api.deleteRetailerRepImage(
    data.id,
    (repImage || []).filter(({ removed }) => removed)
  );
  repImage = (repImage || []).filter(({ removed }) => !removed)[0];

  // delete removed masthead image
  api.deleteRetailerMastheadImage(
    data.id,
    (mastheadImage || []).filter(({ removed }) => removed)
  );
  mastheadImage = (mastheadImage || []).filter(({ removed }) => !removed)[0];

  dispatch(
    setFullscreenLoaderMessage('Please wait while we are saving the data...')
  );
  return api.updateRetailer(data.id, data).then(
    (retailer: Gemsby.IJSONAPIEntity) => {
      return (galleryImages || [])
        .reduce(
          (step: Promise<any>, { id, file }: { id: string; file: File }) => {
            if (id || !file) {
              return step;
            }
            return step
              .then(() => {
                return readFileAsDataUrl(file);
              })
              .then((dataUrl: string) =>
                dispatch(uploadImage(retailer, dataUrl))
              )
              .catch((err: Error) => {
                // TODO: show error
              });
          },
          Promise.resolve()
        )
        .then(() => {
          if (repImage && repImage.file) {
            return readFileAsDataUrl(repImage.file).then(dataUrl =>
              dispatch(uploadRepImage(retailer, dataUrl))
            );
          }
        })
        .then(() => {
          if (mastheadImage && mastheadImage.file) {
            return readFileAsDataUrl(mastheadImage.file).then(dataUrl =>
              dispatch(uploadMastheadImage(retailer, dataUrl))
            );
          }
        })
        .then(() => {
          return api.getRetailer(retailer.id);
        })
        .then((updatedRetailer: Gemsby.IJSONAPIEntity) => {
          dispatch(saveRetailerSuccess(updatedRetailer));
          dispatch(setCurrentUser(updatedRetailer));
        });
    },
    (err: Error) => {
      dispatch(saveRetailerError(err));
      throw err;
    }
  );
};

export const updateRetailerAccount = data => dispatch => {
  dispatch(saveRetailerRequest());

  const { valid, error } = validateRetailerAccount(data.attributes);
  if (!valid) {
    dispatch(saveRetailerError(error));
    return Promise.reject(error);
  }
  dispatch(
    setFullscreenLoaderMessage('Please wait while we are saving the data...')
  );
  return api.updateRetailer(data.id, data).then(
    retailer => {
      dispatch(saveRetailerSuccess(retailer));
      dispatch(setCurrentUser(retailer));
    },
    error => {
      dispatch(saveRetailerError(error));
      throw error;
    }
  );
};

export const DELETE_RETAILER_REQUEST = 'DELETE_RETAILER_REQUEST';
function deleteRetailerRequest() {
  return { type: DELETE_RETAILER_REQUEST };
}

export const DELETE_RETAILER_SUCCESS = 'DELETE_RETAILER_SUCCESS';
function deleteRetailerSuccess() {
  return { type: DELETE_RETAILER_SUCCESS };
}

export const DELETE_RETAILER_ERROR = 'DELETE_RETAILER_ERROR';
function deleteRetailerError(error) {
  return { type: DELETE_RETAILER_ERROR, error };
}

export function deleteRetailer(retailer) {
  return dispatch => {
    dispatch(deleteRetailerRequest());
    return api.deleteRetailer(retailer.id).then(
      () => {
        dispatch(deleteRetailerSuccess());
      },
      error => {
        dispatch(deleteRetailerError(error));
      }
    );
  };
}

export const savePayPalClientId = paypalClientId => {
  return (dispatch, getState) => {
    const data = { paypalClientId };

    const { valid, error } = dispatch(validatePayPalClientId(data));
    if (!valid) {
      dispatch(saveRetailerError(error));
      return Promise.reject(error);
    }

    dispatch(saveRetailerRequest());
    const retailerId = getState().login.currentUser.id;
    return api
      .updateRetailer(retailerId, {
        id: retailerId,
        type: 'retailer',
        attributes: data,
      })
      .then(
        retailer => {
          dispatch(saveRetailerSuccess(retailer));
          dispatch(setCurrentUser(retailer));
          return retailer;
        },
        error => {
          dispatch(saveRetailerError(error));
          throw error;
        }
      );
  };
};

export const validatePayPalClientId = data => dispatch => {
  const result = paypalClientIdValidator(data);
  dispatch(setRetailerError(result.error));
  return result;
};

export const SET_RETAILER_ERROR = 'SET_RETAILER_ERROR';
export const setRetailerError = error => ({ type: SET_RETAILER_ERROR, error });
