import React from 'react';

import HelpCenter from 'common/components/common/HelpCenter';

const AcceptPaymentHelpCenter = props => (
  <HelpCenter
    title="Accepting Customer Payments"
    link="https://intercom.help/gemsby/selling-on-gemsby/handling-orders/how-to-accept-payment-from-customers-through-gemsby"
    {...props}
  />
);

export default AcceptPaymentHelpCenter;
