import React from 'react';

import Fa from 'common/components/common/Fa';
import OutLink from 'common/components/common/OutLink';
import {
  GEMSBY_BLOG_URL, GEMSBY_FACEBOOK_URL,
  GEMSBY_HELP_URL, GEMSBY_INSTAGRAM_URL, GEMSBY_TWITTER_URL,
  MARKETPLACE_URL,
  MOBILE_APP_URL,
  PRIVACY_URL,
  SUPPORT_EMAIL,
  TOS_URL,
} from 'common/constants';

import abcImage from 'seller/assets/images/footer/webfoot-abc.png';
import cbsImage from 'seller/assets/images/footer/webfoot-cbs.png';
import foxImage from 'seller/assets/images/footer/webfoot-fox.png';
import nbcImage from 'seller/assets/images/footer/webfoot-nbc.png';
import styles from './Footer.css';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.section}>
      <h4 className={styles.sectionTitle}>Follow Gemsby On</h4>
      <div className={styles.sectionContent}>
        <OutLink className={styles.socialLink} to={GEMSBY_INSTAGRAM_URL}><Fa brand icon="instagram" /></OutLink>
        <OutLink className={styles.socialLink} to={GEMSBY_FACEBOOK_URL}><Fa brand icon="facebook" /></OutLink>
        <OutLink className={styles.socialLink} to={GEMSBY_TWITTER_URL}><Fa brand icon="twitter" /></OutLink>
        <OutLink className={styles.socialLink} to={GEMSBY_BLOG_URL}><Fa brand icon="medium" /></OutLink>
      </div>
    </div>
    <div className={styles.section}>
      <h4 className={styles.sectionTitle}>As Seen On</h4>
      <div className={styles.sectionContent}>
        <img className={styles.image} src={abcImage} alt="abc logo" />
        <img className={styles.image} src={cbsImage} alt="cbs logo" />
        <img className={styles.image} src={foxImage} alt="fox logo" />
        <img className={styles.image} src={nbcImage} alt="nbc logo" />
      </div>
    </div>
    <ul className={styles.links}>
      <li className={styles.linkItem}>
        <OutLink to={MARKETPLACE_URL}>Gemsby</OutLink>
      </li>
      {/*<li className={styles.dot}>·</li>*/}
      {/*<li className={styles.linkItem}>*/}
      {/*  <OutLink to="http://www.mydiamondrevolution.com">Mission</OutLink>*/}
      {/*</li>*/}
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={`${MARKETPLACE_URL}/jewelry-insurance`}>
          Jewelry Insurance
        </OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={MOBILE_APP_URL}>App</OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={PRIVACY_URL}>Privacy</OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={TOS_URL}>Terms</OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={GEMSBY_BLOG_URL}>Magazine</OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={GEMSBY_HELP_URL}>Help</OutLink>
      </li>
      <li className={styles.dot}>·</li>
      <li className={styles.linkItem}>
        <OutLink to={`mailto:${SUPPORT_EMAIL}`}>Contact Us</OutLink>
      </li>
    </ul>
    <div className={styles.copyright}>
      ©{new Date().getFullYear()} C.G. Diam Corp.&nbsp;All Rights Reserved.
    </div>
  </footer>
);

export default Footer;
