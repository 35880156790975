import React from 'react';

import OutLink from 'common/components/common/OutLink';
import SubSection from 'common/components/common/SubSection';
import OrderField from 'seller/app/components/order/form/OrderField';

import styles from './TrackingDetails.css';

const TrackingDetails = ({
  order: {
    attributes: { shippingService, shippingTrackingId },
  },
}) => {
  return (
    <SubSection
      className={styles.trackingDetails}
      title="Customer Received Tracking Details"
    >
      <p>
        You customer has received an email with the tracking number. They are
        also able to see that the item has shipped using the{' '}
        <OutLink to="#">shared link</OutLink> that contains a basic order
        summary.
      </p>
      <div>
        <OrderField label="Tracking Number" value={shippingTrackingId} />
        <OrderField label="Shipping Service" value={shippingService} />
      </div>
    </SubSection>
  );
};

export default TrackingDetails;
