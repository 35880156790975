import React from 'react';

import styles from './List.css';

const Customer = ({ order }) => {
  const { billingCity, billingName, email } = order.attributes;
  return (
    <div className={styles.orderCustomer}>
      <div>
        <span className={styles.customerName}>{billingName}</span>{' '}
        {/* <span>&lt;{email}&gt;</span> */}
      </div>
      <div className={styles.customerCity}>{billingCity}</div>
    </div>
  );
};

export default Customer;
