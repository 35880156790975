import classNames from 'classnames';

import React from 'react';

import styles from './FieldLabelWithAttribution.css';

interface IFieldLabelWithAttributionProps extends Gemsby.IComponentProps {
  label: string | JSX.Element,
  attribution: string | JSX.Element,
}

const FieldLabelWithAttribution = ({
  className,
  label,
  attribution
}: IFieldLabelWithAttributionProps) => (
  <div className={classNames(styles.fieldLabelWithAttribution, className)}>
    <span className={styles.label}>{label}</span>
    <span className={styles.attribution}>{attribution}</span>
  </div>
);

export default FieldLabelWithAttribution;
