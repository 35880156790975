// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-form-FieldLabelWithAttribution__fieldLabelWithAttribution{display:flex;align-items:center;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"fieldLabelWithAttribution": "projects-common-components-common-form-FieldLabelWithAttribution__fieldLabelWithAttribution"
};
module.exports = exports;
