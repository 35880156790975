// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-EmptyMessage__emptyMessage{width:100%;padding:20px;display:flex;flex-direction:column;align-items:center;justify-content:center}.projects-common-components-common-EmptyMessage__icon{margin-bottom:15px}", ""]);
// Exports
exports.locals = {
	"emptyMessage": "projects-common-components-common-EmptyMessage__emptyMessage",
	"icon": "projects-common-components-common-EmptyMessage__icon"
};
module.exports = exports;
