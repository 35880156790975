import React, { useState } from 'react';

import Button from 'common/components/common/Button';
import Field from 'common/components/common/form/Field';
import HelpCenter from 'common/components/common/HelpCenter';
import OutLink from 'common/components/common/OutLink';
import SectionCard from 'common/components/common/SectionCard';
import AcceptPaymentHelpCenter from 'seller/app/components/payment/AcceptPaymentHelpCenter';

import logoPayPalImage from 'common/assets/images/logo-paypal.png';
import styles from 'seller/app/components/payment/Payment.css';

const ConnectPayPal = () => (
  <div className={styles.instruction}>
    <p>
      <strong>Connect PayPal:</strong>
    </p>
    <ul className={styles.steps}>
      <li className={styles.step}>
        Setup Gemsby app on your{' '}
        <OutLink to="https://developer.paypal.com/developer/applications/">PayPal Developer Dashboard</OutLink>
      </li>
      <li className={styles.step}>
        Enter the Live Client ID into the input box below
      </li>
      <li className={styles.step}>Tap the "Save Client ID" button</li>
    </ul>
    <p>
      Or, read the <OutLink to="">detailed instructions.</OutLink>
    </p>
  </div>
);

const PayPalEnabled = () => (
  <div className={styles.instruction}>
    <p>
      <strong>Online Payment is Enabled</strong>
    </p>
    <p>You have successfully connected your Gemsby account with PayPal.</p>
    <p>
      To confirm a payment from any Gemsby consumer that has chosen to purchase
      your listed inventory, please visit your PayPal account at{' '}
      <OutLink to="https://www.paypal.com">https://www.paypal.com</OutLink>. You
      may also manage payment settings there.
    </p>
  </div>
);

const ConnectPayPalHelpCenter = () => (
  <HelpCenter
    title="How to Connect with PayPal"
    link="https://intercom.help/gemsby/selling-on-gemsby/getting-started/how-to-connect-your-account-with-paypal"
  />
);

const PayPal = ({
  hasPayPal,
  paypalClientId,
  retailerInProgress,
  error,
  state,
  handleFieldChange,
  setState,
  showHelpCenter,
  savePayPalClientId,
}) => {
  const [editMode, setEditMode] = useState(!hasPayPal);

  const onCancelButtonClick = event => {
    event.preventDefault();
    setState({ paypalClientId });
    setEditMode(false);
  };
  const onModifyButtonClick = event => {
    event.preventDefault();
    setEditMode(true);
  };
  const onSaveButtonClick = event => {
    event.preventDefault();
    if (hasPayPal && state.paypalClientId === paypalClientId) {
      setEditMode(false);
      return;
    }
    savePayPalClientId(state.paypalClientId).then(
      () => {
        setEditMode(false);
      },
      () => {}
    );
  };

  const Buttons = () => {
    if (editMode) {
      return (
        <>
          {hasPayPal && (
            <Button
              className={styles.secondaryButton}
              disabled={retailerInProgress}
              onClick={onCancelButtonClick}
            >
              Cancel
            </Button>
          )}
          <Button
            className={styles.button}
            // disabled={
            //   !state.paypalClientId || state.paypalClientId.length < 1 || retailerInProgress
            // }
            onClick={onSaveButtonClick}
          >
            {retailerInProgress ? 'Saving...' : 'Save Client ID'}
          </Button>
        </>
      );
    }
    return (
      <Button className={styles.secondaryButton} onClick={onModifyButtonClick}>
        Modify Client ID
      </Button>
    );
  };

  return (
    <SectionCard
      className={styles.paymentCard}
      title="PayPal (Major Credit Cards / PayPal Payments)"
      alwaysOpen
    >
      <div className={styles.paymentCardContent}>
        <div className={styles.logo}>
          <img src={logoPayPalImage} />
        </div>
        {hasPayPal ? <PayPalEnabled /> : <ConnectPayPal />}
        <form>
          <Field label="PayPal Live Client ID" error={error.paypalClientId}>
            <input
              type="text"
              placeholder="Enter your PayPal Live Client ID"
              disabled={!editMode}
              value={state.paypalClientId}
              onChange={handleFieldChange('paypalClientId')}
            />
          </Field>
          <div className={styles.buttons}>
            <Buttons />
          </div>
        </form>
        {showHelpCenter &&
          (hasPayPal ? (
            <AcceptPaymentHelpCenter />
          ) : (
            <ConnectPayPalHelpCenter />
          ))}
      </div>
    </SectionCard>
  );
};

export default PayPal;
