import classNames from 'classnames';
import React from 'react';

const FieldNote: React.FC<Gemsby.ISimpleWrapperProps> = ({
  className,
  children
}) => (
  <p className={classNames('note', className)}>
    {children}
  </p>
);

export default FieldNote;
