import { splitDataUrl } from 'common/utils/file';
import api from 'seller/app/services/api';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const uploadImageRequest = () => ({ type: UPLOAD_IMAGE_REQUEST });

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const uploadImageSuccess = image => ({
  type: UPLOAD_IMAGE_SUCCESS,
  image,
});

export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const uploadImageError = error => ({ type: UPLOAD_IMAGE_ERROR, error });

export const uploadImage = (entity, dataUrl) => dispatch => {
  dispatch(uploadImageRequest());
  const { mimeType, data: fileContent } = splitDataUrl(dataUrl);
  return api
    .uploadImage(entity.type, entity.id, { mimeType, fileContent })
    .then(
      image => {
        dispatch(uploadImageSuccess(image));
      },
      error => {
        dispatch(uploadImageError(error));
      }
    );
};

export const uploadRepImage = (retailer, dataUrl) => dispatch => {
  dispatch(uploadImageRequest());
  const { mimeType, data: fileContent } = splitDataUrl(dataUrl);
  return api.uploadRepImage(retailer.id, { mimeType, fileContent }).then(
    image => {
      dispatch(uploadImageSuccess(image));
    },
    error => {
      dispatch(uploadImageError(error));
    }
  );
};

export const uploadMastheadImage = (retailer, dataUrl) => dispatch => {
  dispatch(uploadImageRequest());
  const { mimeType, data: fileContent } = splitDataUrl(dataUrl);
  return api.uploadMastheadImage(retailer.id, { mimeType, fileContent }).then(
    image => {
      dispatch(uploadImageSuccess(image));
    },
    error => {
      dispatch(uploadImageError(error));
    }
  );
};

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const deleteImageRequest = () => ({ type: DELETE_IMAGE_REQUEST });

export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const deleteImageSuccess = () => ({ type: DELETE_IMAGE_SUCCESS });

export const DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR';
export const deleteImageError = error => ({ type: DELETE_IMAGE_ERROR, error });
