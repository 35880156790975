import classNames from 'classnames';
import React, { ReactChildren } from 'react';

import DataTableHeader, {
  ITableHeader,
} from 'seller/app/components/common/datatable/DataTableHeader';
import Pagination, {
  IPaginationProps,
} from 'seller/app/components/common/Pagination';

import styles from './DataTable.css';

interface IDataTableProps extends Gemsby.IComponentProps {
  headers: ITableHeader[];
  items: JSX.Element[];
  emptyMessage?: JSX.Element | string;
  pagination: IPaginationProps;
}

const DataTable: React.FC<IDataTableProps> = ({
  headers,
  items,
  emptyMessage,
  pagination,
}) => {
  const emptyMessageRow = emptyMessage && (
    <tr className={styles.emptyRow}>
      <td colSpan={headers.length}>{emptyMessage}</td>
    </tr>
  );
  return (
    <div className={classNames('dataTableContainer', styles.container)}>
      <table className={styles.table}>
        <DataTableHeader headers={headers} />
        <tbody className={styles.tableBody}>
          {items.length > 0 ? items : emptyMessageRow}
        </tbody>
      </table>
      <Pagination {...pagination} />
    </div>
  );
};

export default DataTable;
