import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import { useFormState } from 'common/utils/form';
import { validateTrackingDetails } from 'common/utils/validators';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import OrderForm from 'seller/app/components/order/form/Form';
import { Routes } from 'seller/app/constants';
import api from 'seller/app/services/api';
import Modal from 'common/components/common/mobile/MobileModal';

const OrderFormPageHeader = () => <PageHeader>Manage Order</PageHeader>;

const OrderFormPageMobile = props => (
  <>
  <Modal />
  <Screen>
    <PageMobile>
      <PageContent heightReducedBy={60}>
        <HeaderNavMobile />

        <OrderFormPageHeader />

        <PageSection>
          <BannerAd />
        </PageSection>

        <PageSection>
          <OrderForm {...props} />
        </PageSection>
      </PageContent>

      <TabBar activeItem={Routes.orders} />
    </PageMobile>
  </Screen>
  </>
);

const OrderFormPageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.orders} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection compact>
      <OrderFormPageHeader />

      <OrderForm {...props} />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const OrderFormPage = ({ match, history, isBigScreen }) => {
  const orderId = match.params.id;

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [state, handleFieldChange] = useFormState({
    shippingTrackingId: '',
    shippingService: '',
  });
  const [error, setError] = useState(null);

  const validateTrackingForm = () => {
    // tslint:disable-next-line: no-shadowed-variable
    const { valid, error } = validateTrackingDetails(state);
    setError(error);
    return valid;
  };

  const updateTrackingDetails = event => {
    event.preventDefault();
    const valid = validateTrackingForm(state);
    if (!valid) {
      return;
    }
    return api
      .updateOrderTrackingDetails(order.id, {
        id: order.id,
        type: 'order',
        attributes: state,
      })
      .then(() => {
        Object.assign(order.attributes, state);
        setOrder({
          ...order,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    api.getOrder(orderId).then(orderEntity => {
      setOrder(orderEntity.toJSON());
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (error) {
      validateTrackingForm();
    }
  }, [state.shippingTrackingId, state.shippingService]);

  if (loading) {
    return <SpinningDiamond fullScreen />;
  }

  const props = {
    history,
    order,
    state,
    error: error || {},
    handleFieldChange,
    updateTrackingDetails,
  };

  const FormPage = isBigScreen ? OrderFormPageDesktop : OrderFormPageMobile;
  return <FormPage {...props} />;
};

const mapState = ({ device }) => ({
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(OrderFormPage);

