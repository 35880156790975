import React from 'react';

import OutLink from 'common/components/common/OutLink';
import { MARKETPLACE_URL } from 'common/constants';

import styles from './HeaderButton.css';

const HeaderButtonMarketplace = () => (
  <OutLink className={styles.headerButton} to={MARKETPLACE_URL}>Marketplace</OutLink>
);

export default HeaderButtonMarketplace;
