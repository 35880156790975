import React from 'react';

import ImagePicker from 'common/components/common/form/ImagePicker';
import SectionCard from 'common/components/common/SectionCard';

import styles from './Form.css';

const GalleryCard = ({ state: { galleryImages }, onGalleryImagesChange }) => (
  <SectionCard title="Profile Gallery">
    <p className={styles.sectionIntro}>
      A gallery is ideal for showing promotional offers, store photos, your best
      inventory/creations or anything else that will appeal to consumers. Upload
      up to 5 images. The individual size limit is 15MB an image.
    </p>
    <ImagePicker
      className={styles.imagePicker}
      type="retailer"
      max={5}
      images={galleryImages}
      onChange={onGalleryImagesChange}
    />
  </SectionCard>
);

export default GalleryCard;
