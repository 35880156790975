import classNames from 'classnames';
import React from 'react';


import styles from './EmptyMessage.css';

interface IEmptyMessageProps {
  className?: string;
  icon?: JSX.Element;
  message?: JSX.Element | string;
  children?: JSX.Element;
}

const EmptyMessage: React.FC<IEmptyMessageProps> = ({
  className,
  icon,
  message,
  children,
}) => {
  return (
    <div className={classNames(styles.emptyMessage, className)}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {message && <div className={styles.message}>{message}</div>}
      {children}
    </div>
  );
};

export default EmptyMessage;
