import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from 'seller/app/reducers/root';

const store = createStore(
  rootReducer,
  applyMiddleware(ReduxThunk)
);

export default store;
