import React from 'react';

import CheckboxField from 'common/components/common/form/CheckboxField';
import Field from 'common/components/common/form/Field';
import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SectionCard, {
  SectionIntro,
} from 'common/components/common/SectionCard';
import SubSection from 'common/components/common/SubSection';
import {
  ReturnPolicies,
  returnPolicies,
  shippingDestinations,
} from 'common/utils/retailer';

const FeesCard = ({
  state: {
    currency,
    taxRate,
    shippingDestination,
    shippingFee,
    shippingTime,
    returnPolicy,
    returnPeriod,
    returnPostageRefunded,
  },
  error,
  handleFieldChange,
}) => {
  return (
    <SectionCard title="Tax, Shipping and Returns">
      <SubSection title="Tax">
        <SectionIntro>
          Select the currency you use for all of your item prices, along with
          account settings such as your shipping fee.
        </SectionIntro>
        <Field label="Tax Rate (%)" error={error.taxRate}>
          <input
            type="text"
            placeholder="Enter percentage, i.e. 13"
            value={taxRate}
            onChange={handleFieldChange('taxRate')}
          />
        </Field>
      </SubSection>
      <SubSection title="Shipping">
        <SectionIntro>
          Select the currency you use for all of your item prices, along with
          account settings such as your shipping fee.
        </SectionIntro>
        <RadioGroupField
          label="Shipping Destinations"
          radioGroupName="shippingDestinations"
          options={shippingDestinations}
          selectedOption={shippingDestination}
          onChange={handleFieldChange('shippingDestination')}
        />
        <Field
          label={`Flat Shipping Fee (${(currency || '').toUpperCase()})`}
          error={error.shippingFee}
        >
          <input
            type="text"
            placeholder="Enter flat fee, i.e. 10.99"
            value={shippingFee}
            onChange={handleFieldChange('shippingFee')}
          />
        </Field>
        <Field label="Expected Arrival Time (Days)" error={error.shippingTime}>
          <input
            type="text"
            placeholder="Enter estimated days, i.e. 7"
            value={shippingTime}
            onChange={handleFieldChange('shippingTime')}
          />
        </Field>
      </SubSection>
      <SubSection title="Returns">
        <RadioGroupField
          label="Return Policy"
          radioGroupName="returnPolicies"
          options={returnPolicies}
          selectedOption={returnPolicy}
          onChange={handleFieldChange('returnPolicy')}
        />
        {returnPolicy === ReturnPolicies.RETURN_ACCEPTED && (
          <>
            <Field label="Return Period (Days)" error={error.returnPeriod}>
              <input
                type="text"
                placeholder="Enter days, i.e. 30"
                value={returnPeriod}
                onChange={handleFieldChange('returnPeriod')}
              />
            </Field>
            <CheckboxField
              label="Return postage refunded"
              checked={returnPostageRefunded}
              onChange={handleFieldChange('returnPostageRefunded')}
            />
          </>
        )}
      </SubSection>
    </SectionCard>
  );
};

export default FeesCard;
