import React from 'react';

import OutLink from 'common/components/common/OutLink';
import { API_HOST } from 'common/constants';

import styles from './BannerAd.css';

const BannerAd = () => {
  return (
    <div className={styles.bannerAd}>
      <OutLink to="https://mydiamond.app.link/login_leader">
        <img src={`${API_HOST}/images/adunit/v1/login_728_90_leader.jpg`} alt="nice image" />
      </OutLink>
    </div>
  );
};

export default BannerAd;
