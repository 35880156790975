// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-MessageField__fieldContain{width:95%;padding:10px;border:1px solid #d3d3d3;border-radius:5px;position:absolute;bottom:11px}.projects-common-components-common-MessageField__field{width:calc(100% - 42px);outline:none;border:none;font-family:Montserrat,Arial,sans-serif}.projects-common-components-common-MessageField__sendIcon{padding:4px 0;float:right}.projects-common-components-common-MessageField__attachIcon,.projects-common-components-common-MessageField__sendIcon{color:var(--mainColor);font-size:16px;cursor:pointer}.projects-common-components-common-MessageField__attachIcon{padding:4px}", ""]);
// Exports
exports.locals = {
	"fieldContain": "projects-common-components-common-MessageField__fieldContain",
	"field": "projects-common-components-common-MessageField__field",
	"sendIcon": "projects-common-components-common-MessageField__sendIcon",
	"attachIcon": "projects-common-components-common-MessageField__attachIcon"
};
module.exports = exports;
