import classNames from 'classnames';
import React from 'react';

import styles from './PageSection.css';

interface IPageSectionProps extends Gemsby.ISimpleWrapperProps {
  compact?: boolean;
}

const PageSection: React.FC<IPageSectionProps> = ({
  className,
  compact = false,
  children,
}) => (
  <section
    className={classNames(styles.pageSection, className, {
      [styles.compact]: compact,
    })}
  >
    {children}
  </section>
);

export default PageSection;
