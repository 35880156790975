import React from 'react';

import Button from 'common/components/common/Button';
import ContactDetailsCard from 'seller/app/components/profile/form/ContactDetailsCard';
import CustomUrlCard from 'seller/app/components/profile/form/CustomUrlCard';
import GalleryCard from 'seller/app/components/profile/form/GalleryCard';
import MastheadCard from 'seller/app/components/profile/form/MastheadCard';
import ProfileDetailsCard from 'seller/app/components/profile/form/ProfileDetailsCard';
import ProfileTypeCard from 'seller/app/components/profile/form/ProfileTypeCard';
import SellerRepresentativeCard from 'seller/app/components/profile/form/SellerRepresentativeCard';
import ServicesCard from 'seller/app/components/profile/form/ServicesCard';

import styles from './Form.css';

const FormMobile = props => (
  <>
    <CustomUrlCard {...props} />
    <ProfileDetailsCard {...props} />
    <ContactDetailsCard {...props} />
    <SellerRepresentativeCard {...props} />
    <ServicesCard {...props} />
    <GalleryCard {...props} />
    <MastheadCard {...props} />
    {/* <ProfileTypeCard {...props} /> */}
  </>
);

const FormDesktop = props => (
  <div className={styles.formContent}>
    <div className={styles.formContentLeftColumn}>
      <ProfileDetailsCard {...props} />
      <ContactDetailsCard {...props} />
      <SellerRepresentativeCard {...props} />
      <ServicesCard {...props} />
    </div>
    <div className={styles.formContentRightColumn}>
      <CustomUrlCard {...props} />
      <GalleryCard {...props} />
      <MastheadCard {...props} />
    </div>
  </div>
);

const Form = props => {
  const { isBigScreen, onCancel, onSubmit } = props;

  return (
    <form className={styles.form}>
      {isBigScreen ? <FormDesktop {...props} /> : <FormMobile {...props} />}

      <div className={styles.buttons}>
        <Button className={styles.cancelButton} onClick={onCancel}>
          Cancel
        </Button>
        <Button className={styles.saveButton} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
