import produce from 'immer';
import { TOGGLE_MODAL } from 'seller/app/actions/modal';

const initialState = {
  modalData: false,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TOGGLE_MODAL:
        draft.modalData = action.payload;
        break;
    }
  });
