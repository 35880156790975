import React from 'react';

import {
  enhancements,
  fluorescences,
  genders,
  jewelryConditions,
  jewelryTypes,
  metalPurities,
  metalTypes,
  mountTypes,
  MountTypes,
  polishes,
  Settings,
  symmetries,
  watchMovements,
} from 'common/utils/inventory';
import { firstOption } from 'common/utils/misc';

import SectionCard from 'common/components/common/SectionCard';
import {
  SubSection,
  SubSectionTitle,
} from 'common/components/common/SubSection';

import CheckboxField from 'common/components/common/form/CheckboxField';
import Field from 'common/components/common/form/Field';
import FieldNote from 'common/components/common/form/FieldNote';
import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SelectField from 'common/components/common/form/SelectField';
import ToggleField from 'common/components/common/form/ToggleField';

import styles from './Form.css';

const JewelryConditionField = ({
  state: { jewelryCondition = firstOption(jewelryConditions) },
  error,
  handleFieldChange,
}) => (
  <RadioGroupField
    rowLayout
    label="Condition"
    radioGroupName="jewelryCondition"
    options={jewelryConditions}
    selectedOption={jewelryCondition}
    error={error.jewelryCondition}
    onChange={handleFieldChange('jewelryCondition')}
  />
);

const JewelryTypeField = ({
  state: { jewelryType = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Jewelry Type"
    options={jewelryTypes}
    selectedOption={jewelryType}
    nullOption="Select jewelry type"
    error={error.jewelryType}
    onChange={handleFieldChange('jewelryType')}
  />
);

const MaterialField = ({
  state: { material = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Material" error={error.material}>
    <input
      type="text"
      placeholder="Enter material"
      value={material}
      onChange={handleFieldChange('material')}
    />
  </Field>
);

const BrandField = ({ state: { brand = '' }, error, handleFieldChange }) => (
  <Field label="Brand" error={error.brand}>
    <input
      type="text"
      placeholder="Enter brand"
      value={brand}
      onChange={handleFieldChange('brand')}
    />
  </Field>
);

const SizeField = ({ state: { mountSize = '' }, error, handleFieldChange }) => (
  <Field label="Size" error={error.mountSize}>
    <input
      type="text"
      placeholder="Enter size (i.e. 3)"
      value={mountSize}
      onChange={handleFieldChange('mountSize')}
    />
  </Field>
);

const MetalTypeField = ({
  state: { metalType = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Metal Type"
    options={metalTypes}
    selectedOption={metalType}
    nullOption="Select metal type"
    error={error.metalType}
    onChange={handleFieldChange('metalType')}
  />
);

const MetalPurityField = ({
  state: { metalPurity = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Metal Type"
    options={metalPurities}
    selectedOption={metalPurity}
    nullOption="Select metal purity"
    error={error.metalPurity}
    onChange={handleFieldChange('metalPurity')}
  />
);

const MetalWeightField = ({
  state: { totalWeightGrams = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Metal Weight (g)" error={error.totalWeightGrams}>
    <input
      type="text"
      placeholder="Enter metal weight in grams"
      value={totalWeightGrams}
      onChange={handleFieldChange('totalWeightGrams')}
    />
  </Field>
);

const CertNumField = ({
  state: { certNum = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Certificate Number" error={error.certNum}>
    <input
      type="text"
      placeholder="Enter certificate number"
      value={certNum}
      onChange={handleFieldChange('certNum')}
    />
  </Field>
);

const LinkToCertField = ({
  state: { linkToCert = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Link to Certificate" error={error.linkToCert}>
    <input
      type="text"
      placeholder="Enter the URL/link to certificate "
      value={linkToCert}
      onChange={handleFieldChange('linkToCert')}
    />
  </Field>
);

const FluorescenceField = ({
  state: { fluorescence = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Fluorescence"
    options={fluorescences}
    selectedOption={fluorescence}
    nullOption="Select fluorescence"
    error={error.fluorescence}
    onChange={handleFieldChange('fluorescence')}
  />
);

const EnhancementField = ({
  state: { enhancement = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Enhancement / Treatment"
    options={enhancements}
    selectedOption={enhancement}
    nullOption="Select enhancement"
    error={error.enhancement}
    onChange={handleFieldChange('enhancement')}
  />
);

const SymmetryField = ({
  state: { symmetry = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Symmetry"
    options={symmetries}
    selectedOption={symmetry}
    nullOption="Select symmetry"
    error={error.symmetry}
    onChange={handleFieldChange('symmetry')}
  />
);

const PolishField = ({ state: { polish = '' }, error, handleFieldChange }) => (
  <SelectField
    label="Polish"
    options={polishes}
    selectedOption={polish}
    nullOption="Select polish"
    error={error.polish}
    onChange={handleFieldChange('polish')}
  />
);

const LaserInscriptionField = ({
  state: { laserInscription = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Laser Inscription" error={error.laserInscription}>
    <input
      type="text"
      placeholder="Enter any inscription details"
      value={laserInscription}
      onChange={handleFieldChange('laserInscription')}
    />
  </Field>
);

const SyntheticField = ({
  state: { synthetic = false },
  error,
  handleFieldChange,
}) => (
  <CheckboxField
    label="Synthetic"
    checked={synthetic}
    error={error.synthetic}
    onChange={handleFieldChange('synthetic')}
  />
);

const ProportionsField = ({
  state: { propLength = '', propWidth = '', propDepth = '' },
  error,
  handleFieldChange,
}) => (
  <Field
    label="Proportions (Length x Width x Depth)"
    error={[error.propLength, error.propWidth, error.propDepth]}
  >
    <div className={styles.propsContainer}>
      <input
        type="text"
        placeholder="Length"
        value={propLength}
        onChange={handleFieldChange('propLength')}
      />
      <input
        type="text"
        placeholder="Width"
        value={propWidth}
        onChange={handleFieldChange('propWidth')}
      />
      <input
        type="text"
        placeholder="Depth (mm)"
        value={propDepth}
        onChange={handleFieldChange('propDepth')}
      />
    </div>
  </Field>
);

const SideStonesWeightField = ({
  state: { totalWeightCarat = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Total Side Carat Weight" error={error.totalWeightCarat}>
    <input
      type="text"
      placeholder="Enter total side carats (i.e. 0.75)"
      value={totalWeightCarat}
      onChange={handleFieldChange('totalWeightCarat')}
    />
  </Field>
);

const IsHiddenField = ({
  state: { isHidden = false },
  error,
  handleFieldChange,
}) => (
  <CheckboxField
    label="Hide From Marketplace"
    checked={isHidden}
    error={error.isHidden}
    onChange={handleFieldChange('isHidden')}
  >
    <FieldNote>
      Only hide item if you are not ready to list it. Customers will not be able
      see or search for this item in any form.
    </FieldNote>
  </CheckboxField>
);

const QuantityField = ({
  state: { quantity = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Quantity" error={error.quantity}>
    <input
      type="text"
      placeholder="Enter quantity"
      value={quantity}
      onChange={handleFieldChange('quantity')}
    />
    <FieldNote>How much of this item do you have for sale? (i.e. 5)</FieldNote>
  </Field>
);

const MultiplePurchasesField = ({
  state: { recurring = false },
  error,
  handleFieldChange,
}) => (
  <ToggleField
    label="Allow Multiple Purchases?"
    checked={recurring}
    error={error.recurring}
    onChange={handleFieldChange('recurring')}
  >
    <FieldNote>
      Turn this option on if you would like to keep this item listing active for
      more than one purchase.
    </FieldNote>
  </ToggleField>
);

const InternalIdField = ({
  state: { internalId = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Internal ID" error={error.internalId}>
    <input
      type="text"
      placeholder="Enter internal ID (internal tracking)"
      value={internalId}
      onChange={handleFieldChange('internalId')}
    />
  </Field>
);

const WatchModelField = ({
  state: { watchModel = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Model" error={error.watchModel}>
    <input
      type="text"
      placeholder="Enter model"
      value={watchModel}
      onChange={handleFieldChange('watchModel')}
    />
  </Field>
);

const WatchMovementField = ({
  state: { watchMovement = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Movement"
    options={watchMovements}
    selectedOption={watchMovement}
    nullOption="Select movement"
    error={error.watchMovement}
    onChange={handleFieldChange('watchMovement')}
  />
);

const WatchCaseMaterialField = ({
  state: { watchCaseMaterial = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Case Material" error={error.watchCaseMaterial}>
    <input
      type="text"
      placeholder="Enter case material"
      value={watchCaseMaterial}
      onChange={handleFieldChange('watchCaseMaterial')}
    />
  </Field>
);

const WatchBraceletMaterialField = ({
  state: { watchBraceletMaterial = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Bracelet Material" error={error.watchBraceletMaterial}>
    <input
      type="text"
      placeholder="Enter bracelet material"
      value={watchBraceletMaterial}
      onChange={handleFieldChange('watchBraceletMaterial')}
    />
  </Field>
);

const YearField = ({ state: { year = '' }, error, handleFieldChange }) => (
  <Field label="Year" error={error.year}>
    <input
      type="text"
      placeholder="Enter year"
      value={year}
      onChange={handleFieldChange('year')}
    />
  </Field>
);

const GenderField = ({ state: { gender = '' }, error, handleFieldChange }) => (
  <SelectField
    label="Gender"
    options={genders}
    selectedOption={gender}
    nullOption="Select gender"
    error={error.gender}
    onChange={handleFieldChange('gender')}
  />
);

const SideStonesSection = props => (
  <SubSection title="Side Stones">
    <SideStonesWeightField {...props} />
  </SubSection>
);

const ListingSection = props => (
  <SubSection title="Listing">
    {/*TODO: enable this when backend fixed https://trello.com/c/M7XflOYt
    <IsHiddenField {...props} />*/}
    {props.setting !== Settings.SERVICE && <QuantityField {...props} />}
    <MultiplePurchasesField {...props} />
    <InternalIdField {...props} />
  </SubSection>
);

const renderLooseStone = props => (
  <>
    <CertNumField {...props} />
    <LinkToCertField {...props} />
    <FluorescenceField {...props} />
    <EnhancementField {...props} />
    <SymmetryField {...props} />
    <PolishField {...props} />
    <LaserInscriptionField {...props} />
    <SyntheticField {...props} />
    <ProportionsField {...props} />
    <ListingSection {...props} />
  </>
);

const renderDiamondJewelry = props => {
  const { mountType } = props.state;
  const isCenterStone = mountType === MountTypes.CENTER_STONE;
  return (
    <>
      <JewelryConditionField {...props} />
      <JewelryTypeField {...props} />
      <BrandField {...props} />
      <SizeField {...props} />
      <MetalTypeField {...props} />
      <MetalPurityField {...props} />
      <MetalWeightField {...props} />
      {isCenterStone && <SubSectionTitle title={mountTypes[mountType]} />}
      <CertNumField {...props} />
      <LinkToCertField {...props} />
      <FluorescenceField {...props} />
      <EnhancementField {...props} />
      <SymmetryField {...props} />
      <PolishField {...props} />
      <LaserInscriptionField {...props} />
      <SyntheticField {...props} />
      <ProportionsField {...props} />
      {isCenterStone && <SideStonesSection {...props} />}
      <ListingSection {...props} />
    </>
  );
};

const renderJewelry = props => {
  const { setting, containsGemstones, mountType } = props.state;
  const isCraft = setting === Settings.CRAFT_JEWELRY;
  const isCenterStone = mountType === MountTypes.CENTER_STONE;
  return (
    <>
      <JewelryConditionField {...props} />
      <JewelryTypeField {...props} />
      {isCraft && <MaterialField {...props} />}
      <BrandField {...props} />
      <SizeField {...props} />
      <MetalTypeField {...props} />
      <MetalPurityField {...props} />
      <MetalWeightField {...props} />
      {containsGemstones && (
        <>
          {isCenterStone && <SubSectionTitle title={mountTypes[mountType]} />}
          <CertNumField {...props} />
          <LinkToCertField {...props} />
          <FluorescenceField {...props} />
          <EnhancementField {...props} />
          <SymmetryField {...props} />
          <PolishField {...props} />
          <LaserInscriptionField {...props} />
          <SyntheticField {...props} />
          <ProportionsField {...props} />
          {isCenterStone && <SideStonesSection {...props} />}
        </>
      )}
      <ListingSection {...props} />
    </>
  );
};

const renderWatch = props => {
  const { containsGemstones, mountType } = props.state;
  const isCenterStone = mountType === MountTypes.CENTER_STONE;
  return (
    <>
      <JewelryConditionField {...props} />
      <BrandField {...props} />
      <WatchModelField {...props} />
      <WatchMovementField {...props} />
      <WatchCaseMaterialField {...props} />
      <WatchBraceletMaterialField {...props} />
      <YearField {...props} />
      <GenderField {...props} />
      <SizeField {...props} />
      <MetalTypeField {...props} />
      <MetalPurityField {...props} />
      <MetalWeightField {...props} />
      {containsGemstones && (
        <>
          {isCenterStone && <SubSectionTitle title={mountTypes[mountType]} />}
          <CertNumField {...props} />
          <LinkToCertField {...props} />
          <FluorescenceField {...props} />
          <EnhancementField {...props} />
          <SymmetryField {...props} />
          <PolishField {...props} />
          <LaserInscriptionField {...props} />
          <SyntheticField {...props} />
          <ProportionsField {...props} />
          {isCenterStone && <SideStonesSection {...props} />}
        </>
      )}
      <ListingSection {...props} />
    </>
  );
};

const renderService = props => <ListingSection {...props} />;

const renderers = {
  [Settings.LOOSE_DIAMOND]: renderLooseStone,
  [Settings.LOOSE_GEMSTONE]: renderLooseStone,
  [Settings.DIAMOND_JEWELRY]: renderDiamondJewelry,
  [Settings.JEWELRY]: renderJewelry,
  [Settings.CRAFT_JEWELRY]: renderJewelry,
  [Settings.WATCH]: renderWatch,
  [Settings.SERVICE]: renderService,
};

const InventoryOptionalDetailsCard = props => {
  const {
    state: { setting },
  } = props;

  if (!setting) {
    return null;
  }

  return (
    <SectionCard title="Optional Item Details" initialCollapsed={true}>
      {renderers[setting](props)}
    </SectionCard>
  );
};

export default InventoryOptionalDetailsCard;
