import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import HelpCenter from 'common/components/common/HelpCenter';
import Modal from 'common/components/common/mobile/MobileModal';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import { LIST_PAGE_LENGTH } from 'common/constants';
import { searchInventoryByRetailerId } from 'common/services/algolia';
import { isPrivateRetailer } from 'common/utils/retailer';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import LastWeekViews from 'seller/app/components/common/LastWeekView';
import TabBar from 'seller/app/components/common/TabBar';
import InventoryList from 'seller/app/components/inventory/list/List';
import { TypePrefix } from 'seller/app/components/inventory/list/ListFilters';
import Onboarding from 'seller/app/components/onboarding/Onboarding';
import { Routes } from 'seller/app/constants';

const InventoryListPageMobile = props => (
  <>
    <Modal />
    <Screen>
      <PageMobile>
        <PageContent heightReducedBy={60}>
          <HeaderNavMobile />

          <PageHeader>Inventory</PageHeader>

          <PageSection>
            <BannerAd />
          </PageSection>

          <PageSection>
            <Onboarding />
          </PageSection>

          <PageSection>
            <LastWeekViews />
          </PageSection>

          <PageSection>
            <InventoryList {...props} />
          </PageSection>
        </PageContent>

        <TabBar activeItem={Routes.inventory} />
      </PageMobile>
    </Screen>
  </>
);

const InventoryListPageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.inventory} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <Onboarding />
    </PageSection>

    <PageSection>
      <LastWeekViews />
    </PageSection>

    <PageSection>
      <InventoryList {...props} />
    </PageSection>

    <PageSection>
      <HelpCenter
        title="Tips on Preparing Your Store"
        link="https://intercom.help/gemsby/en/articles/1578642-tips-on-preparing-your-store"
        center
      />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const InventoryListPage = ({ retailer, isBigScreen }) => {
  const [state, setState] = useState({
    loading: true,
    items: null,
    currentPage: 0,
    totalPages: 0,
    query: '',
    type: '',
    setting: '',
    jewelryType: '',
  });

  const setLoading = (loading: boolean) =>
    setState({
      ...state,
      loading,
    });

  const setCurrentPage = (currentPage: number) =>
    setState({
      ...state,
      currentPage,
    });

  const onFilterChange = (query?: string, type?: string) => {
    const [prefix, value] = (type || '').split(':');

    if (prefix === TypePrefix.setting) {
      state.setting = value;
      state.jewelryType = '';
    } else if (prefix === TypePrefix.jewelryType) {
      state.jewelryType = value;
      state.setting = '';
    } else {
      state.setting = '';
      state.jewelryType = '';
    }

    setState({
      ...state,
      type,
      query: query.trim(),
    });
  };

  useEffect(() => {
    const { query, setting, jewelryType } = state;
    setLoading(true);
    searchInventoryByRetailerId({
      retailerId: retailer.id,
      isPrivateRetailer: isPrivateRetailer(retailer),
      query,
      setting,
      jewelryType,
      sortBy: 'recentUpdate',
      page: state.currentPage,
      pageSize: LIST_PAGE_LENGTH,
    }).then(({ hits, page, nbPages }) => {
      setState({
        ...state,
        loading: false,
        items: hits || [],
        currentPage: page,
        totalPages: nbPages,
      });
    });
  }, [state.currentPage, state.query, state.type]);

  const ListPage = isBigScreen
    ? InventoryListPageDesktop
    : InventoryListPageMobile;

  return (
    <ListPage
      isBigScreen={isBigScreen}
      state={state}
      setCurrentPage={setCurrentPage}
      onFilterChange={onFilterChange}
    />
  );
};

const mapState = ({ login, device }) => ({
  retailer: login.currentUser,
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(InventoryListPage);
