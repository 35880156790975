const statusCodeToText = (statusCodes, code) => statusCodes[code] || 'unknown';

enum InventoryStatusCodes {
  AVAILABLE = 1,
  RESERVED,
  HIDDEN,
  SOLD,
  SOLD_OUT,
  DELETED,
  PRIVATE,
}

const inventoryStatusCodes = {
  [InventoryStatusCodes.AVAILABLE]: 'available',
  [InventoryStatusCodes.RESERVED]: 'reserved',
  [InventoryStatusCodes.HIDDEN]: 'hidden',
  [InventoryStatusCodes.SOLD]: 'sold',
  [InventoryStatusCodes.SOLD_OUT]: 'sold',
  [InventoryStatusCodes.DELETED]: 'deleted',
  [InventoryStatusCodes.PRIVATE]: 'private',
};

enum RetailerStatusCodes {
  AVAILABLE = 1,
  DELETED,
  PRIVATE,
}

const retailerStatusCodes = {
  [RetailerStatusCodes.AVAILABLE]: 'available',
  [RetailerStatusCodes.DELETED]: 'deleted',
  [RetailerStatusCodes.PRIVATE]: 'private',
};

export default {
  inventory: {
    statusCodes: InventoryStatusCodes,
    statusCodeToText: statusCodeToText.bind(null, inventoryStatusCodes),
  },
  retailer: {
    statusCodes: RetailerStatusCodes,
    statusCodeToText: statusCodeToText.bind(null, retailerStatusCodes),
  }
};
