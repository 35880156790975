// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-datatable-DataTable__container,.projects-seller-app-components-common-datatable-DataTable__table{width:100%}.projects-seller-app-components-common-datatable-DataTable__table{table-layout:fixed;word-wrap:break-word;border-collapse:collapse}.projects-seller-app-components-common-datatable-DataTable__table tbody tr{border-bottom:1px solid #d7d7d7}.projects-seller-app-components-common-datatable-DataTable__table tbody tr.projects-seller-app-components-common-datatable-DataTable__emptyRow{border:none}.projects-seller-app-components-common-datatable-DataTable__tableHeader{color:#fff;background-color:#333;font-size:14px;font-weight:400}.projects-seller-app-components-common-datatable-DataTable__tableHeader th{height:48px;text-align:center}.projects-seller-app-components-common-datatable-DataTable__tableHeader th:first-of-type{padding-left:20px}.projects-seller-app-components-common-datatable-DataTable__headerItem{display:flex;align-items:center;justify-content:flex-start;padding:0 5px}.projects-seller-app-components-common-datatable-DataTable__headerText{display:inline-block;margin-right:5px}.projects-seller-app-components-common-datatable-DataTable__item{height:68px}.projects-seller-app-components-common-datatable-DataTable__emptyRow td{text-align:center;height:200px}.projects-seller-app-components-common-datatable-DataTable__itemCell{padding:5px}", ""]);
// Exports
exports.locals = {
	"container": "projects-seller-app-components-common-datatable-DataTable__container",
	"table": "projects-seller-app-components-common-datatable-DataTable__table",
	"emptyRow": "projects-seller-app-components-common-datatable-DataTable__emptyRow",
	"tableHeader": "projects-seller-app-components-common-datatable-DataTable__tableHeader",
	"headerItem": "projects-seller-app-components-common-datatable-DataTable__headerItem",
	"headerText": "projects-seller-app-components-common-datatable-DataTable__headerText",
	"item": "projects-seller-app-components-common-datatable-DataTable__item",
	"itemCell": "projects-seller-app-components-common-datatable-DataTable__itemCell"
};
module.exports = exports;
