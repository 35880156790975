import classNames from 'classnames';
import React from 'react';

import spinningDiamondGif from 'common/assets/images/spinning-diamond.gif';
import styles from './SpinningDiamond.css';

interface ISpinningDiamond extends Gemsby.IComponentProps {
  fullScreen?: boolean;
  transparent?: boolean;
  smallIcon?: boolean;
}

const SpinningDiamond: React.FC<ISpinningDiamond> = ({
  className,
  fullScreen,
  transparent,
  smallIcon,
}) => (
  <div
    className={classNames(
      'spinningDiamond',
      styles.spinningDiamond,
      className,
      {
        transparent,
        loadingFullScreen: fullScreen,
        [styles.smallIcon]: smallIcon,
      }
    )}
  >
    <img src={spinningDiamondGif} />
  </div>
);

export default SpinningDiamond;
