// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/mobile/Modal.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-chat-Form__chatContainer{width:100%;position:relative;border:2px solid #d3d3d3;border-radius:7px;height:350px}.projects-seller-app-components-chat-Form__buttons{display:flex;justify-content:center;margin-top:30px}.projects-seller-app-components-chat-Form__messageContain{padding:0 13px;height:283px;overflow-y:scroll}.projects-seller-app-components-chat-Form__fieldContain{display:flex;justify-content:center}.projects-seller-app-components-chat-Form__received{color:var(--mainColor)}.projects-seller-app-components-chat-Form__received,.projects-seller-app-components-chat-Form__sent{font-weight:700;margin-bottom:0}.projects-seller-app-components-chat-Form__sent{color:#7e7db1}.projects-seller-app-components-chat-Form__message{margin-top:3px}.projects-seller-app-components-chat-Form__backButton{width:49%}.projects-seller-app-components-chat-Form__messageContain::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#f5f5f5}.projects-seller-app-components-chat-Form__messageContain::-webkit-scrollbar{width:6px;background-color:#f5f5f5}.projects-seller-app-components-chat-Form__messageContain::-webkit-scrollbar-thumb{background-color:var(--mainColor)}.projects-seller-app-components-chat-Form__chatModal{width:420px;margin:0 10px}.projects-seller-app-components-chat-Form__chatImagePicker,.projects-seller-app-components-chat-Form__uploadButton{margin-top:10px;width:100%}.projects-seller-app-components-chat-Form__uploadButton{}.projects-seller-app-components-chat-Form__loaderContainer{display:flex;justify-content:center;align-items:center}.projects-seller-app-components-chat-Form__imageContainer{width:250px;margin:4px 15px}@media only screen and (max-width:400px){.projects-seller-app-components-chat-Form__imageContainer{width:100%;margin:0 0 4px}}", ""]);
// Exports
exports.locals = {
	"chatContainer": "projects-seller-app-components-chat-Form__chatContainer",
	"buttons": "projects-seller-app-components-chat-Form__buttons",
	"messageContain": "projects-seller-app-components-chat-Form__messageContain",
	"fieldContain": "projects-seller-app-components-chat-Form__fieldContain",
	"received": "projects-seller-app-components-chat-Form__received",
	"sent": "projects-seller-app-components-chat-Form__sent",
	"message": "projects-seller-app-components-chat-Form__message",
	"backButton": "projects-seller-app-components-chat-Form__backButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainButton"] + "",
	"chatModal": "projects-seller-app-components-chat-Form__chatModal " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["modal"] + "",
	"chatImagePicker": "projects-seller-app-components-chat-Form__chatImagePicker",
	"uploadButton": "projects-seller-app-components-chat-Form__uploadButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"loaderContainer": "projects-seller-app-components-chat-Form__loaderContainer",
	"imageContainer": "projects-seller-app-components-chat-Form__imageContainer"
};
module.exports = exports;
