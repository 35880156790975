import React from 'react';
import styles from './AboveCardsButtonMobile.css';
import NoThanksButton from 'seller/app/components/inventory/promote/NoThanksButton/NoThanksButton';

const AboveCardsButtonMobile = () => {
  return (
    <div className={styles.buttonContainer}>
      <NoThanksButton/>
    </div>
  );
};

export default AboveCardsButtonMobile;
