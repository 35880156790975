import React from 'react';

import Button from 'common/components/common/Button';
import Card from 'common/components/common/Card';
import OutLink from 'common/components/common/OutLink';
import OrderDetails from 'seller/app/components/order/form/OrderDetails';
import TrackingDetails from 'seller/app/components/order/form/TrackingDetails';
import TrackingDetailsForm from 'seller/app/components/order/form/TrackingDetailsForm';

import styles from './Form.css';

const Form = ({ history, order, ...trackingFormProps }) => {
  const { shippingTrackingId } = order.attributes;
  return (
    <div>
      <Card>
        {shippingTrackingId ? (
          <TrackingDetails order={order} />
        ) : (
          <TrackingDetailsForm {...trackingFormProps} />
        )}
        <OrderDetails order={order} />
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={event => {
              event.preventDefault();
              history.push(`/chat/${order.id}?anonymous=false`);
            }}
          >
            Message Customer
          </Button>
        </div>
      </Card>
      <div className={styles.buttons}>
        <Button
          className={styles.backButton}
          onClick={event => {
            event.preventDefault();
            history.goBack();
          }}
        >
          Go Back
        </Button>
        <OutLink className={styles.linkButton} to="">
          Shared Link
        </OutLink>
      </div>
    </div>
  );
};

export default Form;
