import { History } from 'history';
import React from 'react';
import { withRouter } from 'react-router';

import Button from 'common/components/common/Button';
import OutLink from 'common/components/common/OutLink';
import SectionCard from 'common/components/common/SectionCard';
import { SUPPORT_EMAIL } from 'common/constants';

import styles from './Form.css';

interface IMultipleItemsFormProps {
  history: History;
}

const MultipleItemsForm: React.FC<IMultipleItemsFormProps> = ({ history }) => {
  return (
    <form className={styles.multipleItemsForm}>
      <SectionCard title="Upload Service">
        <p>
          Looking to transfer multiple items from another platform/marketplace
          into your Gemsby store? Or, you may even have a CSV file with all of
          the data properly formatted.
        </p>
        <p>
          We can upload all of your items for you with our concierge upload
          service. <strong>Our service cost is $0.10 USD per item.</strong>
        </p>
        <p>
          If you are sending us a properly formatted CSV file (
          <a href="">Sample</a>), refer to our{' '}
          <a href="#">CSV Formatting Guide</a>, which follows industry-standard
          naming conventions.
        </p>
        <p>Contact us with your requirements and we’ll go from there!</p>
        <div className={styles.buttons}>
          <Button
            className={styles.cancelButton}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <OutLink className={styles.submitButton} to={`mailto:${SUPPORT_EMAIL}`}>
              Contact Us to Purchase
          </OutLink>
        </div>
      </SectionCard>
    </form>
  );
};

export default withRouter(MultipleItemsForm);
