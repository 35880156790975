import React from 'react';
import { Link } from 'react-router-dom';

import styles from 'seller/app/components/common/Button.css';

const CollectPaymentsButton = () => (
  <Link className={styles.regularActionButton} to="/payment">
    <span>Collect Payments</span>
  </Link>
);

export default CollectPaymentsButton;
