import React from 'react';
import styles from './Form.css';
import getParameterByName from '../../utils/getParameterByName'

const Received = ({ userEmail, message, images }) => {
  const userName = getParameterByName('t','')
  return (
    <div>
      <p className={styles.received}>{userName || userEmail}</p>
      <p className={styles.message}>{message}</p>
      {!!images.length &&
        images.map(item => {
          return (
            <div className={styles.imageContainer}>
              <img src={item} width="100%" />
            </div>
          );
        })}
    </div>
  );
};

export default Received;
