import React from 'react';
import styles from './promoteBannerDesktop.css'
import mobileImage from '../../../../../assets/images/Promote/iphone12.png';
import Button from 'common/components/common/Button';


const PromoteBannerDesktop = () => {
  return (
    <div className={styles.banner}>

      {/* left text*/}
      <div className={styles.textContainer}>
        <h1 className={styles.mainHeading}>
          Promote
        </h1>
        <h2 className={styles.subHeading}>Why need promotion your items</h2>

        <div className={styles.buttonsContainer}>
          <Button className={styles.submitButton}>
            Promote
          </Button>
          <Button className={styles.learnMoreButton}>
            Learn More
          </Button>
        </div>

        <div className={styles.bottomCards}>
          {/* left side of bottom card*/}
          <div className={styles.bottomCardLeft}>
              <h4 className={styles.bottomCardLeftHeading}>Why <br/> Me?</h4>
          </div>

          {/* right side of bottom container*/}
          <div className={styles.bottomCardRight}>
            <h4 className={styles.bottomCardRightMainHeading}>Your <br/> Promotion <br/> Value</h4>
            <h5 className={styles.bottomCardRightSubHeading}>Promotion is great for growing a new business, promotion helps new seller to get more sales.</h5>
          </div>
        </div>

      </div>

      {/* right mobile image*/}
      <div className={styles.imageContainer}>
        <img className={styles.mobileImage} src={mobileImage}/>
      </div>

    </div>
  );
};

export default PromoteBannerDesktop;
