// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js!./Card.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-common-components-common-SectionCard__sectionCard{padding:0;margin-bottom:20px;display:flex;flex-direction:column}.projects-common-components-common-SectionCard__sectionCard.collapsed .projects-common-components-common-SectionCard__header{border:none}.projects-common-components-common-SectionCard__sectionCard.collapsed .projects-common-components-common-SectionCard__content{height:0;padding:0;flex:none}.projects-common-components-common-SectionCard__header{display:flex;align-items:center;padding:20px;color:var(--mainColor);font-weight:400;border-bottom:1px dashed #dedede}.projects-common-components-common-SectionCard__header .fa{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:12px}.projects-common-components-common-SectionCard__title{margin-left:5px;color:var(--mainColor);font-size:14px;font-weight:400}.projects-common-components-common-SectionCard__content{flex:1;padding:20px;overflow:hidden}.projects-common-components-common-SectionCard__sectionIntro{margin:0 0 20px;font-size:14px}", ""]);
// Exports
exports.locals = {
	"sectionCard": "projects-common-components-common-SectionCard__sectionCard " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["card"] + "",
	"header": "projects-common-components-common-SectionCard__header",
	"content": "projects-common-components-common-SectionCard__content",
	"title": "projects-common-components-common-SectionCard__title",
	"sectionIntro": "projects-common-components-common-SectionCard__sectionIntro"
};
module.exports = exports;
