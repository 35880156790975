import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'common/components/common/Button';
import OutLink from 'common/components/common/OutLink';

import styles from './HeaderButton.css';

interface IHeaderLinkProps extends Gemsby.ISimpleWrapperProps {
  to: string;
  external?: boolean;
}

const HeaderLink: React.FC<IHeaderLinkProps> = ({
  to,
  external,
  children,
  ...props
}) => {
  const LinkComponent = external ? OutLink : Link;
  return (
    <LinkComponent to={to}>
      <Button className={styles.headerButton} {...props}>
        {children}
      </Button>
    </LinkComponent>
  );
};

export default HeaderLink;
