import React from 'react';
import { Link } from 'react-router-dom';

import styles from 'seller/app/components/common/Button.css';

const AddMultipleButton = () => (
  <Link className={styles.regularActionButton} to="/inventory/multiple">
    <span>Add Multiple Items</span>
  </Link>
);

export default AddMultipleButton;
