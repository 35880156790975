import React, { useEffect } from 'react';

import { isNaOption } from 'common/utils/form';
import {
  certificates,
  clarities,
  colors,
  colorTypes,
  ColorTypes,
  cuts,
  gemstoneTypes,
  hues,
  intensities,
  MountTypes,
  mountTypes,
  parcelSizes,
  ParcelSizes,
  SaleMarkupTypes,
  saleMarkupTypes,
  Settings,
  shapes,
} from 'seller/app/utils/inventory';

import CheckboxField from 'common/components/common/form/CheckboxField';
import Field from 'common/components/common/form/Field';
import FieldNote from 'common/components/common/form/FieldNote';
import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SelectField from 'common/components/common/form/SelectField';
import ToggleField from 'common/components/common/form/ToggleField';
import SectionCard from 'common/components/common/SectionCard';
import { SubSectionTitle } from 'common/components/common/SubSection';

import styles from './Form.css';

const HidePriceField = ({
  state: { hidePrice = false },
  error,
  handleFieldChange,
}) => (
  <CheckboxField
    className={styles.checkboxField}
    label="Call for price"
    checked={hidePrice}
    error={error.hidePrice}
    onChange={handleFieldChange('hidePrice')}
  />
);

const MarkupField = ({ state: { markup = '' }, error, handleFieldChange }) => (
  <Field label="Markup" error={error.markup}>
    <input
      type="text"
      placeholder="Enter markup % as a decimal (i.e. 0.9 for 90%)"
      value={markup}
      onChange={handleFieldChange('markup')}
    />
    <FieldNote>
      This percentage will mark up the retail price you entered earlier. We will
      only show the marked up price.
    </FieldNote>
  </Field>
);

const SalePriceField = ({
  state: { salePrice = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Sale Price" error={error.salePrice}>
    <input
      type="text"
      placeholder="Enter sale price"
      value={salePrice}
      onChange={handleFieldChange('salePrice')}
    />
    <FieldNote>
      We will automatically show the sale percentage (%) from retail price you
      entered earlier.
    </FieldNote>
  </Field>
);

const SaleMarkupTypeField = props => {
  const {
    state: { saleMarkupType = '' },
    error,
    handleFieldChange,
  } = props;
  return (
    <>
      <RadioGroupField
        className={styles.saleMarkupTypeField}
        rowLayout
        label="Sale or Markup?"
        radioGroupName="saleMarkupType"
        options={saleMarkupTypes}
        selectedOption={saleMarkupType}
        error={error.saleMarkupType}
        onChange={handleFieldChange('saleMarkupType')}
      />
      {saleMarkupType === SaleMarkupTypes.SALE && <SalePriceField {...props} />}
      {saleMarkupType === SaleMarkupTypes.MARKUP && <MarkupField {...props} />}
    </>
  );
};

const PriceField = ({
  state: { price, currency },
  error,
  handleFieldChange,
}) => (
  <Field label={`Price (${currency})`} error={error.price}>
    <input
      type="text"
      placeholder="Enter retail price"
      value={price}
      onChange={handleFieldChange('price')}
    />
    <FieldNote>
      Currency is selected in your Account Settings. If you want to hide the
      price, check the box below after entering any number.
    </FieldNote>
  </Field>
);

const ItemTitleField = ({
  state: { customTitle = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Item Title" error={error.customTitle}>
    <input
      type="text"
      placeholder="Enter the title the item"
      maxLength={50}
      value={customTitle}
      onChange={handleFieldChange('customTitle')}
    />
    <FieldNote>
      Maximum 50 characters. The item title will be generated automatically if
      this field is left blank.
    </FieldNote>
  </Field>
);

const ItemDescriptionField = ({
  state: { ringDescription = '' },
  error,
  handleFieldChange,
}) => (
  <Field label="Item Description" error={error.ringDescription}>
    <textarea
      placeholder="Enter basic details about the item. These words are directly searchable on the marketplace."
      value={ringDescription}
      onChange={handleFieldChange('ringDescription')}
    />
  </Field>
);

const ContainsGemstonesField = ({
  state: { containsGemstones = false },
  handleFieldChange,
}) => (
  <ToggleField
    label="Contains Gemstones?"
    checked={containsGemstones}
    onChange={handleFieldChange('containsGemstones')}
  />
);

const MountTypeField = ({
  state: { mountType = MountTypes.CENTER_STONE },
  error,
  handleFieldChange,
}) => (
  <RadioGroupField
    label="How are the gemstones presented?"
    radioGroupName="mountType"
    options={mountTypes}
    selectedOption={mountType}
    error={error.mountType}
    onChange={handleFieldChange('mountType')}
  />
);

const GemstoneTypeField = ({
  state: { gemstone = '' },
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Gemstone"
    options={gemstoneTypes}
    selectedOption={gemstone}
    nullOption="Select gemstone"
    error={error.gemstone}
    onChange={handleFieldChange('gemstone')}
  />
);

const ShapeField = ({
  state: { shape = '' },
  naOption = true,
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Shape"
    options={shapes}
    selectedOption={shape}
    nullOption="Select shape"
    naOption={naOption}
    error={error.shape}
    onChange={handleFieldChange('shape')}
  />
);

const CaratField = ({
  state: { carat = '', mountType },
  error,
  handleFieldChange,
}) => {
  const value = isNaOption(carat) ? 'Not Applicable' : carat;
  const isMultiple = mountType === MountTypes.MULTIPLE_STONES;
  const label = isMultiple ? 'Total Carats' : 'Carat';
  const placeholder = isMultiple
    ? 'Enter total carats (i.e. 0.75)'
    : 'Enter carat (i.e. 0.75)';
  return (
    <Field label={label} error={error.carat}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleFieldChange('carat')}
      />
    </Field>
  );
};

const CutField = ({
  state: { cut = '' },
  naOption = true,
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Cut"
    options={cuts}
    selectedOption={cut}
    nullOption="Select cut"
    naOption={naOption}
    error={error.cut}
    onChange={handleFieldChange('cut')}
  />
);

const ClarityField = ({
  state: { clarity = '' },
  naOption = true,
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Clarity"
    options={clarities}
    selectedOption={clarity}
    nullOption="Select clarity"
    naOption={naOption}
    error={error.clarity}
    onChange={handleFieldChange('clarity')}
  />
);

const ColorTypeField = ({ state, error, setState }) => {
  const { setting, colorType } = state;

  const onChange = event => {
    setState({
      ...state,
      color: '',
      colorType: event.target.value,
    });
  };

  return (
    <RadioGroupField
      rowLayout
      label="Color Type"
      radioGroupName="colorType"
      options={colorTypes}
      selectedOption={colorType}
      naOption={
        setting !== Settings.LOOSE_DIAMOND &&
        setting !== Settings.DIAMOND_JEWELRY
      }
      error={error.colorType}
      onChange={onChange}
    />
  );
};

const ColorField = ({
  state: { color = '', colorType },
  naOption = true,
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Color"
    options={colors[colorType || ColorTypes.REGULAR]}
    selectedOption={color}
    nullOption="Select color"
    naOption={naOption}
    error={error.color}
    onChange={handleFieldChange('color')}
  />
);

const IntensityField = ({
  state: { intensity = '', colorType },
  naOption = true,
  error,
  handleFieldChange,
}) =>
  colorType === ColorTypes.FANCY && (
    <SelectField
      label="Intensity"
      options={intensities}
      selectedOption={intensity}
      nullOption="Select intensity"
      naOption={naOption}
      error={error.intensity}
      onChange={handleFieldChange('intensity')}
    />
  );

const HueField = ({
  state: { hue = '', colorType },
  naOption = true,
  error,
  handleFieldChange,
}) =>
  colorType === ColorTypes.FANCY && (
    <SelectField
      label="Hue"
      options={hues}
      selectedOption={hue}
      nullOption="Select hue"
      naOption={naOption}
      error={error.hue}
      onChange={handleFieldChange('hue')}
    />
  );

const CertificateField = ({
  state: { certificate = '' },
  naOption = true,
  error,
  handleFieldChange,
}) => (
  <SelectField
    label="Certificate"
    options={certificates}
    selectedOption={certificate}
    nullOption="Select certificate"
    naOption={naOption}
    error={error.certificate}
    onChange={handleFieldChange('certificate')}
  />
);

const ParcelSizeField = ({
  state: { parcelSize = ParcelSizes.SINGLE },
  error,
  handleFieldChange,
}) => (
  <RadioGroupField
    label="Parcel Size"
    radioGroupName="parcelSize"
    options={parcelSizes}
    selectedOption={parcelSize}
    error={error.parcelSize}
    onChange={handleFieldChange('parcelSize')}
  />
);

const BasicInfoSection = props => (
  <>
    <PriceField {...props} />
    <HidePriceField {...props} />
    <SaleMarkupTypeField {...props} />
    <ItemTitleField {...props} />
    <ItemDescriptionField {...props} />
  </>
);

const renderLooseStone = props => {
  const { setting } = props.state;
  const isGemstone = setting === Settings.LOOSE_GEMSTONE;
  return (
    <>
      <BasicInfoSection {...props} />
      {isGemstone && <GemstoneTypeField {...props} />}
      <ShapeField {...props} naOption={isGemstone} />
      <CaratField {...props} />
      <CutField {...props} naOption={isGemstone} />
      <ClarityField {...props} naOption={isGemstone} />
      <ColorTypeField {...props} />
      <ColorField {...props} naOption={isGemstone} />
      <IntensityField {...props} naOption={isGemstone} />
      <HueField {...props} naOption={isGemstone} />
      <CertificateField {...props} naOption={isGemstone} />
      <ParcelSizeField {...props} />
    </>
  );
};

const renderDiamondJewelry = props => {
  const { mountType } = props.state;
  return (
    <>
      <BasicInfoSection {...props} />
      <MountTypeField {...props} />
      <GemstoneTypeField {...props} />
      <SubSectionTitle title={mountTypes[mountType]} />
      <ShapeField {...props} />
      <CaratField {...props} />
      <CutField {...props} />
      <ClarityField {...props} />
      <ColorTypeField {...props} />
      <ColorField {...props} />
      <IntensityField {...props} />
      <HueField {...props} />
      <CertificateField {...props} />
      <ParcelSizeField {...props} />
    </>
  );
};

const renderJewelry = props => {
  const { containsGemstones, mountType } = props.state;
  return (
    <>
      <BasicInfoSection {...props} />
      <ContainsGemstonesField {...props} />
      {containsGemstones && (
        <>
          <MountTypeField {...props} />
          <GemstoneTypeField {...props} />
          <SubSectionTitle title={mountTypes[mountType]} />
          <ShapeField {...props} />
          <CaratField {...props} />
          <CutField {...props} />
          <ClarityField {...props} />
          <ColorTypeField {...props} />
          <ColorField {...props} />
          <IntensityField {...props} />
          <HueField {...props} />
          <CertificateField {...props} />
          <ParcelSizeField {...props} />
        </>
      )}
    </>
  );
};

const renderService = props => (
  <>
    <PriceField {...props} />
    <SaleMarkupTypeField {...props} />
    <ItemTitleField {...props} />
    <ItemDescriptionField {...props} />
  </>
);

const renderers = {
  [Settings.LOOSE_DIAMOND]: renderLooseStone,
  [Settings.LOOSE_GEMSTONE]: renderLooseStone,
  [Settings.DIAMOND_JEWELRY]: renderDiamondJewelry,
  [Settings.JEWELRY]: renderJewelry,
  [Settings.CRAFT_JEWELRY]: renderJewelry,
  [Settings.WATCH]: renderJewelry,
  [Settings.SERVICE]: renderService,
};

const InventoryDetailsCard = props => {
  const {
    state: { setting },
  } = props;

  if (!setting) {
    return null;
  }

  return (
    <SectionCard title="Item Details">{renderers[setting](props)}</SectionCard>
  );
};

export default InventoryDetailsCard;
