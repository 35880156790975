import moment from 'moment'
import React from 'react';

import OutLink from 'common/components/common/OutLink';
import SubSection from 'common/components/common/SubSection';
import OrderField from 'seller/app/components/order/form/OrderField';

const OrderDetails = ({ order }) => {
  const {
    billingCity,
    billingCountry,
    billingName,
    billingZip,
    billingAddress1,
    paymentType,
    createdDay,
    email,
    phone,
    orderId,
  } = order.attributes;
  return (
    <SubSection title="Order Details">
      <OrderField label="Order Number" value={orderId} />
      <OrderField label="Order Date" value={moment(createdDay).format('ll')} />
      <OrderField label="Payment" value={paymentType.toUpperCase()} />
      <OrderField label="Customer Name" value={billingName} />
      {/* <OrderField label="Customer Email" value={<OutLink to={`mailto:${email}`}>{email}</OutLink>} />
      <OrderField label="Customer Phone" value={phone} /> */}
      <OrderField label="Shipping Address" value={billingAddress1} />
      <OrderField label="City" value={billingCity} />
      <OrderField label="Zip / Postal Code" value={billingZip} />
      <OrderField label="Country" value={billingCountry} />
    </SubSection>
  );
};

export default OrderDetails;
