// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-landing-Footer__footer{padding:20px 10px}.projects-seller-app-components-landing-Footer__footer div{text-align:center;margin:5px 0;color:var(--headerTextColor);font-weight:400}.projects-seller-app-components-landing-Footer__footer a{color:var(--mainColor)}.projects-seller-app-components-landing-Footer__seenOn{display:block;margin-bottom:20px}.projects-seller-app-components-landing-Footer__seenOn h3{font-size:14px;font-weight:700;color:#aaa;margin:10px 0}.projects-seller-app-components-landing-Footer__seenOn img{display:inline-block;margin:10px 30px;max-height:60px;max-width:120px;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"footer": "projects-seller-app-components-landing-Footer__footer",
	"seenOn": "projects-seller-app-components-landing-Footer__seenOn"
};
module.exports = exports;
