// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-common-components-common-Dialog__container{position:absolute;top:0;bottom:0;left:0;right:0;z-index:99999;background-color:rgba(0,0,0,.65);display:flex;align-items:center;justify-content:center}.projects-common-components-common-Dialog__dialog{width:500px;height:200px;padding:20px;display:flex;flex-direction:column;background-color:#fff;border-radius:2px}.projects-common-components-common-Dialog__message{flex:1}.projects-common-components-common-Dialog__buttons{display:flex;justify-content:flex-end}.projects-common-components-common-Dialog__button{min-width:120px;margin:0 5px}.projects-common-components-common-Dialog__okButton{color:#fff}@media (max-width:768px){.projects-common-components-common-Dialog__dialog{width:auto;margin:20px}.projects-common-components-common-Dialog__buttons{justify-content:center}.projects-common-components-common-Dialog__button{min-width:auto;padding:10px;flex:1}}", ""]);
// Exports
exports.locals = {
	"container": "projects-common-components-common-Dialog__container",
	"dialog": "projects-common-components-common-Dialog__dialog",
	"message": "projects-common-components-common-Dialog__message",
	"buttons": "projects-common-components-common-Dialog__buttons",
	"button": "projects-common-components-common-Dialog__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainButton"] + "",
	"okButton": "projects-common-components-common-Dialog__okButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + ""
};
module.exports = exports;
