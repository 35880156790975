import React from 'react';

import device from 'common/services/device';

import styles from './StatusBarFill.css';

const StatusBarFill = ({ color }) => {
  if (!device.isIos()) {
    return null;
  }
  let inlineStyle = {};
  if (color) {
    inlineStyle.backgroundColor = color;
  }
  if (device.hasNotch()) {
    inlineStyle.height = 0;
  }
  return (
    <div
      className={`statusBarFill ${styles.statusBarFill}`}
      style={inlineStyle}
    />
  );
};

export default StatusBarFill;
