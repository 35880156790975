import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from 'seller/app/components/common/header/Header';
import HeaderButtonMarketplace from 'seller/app/components/common/header/HeaderButtonMarketplace';
import { Routes } from 'seller/app/constants';
import auth from 'seller/app/services/auth';
import { connect } from 'react-redux';
import styles from './HeaderNav.css';

interface IHeaderDesktopProps extends Gemsby.IComponentProps {
  activeItem: Routes;
}

export const renderLink = (
  route: Routes,
  label: string,
  activeItem: Routes,
  notifications = []
) => (
  <Link
    className={classNames(styles.link, {
      [styles.activeLink]: activeItem === route,
    })}
    to={route}
  >
    {label}
    {label === 'Orders' && !!notifications.length && (
      <span className={styles.badge}></span>
    )}
  </Link>
);

const logout = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  auth.logout().then(() => {
    window.location.pathname = '/';
  });
};

const HeaderNavDesktop: React.FC<IHeaderDesktopProps> = ({
  activeItem,
  notificationList,
}) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (
      notificationList.hasOwnProperty('data') &&
      notificationList.data.length !== notifications.length
    ) {
      setNotifications(notificationList.data);
    }
  });
  return (
    <Header>
      <div className={styles.header}>
        {renderLink(Routes.inventory, 'Inventory', activeItem)}
        {renderLink(Routes.profile, 'Profile', activeItem)}
        {renderLink(Routes.orders, 'Orders', activeItem, notifications)}
        {renderLink(Routes.account, 'Account', activeItem)}
        <div className={styles.divider} />
        <a className={styles.logoutLink} onClick={logout}>
          Log Out
        </a>
        <HeaderButtonMarketplace />
      </div>
    </Header>
  );
};
const mapStateToProps = ({ chat }) => ({
  notificationList: chat.notifications,
});

export default connect(
  mapStateToProps,
  null
)(HeaderNavDesktop);
