import React, { useState } from 'react';

import Button from 'common/components/common/Button';
import Fa from 'common/components/common/Fa';
import Field from 'common/components/common/form/Field';
import SelectField, {
  ISelectOptions,
} from 'common/components/common/form/SelectField';
import { jewelryTypes, settings } from 'common/utils/inventory';

import styles from './ListFilters.css';

export enum TypePrefix {
  setting = 'setting',
  jewelryType = 'jewelryType',
}

const buildOptions = (prefix: string, obj: ISelectOptions) =>
  Object.keys(obj).reduce(
    (options: ISelectOptions, key) =>
      Object.assign(options, {
        [`${prefix}:${key}`]: obj[key],
      }),
    {}
  );

const allOptions = Object.assign(
  buildOptions(TypePrefix.setting, settings),
  { '-': null },
  buildOptions(TypePrefix.jewelryType, jewelryTypes)
);

interface IListFiltersProps {
  initialQuery?: string;
  initialType?: string;
  onChange: (query: string, type: string) => void;
}

const ListFilters: React.FC<IListFiltersProps> = ({
  initialQuery,
  initialType,
  onChange,
}) => {
  const [query, setQuery] = useState(initialQuery || '');
  const [type, setType] = useState(initialType || '');
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  const handleSearchInput: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    event.preventDefault();
    const input = event.target.value;
    setQuery(input);
    setIsSearchDisabled(
      input.length < 1 ? !initialQuery : input.trim() === initialQuery
    );
  };

  const handleSearchButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    onChange(query, type);
    setIsSearchDisabled(true);
  };

  const handleTypeChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = event => {
    event.preventDefault();
    const input = event.target.value;
    setType(input);
    onChange(query, input);
  };

  return (
    <form className={styles.filters}>
      <div className={styles.searchField}>
        <Field className={styles.searchInput}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={handleSearchInput}
          />
        </Field>
        <Button
          className={styles.searchButton}
          disabled={isSearchDisabled}
          onClick={handleSearchButtonClick}
        >
          <Fa icon="search" />
        </Button>
      </div>
      <div className={styles.categoryField}>
        <label>Category:</label>
        <SelectField
          className={styles.categoryFieldSelect}
          options={allOptions}
          selectedOption={type}
          nullOption="All Settings/Jewelry"
          onChange={handleTypeChange}
        />
      </div>
    </form>
  );
};

export default ListFilters;
