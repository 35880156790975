import React from 'react';
import { Link } from 'react-router-dom';

import Fa from 'common/components/common/Fa';
import { renderText as getInventoryDescription } from 'common/components/common/InventoryShortDescription';
import OutLink from 'common/components/common/OutLink';
import { marketplaceUrl, settingIcons } from 'common/utils/inventory';
import { cutText } from 'common/utils/misc';
import { formatPrice } from 'common/utils/valueFormatter';
import Thumbnail from 'seller/app/components/inventory/list/Thumbnail';

import styles from './List.css';

const ListItem = ({ item }) => (
  <li className={styles.listItem}>
    <div className={styles.itemContent}>
      <div className={styles.itemImage}>
        <Thumbnail item={item} />
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.itemTitle}>{getInventoryDescription(item)}</div>
        <div className={styles.itemDescription}>
          {cutText(item.ringDescription, 90) || 'No description available'}
        </div>
        <div className={styles.itemInfoContainer}>
          <div className={styles.itemInfo}>
            <div className={styles.itemType}>
              <span className={styles.itemInfoLabel}>Type:</span>
              <span className={styles.itemInfoValue}>
                {settingIcons[item.setting]}
              </span>
            </div>
            <div className={styles.itemPrice}>
              <span className={styles.itemInfoLabel}>Price:</span>
              <span className={styles.itemInfoValue}>
                {formatPrice(item.price)}
              </span>
            </div>
            <div className={styles.itemPrice}>
              <span className={styles.itemInfoLabel}>Views:</span>
              <span className={styles.itemInfoValue}>
                {item?.viewCount}
              </span>
            </div>
          </div>
          <div className={styles.itemActionContainer}>
            <Link
              className={styles.itemAction}
              to={`/inventory/edit/${encodeURIComponent(item.id)}`}
            >
              <Fa icon="pen" /> Edit
            </Link>
            <OutLink className={styles.itemAction} to={marketplaceUrl(item)}>
              <Fa icon="external-link-alt" /> View on Gemsby
            </OutLink>
          </div>
        </div>
      </div>
    </div>
  </li>
);

export default ListItem;
