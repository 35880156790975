import classNames from 'classnames';
import React from 'react';

import styles from './ContentPageHeader.css';

interface IListContentHeaderProps extends Gemsby.IComponentProps {
  title: string | JSX.Element;
  renderActions?: () => React.ReactNode
}

const ContentPageHeader = ({ className, title, renderActions = () => null }: IListContentHeaderProps) => (
  <div className={classNames(styles.contentPageHeader, className)}>
    <h3>{title}</h3>
    <div className={styles.contentPageHeaderActions}>
      {renderActions()}
    </div>
  </div>
);

export default ContentPageHeader;
