import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import FormErrorMessage from 'common/components/common/form/FormErrorMessage';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import { useFormState } from 'common/utils/form';
import { VALIDATION_ERROR_TYPE } from 'common/utils/validators';
import {
  savePayPalClientId,
  validatePayPalClientId,
} from 'seller/app/actions/retailer';
import BannerAd from 'seller/app/components/common/BannerAd';
import ContentPageHeader from 'seller/app/components/common/ContentPageHeader';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import AcceptPaymentHelpCenter from 'seller/app/components/payment/AcceptPaymentHelpCenter';
import ManageOrdersButton from 'seller/app/components/payment/ManageOrdersButton';
import PayPalCard from 'seller/app/components/payment/PayPal';
import StripeCard from 'seller/app/components/payment/Stripe';
import { Routes } from 'seller/app/constants';
import onboarding from 'seller/app/services/onboarding';
import Modal from 'common/components/common/mobile/MobileModal';
import styles from './Payment.css';

const formErrorRef = React.createRef();

const PaymentPageMobile = props => (
  <>
  <Modal />
  <Screen>
    <PageMobile>
      <PageContent heightReducedBy={60}>
        <HeaderNavMobile />

        <PageHeader>Collect Payments</PageHeader>

        <PageSection>
          <BannerAd />
        </PageSection>

        <PageSection>
          <FormErrorMessage error={props.error} ref={formErrorRef} />

          <StripeCard {...props} />
          <PayPalCard {...props} />

          <ManageOrdersButton />

          {!props.showHelpCenter && <AcceptPaymentHelpCenter center />}
        </PageSection>
      </PageContent>

      <TabBar activeItem={Routes.orders} />
    </PageMobile>
  </Screen>
  </>
);

const PaymentPageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.orders} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <ContentPageHeader
        title="Collect Payments"
        renderActions={() => <ManageOrdersButton />}
      />

      <FormErrorMessage error={props.error} ref={formErrorRef} />

      <div className={styles.paymentPage}>
        <StripeCard {...props} />
        <PayPalCard {...props} />
      </div>

      {!props.showHelpCenter && <AcceptPaymentHelpCenter center />}
    </PageSection>

    <Footer />
  </PageDesktop>
);

const PaymentPage = props => {
  const {
    isBigScreen,
    paypalClientId,
    hasPayPal,
    hasStripe,
    error,
    // tslint:disable-next-line:no-shadowed-variable
    validatePayPalClientId,
  } = props;
  const paymentConnected = hasPayPal || hasStripe;

  const [state, handleFieldChange, setState] = useFormState({
    paypalClientId,
  });

  useEffect(() => {
    if (paymentConnected) {
      onboarding.setPaymentConnected();
    }
  });

  useEffect(() => {
    if (error.type === VALIDATION_ERROR_TYPE) {
      validatePayPalClientId({ paypalClientId: state.paypalClientId });
    }
  }, [state.paypalClientId]);

  props = {
    ...props,
    state,
    handleFieldChange,
    setState,
    showHelpCenter: !(hasStripe && hasPayPal),
  };

  return isBigScreen ? (
    <PaymentPageDesktop {...props} />
  ) : (
    <PaymentPageMobile {...props} />
  );
};

const mapState = ({ device, login, retailer }) => {
  const paypalClientId = login.currentUser.get('paypalClientId');
  return {
    isBigScreen: device.isBigScreen,
    hasStripe: !!login.currentUser.get('stripeAccount'),
    paypalClientId,
    hasPayPal: !!paypalClientId,
    retailerInProgress: retailer.inProgress,
    error: retailer.error,
  };
};

const mapDispatch = {
  validatePayPalClientId,
  savePayPalClientId,
};

export default connect(
  mapState,
  mapDispatch
)(PaymentPage);
