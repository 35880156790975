import React from 'react';

import Button from 'common/components/common/Button';
import Field from 'common/components/common/form/Field';
import OutLink from 'common/components/common/OutLink';
import SubSection from 'common/components/common/SubSection';

import styles from './TrackingDetails.css';

const TrackingDetailsForm = ({
  state: { shippingTrackingId, shippingService },
  error,
  handleFieldChange,
  updateTrackingDetails,
}) => {
  return (
    <SubSection
      className={styles.trackingDetailsForm}
      title="Customer Awaiting Tracking Details"
    >
      <p>
        Once you have accepted a customer’s payment (
        <OutLink to="#">see guide here</OutLink>) and shipped out the item, add
        a tracking number and service below to notify your customer. They will
        receive an email.
      </p>
      <form>
        <Field label="Tracking Number" error={error.shippingTrackingId}>
          <input
            type="text"
            placeholder="Enter tracking number for customer"
            value={shippingTrackingId}
            onChange={handleFieldChange('shippingTrackingId')}
          />
        </Field>
        <Field label="Shipping Service" error={error.shippingService}>
          <input
            type="text"
            placeholder="Enter shipping service for tracking (i.e. DHL)"
            value={shippingService}
            onChange={handleFieldChange('shippingService')}
          />
        </Field>
        <Button className={styles.button} onClick={updateTrackingDetails}>
          Save Tracking Details
        </Button>
      </form>
    </SubSection>
  );
};

export default TrackingDetailsForm;
