import React, { useState, useEffect } from 'react';

import GemsbyIcon from 'common/components/common/GemsbyIcon';
import TabBar from 'common/components/common/mobile/tabbar/TabBar';
import TabBarItem from 'common/components/common/mobile/tabbar/TabBarItem';
import { MARKETPLACE_URL } from 'common/constants';
import { Routes } from 'seller/app/constants';
import { connect } from 'react-redux';
import logoGemsbyImage from 'seller/assets/images/tabbar/logo-gemsby.png';
import logoMenu from 'seller/assets/images/tabbar/menu-thin.svg';
import logoClose from 'seller/assets/images/tabbar/close-thin.svg';
import { toggleModal } from 'seller/app/actions/modal';
import styles from './TabBar.css';

interface ITabBarProps extends Gemsby.IComponentProps {
  activeItem: Routes;
}

const TabBarMobile: React.FC<ITabBarProps> = ({
  activeItem,
  notificationList,
  user,
  toggleModal,
  modalData,
}) => {
  const [notifications, setNotifications] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    toggleModal(!toggle);
  };

  useEffect(() => {
    if (
      notificationList.hasOwnProperty('data') &&
      notificationList.data.length !== notifications.length
    ) {
      setNotifications(notificationList.data);
    }
    if (modalData !== toggle) {
      setToggle(modalData);
    }
  });

  return (
    <TabBar>
      <TabBarItem
        path={Routes.inventory}
        icon={<GemsbyIcon icon="inventory" />}
        active={activeItem === Routes.inventory}
      />
      <TabBarItem
        path={Routes.profile}
        icon={<GemsbyIcon icon="profile" />}
        active={activeItem === Routes.profile}
      />
      <TabBarItem
        path={MARKETPLACE_URL}
        icon={<img src={logoGemsbyImage} />}
        extraCls={styles.imageButton}
        external
      />
      <TabBarItem
        path={Routes.orders}
        icon={<GemsbyIcon icon="payments" />}
        active={activeItem === Routes.orders}
        extraCls={styles.payments}
        hasBadges={!!notifications.length}
        showBadge={true}
      />
      <TabBarItem
        icon={
          toggle ? (
            <img src={logoClose} onClick={() => handleToggle()} />
          ) : (
            <img
              src={logoMenu}
              onClick={() => handleToggle()}
              className={styles.cross}
            />
          )
        }
        active={activeItem === Routes.account}
      />
    </TabBar>
  );
};

const mapStateToProps = ({ chat, login, modal }) => ({
  notificationList: chat.notifications,
  user: login.currentUser,
  modalData: modal.modalData,
});

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: data => dispatch(toggleModal(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabBarMobile);
