import classNames from 'classnames';
import React from 'react';

import styles from './Form.css';

export interface IFieldProps extends Gemsby.IComponentProps {
  containerClassName?: string;
  label?: string | JSX.Element;
  error?: string | string[];
}

const Field: React.FC<IFieldProps> = ({
  className,
  containerClassName,
  label,
  error,
  children,
}) => {
  const errorList = [].concat(error).filter(e => e);

  const renderError = () => {
    if (errorList.length < 1) {
      return null;
    }
    return errorList.map(err => (
      <div key={err} className={styles.errorMessage}>
        {err}
      </div>
    ));
  };

  return (
    <div
      className={classNames(styles.field, className, {
        [styles.errorField]: errorList.length > 0,
      })}
    >
      <label className={classNames(styles.fieldContainerClassName, containerClassName)}>
        {label}
        {children}
      </label>
      {renderError()}
    </div>
  );
};

export default Field;
