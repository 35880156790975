import React from 'react';

import styles from './List.css';

const TrackingNumber = ({ order }) => {
  const { shippingTrackingId } = order.attributes;
  return shippingTrackingId ? (
    <div className={styles.trackingNumber}>{shippingTrackingId}</div>
  ) : (
    <div className={styles.noTracking}>Awaiting tracking #</div>
  );
};

export default TrackingNumber;
