// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!./Form.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-common-components-common-form-ImagePicker__images{display:flex;flex-wrap:wrap;left:-15px}.projects-common-components-common-form-ImagePicker__image,.projects-common-components-common-form-ImagePicker__images{position:relative}.projects-common-components-common-form-ImagePicker__image{width:80px;height:80px;margin:0 0 15px 15px;box-shadow:0 0 2px 0 #888}.projects-common-components-common-form-ImagePicker__image img{display:block;max-width:100%;max-height:100%;position:absolute;top:50%;left:50%;transform:translate3d(-50%,-50%,0)}.projects-common-components-common-form-ImagePicker__removeButton{position:absolute;width:20px;height:20px;line-height:20px;text-align:center;top:-8px;right:-10px;background-color:#fff}.projects-common-components-common-form-ImagePicker__removeButton .fa{color:red;font-size:18px}.projects-common-components-common-form-ImagePicker__selectButton{display:block}.projects-common-components-common-form-ImagePicker__errorMessage{color:red}", ""]);
// Exports
exports.locals = {
	"images": "projects-common-components-common-form-ImagePicker__images",
	"image": "projects-common-components-common-form-ImagePicker__image",
	"removeButton": "projects-common-components-common-form-ImagePicker__removeButton",
	"selectButton": "projects-common-components-common-form-ImagePicker__selectButton",
	"errorMessage": "projects-common-components-common-form-ImagePicker__errorMessage " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["errorMessage"] + ""
};
module.exports = exports;
