import React from 'react';
import styles from './PriceCard.css';
import TextWithTick from 'seller/app/components/inventory/promote/priceCards/TextWithTick/TextWithTick';
import Button from 'common/components/common/Button';

interface IPriceCardProps {
  options: string[];
  price: number;
  heading: string;
  completePurchaseOpenValueHandler: (v:boolean) => void;
  setPromotionType: any;
}

const PriceCard: React.FC<IPriceCardProps> = ({options,price,heading,completePurchaseOpenValueHandler,setPromotionType}) => {

  const openPurchaseHandler = () => {
    setPromotionType({
      value: heading.toLowerCase(),
      price: price,
    })
    completePurchaseOpenValueHandler(true);
    document.getElementById('purchase').scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <div className={styles.priceCard}>
      {/*  card header*/}
      <div className={styles.cardHeader}>
        {/* header title*/}
        <h2 className={styles.cardTitle}>
          {heading}
        </h2>

        {/* Price Container*/}
        <div className={styles.pricePerWeek}>
          {/* price*/}
          <h1 className={styles.price}>
            ${price}
          </h1>
          {/*  per week container*/}
          <span className={styles.perWeek}>
              / week
            </span>
        </div>
      </div>

      {/* card content*/}
      <div className={styles.cardContent}>
        {
          options.map((opt,i) => (
            <div key={opt + i} className={styles.textWithTickContainer}>
              <TextWithTick>
                {opt}
              </TextWithTick>
            </div>
          ) )
        }

      </div>

      {/*  card footer*/}
      <div style={{
        flexGrow: 1
      }}/>
      <div className={styles.cardFooter}>
        <Button onClick={openPurchaseHandler} className={styles.footerButton}>
          Promote
        </Button>
      </div>

    </div>
  );
};

export default PriceCard;
