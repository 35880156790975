import React from 'react';
import Geosuggest from 'react-geosuggest';

import Field from 'common/components/common/form/Field';
import FieldLabelWithAttribution from 'common/components/common/form/FieldLabelWithAttribution';
import FieldNote from 'common/components/common/form/FieldNote';
import SectionCard from 'common/components/common/SectionCard';
import { parseGeoSuggest } from 'common/utils/gmaps';
import PoweredByGoogle from 'seller/app/components/common/PoweredByGoogle';

import styles from './Form.css';

const LocationFieldLabel = () => (
  <FieldLabelWithAttribution
    label="Location"
    attribution={<PoweredByGoogle />}
  />
);

const ProfileDetailsCard = ({ state, error, handleFieldChange }) => (
  <SectionCard title="Profile Details">
    <Field label="Seller Name / Store Name" error={error.name}>
      <input
        type="text"
        placeholder="Enter a name visible to the public"
        value={state.name}
        onChange={handleFieldChange('name')}
      />
    </Field>
    <Field label="Seller Description">
      <input
        type="text"
        placeholder="Enter a description that describes you or your services (i.e. a bit about you, your mission statement, the experience in your store, if relevant, a key differentiating factor, etc.)"
        value={state.description}
        onChange={handleFieldChange('description')}
      />
    </Field>
    <Field label={<LocationFieldLabel />} error={error.storeAddress}>
      <Geosuggest
        className={styles.addressInput}
        placeholder="i.e. NYC, NY, United States"
        initialValue={state.storeAddress}
        onSuggestSelect={suggest => {
          if (!suggest) {
            return;
          }
          handleFieldChange('geo')({
            target: {
              type: 'geo',
              value: parseGeoSuggest(suggest),
            },
          });
        }}
        onChange={value =>
          handleFieldChange('storeAddress')({
            target: {
              type: 'text',
              value,
            },
          })
        }
      />
      <FieldNote>
        Enter your location (i.e. City, State, Country), or store address if relevant.
      </FieldNote>
    </Field>
    <Field label="Unit #">
      <input
        type="text"
        placeholder="Enter the unit # if it applies"
        value={state.storeUnit}
        onChange={handleFieldChange('storeUnit')}
      />
    </Field>
  </SectionCard>
);

export default ProfileDetailsCard;
