import React from 'react';
import { Link } from 'react-router-dom';

import styles from './List.css';

const CreateFirstItemMessage = () => (
  <div className={styles.emptyMessage}>
    <p>No items have been added.</p>
    <Link to="/inventory/new">Upload your first item.</Link>
  </div>
);

export default CreateFirstItemMessage;
