// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-list-Thumbnail__thumbnail{width:auto;height:100%;padding:5px;display:flex;align-items:center;justify-content:center}.projects-seller-app-components-inventory-list-Thumbnail__thumbnail img{max-width:100%;max-height:100%}@media (max-width:768px){.projects-seller-app-components-inventory-list-Thumbnail__thumbnail{width:90px;height:130px;padding:0;margin-right:15px;box-shadow:0 0 2px 1px #ccc}}", ""]);
// Exports
exports.locals = {
	"thumbnail": "projects-seller-app-components-inventory-list-Thumbnail__thumbnail"
};
module.exports = exports;
