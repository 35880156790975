// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-order-form-OrderField__orderField{display:flex;margin-bottom:10px}.projects-seller-app-components-order-form-OrderField__orderField:last-of-type{margin-bottom:0}.projects-seller-app-components-order-form-OrderField__label{display:inline-block;width:145px;font-size:11px;font-weight:700;text-transform:uppercase}.projects-seller-app-components-order-form-OrderField__value{width:calc(100% - 145px);font-size:12px}.projects-seller-app-components-order-form-OrderField__value a{color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"orderField": "projects-seller-app-components-order-form-OrderField__orderField",
	"label": "projects-seller-app-components-order-form-OrderField__label",
	"value": "projects-seller-app-components-order-form-OrderField__value"
};
module.exports = exports;
