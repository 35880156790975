// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/mobile/BottomBar.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-tabbar-TabBar__tabBar{justify-content:space-around;background-color:var(--contentBgColor);box-shadow:0 -1px 1px 0 #dcdcdc}", ""]);
// Exports
exports.locals = {
	"tabBar": "projects-common-components-common-mobile-tabbar-TabBar__tabBar " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["bottomBar"] + ""
};
module.exports = exports;
