import { API_HOST } from 'common/constants';

export const imagePath = (type: string, path: string) =>
  `images/${type}/${path}`;

export const isValidImage = (image: string) =>
  /\.jpe?g$/i.test(image) || /\.png$/i.test(image) || /\.gif$/i.test(image);

export const isImageUrl = (url: string) => /^https?:\/\//.test(url);

export default (type: string, path: string) => {
  if (isImageUrl(path)) {
    return path;
  }
  if (path[0] === '/') {
    path = path.substring(1);
  }
  return path && `${API_HOST}/${imagePath(type, path)}`;
};
