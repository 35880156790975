import React from 'react';

import CheckboxField from 'common/components/common/form/CheckboxField';
import SectionCard from 'common/components/common/SectionCard';
import { services } from 'common/utils/retailer';

import styles from './Form.css';

const ServicesCard = ({ state, handleFieldChange }) => {
  return (
    <SectionCard title="Your Services">
      <p className={styles.sectionIntro}>
        Select as many services as you offer.
      </p>
      <div>
        {Object.entries(services).map(([service, label]) => (
          <CheckboxField
            key={service}
            label={label}
            checked={state[service]}
            onChange={handleFieldChange(service)}
          />
        ))}
      </div>
    </SectionCard>
  );
};

export default ServicesCard;
