import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Payment.css';

const ManageOrdersButton = () => (
  <Link className={styles.manageOrdersButton} to="/orders">
    <span>Manage Orders</span>
  </Link>
);

export default ManageOrdersButton;
