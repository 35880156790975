// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-header-Header__header{height:100px;padding:0 15px;display:flex;align-items:center;justify-content:space-between;background-color:#fff}@media (max-width:768px){.projects-seller-app-components-common-header-Header__header{height:60px;border-bottom:1px solid #ddd}}.projects-seller-app-components-common-header-Header__branding{display:flex;align-items:center}.projects-seller-app-components-common-header-Header__logo{height:35px}.projects-seller-app-components-common-header-Header__logo img{width:auto;height:100%}@media (max-width:768px){.projects-seller-app-components-common-header-Header__logo{height:25px}}.projects-seller-app-components-common-header-Header__title{padding:3px 15px}.projects-seller-app-components-common-header-Header__title h1{color:#3f3e53;font-size:17px;font-family:Montserrat;font-weight:700;letter-spacing:.3px;text-transform:uppercase}@media (max-width:768px){.projects-seller-app-components-common-header-Header__title{margin-left:15px;border-left:1px solid #ddd}}.projects-seller-app-components-common-header-Header__nav{flex:1}", ""]);
// Exports
exports.locals = {
	"header": "projects-seller-app-components-common-header-Header__header",
	"branding": "projects-seller-app-components-common-header-Header__branding",
	"logo": "projects-seller-app-components-common-header-Header__logo",
	"title": "projects-seller-app-components-common-header-Header__title",
	"nav": "projects-seller-app-components-common-header-Header__nav"
};
module.exports = exports;
