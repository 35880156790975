import { CLOUDINARY_URL, IMGIX_URL } from 'common/constants';
import imageUrl, { isImageUrl } from 'common/utils/imageUrl';

type ImageScaler = (
  type: string,
  path: string,
  width?: number,
  height?: number
) => string;

export const imgix: ImageScaler = (type, path, width, height) => {
  if (isImageUrl(path)) {
    return path;
  }
  if (path[0] === '/') {
    path = `${type}${path}`;
  }
  const url = `${IMGIX_URL}/${path}`;
  const qs = [];
  if (width) {
    qs.push(`w=${width}`);
  }
  if (height) {
    qs.push(`h=${height}`);
  }
  if (qs.length > 0) {
    return `${url}?${qs.join('&')}`;
  }
  return url;
};

export const cloudinary: ImageScaler = (type, path, width, height) => {
  let url = CLOUDINARY_URL;
  const params = [];
  if (width) {
    params.push(`w_${width}`);
  }
  if (height) {
    params.push(`h_${height}`);
  }
  if (params.length > 0) {
    url += `/${params.join(',')}`;
  }
  return `${url}/${imageUrl(type, path)}`;
};

export default process.env.NODE_ENV === 'production' ? imgix : cloudinary;
