import request from 'fetch.jsonapi';
import _ from 'lodash';

import constants from 'common/constants';

const buildUrl = (url, params) => {
  const qs = _.map(
    params,
    (v, k) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  ).join('&');
  if (qs) {
    return `${url}?${qs}`;
  }
  return url;
};

const loginUrl = `${constants.API_ROOT}/${constants.API_LOGIN_PATH}`;
const retailerUrl = `${constants.API_ROOT}/${constants.API_RETAILER_PATH}`;
const inventoryUrl = `${constants.API_ROOT}/${constants.API_INVENTORY_PATH}`;
const ordersUrl = `${constants.API_ROOT}/${constants.API_ORDERS_PATH}`;
const orderUrl = `${constants.API_ROOT}/${constants.API_ORDER_PATH}`;
const chatUrl = `${constants.API_ROOT}/${constants.API_CHAT_PATH}`;

const uploadImageUrl = (entityType: string, imageType: string) => {
  if (imageType) {
    return `${constants.API_ROOT}/${entityType}/upload_image_${imageType}`;
  }
  return `${constants.API_ROOT}/${entityType}/upload_image`;
};
const uploadCSVUrl = `${retailerUrl}/upload_csv`;

const defaultHeaders = {
  'content-type': 'application/vnd.api+json',
  accept: 'application/vnd.api+json',
};

// tslint:disable-next-line: variable-name
let _token: string;

const api = {
  setErrorHandler(wrap) {
    ['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
      const fn = request[method].bind(request);
      request[method] = wrap(fn);
    });
  },
  login(email: string, password: string) {
    return request.post(loginUrl, { username: email, password });
  },
  setToken(token: string) {
    if (token !== _token) {
      _token = token;
      if (_token) {
        request.headers(
          _.extend({}, defaultHeaders, {
            authorization: _token,
          })
        );
      } else {
        request.headers(defaultHeaders);
      }
    }
    return this;
  },
  refreshToken(username: string) {
    return request
      .get(`${constants.API_ROOT}/refresh_token/${username}`)
      .then(response => {
        const { attributes } = response.toJSON();
        return (attributes && attributes.token) || _token;
      });
  },
  getRetailers(pageNumber: number) {
    return request.get(retailerUrl, {
      page: {
        number: pageNumber,
      },
    });
  },
  getRetailer(retailerId: string) {
    return request.get(`${retailerUrl}/${retailerId}`);
  },
  getRetailerByCustomUrl(customUrl: string) {
    return request.get(`${retailerUrl}/custom_url/${customUrl}`);
  },
  checkCustomUrl(customUrl: string) {
    return api.getRetailerByCustomUrl(customUrl).then(
      () => false,
      error => {
        if (error.status === 404) {
          return true;
        }
        throw error;
      }
    );
  },
  addRetailer(data: Gemsby.IRequestPayload) {
    return request.post(retailerUrl, data);
  },
  updateRetailer(retailerId: string, data: Gemsby.IRequestPayload) {
    return request.patch(`${retailerUrl}/${retailerId}`, data);
  },
  deleteRetailer(retailerId: string) {
    return request.delete(`${retailerUrl}/${retailerId}`);
  },
  getInventoryList(pageNumber: number) {
    return request.get(inventoryUrl, {
      page: {
        number: pageNumber,
      },
    });
  },
  getInventory(inventoryId: string) {
    return request.get(`${inventoryUrl}/${inventoryId}`);
  },
  addInventory(data: Gemsby.IRequestPayload) {
    return request.post(inventoryUrl, data);
  },
  updateInventory(inventoryId: string, data: Gemsby.IRequestPayload) {
    return request.patch(`${inventoryUrl}/${inventoryId}`, data);
  },
  deleteInventory(inventoryId: string) {
    return request.delete(`${inventoryUrl}/${inventoryId}`);
  },
  uploadImage(
    entityType: string,
    entityId: string,
    data: Gemsby.IRequestPayload
  ) {
    return request.post(`${uploadImageUrl(entityType)}/${entityId}`, data);
  },
  uploadRepImage(retailerId: string, data: Gemsby.IRequestPayload) {
    return request.post(
      `${uploadImageUrl('retailer', 'rep')}/${retailerId}`,
      data
    );
  },
  uploadRetailerLogoImage(retailerId: string, data: Gemsby.IRequestPayload) {
    return request.post(
      `${uploadImageUrl('retailer', 'logo')}/${retailerId}`,
      data
    );
  },
  uploadMastheadImage(retailerId: string, data: Gemsby.IRequestPayload) {
    return request.post(
      `${uploadImageUrl('retailer', 'mast_head')}/${retailerId}`,
      data
    );
  },
  deleteImage(
    typeUrl: string,
    entityId: string,
    relationship: string,
    images: Gemsby.IRequestPayload[]
  ) {
    images = [].concat(images).filter(image => image);
    if (images.length < 1) {
      return Promise.resolve();
    }
    return request.delete(
      `${typeUrl}/${entityId}/relationships/${relationship}`,
      images.map(image => _.pick(image, ['type', 'id']))
    );
  },
  deleteInventoryImage(inventoryId: string, images: Gemsby.IRequestPayload[]) {
    return this.deleteImage(inventoryUrl, inventoryId, 'images', images);
  },
  deleteRetailerImage(retailerId: string, images: Gemsby.IRequestPayload[]) {
    return this.deleteImage(retailerUrl, retailerId, 'images', images);
  },
  deleteRetailerRepImage(retailerId: string, images: Gemsby.IRequestPayload[]) {
    return this.deleteImage(retailerUrl, retailerId, 'image_rep', images);
  },
  deleteRetailerMastheadImage(
    retailerId: string,
    images: Gemsby.IRequestPayload[]
  ) {
    return this.deleteImage(retailerUrl, retailerId, 'image_mast_head', images);
  },
  csvUpload(retailerId: string, data: Gemsby.IRequestPayload) {
    return request.post(`${uploadCSVUrl}/${retailerId}`, data);
  },
  getOrderList(pageNumber: number) {
    return request.get(ordersUrl, {
      page: {
        number: pageNumber,
      },
    });
  },
  getOrder(orderId: string) {
    return request.get(`${orderUrl}/${orderId}`);
  },
  updateOrderTrackingDetails(orderId: string, data: Gemsby.IRequestPayload) {
    return request.post(
      `${orderUrl}/update_shipping_tracking_id/${orderId}`,
      data
    );
  },
  trackChat(data: Gemsby.IRequestPayload) {
    return request.post(`${chatUrl}/track`, data);
  },
};

export default api;
