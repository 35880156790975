import React from 'react';
import { connect } from 'react-redux';

import OutLink from 'common/components/common/OutLink';
import Well from 'common/components/common/Well';

import styles from './LastWeekViews.css';
import { getMarketplaceUrl } from 'common/utils/retailer';

interface ILastWeekViewsProps {
  retailer: Gemsby.IJSONAPIEntity;
}

const LastWeekViews: React.FC<ILastWeekViewsProps> = ({ retailer }) => {
  const views = retailer.get('weeklyViewCount') as number;
  let viewsString = '';
  if (views < 1) {
    viewsString = 'no views';
  } else if (views === 1) {
    viewsString = '1 view';
  } else {
    viewsString = `${views} views`;
  }
  return (
    <Well className={styles.lastWeekViews}>
      Last week you had {viewsString} on the Gemsby marketplace.{' '}
      <OutLink to={getMarketplaceUrl(retailer)}>
        View your live Gemsby profile
      </OutLink>
      .
    </Well>
  );
};

const mapState = ({ login }) => ({
  retailer: login.currentUser,
});

export default connect(mapState)(LastWeekViews);
