// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-PageHeader__pageHeader{padding:15px 0}.projects-common-components-common-PageHeader__pageHeader h3{font-size:22px;font-weight:400;color:#444}@media (max-width:768px){.projects-common-components-common-PageHeader__pageHeader{padding:15px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:var(--mainColor)}.projects-common-components-common-PageHeader__pageHeader h3{color:#fff;font-size:14px;font-weight:300;text-transform:uppercase}}", ""]);
// Exports
exports.locals = {
	"pageHeader": "projects-common-components-common-PageHeader__pageHeader"
};
module.exports = exports;
