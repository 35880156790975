import React from 'react';

import {MARKETPLACE_URL} from 'common/constants';

import GemsbyIcon from 'common/components/common/GemsbyIcon';
import { NA_OPTION } from 'common/utils/form';
import imageScaler from 'common/utils/imageScaler';
import shapeImage from 'common/utils/shapeImage';
import statusCode from 'common/utils/statusCode';

export const { statusCodes } = statusCode.inventory;

export enum Settings {
  LOOSE_DIAMOND = 'loose',
  LOOSE_GEMSTONE = 'gemstone',
  DIAMOND_JEWELRY = 'mounted',
  JEWELRY = 'non-diamond-jewelry',
  CRAFT_JEWELRY = 'craft-jewelry',
  WATCH = 'watch',
  SERVICE = 'service',
}

export const settings = {
  [Settings.LOOSE_DIAMOND]: 'Loose Diamond',
  [Settings.LOOSE_GEMSTONE]: 'Loose Gemstone',
  [Settings.DIAMOND_JEWELRY]: 'Diamond Jewelry',
  [Settings.JEWELRY]: 'Jewelry',
  [Settings.CRAFT_JEWELRY]: 'Craft Jewelry',
  [Settings.WATCH]: 'Watch',
  [Settings.SERVICE]: 'Service',
};

interface ISettingIcons {
  [key: string]: JSX.Element
}

export const settingIcons: ISettingIcons = {
  [Settings.LOOSE_DIAMOND]: <GemsbyIcon icon="type-loose-diamond"/>,
  [Settings.LOOSE_GEMSTONE]: <GemsbyIcon icon="type-loose-gemstone"/>,
  [Settings.DIAMOND_JEWELRY]: <GemsbyIcon icon="type-diamond-jewelry"/>,
  [Settings.JEWELRY]: <GemsbyIcon icon="type-jewelry"/>,
  [Settings.CRAFT_JEWELRY]: <GemsbyIcon icon="type-craft-jewelry"/>,
  [Settings.WATCH]: <GemsbyIcon icon="type-watch"/>,
  [Settings.SERVICE]: <GemsbyIcon icon="type-service"/>,
};

export enum JewelryTypes {
  RING = 'ring',
  MENS_RING = 'mens-ring',
  ENGAGEMENT_RING = 'engagement-ring',
  WEDDING_BAND = 'wedding-band',
  ENGAGEMENT_BAND_SET = 'engagement-band-set',
  PENDANT = 'pendant',
  BRACELET = 'bracelet',
  EARRINGS = 'earrings',
  STUD_EARRINGS = 'stud-earrings',
  WATCH = 'watch',
  WIRE_WRAP = 'wire-wrap',
  NECKLACE = 'necklace',
  BROACH = 'broach',
  FLEXIBLE_LINK_RING = 'flexible-link-ring',
  COIN = 'coin',
  OTHER = 'other',
}

export const jewelryTypes = {
  [JewelryTypes.RING]: 'Ring',
  [JewelryTypes.MENS_RING]: "Men's Ring",
  [JewelryTypes.ENGAGEMENT_RING]: 'Engagement Ring',
  [JewelryTypes.WEDDING_BAND]: 'Wedding Band',
  [JewelryTypes.ENGAGEMENT_BAND_SET]: 'Engagement/Band Set',
  [JewelryTypes.PENDANT]: 'Pendant',
  [JewelryTypes.BRACELET]: 'Bracelet',
  [JewelryTypes.EARRINGS]: 'Earrings',
  [JewelryTypes.STUD_EARRINGS]: 'Stud Earrings',
  [JewelryTypes.WATCH]: 'Watch',
  [JewelryTypes.WIRE_WRAP]: 'Wire Wrap',
  [JewelryTypes.NECKLACE]: 'Necklace',
  [JewelryTypes.BROACH]: 'Broach',
  [JewelryTypes.FLEXIBLE_LINK_RING]: 'Flexible Link Ring',
  [JewelryTypes.COIN]: 'Coin',
  [JewelryTypes.OTHER]: 'Other',
};

export const shapes = {
  'round-brilliant': 'Round Brilliant',
  cushion: 'Cushion',
  asscher: 'Asscher',
  princess: 'Princess',
  pear: 'Pear',
  emerald: 'Emerald',
  marquise: 'Marquise',
  heart: 'Heart',
  oval: 'Oval',
  trilliant: 'Trilliant',
  baguette: 'Baguette',
  'tapered-baguette': 'Tapered Baguette',
  radiant: 'Radiant',
  'rose-cut': 'Rose Cut',
  rough: 'Rough',
  cabochon: 'Cabochon',
  'mineral-specimen': 'Mineral Specimen',
  other: 'Other',
};

export const cuts = {
  ideal: 'Ideal',
  excellent: 'Excellent',
  'very-good': 'Very Good',
  good: 'Good',
  fair: 'Fair',
  poor: 'Poor',
};

export const cutsShort = {
  ideal: 'ID',
  excellent: 'EX',
  'very-good': 'VG',
  good: 'GD',
  fair: 'FA',
  poor: 'PR',
};

export const clarities = {
  f: 'F',
  if: 'IF',
  vvs1: 'VVS1',
  vvs2: 'VVS2',
  vs1: 'VS1',
  vs2: 'VS2',
  vs: 'VS Clarity',
  si1: 'SI1',
  si2: 'SI2',
  si3: 'SI3',
  si: 'SI Clarity',
  i1: 'I1',
  i2: 'I2',
  i: 'I Clarity',
  'eye-clean': 'Eye Clean',
};

export enum ColorTypes {
  REGULAR = 'regular',
  FANCY = 'fancy',
}

export const colorTypes = {
  [ColorTypes.REGULAR]: 'Regular',
  [ColorTypes.FANCY]: 'Fancy Color',
};

export const colors = {
  [ColorTypes.REGULAR]: {
    d: 'D',
    e: 'E',
    f: 'F',
    g: 'G',
    h: 'H',
    'f/g/h': 'F/G/H',
    i: 'I',
    j: 'J',
    k: 'K',
    l: 'L',
    m: 'M',
    n: 'N',
    'o-s': 'O-S',
    't-': 'T-',
  },
  [ColorTypes.FANCY]: {
    pink: 'Pink',
    orange: 'Orange',
    brown: 'Brown',
    yellow: 'Yellow',
    green: 'Green',
    red: 'Red',
    blue: 'Blue',
    purple: 'Purple',
    black: 'Black',
    grey: 'Grey',
    champagne: 'Champagne',
    canary: 'Canary',
    cognac: 'Cognac',
    'salt-and-pepper': 'Salt and Pepper',
  },
  [NA_OPTION]: {},
};

export const intensities = {
  natural: 'Natural',
  faint: 'Faint',
  light: 'Light',
  fancy: 'Fancy',
  intense: 'Intense',
  vivid: 'Vivid',
  deep: 'Deep',
  dark: 'Dark',
};

export const hues = {
  even: 'Even',
  reddish: 'Reddish',
  brownish: 'Brownish',
  yellowish: 'Yellowish',
  pinkish: 'Pinkish',
  greenish: 'Greenish',
  orangish: 'Orangish',
};

export enum SaleMarkupTypes {
  NONE = 'none',
  SALE = 'sale',
  MARKUP = 'markup',
}

export const saleMarkupTypes = {
  [SaleMarkupTypes.NONE]: 'None',
  [SaleMarkupTypes.SALE]: 'Sale',
  [SaleMarkupTypes.MARKUP]: 'Markup',
};

export enum MountTypes {
  CENTER_STONE = 'center-stone',
  MULTIPLE_STONES = 'multiple-stones',
}

export const mountTypes = {
  [MountTypes.CENTER_STONE]: 'Center Stone',
  [MountTypes.MULTIPLE_STONES]: 'Multiple Stones',
};

export const gemstoneTypes = {
  agate: 'Agate',
  alexandrite: 'Alexandrite',
  amazonite: 'Amazonite',
  amber: 'Amber',
  amethyst: 'Amethyst',
  ammolite: 'Ammolite',
  aquamarine: 'Aquamarine',
  aventurine: 'Aventurine',
  azurite: 'Azurite',
  calcite: 'Calcite',
  citrine: 'Citrine',
  coral: 'Coral',
  emerald: 'Emerald',
  epidote: 'Epidote',
  fluorite: 'Fluorite',
  fossil: 'Fossil',
  garnet: 'Garnet',
  glass: 'Glass',
  'grape-agate': 'Grape Agate',
  grossular: 'Grossular',
  hikmanite: 'Hikmanite',
  idocrase: 'Idocrase',
  'iron-stone': 'Iron Stone',
  jade: 'Jade',
  jadeite: 'Jadeite',
  k2nite: 'K2nite',
  kunzite: 'Kunzite',
  labradorite: 'Labradorite',
  'lapis-lazuli': 'Lapis Lazuli',
  lazurite: 'Lazurite',
  mirganite: 'Mirganite',
  moonstone: 'Moonstone',
  morganite: 'Morganite',
  'natural-wood': 'Natural Wood',
  nephrite: 'Nephrite',
  obsidian: 'Obsidian',
  onyx: 'Onyx',
  opal: 'Opal',
  pearls: 'Pearls',
  peridot: 'Peridot',
  pyrite: 'Pyrite',
  quartz: 'Quartz',
  'rare-minerals': 'Rare Minerals',
  rubellite: 'Rubellite',
  ruby: 'Ruby',
  sapphire: 'Sapphire',
  scapolite: 'Scapolite',
  spinel: 'Spinel',
  tanzanite: 'Tanzanite',
  'tiger-eye': 'Tiger Eye',
  titanite: 'Titanite',
  topaz: 'Topaz',
  tourmaline: 'Tourmaline',
  turquoise: 'Turquoise',
  zircon: 'Zircon',
  other: 'Other',
};

export const certificates = {
  gia: 'GIA',
  ags: 'AGS',
  igi: 'IGI',
  gemscan: 'GEMSCAN',
  hrd: 'HRD',
  egl: 'EGL',
  ugl: 'UGL',
  igl: 'IGL',
  gfco: 'GFCO',
  other: 'Other',
};

export const fluorescences = {
  non: 'Non',
  faint: 'Faint',
  med: 'Med',
  strong: 'Strong',
  'very-strong': 'Very Strong',
};

export const enhancements = {
  hpht: 'High Pressure & Temp.',
  'laser-drilling': 'Laser Drilling',
  'fracture-filling': 'Fracture Fillings',
};

export const symmetries = {
  excellent: 'Excellent',
  'very-good': 'Very Good',
  good: 'Good',
  fair: 'Fair',
  poor: 'Poor',
};

export const polishes = {
  excellent: 'Excellent',
  'very-good': 'Very Good',
  good: 'Good',
  fair: 'Fair',
  poor: 'Poor',
};

export enum JewelryConditions {
  NEW = 'new',
  USED = 'used',
  HANDMADE = 'handmade',
}

export const jewelryConditions = {
  [JewelryConditions.NEW]: 'New',
  [JewelryConditions.USED]: 'Used',
  [JewelryConditions.HANDMADE]: 'Handmade',
};

export const metalTypes = {
  'yellow-gold': 'Yellow Gold',
  'white-gold': 'White Gold',
  'rose-gold': 'Rose Gold',
  silver: 'Silver',
  platinum: 'Platinum',
  'natural-wood': 'Natural Wood',
  other: 'Other',
};

export const metalPurities = {
  '24k': '24K',
  '22k': '22K',
  '18k': '18K',
  '14k': '14K',
  '10k': '10K',
  lessThan10k: 'Less than 10K',
  'silver.992': 'Silver .992',
  'silver.925': 'Silver .925',
  plated: 'Plated',
};

export enum ParcelSizes {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export const parcelSizes = {
  [ParcelSizes.SINGLE]: 'Single Stone',
  [ParcelSizes.MULTIPLE]: 'Multiple Stones',
};

export enum GENDERS {
  MEN = 'men',
  WOMEN = 'women',
  UNISEX = 'unisex',
}

export const genders = {
  [GENDERS.MEN]: 'Men',
  [GENDERS.WOMEN]: 'Women',
  [GENDERS.UNISEX]: 'Unisex',
};

export const watchMovements = {
  quartz: 'Quartz',
  mechanical: 'Mechanical',
  auto: 'Automatic',
};

export const defaultImage = (inventory, width = 300) => {
  if (inventory.data && inventory.data.attributes) {
    inventory = inventory.data.attributes;
  }
  const { firstImagePath, shape, color } = inventory;
  if (firstImagePath) {
    return imageScaler('inventory', firstImagePath, width);
  }
  return shapeImage(shape, color);
};

export const marketplaceUrl = inventory =>
  `${MARKETPLACE_URL}/diamond/${encodeURIComponent(inventory.id)}`;

export default {
  statusCodes,
  defaultImage,
  marketplaceUrl,
};
