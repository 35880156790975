import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import Fa from 'common/components/common/Fa';
import OutLink from 'common/components/common/OutLink';
import { marketplaceUrl } from 'common/utils/inventory';
import { NA } from 'common/utils/valueFormatter';
import Customer from 'seller/app/components/order/list/Customer';
import TrackingNumber from 'seller/app/components/order/list/TrackingNumber';

import styles from './List.css';

interface IFieldProps {
  label: string;
  value: string | JSX.Element;
}

const Field = ({ label, value }: IFieldProps) => (
  <div className={styles.orderField}>
    <div className={styles.fieldLabel}>{label}:</div>
    <div className={styles.fieldValue}>{value}</div>
  </div>
);

interface IListItemProps {
  item: any;
  notifications: any;
  onClickMessage: any;
}

const ListItem = ({ item, notifications, onClickMessage }: IListItemProps) => {
  const { createdDay, orderId, paymentType } = item.attributes;
  const showNotification = notifications.includes(orderId) ? '(NEW)' : '';
  return (
    <li className={styles.listItem}>
      <div className={styles.orderFields}>
        <Field label="Date" value={moment(createdDay * 1000).format('ll')} />
        <Field label="Order #" value={orderId} />
        <Field label="Customer" value={<Customer order={item} />} />
        <Field label="Payment" value={paymentType || NA} />
        <Field label="Tracking #" value={<TrackingNumber order={item} />} />
      </div>
      <div className={styles.itemActionContainer}>
        <Link
          className={styles.itemAction}
          to={`/orders/${encodeURIComponent(item.id)}`}
        >
          <Fa icon="pen" /> Manage
        </Link>
        <Link
          className={styles.itemAction}
          to={{
            pathname: `/chat/${encodeURIComponent(item.id)}?anonymous=false`,
            state: { item },
          }}
          onClick={() => {
            !!showNotification && onClickMessage(orderId);
          }}
        >
          <Fa icon="comment" /> Message{' '}
          {showNotification && (
            <span className={styles.noTracking}>{showNotification}</span>
          )}
        </Link>
        <OutLink className={styles.itemAction} to={marketplaceUrl(item)}>
          <Fa icon="external-link-alt" /> Shared Link
        </OutLink>
      </div>
    </li>
  );
};

export default ListItem;
