import produce from 'immer';

import { DialogAction } from 'seller/app/actions/dialog';

const initialState = {
  isDialogShown: false,
  message: '',
  buttons: [] as Gemsby.IDialogButton[],
};

export default (state = initialState, action: Gemsby.Action) => produce(state, (draft) => {
  switch (action.type) {
    case DialogAction.SHOW_DIALOG:
      draft.isDialogShown = true;
      draft.message = action.message;
      draft.buttons = action.buttons;
      break;
    case DialogAction.HIDE_DIALOG:
      draft.isDialogShown = false;
  }
});
