import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { MARKETPLACE_URL } from 'common/constants';

import Card from 'common/components/common/Card';
import { validateRegister } from 'common/utils/validators';
import { login, register, registerError } from 'seller/app/actions/login';
import Footer from 'seller/app/components/landing/Footer';
import SignUpForm from 'seller/app/components/landing/SignUpForm';

import equationJeweler from 'common/assets/images/landing/landing-equation-jeweler.jpg';
import equationRing from 'common/assets/images/landing/landing-equation-ring.jpg';
import equationStore from 'common/assets/images/landing/landing-equation-store.jpg';
import featuredProfile from 'common/assets/images/landing/landing-featured-profile.jpg';
import howitworks1 from 'common/assets/images/landing/landing-howitworks-1.jpg';
import howitworks2 from 'common/assets/images/landing/landing-howitworks-2.jpg';
import howitworks3 from 'common/assets/images/landing/landing-howitworks-3.jpg';
import howitworks4 from 'common/assets/images/landing/landing-howitworks-4.jpg';
import forDesigners from 'common/assets/images/landing/landing-icon-designers.svg';
import equationHeart from 'common/assets/images/landing/landing-icon-heart.svg';
import forIndividuals from 'common/assets/images/landing/landing-icon-individuals.svg';
import forRetailers from 'common/assets/images/landing/landing-icon-retailers.svg';
import equationSum from 'common/assets/images/landing/landing-icon-sum.svg';
import forWholesalers from 'common/assets/images/landing/landing-icon-wholesalers.svg';
import portrait1 from 'common/assets/images/landing/landing-portrait-1.jpg';
import portrait10 from 'common/assets/images/landing/landing-portrait-10.jpg';
import portrait11 from 'common/assets/images/landing/landing-portrait-11.jpg';
import portrait12 from 'common/assets/images/landing/landing-portrait-12.jpg';
import portrait2 from 'common/assets/images/landing/landing-portrait-2.jpg';
import portrait3 from 'common/assets/images/landing/landing-portrait-3.jpg';
import portrait4 from 'common/assets/images/landing/landing-portrait-4.jpg';
import portrait5 from 'common/assets/images/landing/landing-portrait-5.jpg';
import portrait6 from 'common/assets/images/landing/landing-portrait-6.jpg';
import portrait7 from 'common/assets/images/landing/landing-portrait-7.jpg';
import portrait8 from 'common/assets/images/landing/landing-portrait-8.jpg';
import portrait9 from 'common/assets/images/landing/landing-portrait-9.jpg';
import testimonial1 from 'common/assets/images/landing/landing-testimonials-1.jpg';
import testimonial2 from 'common/assets/images/landing/landing-testimonials-2.jpg';
import testimonial3 from 'common/assets/images/landing/landing-testimonials-3.jpg';
import testimonial4 from 'common/assets/images/landing/landing-testimonials-4.jpg';
import whatIsMyDiamond from 'common/assets/images/landing/landing-whatismydiamond.jpg';
import stripe from 'common/assets/images/logo-stripe.png';
import spinningDiamond from 'common/assets/images/spinning-diamond.gif';
import styles from './Landing.css';

const signupFormRef = React.createRef<HTMLDivElement>();

const scrollToForm = (event?: React.SyntheticEvent) => {
  if (event) {
    event.preventDefault();
  }
  if (signupFormRef.current) {
    const form = signupFormRef.current;
    form.scrollIntoView({ behavior: 'smooth' });
  }
};

const Landing = ({ inProgress, error, onSubmit }) => {
  return (
    <div className={styles.landing}>
      {/* MASTHEAD */}

      <div className={styles.landingHero}>
        <div className={styles.landingHeroLeft}>
          <div className={styles.landingHeroLeftWrapper}>
            <h4>
              Jewelry Designers &nbsp;&bull;&nbsp; Retailers &nbsp;&bull;&nbsp;
              <br />
              Individuals &nbsp;&bull;&nbsp; ‘WHOLESALERS’
            </h4>
            <h2>Join the Gemsby Marketplace</h2>
            <h3>
              Create a FREE Store and Sell Your Gemstones, Diamonds &amp;
              Jewelry
            </h3>
            <button
              type="submit"
              className={styles.largeButton}
              onClick={scrollToForm}
            >
              Get Started
            </button>
          </div>
        </div>
        <div className={styles.landingHeroRight}>
          <div className={styles.landingHeroPortraitsCol1}>
            <img src={portrait1} />
            <img src={portrait2} />
            <img src={portrait3} />
            <img className={styles.bottom} src={portrait4} />
          </div>
          <div className={styles.landingHeroPortraitsCol2}>
            <img src={portrait5} />
            <img src={portrait6} />
            <img src={portrait7} />
            <img className={styles.bottom} src={portrait8} />
          </div>
          <div className={styles.landingHeroPortraitsCol3}>
            <img src={portrait9} />
            <img src={portrait10} />
            <img src={portrait11} />
            <img className={styles.bottom} src={portrait12} />
          </div>
          <div className={styles.landingHeroPortraitsSingle}>
            <img src={portrait1} />
            <img src={portrait3} />
            <img src={portrait6} />
            <img src={portrait9} />
          </div>
        </div>
      </div>

      {/* HOW IT WORKS */}

      <div className={styles.landingHowitworks}>
        <h2>How does it work?</h2>
        <div className={styles.wrapper}>
          <div className={styles.smallBlock}>
            <img src={howitworks1} />
            <h3>Gems & Jewelry For Sale?</h3>
            <p>
              Do you have gemstones, diamonds or jewelry for sale? Free up some
              money and sell your items on Gemsby!
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={howitworks2} />
            <h3>Open a Gemsby Store</h3>
            <p>
              Open your own FREE Gemsby store where you can sell to thousands of
              customers searching on our marketplace. All of your stock{' '}
              <strong>stays with you until it sells</strong> and a customer
              sends payment.
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={howitworks3} />
            <h3>Collect Payments Directly</h3>
            <p>
              Get paid directly by using PayPal or Stripe for payment collection
              on any items you sell. You can easily set up your payments once
              you create your own Gemsby store!
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={howitworks4} />
            <h3>Ship Your Sold Items</h3>
            <p>
              Add shipping details to your store! Once your items sell, you are
              responsible for shipping them out.
            </p>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className={`btn btn-primary ${styles.largeButton}`}
            disabled={inProgress}
            onClick={scrollToForm}
          >
            Get Started
          </button>
        </div>
      </div>

      {/* IS IT FOR ME? */}

      <div className={styles.landingIsItForMe}>
        <div className={styles.textBlock}>
          <h2>Is it for me?</h2>
          <p>
            Jewelry designers, retailers, individuals and wholesalers looking to
            sell gems & jewelry, and related items & services, are all creating
            their own Gemsby store that often acts as their main website.
          </p>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.mediumBlock}>
            <h3>Jewelry Designers</h3>
            <img src={forDesigners} />
            <p>
              As a Jewelry Desiger, easily set up your online store without the
              hastle and fees involved with other platforms, or an expensive web
              design agency. Showcase jewelry designs on your profile gallery,
              and reach customers searching the Gemsby marketplace.
            </p>
            <div className={styles.ourFees}>
              Listing Fee per Item: &nbsp;$0
              <br />
              Platform Fee on Sales: &nbsp;2.5%
              <br />
              + <img className={styles.stripeTiny} src={stripe} /> or PayPal
              Processing Fee
            </div>
          </div>
          <div className={styles.mediumBlock}>
            <h3>Retailers</h3>
            <img src={forRetailers} />
            <p>
              As a Retailer, enjoy inventory management for large quantities of
              items, and reach customers that are searching the Gemsby
              marketplace to either buy online, or step into your physical
              store.
            </p>
            <div className={styles.ourFees}>
              Listing Fee per Item: &nbsp;$0
              <br />
              Platform Fee on Sales: &nbsp;2.5%
              <br />
              + <img className={styles.stripeTiny} src={stripe} /> or PayPal
              Processing Fee
            </div>
          </div>
          <div className={styles.mediumBlock}>
            <h3>Individuals</h3>
            <img src={forIndividuals} />
            <p>
              As an Individual, quickly and easily list your new or used items
              on the Gemsby marketplace. Local buyers can contact you directly,
              allowing you to make the final sale offline. By verifying your
              profile, you can also sell online (for the standard Platform Sales
              Fee of 2.5%).
            </p>
            <div className={styles.ourFees}>
              Listing Fee per Item: &nbsp;$0
              <br />
              Platform Fee on Sales: &nbsp;N/A
              <br />
            </div>
          </div>
          <div className={styles.mediumBlock}>
            <h3>Wholesalers</h3>
            <img src={forWholesalers} />
            <p>
              As a Wholesaler, ensure that only the right people can view and
              purchase your items by adding a password to your store: everything
              will also remain hidden from the Gemsby marketplace, leaving
              direct access up to your discretion (feature coming soon).
            </p>
            <div className={styles.ourFees}>
              Listing Fee per Item: &nbsp;$0
              <br />
              Platform Fee on Sales: &nbsp;1.0%
              <br />
              + <img className={styles.stripeTiny} src={stripe} /> or PayPal
              Processing Fee
            </div>
          </div>
        </div>
      </div>

      {/* REGISTER FORM */}

      <div className={styles.landingSignUpForm} ref={signupFormRef}>
        <div className={styles.wrapper}>
          <h2>Join the Gemsby Marketplace</h2>
          <p>
            Sign up below to create your FREE online store, and to post on the{' '}
            <a href="https://gemsby.com" target="_blank">
              Gemsby marketplace
            </a>
            . Never pay listing fees.
          </p>
          <Card className={styles.landingForm}>
            <SignUpForm
              inProgress={inProgress}
              error={error}
              onSubmit={onSubmit}
            />

            {/*registered
              ? <RegisterSuccessPage />
              : <RegisterForm
                  subscriptionPlan={this.state.subscriptionPlan}
                  inProgress={inProgress}
                  registered={registered}
                  error={error}
                  onChangeFactory={handleFieldChange.bind(null, this)}
                  onGeoSuggestSelect={this.handleGeoSuggestSelect}
                  onSubmit={this.onSubmit}
              />*/}
          </Card>
          <p>Need more info? Scroll down to learn more.</p>
        </div>
      </div>

      {/* WHAT IS MYDIAMOND? */}

      <div className={styles.landingWhatIsIt}>
        <div className={styles.wideBlock}>
          <div className={styles.leftSide}>
            <img className={styles.diamondSpinning} src={spinningDiamond} />
            <img src={whatIsMyDiamond} />
          </div>
          <div className={styles.rightSide}>
            <h2>What is Gemsby?</h2>
            <p>
              With Gemsby&trade; you can set up your personalized online store
              for FREE, and list an unlimited amount of gems & jewelry, related
              items and services, with zero listing fees.
            </p>
            <p>
              All items and services appear on the{' '}
              <a href={MARKETPLACE_URL} target="_blank">
                Gemsby marketplace
              </a>{' '}
              so part of your marketing strategy is already in effect after your
              first upload!
            </p>
          </div>
        </div>
      </div>

      {/* WHAT'S THE BIG DEAL? */}

      <div className={styles.landingTheBigDeal}>
        <div className={styles.textBlock}>
          <p className={styles.textFeaturedProfile}>
            <span className={styles.textLabel}>Featured Store: </span>
            <a href="https://gemsby.com/blkdmndtoronto" target="_blank">
              <span className={styles.textDomain}>Gemsby.com/</span>
              BlkDmndToronto
            </a>
          </p>
        </div>
        <div>
          <img src={featuredProfile} />
        </div>
      </div>

      {/* TESTIMONIALS */}

      <div className={styles.landingTestimonials}>
        <div className={styles.wrapper}>
          <div className={styles.smallBlock}>
            <img src={testimonial1} />
            <h2>Sam S.</h2>
            <h3>W.J. Watch &amp; Son</h3>
            <h4>
              <a href="https://gemsby.com/wjwatch" target="_blank">
                <span>Gemsby.com/</span>WJWatch
              </a>
            </h4>
            <p>
              &quot;I have used Gemsby from day one. We were lucky to be one of
              the first retailers verified on the platform and it has been a
              great experience!&quot;
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={testimonial2} />
            <h2>Martina</h2>
            <h3>MGD Toronto</h3>
            <h4>
              <a href="https://gemsby.com/mgdtoronto" target="_blank">
                <span>Gemsby.com/</span>MGDToronto
              </a>
            </h4>
            <p>
              &quot;I was too busy to add my inventory, so the guys from Gemsby
              helped me import all of my rings and designs into my profile and
              store.&quot;
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={testimonial3} />
            <h2>Dawn M.</h2>
            <h3>Fancy Colour Toronto</h3>
            <h4>
              <a href="https://gemsby.com/fancycolourtoronto" target="_blank">
                <span>Gemsby.com/</span>FancyColourToronto
              </a>
            </h4>
            <p>
              &quot;I have never sold diamonds online as quickly as I was able
              to through gemsby.com. Payments and shipping were a breeze. Thanks
              Gemsby!&quot;
            </p>
          </div>
          <div className={styles.smallBlock}>
            <img src={testimonial4} />
            <h2>Evangeline Moreau</h2>
            <h3>Customer</h3>
            <h4>
              <span>Facebook Feedback</span>
            </h4>
            <p>
              &quot;I just wanted to say how easy it was to find a beautiful
              diamond ring from a local seller. Already let my friends know!
              😀&quot;
            </p>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className={`btn btn-primary ${styles.largeButton}`}
            disabled={/*inProgress*/ false}
            onClick={scrollToForm}
          >
            Get Started
          </button>
        </div>
      </div>

      {/* DIGITAL STORE EQUATION */}

      <div className={styles.landingEquation}>
        <div className={styles.wrapper}>
          <div className={styles.smallBlock}>
            <img src={equationJeweler} />
          </div>
          <div className={styles.smallBlock}>
            <img className={styles.equationIcon} src={equationHeart} />
            <img src={equationRing} />
          </div>
          <div className={styles.smallBlock}>
            <img className={styles.equationIcon} src={equationSum} />
            <img src={equationStore} />
          </div>
        </div>
      </div>

      {/* VIDEO AD */}

      <div className={styles.landingVideo}>
        <div className={`${styles.landingVideoWrapper}`}>
          <div className={`${styles.video}`}>
            <div className={styles.containerYouTube}>
              <iframe
                className={styles.player}
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/wnliq-PI0Po?autoplay=0&rel=0&modestbranding=1&showinfo=0"
                frameBorder="0"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapState = ({ login }) => ({
  inProgress: login.inProgress,
  error: login.error || {},
});

const mapDispatch = (dispatch, props) => ({
  onSubmit(data) {
    const { valid, error } = validateRegister(data);
    if (!valid) {
      return dispatch(registerError(error));
    }
    return dispatch(register(data))
      .then(() => {
        const { email, password } = data;
        return dispatch(login(email, password, true));
      })
      .then(props.goToDashboard);
  },
});

export default connect(
  mapState,
  mapDispatch
)(Landing);
