import React from 'react';

import SectionCard from 'common/components/common/SectionCard';
import Field from 'common/components/common/form/Field';
import FieldNote from 'common/components/common/form/FieldNote';

import styles from './Form.css';

const ContactDetailsCard = ({ state, handleFieldChange }) => {
  return (
    <SectionCard title="Contact Details">
      <Field label="Country Code (Phone)">
        <input
          type="text"
          placeholder="i.e. +1"
          value={state.countryCode}
          onChange={handleFieldChange('countryCode')}
        />
      </Field>
      <Field label="Phone Number">
        <input
          type="text"
          placeholder="Enter your phone # (i.e. 123-123-1234)"
          value={state.phone}
          onChange={handleFieldChange('phone')}
        />
        <FieldNote>Visible on your live profile.</FieldNote>
      </Field>
      <Field label="Email">
        <div className={styles.emailInput}>{state.email}</div>
        <FieldNote>
          You must visit your Account Settings to change your email. Your email
          will be how Gemsby consumer messages get to you. It is hidden on your
          live profile, but visible to consumers that contact you.
        </FieldNote>
      </Field>
    </SectionCard>
  );
};

export default ContactDetailsCard;
