// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-BottomBar__bottomBar{position:fixed;left:0;right:0;bottom:0;height:calc(60px + constant(safe-area-inset-bottom));height:calc(60px + env(safe-area-inset-bottom));padding-bottom:env(safe-area-inset-bottom);display:flex;align-items:center;text-align:center;z-index:2000;color:#fff;background-color:var(--headerTextColor)}.projects-common-components-common-mobile-BottomBar__header{flex:1;font-size:16px}.projects-common-components-common-mobile-BottomBar__itemRight{position:absolute;top:50%;right:15px;transform:translateY(-50%);color:var(--mainColor)}.projects-common-components-common-mobile-BottomBar__itemRight.fa,.projects-common-components-common-mobile-BottomBar__itemRight.icon{font-size:32px}", ""]);
// Exports
exports.locals = {
	"bottomBar": "projects-common-components-common-mobile-BottomBar__bottomBar",
	"header": "projects-common-components-common-mobile-BottomBar__header",
	"itemRight": "projects-common-components-common-mobile-BottomBar__itemRight"
};
module.exports = exports;
