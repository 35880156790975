import _ from 'lodash';

export const getAddressComponent = (gmaps: any, type: string): string => {
  const component = _.find(gmaps.address_components, ({ types }) =>
    _.find(types, t => t === type)
  );
  if (component) {
    return component.long_name;
  }
  return '';
};

export const parseGeoSuggest = (suggest: any) => {
  const addrComponent = getAddressComponent.bind(null, suggest.gmaps)
  return {
    storeAddress: suggest.label,
    storeLat: suggest.location.lat,
    storeLng: suggest.location.lng,
    gmapStreetAddr: suggest.gmaps.formatted_address,
    gmapRoute: addrComponent('route'),
    gmapIntersection: addrComponent('intersection'),
    gmapPolitical: addrComponent('political'),
    gmapCountry: addrComponent('country'),
    gmapAdminAreaLvl1: addrComponent('administrative_area_level_1'),
    gmapAdminAreaLvl2: addrComponent('administrative_area_level_2'),
    gmapAdminAreaLvl3: addrComponent('administrative_area_level_3'),
    gmapAdminAreaLvl4: addrComponent('administrative_area_level_4'),
    gmapAdminAreaLvl5: addrComponent('administrative_area_level_5'),
    gmapColloquialArea: addrComponent('colloquial_area'),
    gmapLocality: addrComponent('locality'),
    gmapSublocality: addrComponent('sublocality'),
    gmapSublocality1: addrComponent('sublocality_level_1'),
    gmapSublocality2: addrComponent('sublocality_level_2'),
    gmapSublocality3: addrComponent('sublocality_level_3'),
    gmapSublocality4: addrComponent('sublocality_level_4'),
    gmapSublocality5: addrComponent('sublocality_level_5'),
    gmapNeighborhood: addrComponent('neighborhood'),
    gmapPremise: addrComponent('premise'),
    gmapSubPremise: addrComponent('subpremise'),
    gmapPostalCode: addrComponent('postal_code'),
    gmapNaturalFeature: addrComponent('natural_feature'),
    gmapAirport: addrComponent('airport'),
    gmapPark: addrComponent('park')
  };
};
