import React from 'react';

import device from 'common/services/device';

interface IOutLinkProps extends Gemsby.IComponentProps {
  to: string;
}

const OutLink = ({ className = '', to, children }: IOutLinkProps) => (
  <a className={className} href={to} onClick={(event) => {
    event.preventDefault();
    window.open(to, device.isBrowser() ? '_blank' : '_system');
  }}>
    {children}
  </a>
);

export default OutLink;
