import React, { useEffect, useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

import paypalWithGreenBorder from '../../../../../assets/images/Promote/PayPalWithGreenBorder.svg';
import masterCardWithGreenBorder from '../../../../../assets/images/Promote/MastercardWithGreenBorder.svg';
import visaIconWithGreenBorder from '../../../../../assets/images/Promote/VisaIconWithGreenBorder.svg';
import inputCardRightIcon from '../../../../../assets/images/Promote/inputCardNumberRightIcon.svg';
import cvvRightIcon from '../../../../../assets/images/Promote/cvvRightIcon.svg';
import { useForm } from 'react-hook-form';

import styles from './CompletePurchase.css';
import Button from 'common/components/common/Button';
import constants, { API_ROOT } from 'common/constants';
import api from 'common/services/api';
import { useHistory } from 'react-router';

interface Inputs {
  cardNumber: string,
  cvv: string,
  nameOnCard: string,
  expiryDate: string,
}

const CompletePurchase = ({ state, completePurchaseOpen, promotionType }) => {

  const purchaseEl = document.getElementById('purchase');
  const [weeks, setWeeks] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('credit/debit card');
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const history = useHistory();

  console.log(state,'state is there');

  // this function is called when the form is submitted

  const onSubmit = (data: Inputs) => {

    // fetch('', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     data: {
    //       type: 'retailer',
    //       retailer_id: '',
    //       attributes: {
    //         promoOption: promotionType.value,
    //         promoWeeks: weeks,
    //       },
    //     },
    //   }),
    // }).then((res) => {
    //   console.log(res);
    // }).catch((err) => {
    //   console.log(err.message);
    // });

    console.log(paymentMethod, weeks, promotionType);
    console.log(data);

  };


  useEffect(() => {

    if (purchaseEl) {

      purchaseEl.scrollIntoView({
        behavior: 'smooth',
      });

    }

  }, [purchaseEl]);


  const weekHandler = (value: number) => {
    setWeeks(value);
  };

  const paymentMethodHandler = (value: string) => {
    setPaymentMethod(value);
  };

  return (

    <div className={styles.completePurchase} style={{ display: completePurchaseOpen ? 'block' : 'none' }}
         id={'purchase'}>
      <form onSubmit={handleSubmit(onSubmit)}>

        {/* purchase section heading*/}
        <div className={styles.completePurchaseHeadingContainer}>
          <h3 className={styles.completePurchaseHeading}>Complete Purchase</h3>
        </div>

        {/* purchase section divider*/}

        <div className={styles.dividerContainer}>
          <div className={styles.divider} />
        </div>

        {/*  Purchase sections*/}

        <div className={styles.purchaseSections}>

          {/* Purchase section left*/}

          <div className={styles.purchaseSectionLeft}>

            <h4 className={styles.paymentMethods}>Payment Methods</h4>

            <div className={styles.paymentOptionContainer}>

              <div onClick={paymentMethodHandler.bind(this, 'credit/debit card')}
                   className={`${styles.leftOptionContainer} ${styles.miniCard} ${(paymentMethod === 'credit/debit card') && styles.active}`}>

                <PayPalButton
                  amount={weeks * promotionType.price}
                  shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                  onSuccess={(details: any, data: any) => {
                    // alert('Transaction completed by ' + details.payer.name.given_name);
                    // OPTIONAL: Call your server to save the transaction
                    return api.postPromotedItem(
                      `${constants.API_ROOT}/promo/update_status/${state.createdDay}/${state.diamondId}`, {
                        data: {
                          // orderId: data.orderID,
                          type: 'retailer',
                          retailerId: state.retailerId,
                          attributes: {
                            promoOption: promotionType.value,
                            promoWeeks: weeks,
                          },
                        }
                      }
                    ).then((res:any )=> {
                      console.log('here is the response');
                      history.push(`/inventory`);
                      console.log(res);
                    }).catch((err:any )=> {
                      console.log(err);
                    })

                    //   fetch(, {
                    //     method: 'post',
                    //     body: JSON.stringify(,
                    //     ),
                    //   })

                    //     ;
                  }}
                  options={{
                    clientId: 'ARvQpqd-onR0hSIwjurjlLlhO6olu3Ru3JV7vKbdnnCRG52CMXooYW1JPYxXijsLDlpN30MhVvtpUVTH',
                  }}
                />
                {/*</Button>*/}

                {/*<img*/}
                {/*  src={visaIconWithGreenBorder}*/}
                {/*  className={`${styles.miniImage} ${styles.miniImageLeft}`}*/}
                {/*  alt={'visa card'}*/}
                {/*/>*/}
                {/*<img*/}
                {/*  src={masterCardWithGreenBorder}*/}
                {/*  className={`${styles.miniImage} ${styles.miniImageRight}`}*/}
                {/*  alt={'master card option card'}*/}
                {/*/>*/}

                {/*<h4 className={styles.miniCardText}>Credit / Debit Card</h4>*/}

              </div>

              {/*<div onClick={paymentMethodHandler.bind(this, 'paypal')}*/}
              {/*     className={`${styles.rightOptionContainer} ${styles.miniCard} ${(paymentMethod === 'paypal') && styles.active}`}>*/}

              {/*  <img*/}
              {/*    src={paypalWithGreenBorder}*/}
              {/*    className={`${styles.miniImage} ${styles.miniImageRight}`}*/}
              {/*    alt={'paypal option'}*/}
              {/*  />*/}

              {/*  <h4 className={styles.miniCardText}>Paypal</h4>*/}

              {/*</div>*/}

            </div>

            {/* inputs container*/}


            {/*  First Two inputs*/}
            {/*<div className={styles.firstTwoInputsContainer}>*/}

            {/*  <div className={`${styles.inputContainer} ${styles.leftInputContainer}`}>*/}

            {/*    <label className={styles.inputLabel} htmlFor='cardNumber'>Card Number</label>*/}

            {/*    <div style={{ position: 'relative' }}>*/}
            {/*      <input placeholder={'0000 0000 0000 0000'}*/}
            {/*             className={*/}
            {/*               `*/}
            {/*               ${styles.input}*/}
            {/*                ${styles.inputCardNumber}*/}
            {/*                ${errors.cardNumber && (styles.errorBorder)}*/}
            {/*                `*/}
            {/*             }*/}
            {/*             type='number' id={'cardNumber'}*/}
            {/*             {...register('cardNumber', {*/}
            {/*               required: {*/}
            {/*                 value: true,*/}
            {/*                 message: 'You must have to specify your card number',*/}
            {/*               },*/}
            {/*             })}*/}
            {/*      />*/}

            {/*      {*/}
            {/*        errors.cardNumber && (*/}
            {/*          <div className={`${errors.cardNumber && (styles.errorMessage)}`}>*/}
            {/*            {errors.cardNumber.message}*/}
            {/*          </div>*/}
            {/*        )*/}
            {/*      }*/}

            {/*      <img className={styles.inputCardNumberIcon} src={inputCardRightIcon} alt={'input card number'} />*/}
            {/*    </div>*/}

            {/*  </div>*/}

            {/*  <div className={styles.inputContainer}>*/}

            {/*    <label className={styles.inputLabel} htmlFor='name'>Name on Card</label>*/}
            {/*    <input*/}
            {/*      placeholder={'Enter Your Name Here'}*/}
            {/*      className={styles.input} type='text' id={'name'}*/}
            {/*      {...register('nameOnCard', {*/}
            {/*        required: {*/}
            {/*          value: true,*/}
            {/*          message: 'You must have to specify your name',*/}
            {/*        },*/}
            {/*      })}*/}
            {/*    />*/}

            {/*    {*/}
            {/*      errors.nameOnCard && (*/}
            {/*        <div className={`${errors.nameOnCard && (styles.errorMessage)}`}>*/}
            {/*          {errors.nameOnCard.message}*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    }*/}

            {/*  </div>*/}

            {/*</div>*/}

            {/*<div className={styles.secondTwoInputsContainer}>*/}

            {/*  <div className={`${styles.inputContainer} ${styles.inputContainerDate}`}>*/}
            {/*    <label className={styles.inputLabel} htmlFor='expiryDate'>Expiry Date</label>*/}
            {/*    <input placeholder={'MM / YY'}*/}
            {/*           className={`${styles.input} ${styles.dateInput} ${errors.expiryDate && styles.errorWidth}`}*/}
            {/*           type='text'*/}
            {/*           id={'expiryDate'}*/}
            {/*           {...register('expiryDate', {*/}
            {/*             required: {*/}
            {/*               value: true,*/}
            {/*               message: 'You must have to specify expiry date',*/}
            {/*             },*/}
            {/*           })}*/}
            {/*    />*/}

            {/*    {*/}
            {/*      errors.expiryDate && (*/}
            {/*        <div className={`${errors.expiryDate && (styles.errorMessage)}`}>*/}
            {/*          {errors.expiryDate.message}*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    }*/}

            {/*  </div>*/}

            {/*  <div className={`${styles.inputContainer} ${styles.inputContainerCVV}`}>*/}

            {/*    <label className={styles.inputLabel} htmlFor='cvv'>CVV</label>*/}

            {/*    <div style={{ position: 'relative' }}>*/}
            {/*      <input*/}
            {/*        placeholder={'---'}*/}
            {/*        className={`${styles.input} ${styles.cvvInput} ${errors.cvv && (styles.errorWidth)}`}*/}
            {/*        type='number'*/}
            {/*        id={'cvv'}*/}
            {/*        {...register('cvv', {*/}
            {/*          required: {*/}
            {/*            value: true,*/}
            {/*            message: 'You must have to specify cvv',*/}
            {/*          },*/}
            {/*          maxLength: {*/}
            {/*            value: 3,*/}
            {/*            message: 'The length of cvv must be 3',*/}
            {/*          },*/}
            {/*        })}*/}
            {/*      />*/}

            {/*      {*/}
            {/*        errors.cvv && (*/}
            {/*          <div className={`${errors.cvv && (styles.errorMessage)}`}>*/}
            {/*            {errors.cvv.message}*/}
            {/*          </div>*/}
            {/*        )*/}
            {/*      }*/}

            {/*      <img className={styles.cvvRightIcon} src={cvvRightIcon} alt={'input card number'} />*/}
            {/*    </div>*/}

            {/*  </div>*/}

            {/*</div>*/}


          </div>


          {/* Purchase section right*/}
          <div className={styles.purchaseSectionRight}>

            {/* top cards*/}

            <div className={styles.topWeekCards}>

              <div onClick={weekHandler.bind(this, 1)}
                   className={`${styles.weekCardOne} ${styles.weekCard} ${(weeks === 1) && styles.activeCard}`}>
                1 week
              </div>

              <div onClick={weekHandler.bind(this, 2)}
                   className={`${styles.weekCardTwo} ${styles.weekCard} ${(weeks === 2) && styles.activeCard}`}>
                2 week
              </div>

              <div onClick={weekHandler.bind(this, 3)}
                   className={`${styles.weekCardThree} ${styles.weekCard} ${(weeks === 3) && styles.activeCard}`}>
                3 week
              </div>

              <div onClick={weekHandler.bind(this, 4)}
                   className={`${styles.weekCardFour} ${styles.weekCard} ${(weeks === 4) && styles.activeCard}`}>
                4 week
              </div>

            </div>

            {/* cost */}

            <div className={styles.costOfPromoteContainer}>
              <h3 className={styles.costOfPromote}>
                Total Cost: ${weeks * promotionType.price}
              </h3>
            </div>

            {/*product image*/}

            <div className={styles.productImageContainer}>


              <img className={styles.productImageContainer}
                   src={state.firstImagePath} alt={'Product'}
              />
            </div>

            <div className={styles.productImageBottomTextContainer}>
              <h4 className={styles.productImageBottomText}>Promoting Product</h4>
            </div>

          </div>

        </div>

        {/* purchase section divider*/}

        <div className={styles.dividerContainer}>
          <div className={styles.divider} />
        </div>

        <div className={styles.makePaymentButtonContainer}>
          {/*<Button type={'submit'} className={styles.makePaymentButton}>*/}

        </div>

      </form>
    </div>
  );
};

export default CompletePurchase;
