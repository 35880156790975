// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/mobile/tabbar/TabBarItem.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-common-TabBar__imageButton{}.projects-seller-app-components-common-TabBar__payments{font-size:28px;position:relative;left:3px}.projects-seller-app-components-common-TabBar__cross{color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"imageButton": "projects-seller-app-components-common-TabBar__imageButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["imageButton"] + "",
	"payments": "projects-seller-app-components-common-TabBar__payments",
	"cross": "projects-seller-app-components-common-TabBar__cross"
};
module.exports = exports;
