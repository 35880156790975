import React from 'react';

import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SectionCard from 'common/components/common/SectionCard';
import { settingIcons, settings } from 'seller/app/utils/inventory';

import styles from './Form.css';

const settingsWithIcons = Object.entries(settings).reduce((result, [k, v]) => Object.assign(result, {
  [k]: (
    <div className={styles.settingItemLabel}>
      {settingIcons[k]}
      {v}
    </div>
  )
}), {});

const InventorySettingCard = ({
  state: { setting, diamondId },
  error,
  handleFieldChange,
}) => (
  <SectionCard title="Item Type/Setting">
    <RadioGroupField
      itemClassName={styles.settingItem}
      label="What type of item are you selling?"
      radioGroupName="setting"
      options={settingsWithIcons}
      selectedOption={setting}
      error={error.setting}
      disabled={!!diamondId}
      onChange={handleFieldChange('setting')}
    />
  </SectionCard>
);

export default InventorySettingCard;
