import React from 'react';
import { BrowserRouter, HashRouter, Redirect, Switch } from 'react-router-dom';

import Dialog from 'common/components/common/Dialog';
import device from 'common/services/device';
import FullscreenLoader from 'seller/app/components/common/FullscreenLoader';
import PrivateRoute from 'seller/app/components/common/PrivateRoute';
import PublicRoute from 'seller/app/components/common/PublicRoute';
import InventoryFormPage from 'seller/app/components/inventory/FormPage';
import InventoryListPage from 'seller/app/components/inventory/ListPage';
import InventoryMultipleItemsFormPage from 'seller/app/components/inventory/MultipleItemsFormPage';
import LandingPage from 'seller/app/components/landing/LandingPage';
import ProfilePage from 'seller/app/components/profile/ProfilePage';
import SignInPage from 'seller/app/components/landing/SignInPage';
import PromotePromptPage from './components/inventory/promote/PromotePromptPage';
import AccountPage from './components/account/AccountPage';
import ChatPage from './components/chat/ChatPage';
import OrderFormPage from './components/order/FormPage';
import OrderListPage from './components/order/ListPage';
import PaymentPage from './components/payment/PaymentPage';

const App = () => {
  const Router = device.isBrowser() ? BrowserRouter : HashRouter;

  return (
    <Router>
      <Switch>
        <Redirect from="/signon" to="/" />
        <PublicRoute path="/" exact component={LandingPage} />
      </Switch>
      <PublicRoute path="/signin" exact component={SignInPage} />
      <PrivateRoute path="/dashboard" component={InventoryListPage} />
      <PrivateRoute path="/inventory/new" component={InventoryFormPage} />
      <PrivateRoute path="/inventory/promote/:id" component={PromotePromptPage} />
      <PrivateRoute path="/inventory" exact component={InventoryListPage} />
      <PrivateRoute path="/inventory/edit/:id" component={InventoryFormPage} />
      <PrivateRoute
        path="/inventory/multiple"
        component={InventoryMultipleItemsFormPage}
      />
      <PrivateRoute path="/orders" exact component={OrderListPage} />
      <PrivateRoute path="/orders/:id" component={OrderFormPage} />
      <PrivateRoute path="/chat/:id" component={ChatPage} />
      <PrivateRoute path="/payment" component={PaymentPage} />
      <PrivateRoute path="/stripe_connect/:retailerId" component={PaymentPage} />
      <PrivateRoute path="/profile" component={ProfilePage} />
      <PrivateRoute path="/account" component={AccountPage} />

      <FullscreenLoader />
      <Dialog />
    </Router>
  );
};

export default App;
