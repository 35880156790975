// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js!common/components/common/Card.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-common-components-common-TitleCard__titleCard{}.projects-common-components-common-TitleCard__titleCard:not(:first-of-type){margin-top:20px}.projects-common-components-common-TitleCard__header{margin-bottom:20px;display:flex;flex-direction:column;align-items:center;text-transform:uppercase}.projects-common-components-common-TitleCard__tagline{margin:0 0 8px;font-size:12px}.projects-common-components-common-TitleCard__tagline,.projects-common-components-common-TitleCard__title{color:var(--textColor)}.projects-common-components-common-TitleCard__title{font-size:18px}.projects-common-components-common-TitleCard__titleSeparator{width:125px;margin-top:8px;border-bottom:6px solid #ebebeb}", ""]);
// Exports
exports.locals = {
	"titleCard": "projects-common-components-common-TitleCard__titleCard " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["card"] + "",
	"header": "projects-common-components-common-TitleCard__header",
	"tagline": "projects-common-components-common-TitleCard__tagline",
	"title": "projects-common-components-common-TitleCard__title",
	"titleSeparator": "projects-common-components-common-TitleCard__titleSeparator"
};
module.exports = exports;
