import classNames from 'classnames';
import React from 'react';

import Icon from 'common/components/common/Icon';

import styles from './Checkbox.css';

interface ICheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  disabled,
  onChange,
}) => (
  <label className={classNames('checkbox', styles.checkboxContainer)}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <Icon icon={`checkbox-${checked ? 'checked' : 'unchecked'}`} />
  </label>
);

export default Checkbox;
