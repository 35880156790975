import React, { useState } from 'react';
import Geosuggest from 'react-geosuggest';
import { Link } from 'react-router-dom';

import CheckboxField from 'common/components/common/form/CheckboxField';
import Field from 'common/components/common/form/Field';
import FieldLabelWithAttribution from 'common/components/common/form/FieldLabelWithAttribution';
import { PRIVACY_URL, TOS_URL } from 'common/constants';
import { useFormState } from 'common/utils/form';
import { parseGeoSuggest } from 'common/utils/gmaps';
import PoweredByGoogle from 'seller/app/components/common/PoweredByGoogle';

import formStyles from 'common/components/common/form/Form.css';
import styles from './Form.css';

const LocationFieldLabel = () => (
  <FieldLabelWithAttribution
    className={styles.locationFieldLabel}
    label="Location (City, State, Country)"
    attribution={<PoweredByGoogle />}
  />
);

interface ISignUpFormProps {
  inProgress: boolean;
  error: Gemsby.FormError;
  onSubmit: any;
}

const SignUpForm = ({ inProgress, error, onSubmit }: ISignUpFormProps) => {
  const [state, handleFieldChange] = useFormState({
    email: '',
    password: '',
    name: '',
    emailSubscribe: true,
  });
  const [geo, setGeo] = useState();

  return (
    <form className={styles.form}>
      <fieldset>
        <Field label="Email" error={error.username}>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={state.email}
            onChange={handleFieldChange('email')}
          />
        </Field>
        <Field label="Password" error={error.password}>
          <input
            type="password"
            name="password"
            placeholder="Enter a password"
            value={state.password}
            onChange={handleFieldChange('password')}
          />
        </Field>
        <Field label="Seller Name / Store Name" error={error.name}>
          <input
            type="input"
            name="storeName"
            placeholder="Enter store name"
            value={state.name}
            onChange={handleFieldChange('name')}
          />
        </Field>
        <Field label={<LocationFieldLabel />} error={error.location}>
          <Geosuggest
            className={formStyles.addressInput}
            placeholder="i.e. NYC, NY, United States"
            location={
              new window.google.maps.LatLng(43.653226, -79.38318429999998)
            }
            radius={20}
            onSuggestSelect={suggest => {
              if (!suggest) {
                return;
              }
              const geo = parseGeoSuggest(suggest);
              setGeo(geo);
            }}
          />
        </Field>
        <CheckboxField
          checked={state.emailSubscribe}
          label={
            <span className={styles.checkboxText}>
              Receive FREE Gemsby Seller tips &amp; news
            </span>
          }
          onChange={handleFieldChange('emailSubscribe')}
        />
      </fieldset>
      <div className={styles.footer}>
        <button
          type="submit"
          className={styles.submitButton}
          disabled={inProgress}
          onClick={event => {
            event.preventDefault();
            onSubmit({
              ...state,
              ...geo,
            });
          }}
        >
          {inProgress ? 'Processing...' : 'Sign Up for FREE'}
        </button>
        <div className={styles.secondaryAction}>
          Already have an account? <Link to="/signin">Sign In</Link>.
        </div>
        <div className={styles.note}>
          <p>By signing up you agree to our</p>
          <p>
            <a href={PRIVACY_URL} target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href={TOS_URL} target="_blank">
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
