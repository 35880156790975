// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-list-RefreshButton__container{margin-top:20px;display:flex;justify-content:center;font-size:12px}.projects-seller-app-components-inventory-list-RefreshButton__text{margin-left:5px}", ""]);
// Exports
exports.locals = {
	"container": "projects-seller-app-components-inventory-list-RefreshButton__container",
	"text": "projects-seller-app-components-inventory-list-RefreshButton__text"
};
module.exports = exports;
