import { History } from 'history';
import React from 'react';
import { withRouter } from 'react-router-dom';

import Fa from 'common/components/common/Fa';
import GemsbyIcon from 'common/components/common/GemsbyIcon';
import Card from 'seller/app/components/onboarding/Card';
import onboarding from 'seller/app/services/onboarding';

import logoPaypal from 'common/assets/images/logo-paypal.png';
import styles from './Onboarding.css';

interface IOnboardingProps {
  history: History;
}

const Onboarding: React.FC<IOnboardingProps> = ({ history }) => {
  if (onboarding.isCompleted()) {
    return null;
  }

  const handleCardClick = (path: string) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    history.push(path);
  };

  return (
    <div className={styles.onboarding}>
      <Card
        tagline="Step 1 of 4"
        title="Upload First Item"
        icon={<Fa icon="plus-circle" />}
        desc="Upload your first item to Gemsby."
        isChecked={onboarding.isInventoryUploaded()}
        onClick={handleCardClick('/inventory/new')}
      />
      <Card
        tagline="Step 2 of 4"
        title="Connect to PayPal"
        icon={<img src={logoPaypal} alt="logo_paypal" />}
        desc="Collect payments with PayPal or Stripe."
        isChecked={onboarding.isPaymentConnected()}
        onClick={handleCardClick('/payment')}
      />
      <Card
        tagline="Step 3 of 4"
        title="Update Profile"
        icon={<GemsbyIcon icon="profile" />}
        desc="Customize your seller profile."
        isChecked={onboarding.isProfileUpdated()}
        onClick={handleCardClick('/profile')}
      />
      <Card
        tagline="Step 4 of 4"
        title="Account Info."
        icon={<Fa icon="cog" />}
        desc="Update account information and currency / shipping details."
        isChecked={onboarding.isAccountUpdated()}
        onClick={handleCardClick('/account')}
      />
    </div>
  );
};

export default withRouter(Onboarding);
