import React from 'react';

import Field from 'common/components/common/form/Field';
import FieldNote from 'common/components/common/form/FieldNote';
import ImagePicker from 'common/components/common/form/ImagePicker';
import RadioGroupField from 'common/components/common/form/RadioGroupField';
import SectionCard from 'common/components/common/SectionCard';
import { firstOption } from 'common/utils/misc';
import { repTitles } from 'common/utils/retailer';

import styles from './Form.css';

const SellerRepresentativeCard = ({
  state,
  handleFieldChange,
  onRepImageChange,
}) => {
  return (
    <SectionCard title="Seller Representative">
      <p className={styles.sectionIntro}>
        Filling out a sales representative (this may be you!) gives a more
        personal feel to your profile. It is best if the representative matches
        the phone and email used in your contact information.
      </p>
      <Field label="Representative's Name">
        <input
          type="text"
          placeholder="Enter representative’s name (i.e John Doe)"
          value={state.storeRep}
          onChange={handleFieldChange('storeRep')}
        />
      </Field>
      <RadioGroupField
        label="Representative's Title"
        radioGroupName="storeRepTitle"
        selectedOption={state.storeRepTitle || firstOption(repTitles)}
        options={repTitles}
        onChange={handleFieldChange('storeRepTitle')}
      >
        <FieldNote>This will appear under the representative’s name.</FieldNote>
      </RadioGroupField>
      <Field label="Representative's Photo">
        <ImagePicker
          className={styles.imagePicker}
          max={1}
          images={state.repImage}
          onChange={onRepImageChange}
        />
        <FieldNote>
          Recommended image dimensions: 400px wide by 400px high, or a 1:1 size
          ratio.
        </FieldNote>
      </Field>
    </SectionCard>
  );
};

export default SellerRepresentativeCard;
