import React from 'react';

import { defaultImage } from 'common/utils/inventory';

import styles from './Thumbnail.css';

const Thumbnail = ({ item }) => {
  return (
    <div className={styles.thumbnail}>
      <img src={defaultImage(item, 100)} />
    </div>
  );
};

export default Thumbnail;
