import React from 'react';

import Button from 'common/components/common/Button';

import styles from './HeaderButton.css';

interface IHeaderButtonProps {
  children: React.ReactChild;
  onClick?: React.ReactEventHandler;
  props?: any;
}

const HeaderButton: React.FC<IHeaderButtonProps> = ({
  children,
  onClick,
  ...props
}) => (
  <Button className={styles.headerButton} onClick={onClick} {...props}>
    {children}
  </Button>
);

export default HeaderButton;
