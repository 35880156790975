import React from 'react';
import styles from './MessageField.css';
import Fa from 'common/components/common/Fa';

const ChatMessageInputField = props => {
  return (
    <div className={styles.fieldContain}>
      <input
        type="text"
        placeholder="Message"
        value={props.messageInput}
        onChange={e => props.handleMessage(e)}
        onKeyPress={e => props.submitMessageOnEnter(e)}
        className={styles.field}
      />
      <Fa
        icon="paperclip"
        className={styles.attachIcon}
        onClick={props.toggle}
      />
      <Fa
        icon="paper-plane"
        className={styles.sendIcon}
        onClick={props.handleClick}
      />
    </div>
  );
};

export default ChatMessageInputField;
