import React from 'react';

import classNames from 'classnames';

const Icon = ({ className = '', prefix = '', icon, onClick = () => {} }) => {
  let iconClassName = `icon-${icon}`;
  if (prefix) {
    iconClassName = `${prefix}-${iconClassName}`;
  }
  return (
    <i className={classNames('icon', iconClassName, className)} onClick={onClick} />
  );
};

export default Icon;
