import algoliasearch from 'algoliasearch';
import { Collection } from 'fetch.jsonapi';

import { ALGOLIA_INDICES, LIST_PAGE_LENGTH } from 'common/constants';

const client = algoliasearch(ALGOLIA_APPID, ALGOLIA_APIKEY);

export const retailersIndex = client.initIndex(ALGOLIA_INDICES.retailers);
export const inventoryIndex = client.initIndex(ALGOLIA_INDICES.inventory);

export const search = (index, type, query, page, filters) =>
  new Promise((resolve, reject) => {
    const settings = {
      page,
      hitsPerPage: LIST_PAGE_LENGTH,
    };
    if (filters) {
      settings.facets = '*';
      settings.filters = filters;
    }
    index.search(query, settings, function(err, result) {
      if (err) {
        return reject(err);
      }
      return resolve(
        new Collection({
          data: result.hits.map(hit => ({
            id: `${hit.createdDay}/${hit.objectID}`,
            type,
            source: 'search',
            attributes: hit,
          })),
          meta: {
            pageSize: result.hitsPerPage,
            pageNumber: `${result.page + 1}`,
            recordsTotal: result.nbHits,
            'total-pages': result.nbPages,
            query: result.query,
          },
        })
      );
    });
  });

export const searchInventory = (
  query,
  page,
  retailerId,
  setting,
  jewelryType
) => {
  let filters = [];
  if (retailerId) {
    filters.push(`retailerId:${retailerId}`);
  }
  if (setting) {
    filters.push(`setting:${setting}`);
  }
  if (jewelryType) {
    filters.push(`jewelryType:${jewelryType}`);
  }
  filters = filters.join(' AND ');
  return search(inventoryIndex, 'inventory', query, page, filters);
};

export const searchRetailers = (query, page) => {
  return search(retailersIndex, 'retailer', query, page);
};
