// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-MobileModal__item{padding:20px;margin:0}.projects-common-components-common-mobile-MobileModal__borderBottom{border-bottom:1px solid #d3d3d3}.projects-common-components-common-mobile-MobileModal__overlay{position:fixed;height:100%;width:100%;background-color:rgba(0,0,0,.6);z-index:1000}.projects-common-components-common-mobile-MobileModal__customModal{width:100%;position:absolute;bottom:calc(61px + constant(safe-area-inset-bottom));bottom:calc(61px + env(safe-area-inset-bottom));background-color:#fff;box-shadow:3px 3px 20px 1px rgba(0,0,0,.6);z-index:1001}", ""]);
// Exports
exports.locals = {
	"item": "projects-common-components-common-mobile-MobileModal__item",
	"borderBottom": "projects-common-components-common-mobile-MobileModal__borderBottom",
	"overlay": "projects-common-components-common-mobile-MobileModal__overlay",
	"customModal": "projects-common-components-common-mobile-MobileModal__customModal"
};
module.exports = exports;
