import { useState } from 'react';

export const NA_OPTION = 'not-applicable';
export const NA_TEXT = 'not applicable';
export const isNaOption = (option: string) =>
  option === NA_OPTION || option.toLowerCase() === NA_TEXT;
export const isNullOption = (option: string) => option === '' || option === '-';

const defaultImage = {
  attributes: {
    path: '',
    caption: '',
  },
  file: null,
};

export function errorClassName(error) {
  return error ? 'state-error' : '';
}

export const useFormState = (initialState = {}) => {
  const [state, setState] = useState(initialState);
  const handleFieldChange = field => event => {
    const { type, value, checked } = event.target;
    setState({
      ...state,
      [field]: type === 'checkbox' ? checked : value,
    });
  };
  return [state, handleFieldChange, setState];
};

export const scrollToFormError = formErrorRef => {
  requestAnimationFrame(() => {
    if (formErrorRef.current) {
      formErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  });
};
