import React from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import InventoryMultipleItemsForm from 'seller/app/components/inventory/form/MultipleItemsForm';
import { Routes } from 'seller/app/constants';
import Modal from 'common/components/common/mobile/MobileModal';

const PageTitle = () => <PageHeader>Add Multiple Items</PageHeader>;

const InventoryMultipleItemsFormPageMobile = () => (
  <>
    <Modal />
    <Screen>
      <PageMobile>
        <PageContent heightReducedBy={60}>
          <HeaderNavMobile />

          <PageTitle />

          <PageSection>
            <BannerAd />
          </PageSection>

          <PageSection>
            <InventoryMultipleItemsForm />
          </PageSection>
        </PageContent>

        <TabBar activeItem={Routes.inventory} />
      </PageMobile>
    </Screen>
  </>
);

const InventoryMultipleItemsFormPageDesktop = () => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.inventory} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <PageTitle />
      <InventoryMultipleItemsForm />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const InventoryMultipleItemsFormPage = ({ isBigScreen }) =>
  isBigScreen ? (
    <InventoryMultipleItemsFormPageDesktop />
  ) : (
    <InventoryMultipleItemsFormPageMobile />
  );

const mapState = ({ device, login }) => ({
  isBigScreen: device.isBigScreen,
  retailer: login.currentUser,
});

export default connect(mapState)(InventoryMultipleItemsFormPage);
