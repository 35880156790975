// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-common-Button__button{height:45px;display:flex;align-items:center;justify-content:center;border-radius:3px}.projects-seller-app-components-common-Button__button .fa{font-size:22px;margin-right:10px}.projects-seller-app-components-common-Button__mainActionButton{}.projects-seller-app-components-common-Button__regularActionButton{color:var(--textColor);margin-right:10px}@media (max-width:480px){.projects-seller-app-components-common-Button__regularActionButton{margin-right:0}}", ""]);
// Exports
exports.locals = {
	"button": "projects-seller-app-components-common-Button__button",
	"mainActionButton": "projects-seller-app-components-common-Button__mainActionButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + " projects-seller-app-components-common-Button__button",
	"regularActionButton": "projects-seller-app-components-common-Button__regularActionButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["button"] + " projects-seller-app-components-common-Button__button"
};
module.exports = exports;
