import React from 'react';

import Field from 'common/components/common/form/Field';
import SectionCard from 'common/components/common/SectionCard';

const ChangePasswordCard = ({
  state: { oldPassword, password, verifyPassword },
  error,
  handleFieldChange,
}) => {
  return (
    <SectionCard title="Change Password">
      <Field label="Old Password" error={error.oldPassword}>
        <input
          type="password"
          placeholder="Enter old password"
          autoComplete="new-password"
          value={oldPassword}
          onChange={handleFieldChange('oldPassword')}
        />
      </Field>
      <Field label="Password" error={error.password}>
        <input
          type="password"
          placeholder="Enter new password"
          value={password}
          onChange={handleFieldChange('password')}
        />
      </Field>
      <Field label="Confirm Password" error={error.verifyPassword}>
        <input
          type="password"
          placeholder="Enter new password again"
          value={verifyPassword}
          onChange={handleFieldChange('verifyPassword')}
        />
      </Field>
    </SectionCard>
  );
};

export default ChangePasswordCard;
