import React from 'react';
import { Link } from 'react-router-dom';

import CheckboxField from 'common/components/common/form/CheckboxField';
import Field from 'common/components/common/form/Field';
import { useFormState } from 'common/utils/form';

import styles from './Form.css';

interface ISignInFormProps {
  inProgress: boolean;
  error: Gemsby.FormError;
  onSubmit: any;
}

const SignInForm = ({ inProgress, error, onSubmit }: ISignInFormProps) => {
  const [state, handleFieldChange] = useFormState({
    email: '',
    password: '',
    saveLogin: true,
  });

  return (
    <form className={styles.form}>
      <fieldset>
        <Field label="Email" error={error.email}>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={state.email}
            disabled={inProgress}
            onChange={handleFieldChange('email')}
          />
        </Field>
        <Field label="Password" error={error.password}>
          <input
            type="password"
            name="password"
            placeholder="Enter a password"
            value={state.password}
            disabled={inProgress}
            onChange={handleFieldChange('password')}
          />
        </Field>
        <CheckboxField
          checked={state.saveLogin}
          label={<span className={styles.checkboxText}>Stay signed in</span>}
          disabled={inProgress}
          onChange={handleFieldChange('saveLogin')}
        />
      </fieldset>
      <div className={styles.footer}>
        <button
          type="submit"
          className={styles.submitButton}
          disabled={inProgress}
          onClick={event => {
            event.preventDefault();
            onSubmit(state);
          }}
        >
          {inProgress ? 'Processing...' : 'Sign In'}
        </button>
        <div className={styles.secondaryAction}>
          <Link to="#">Forgot Password?</Link>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
