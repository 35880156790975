// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-tabbar-TabBarItem__item{align-items:center;justify-content:center;width:50px;height:50px;font-size:30px;color:#999}.projects-common-components-common-mobile-tabbar-TabBarItem__item,.projects-common-components-common-mobile-tabbar-TabBarItem__item a{display:flex}.projects-common-components-common-mobile-tabbar-TabBarItem__activeItem{}.projects-common-components-common-mobile-tabbar-TabBarItem__activeItem,.projects-common-components-common-mobile-tabbar-TabBarItem__activeItem .icon:before{color:var(--mainColor)}.projects-common-components-common-mobile-tabbar-TabBarItem__imageButton img{width:45px;height:45px}.projects-common-components-common-mobile-tabbar-TabBarItem__badges{position:absolute;top:7px;right:3px;height:8px;width:8px;border-radius:50%;background-color:#b80000}", ""]);
// Exports
exports.locals = {
	"item": "projects-common-components-common-mobile-tabbar-TabBarItem__item",
	"activeItem": "projects-common-components-common-mobile-tabbar-TabBarItem__activeItem projects-common-components-common-mobile-tabbar-TabBarItem__item",
	"imageButton": "projects-common-components-common-mobile-tabbar-TabBarItem__imageButton",
	"badges": "projects-common-components-common-mobile-tabbar-TabBarItem__badges"
};
module.exports = exports;
