import roundBrilliantImage from 'common/assets/images/shapes/round-brilliant.jpg'
import roundBrilliantBlackImage from 'common/assets/images/shapes/round-brilliant-black.jpg'
import roundBrilliantBlueImage from 'common/assets/images/shapes/round-brilliant-blue.jpg'
import roundBrilliantBrownImage from 'common/assets/images/shapes/round-brilliant-brown.jpg'
import roundBrilliantCanaryImage from 'common/assets/images/shapes/round-brilliant-canary.jpg'
import roundBrilliantChampagneImage from 'common/assets/images/shapes/round-brilliant-champagne.jpg'
import roundBrilliantCognacImage from 'common/assets/images/shapes/round-brilliant-cognac.jpg'
import roundBrilliantGreenImage from 'common/assets/images/shapes/round-brilliant-green.jpg'
import roundBrilliantGreyImage from 'common/assets/images/shapes/round-brilliant-grey.jpg'
import roundBrilliantOrangeImage from 'common/assets/images/shapes/round-brilliant-orange.jpg'
import roundBrilliantPinkImage from 'common/assets/images/shapes/round-brilliant-pink.jpg'
import roundBrilliantPurpleImage from 'common/assets/images/shapes/round-brilliant-purple.jpg'
import roundBrilliantRedImage from 'common/assets/images/shapes/round-brilliant-red.jpg'
import roundBrilliantYellowImage from 'common/assets/images/shapes/round-brilliant-yellow.jpg'

import cushionImage from 'common/assets/images/shapes/cushion.jpg'
import cushionBlackImage from 'common/assets/images/shapes/cushion-black.jpg'
import cushionBlueImage from 'common/assets/images/shapes/cushion-blue.jpg'
import cushionBrownImage from 'common/assets/images/shapes/cushion-brown.jpg'
import cushionCanaryImage from 'common/assets/images/shapes/cushion-canary.jpg'
import cushionChampagneImage from 'common/assets/images/shapes/cushion-champagne.jpg'
import cushionCognacImage from 'common/assets/images/shapes/cushion-cognac.jpg'
import cushionGreenImage from 'common/assets/images/shapes/cushion-green.jpg'
import cushionGreyImage from 'common/assets/images/shapes/cushion-grey.jpg'
import cushionOrangeImage from 'common/assets/images/shapes/cushion-orange.jpg'
import cushionPinkImage from 'common/assets/images/shapes/cushion-pink.jpg'
import cushionPurpleImage from 'common/assets/images/shapes/cushion-purple.jpg'
import cushionRedImage from 'common/assets/images/shapes/cushion-red.jpg'
import cushionYellowImage from 'common/assets/images/shapes/cushion-yellow.jpg'

import asscherImage from 'common/assets/images/shapes/asscher.jpg'
import asscherBlackImage from 'common/assets/images/shapes/asscher-black.jpg'
import asscherBlueImage from 'common/assets/images/shapes/asscher-blue.jpg'
import asscherBrownImage from 'common/assets/images/shapes/asscher-brown.jpg'
import asscherCanaryImage from 'common/assets/images/shapes/asscher-canary.jpg'
import asscherChampagneImage from 'common/assets/images/shapes/asscher-champagne.jpg'
import asscherCognacImage from 'common/assets/images/shapes/asscher-cognac.jpg'
import asscherGreenImage from 'common/assets/images/shapes/asscher-green.jpg'
import asscherGreyImage from 'common/assets/images/shapes/asscher-grey.jpg'
import asscherOrangeImage from 'common/assets/images/shapes/asscher-orange.jpg'
import asscherPinkImage from 'common/assets/images/shapes/asscher-pink.jpg'
import asscherPurpleImage from 'common/assets/images/shapes/asscher-purple.jpg'
import asscherRedImage from 'common/assets/images/shapes/asscher-red.jpg'
import asscherYellowImage from 'common/assets/images/shapes/asscher-yellow.jpg'

import princessImage from 'common/assets/images/shapes/princess.jpg'
import princessBlackImage from 'common/assets/images/shapes/princess-black.jpg'
import princessBlueImage from 'common/assets/images/shapes/princess-blue.jpg'
import princessBrownImage from 'common/assets/images/shapes/princess-brown.jpg'
import princessCanaryImage from 'common/assets/images/shapes/princess-canary.jpg'
import princessChampagneImage from 'common/assets/images/shapes/princess-champagne.jpg'
import princessCognacImage from 'common/assets/images/shapes/princess-cognac.jpg'
import princessGreenImage from 'common/assets/images/shapes/princess-green.jpg'
import princessGreyImage from 'common/assets/images/shapes/princess-grey.jpg'
import princessOrangeImage from 'common/assets/images/shapes/princess-orange.jpg'
import princessPinkImage from 'common/assets/images/shapes/princess-pink.jpg'
import princessPurpleImage from 'common/assets/images/shapes/princess-purple.jpg'
import princessRedImage from 'common/assets/images/shapes/princess-red.jpg'
import princessYellowImage from 'common/assets/images/shapes/princess-yellow.jpg'

import pearImage from 'common/assets/images/shapes/pear.jpg'
import pearBlackImage from 'common/assets/images/shapes/pear-black.jpg'
import pearBlueImage from 'common/assets/images/shapes/pear-blue.jpg'
import pearBrownImage from 'common/assets/images/shapes/pear-brown.jpg'
import pearCanaryImage from 'common/assets/images/shapes/pear-canary.jpg'
import pearChampagneImage from 'common/assets/images/shapes/pear-champagne.jpg'
import pearCognacImage from 'common/assets/images/shapes/pear-cognac.jpg'
import pearGreenImage from 'common/assets/images/shapes/pear-green.jpg'
import pearGreyImage from 'common/assets/images/shapes/pear-grey.jpg'
import pearOrangeImage from 'common/assets/images/shapes/pear-orange.jpg'
import pearPinkImage from 'common/assets/images/shapes/pear-pink.jpg'
import pearPurpleImage from 'common/assets/images/shapes/pear-purple.jpg'
import pearRedImage from 'common/assets/images/shapes/pear-red.jpg'
import pearYellowImage from 'common/assets/images/shapes/pear-yellow.jpg'

import emeraldImage from 'common/assets/images/shapes/emerald.jpg'
import emeraldBlackImage from 'common/assets/images/shapes/emerald-black.jpg'
import emeraldBlueImage from 'common/assets/images/shapes/emerald-blue.jpg'
import emeraldBrownImage from 'common/assets/images/shapes/emerald-brown.jpg'
import emeraldCanaryImage from 'common/assets/images/shapes/emerald-canary.jpg'
import emeraldChampagneImage from 'common/assets/images/shapes/emerald-champagne.jpg'
import emeraldCognacImage from 'common/assets/images/shapes/emerald-cognac.jpg'
import emeraldGreenImage from 'common/assets/images/shapes/emerald-green.jpg'
import emeraldGreyImage from 'common/assets/images/shapes/emerald-grey.jpg'
import emeraldOrangeImage from 'common/assets/images/shapes/emerald-orange.jpg'
import emeraldPinkImage from 'common/assets/images/shapes/emerald-pink.jpg'
import emeraldPurpleImage from 'common/assets/images/shapes/emerald-purple.jpg'
import emeraldRedImage from 'common/assets/images/shapes/emerald-red.jpg'
import emeraldYellowImage from 'common/assets/images/shapes/emerald-yellow.jpg'

import marquiseImage from 'common/assets/images/shapes/marquise.jpg'
import marquiseBlackImage from 'common/assets/images/shapes/marquise-black.jpg'
import marquiseBlueImage from 'common/assets/images/shapes/marquise-blue.jpg'
import marquiseBrownImage from 'common/assets/images/shapes/marquise-brown.jpg'
import marquiseCanaryImage from 'common/assets/images/shapes/marquise-canary.jpg'
import marquiseChampagneImage from 'common/assets/images/shapes/marquise-champagne.jpg'
import marquiseCognacImage from 'common/assets/images/shapes/marquise-cognac.jpg'
import marquiseGreenImage from 'common/assets/images/shapes/marquise-green.jpg'
import marquiseGreyImage from 'common/assets/images/shapes/marquise-grey.jpg'
import marquiseOrangeImage from 'common/assets/images/shapes/marquise-orange.jpg'
import marquisePinkImage from 'common/assets/images/shapes/marquise-pink.jpg'
import marquisePurpleImage from 'common/assets/images/shapes/marquise-purple.jpg'
import marquiseRedImage from 'common/assets/images/shapes/marquise-red.jpg'
import marquiseYellowImage from 'common/assets/images/shapes/marquise-yellow.jpg'

import heartImage from 'common/assets/images/shapes/heart.jpg'
import heartBlackImage from 'common/assets/images/shapes/heart-black.jpg'
import heartBlueImage from 'common/assets/images/shapes/heart-blue.jpg'
import heartBrownImage from 'common/assets/images/shapes/heart-brown.jpg'
import heartCanaryImage from 'common/assets/images/shapes/heart-canary.jpg'
import heartChampagneImage from 'common/assets/images/shapes/heart-champagne.jpg'
import heartCognacImage from 'common/assets/images/shapes/heart-cognac.jpg'
import heartGreenImage from 'common/assets/images/shapes/heart-green.jpg'
import heartGreyImage from 'common/assets/images/shapes/heart-grey.jpg'
import heartOrangeImage from 'common/assets/images/shapes/heart-orange.jpg'
import heartPinkImage from 'common/assets/images/shapes/heart-pink.jpg'
import heartPurpleImage from 'common/assets/images/shapes/heart-purple.jpg'
import heartRedImage from 'common/assets/images/shapes/heart-red.jpg'
import heartYellowImage from 'common/assets/images/shapes/heart-yellow.jpg'

import ovalImage from 'common/assets/images/shapes/oval.jpg'
import ovalBlackImage from 'common/assets/images/shapes/oval-black.jpg'
import ovalBlueImage from 'common/assets/images/shapes/oval-blue.jpg'
import ovalBrownImage from 'common/assets/images/shapes/oval-brown.jpg'
import ovalCanaryImage from 'common/assets/images/shapes/oval-canary.jpg'
import ovalChampagneImage from 'common/assets/images/shapes/oval-champagne.jpg'
import ovalCognacImage from 'common/assets/images/shapes/oval-cognac.jpg'
import ovalGreenImage from 'common/assets/images/shapes/oval-green.jpg'
import ovalGreyImage from 'common/assets/images/shapes/oval-grey.jpg'
import ovalOrangeImage from 'common/assets/images/shapes/oval-orange.jpg'
import ovalPinkImage from 'common/assets/images/shapes/oval-pink.jpg'
import ovalPurpleImage from 'common/assets/images/shapes/oval-purple.jpg'
import ovalRedImage from 'common/assets/images/shapes/oval-red.jpg'
import ovalYellowImage from 'common/assets/images/shapes/oval-yellow.jpg'

import trilliantImage from 'common/assets/images/shapes/trilliant.jpg'
import trilliantBlackImage from 'common/assets/images/shapes/trilliant-black.jpg'
import trilliantBlueImage from 'common/assets/images/shapes/trilliant-blue.jpg'
import trilliantBrownImage from 'common/assets/images/shapes/trilliant-brown.jpg'
import trilliantCanaryImage from 'common/assets/images/shapes/trilliant-canary.jpg'
import trilliantChampagneImage from 'common/assets/images/shapes/trilliant-champagne.jpg'
import trilliantCognacImage from 'common/assets/images/shapes/trilliant-cognac.jpg'
import trilliantGreenImage from 'common/assets/images/shapes/trilliant-green.jpg'
import trilliantGreyImage from 'common/assets/images/shapes/trilliant-grey.jpg'
import trilliantOrangeImage from 'common/assets/images/shapes/trilliant-orange.jpg'
import trilliantPinkImage from 'common/assets/images/shapes/trilliant-pink.jpg'
import trilliantPurpleImage from 'common/assets/images/shapes/trilliant-purple.jpg'
import trilliantRedImage from 'common/assets/images/shapes/trilliant-red.jpg'
import trilliantYellowImage from 'common/assets/images/shapes/trilliant-yellow.jpg'

import baguetteImage from 'common/assets/images/shapes/baguette.jpg'
import baguetteBlackImage from 'common/assets/images/shapes/baguette-black.jpg'
import baguetteBlueImage from 'common/assets/images/shapes/baguette-blue.jpg'
import baguetteBrownImage from 'common/assets/images/shapes/baguette-brown.jpg'
import baguetteCanaryImage from 'common/assets/images/shapes/baguette-canary.jpg'
import baguetteChampagneImage from 'common/assets/images/shapes/baguette-champagne.jpg'
import baguetteCognacImage from 'common/assets/images/shapes/baguette-cognac.jpg'
import baguetteGreenImage from 'common/assets/images/shapes/baguette-green.jpg'
import baguetteGreyImage from 'common/assets/images/shapes/baguette-grey.jpg'
import baguetteOrangeImage from 'common/assets/images/shapes/baguette-orange.jpg'
import baguettePinkImage from 'common/assets/images/shapes/baguette-pink.jpg'
import baguettePurpleImage from 'common/assets/images/shapes/baguette-purple.jpg'
import baguetteRedImage from 'common/assets/images/shapes/baguette-red.jpg'
import baguetteYellowImage from 'common/assets/images/shapes/baguette-yellow.jpg'

import taperedBaguetteImage from 'common/assets/images/shapes/tapered-baguette.jpg'
import taperedBaguetteBlackImage from 'common/assets/images/shapes/tapered-baguette-black.png'
import taperedBaguetteBlueImage from 'common/assets/images/shapes/tapered-baguette-blue.png'
import taperedBaguetteBrownImage from 'common/assets/images/shapes/tapered-baguette-brown.png'
import taperedBaguetteCanaryImage from 'common/assets/images/shapes/tapered-baguette-canary.png'
import taperedBaguetteChampagneImage from 'common/assets/images/shapes/tapered-baguette-champagne.png'
import taperedBaguetteCognacImage from 'common/assets/images/shapes/tapered-baguette-cognac.png'
import taperedBaguetteGreenImage from 'common/assets/images/shapes/tapered-baguette-green.png'
import taperedBaguetteGreyImage from 'common/assets/images/shapes/tapered-baguette-grey.png'
import taperedBaguetteOrangeImage from 'common/assets/images/shapes/tapered-baguette-orange.png'
import taperedBaguettePinkImage from 'common/assets/images/shapes/tapered-baguette-pink.png'
import taperedBaguettePurpleImage from 'common/assets/images/shapes/tapered-baguette-purple.png'
import taperedBaguetteRedImage from 'common/assets/images/shapes/tapered-baguette-red.png'
import taperedBaguetteYellowImage from 'common/assets/images/shapes/tapered-baguette-yellow.png'

import radiantImage from 'common/assets/images/shapes/radiant.jpg'
import radiantBlackImage from 'common/assets/images/shapes/radiant-black.jpg'
import radiantBlueImage from 'common/assets/images/shapes/radiant-blue.jpg'
import radiantBrownImage from 'common/assets/images/shapes/radiant-brown.jpg'
import radiantCanaryImage from 'common/assets/images/shapes/radiant-canary.jpg'
import radiantChampagneImage from 'common/assets/images/shapes/radiant-champagne.jpg'
import radiantCognacImage from 'common/assets/images/shapes/radiant-cognac.jpg'
import radiantGreenImage from 'common/assets/images/shapes/radiant-green.jpg'
import radiantGreyImage from 'common/assets/images/shapes/radiant-grey.jpg'
import radiantOrangeImage from 'common/assets/images/shapes/radiant-orange.jpg'
import radiantPinkImage from 'common/assets/images/shapes/radiant-pink.jpg'
import radiantPurpleImage from 'common/assets/images/shapes/radiant-purple.jpg'
import radiantRedImage from 'common/assets/images/shapes/radiant-red.jpg'
import radiantYellowImage from 'common/assets/images/shapes/radiant-yellow.jpg'

import roseCutImage from 'common/assets/images/shapes/rose-cut.jpg'

import otherImage from 'common/assets/images/shapes/other.jpg'

const imageMap = {
  'round-brilliant': {
    noColor: roundBrilliantImage,
    black: roundBrilliantBlackImage,
    blue: roundBrilliantBlueImage,
    brown: roundBrilliantBrownImage,
    canary: roundBrilliantCanaryImage,
    champagne: roundBrilliantChampagneImage,
    cognac: roundBrilliantCognacImage,
    green: roundBrilliantGreenImage,
    grey: roundBrilliantGreyImage,
    orange: roundBrilliantOrangeImage,
    pink: roundBrilliantPinkImage,
    purple: roundBrilliantPurpleImage,
    red: roundBrilliantRedImage,
    yellow: roundBrilliantYellowImage
  },
  cushion: {
    noColor: cushionImage,
    black: cushionBlackImage,
    blue: cushionBlueImage,
    brown: cushionBrownImage,
    canary: cushionCanaryImage,
    champagne: cushionChampagneImage,
    cognac: cushionCognacImage,
    green: cushionGreenImage,
    grey: cushionGreyImage,
    orange: cushionOrangeImage,
    pink: cushionPinkImage,
    purple: cushionPurpleImage,
    red: cushionRedImage,
    yellow: cushionYellowImage
  },
  asscher: {
    noColor: asscherImage,
    black: asscherBlackImage,
    blue: asscherBlueImage,
    brown: asscherBrownImage,
    canary: asscherCanaryImage,
    champagne: asscherChampagneImage,
    cognac: asscherCognacImage,
    green: asscherGreenImage,
    grey: asscherGreyImage,
    orange: asscherOrangeImage,
    pink: asscherPinkImage,
    purple: asscherPurpleImage,
    red: asscherRedImage,
    yellow: asscherYellowImage
  },
  princess: {
    noColor: princessImage,
    black: princessBlackImage,
    blue: princessBlueImage,
    brown: princessBrownImage,
    canary: princessCanaryImage,
    champagne: princessChampagneImage,
    cognac: princessCognacImage,
    green: princessGreenImage,
    grey: princessGreyImage,
    orange: princessOrangeImage,
    pink: princessPinkImage,
    purple: princessPurpleImage,
    red: princessRedImage,
    yellow: princessYellowImage
  },
  pear: {
    noColor: pearImage,
    black: pearBlackImage,
    blue: pearBlueImage,
    brown: pearBrownImage,
    canary: pearCanaryImage,
    champagne: pearChampagneImage,
    cognac: pearCognacImage,
    green: pearGreenImage,
    grey: pearGreyImage,
    orange: pearOrangeImage,
    pink: pearPinkImage,
    purple: pearPurpleImage,
    red: pearRedImage,
    yellow: pearYellowImage
  },
  emerald: {
    noColor: emeraldImage,
    black: emeraldBlackImage,
    blue: emeraldBlueImage,
    brown: emeraldBrownImage,
    canary: emeraldCanaryImage,
    champagne: emeraldChampagneImage,
    cognac: emeraldCognacImage,
    green: emeraldGreenImage,
    grey: emeraldGreyImage,
    orange: emeraldOrangeImage,
    pink: emeraldPinkImage,
    purple: emeraldPurpleImage,
    red: emeraldRedImage,
    yellow: emeraldYellowImage
  },
  marquise: {
    noColor: marquiseImage,
    black: marquiseBlackImage,
    blue: marquiseBlueImage,
    brown: marquiseBrownImage,
    canary: marquiseCanaryImage,
    champagne: marquiseChampagneImage,
    cognac: marquiseCognacImage,
    green: marquiseGreenImage,
    grey: marquiseGreyImage,
    orange: marquiseOrangeImage,
    pink: marquisePinkImage,
    purple: marquisePurpleImage,
    red: marquiseRedImage,
    yellow: marquiseYellowImage
  },
  heart: {
    noColor: heartImage,
    black: heartBlackImage,
    blue: heartBlueImage,
    brown: heartBrownImage,
    canary: heartCanaryImage,
    champagne: heartChampagneImage,
    cognac: heartCognacImage,
    green: heartGreenImage,
    grey: heartGreyImage,
    orange: heartOrangeImage,
    pink: heartPinkImage,
    purple: heartPurpleImage,
    red: heartRedImage,
    yellow: heartYellowImage
  },
  oval: {
    noColor: ovalImage,
    black: ovalBlackImage,
    blue: ovalBlueImage,
    brown: ovalBrownImage,
    canary: ovalCanaryImage,
    champagne: ovalChampagneImage,
    cognac: ovalCognacImage,
    green: ovalGreenImage,
    grey: ovalGreyImage,
    orange: ovalOrangeImage,
    pink: ovalPinkImage,
    purple: ovalPurpleImage,
    red: ovalRedImage,
    yellow: ovalYellowImage
  },
  trilliant: {
    noColor: trilliantImage,
    black: trilliantBlackImage,
    blue: trilliantBlueImage,
    brown: trilliantBrownImage,
    canary: trilliantCanaryImage,
    champagne: trilliantChampagneImage,
    cognac: trilliantCognacImage,
    green: trilliantGreenImage,
    grey: trilliantGreyImage,
    orange: trilliantOrangeImage,
    pink: trilliantPinkImage,
    purple: trilliantPurpleImage,
    red: trilliantRedImage,
    yellow: trilliantYellowImage
  },
  baguette: {
    noColor: baguetteImage,
    black: baguetteBlackImage,
    blue: baguetteBlueImage,
    brown: baguetteBrownImage,
    canary: baguetteCanaryImage,
    champagne: baguetteChampagneImage,
    cognac: baguetteCognacImage,
    green: baguetteGreenImage,
    grey: baguetteGreyImage,
    orange: baguetteOrangeImage,
    pink: baguettePinkImage,
    purple: baguettePurpleImage,
    red: baguetteRedImage,
    yellow: baguetteYellowImage
  },
  'tapered-baguette': {
    noColor: taperedBaguetteImage,
    black: taperedBaguetteBlackImage,
    blue: taperedBaguetteBlueImage,
    brown: taperedBaguetteBrownImage,
    canary: taperedBaguetteCanaryImage,
    champagne: taperedBaguetteChampagneImage,
    cognac: taperedBaguetteCognacImage,
    green: taperedBaguetteGreenImage,
    grey: taperedBaguetteGreyImage,
    orange: taperedBaguetteOrangeImage,
    pink: taperedBaguettePinkImage,
    purple: taperedBaguettePurpleImage,
    red: taperedBaguetteRedImage,
    yellow: taperedBaguetteYellowImage
  },
  radiant: {
    noColor: radiantImage,
    black: radiantBlackImage,
    blue: radiantBlueImage,
    brown: radiantBrownImage,
    canary: radiantCanaryImage,
    champagne: radiantChampagneImage,
    cognac: radiantCognacImage,
    green: radiantGreenImage,
    grey: radiantGreyImage,
    orange: radiantOrangeImage,
    pink: radiantPinkImage,
    purple: radiantPurpleImage,
    red: radiantRedImage,
    yellow: radiantYellowImage
  },
  'rose-cut': {
    noColor: roseCutImage
  },
  other: {
    noColor: otherImage
  }
}

const shapeImage = (shape, color) => {
  const images = imageMap[shape]
  if (!images) {
    return imageMap['other']['noColor']
  }
  if (color && images[color]) {
    return images[color]
  }
  return images['noColor']
}

export default shapeImage
