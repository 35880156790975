import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Fa from 'common/components/common/Fa';
import SelectFileButton from 'common/components/common/form/SelectFileButton';

import styles from './ImagePicker.css';

interface IImagePickerProps extends Gemsby.IComponentProps {
  max?: number;
  images: Gemsby.FormImage[];
  error: any;
  onSelect: any;
  onRemove: any;
}

const normalizeImageFiles = images => [].concat(images || []);

const ImagePicker: React.FC<IImagePickerProps> = ({
  className,
  max = 5,
  size,
  images,
  error,
  onSelect,
  onRemove,
  onChange,
}) => {
  const [imageFiles, setImageFiles] = useState(normalizeImageFiles(images));
  const [defaultError, setError] = useState('');
  useEffect(() => setImageFiles(normalizeImageFiles(images)), [images]);

  const handleImageSelect = file => {
    setError('');
    if (size && file.size > size * 1024 * 1024) {
      setError('Attached image size should be up to 1MB');
      return;
    }
    const imageFile = {
      file,
      url: URL.createObjectURL(file),
    };
    imageFiles = imageFiles.concat(imageFile);
    setImageFiles(imageFiles);

    if (onSelect) {
      onSelect(imageFile, imageFiles);
    }
    if (onChange) {
      onChange(imageFiles);
    }
  };

  const handleImageRemove = imageFile => event => {
    event.preventDefault();
    imageFile.removed = true;
    setImageFiles([...imageFiles]);

    if (imageFile.file) {
      URL.revokeObjectURL(imageFile.url);
      imageFile.url = null;
    }

    if (onRemove) {
      onRemove(imageFile, imageFiles);
    }
    if (onChange) {
      onChange(imageFiles);
    }
  };

  const availableImageFiles = imageFiles.filter(({ removed }) => !removed);

  return (
    <div className={classNames(styles.imagePicker, className)}>
      <ul className={styles.images}>
        {availableImageFiles.map((imageFile, index) => (
          <li className={styles.image} key={index}>
            <img src={imageFile.url} alt="image" />
            <div
              className={styles.removeButton}
              onClick={handleImageRemove(imageFile)}
            >
              <Fa icon="times-circle" />
            </div>
          </li>
        ))}
      </ul>
      <SelectFileButton
        className={styles.selectButton}
        text="Select Image"
        accept="image/jpeg, image/png"
        disabled={availableImageFiles.length === max}
        onSelect={handleImageSelect}
      />
      {error ? (
        <div className={styles.errorMessage}>{error}</div>
      ) : (
        <div className={styles.errorMessage}>{defaultError}</div>
      )}
    </div>
  );
};

export default ImagePicker;
