import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/common/Button';

import styles from './Dialog.css';

interface IDialog extends Gemsby.IComponentProps {
  containerClassName?: string;
  show: boolean;
  message: string;
  buttons: Gemsby.IDialogButton[];
}

const Dialog = ({
  className,
  containerClassName,
  show,
  message,
  buttons,
}: IDialog) => {
  if (!show) {
    return null;
  }
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={classNames(styles.dialog, className)}>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttons}>
          {/* tslint:disable-next-line:no-shadowed-variable */}
          {buttons.map(({ className = '', label, onClick }, i) => (
            <Button
              key={i}
              className={classNames(styles.button, className)}
              onClick={onClick}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapState = ({ dialog }) => ({
  show: dialog.isDialogShown,
  message: dialog.message,
  buttons: dialog.buttons,
});

export default connect(mapState)(Dialog);
