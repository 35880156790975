export const readFileAsDataUrl = file => new Promise(resolve => {
  const reader = new window.FileReader();
  reader.addEventListener(
    'load',
    () => {
      resolve(reader.result);
    },
    false
  )
  reader.readAsDataURL(file);
});

export const splitDataUrl = (dataUrl) => {
  const m = /data:([^;]+);base64,(.+)/.exec(dataUrl);
  let mimeType = '';
  let data = '';
  if (m) {
    mimeType = m[1];
    data = m[2];
  }
  return { mimeType, data };
}

export const readFile = file => readFileAsDataUrl(file)
  .then(splitDataUrl)
  .then(({ mimeType, data }) => ({
    mimeType,
    fileContent: data
  }));

export default {
  readFileAsDataUrl,
  splitDataUrl,
  readFile
};
