import React from 'react';
import classNames from 'classnames';

import Fa from 'common/components/common/Fa';
import OutLink from 'common/components/common/OutLink';

import styles from './HelpCenter.css';

interface IHelpCenterProps extends Gemsby.IComponentProps {
  title: string;
  link: string;
  center?: boolean;
}

const HelpCenter: React.FC<IHelpCenterProps> = ({ title, link, center }) => {
  return (
    <div className={classNames(styles.helpCenter, { [styles.center]: center })}>
      {!center && <Fa className={styles.questionIcon} icon="question-circle" />}
      <div className={styles.blurb}>
        <OutLink className={styles.helpCenterLink} to="https://intercom.help/gemsby/selling-on-gemsby">
          Gemsby Help Center
        </OutLink>
        <OutLink className={styles.articleLink} to={link}>
          {title}
        </OutLink>
      </div>
    </div>
  );
};

export default HelpCenter;
