import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import HelpCenter from 'common/components/common/HelpCenter';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import OrderList from 'seller/app/components/order/list/List';
import { Routes } from 'seller/app/constants';
import api from 'seller/app/services/api';
import Modal from 'common/components/common/mobile/MobileModal';

const OrderListPageMobile = props => (
  <>
  <Modal />
  <Screen>
    <PageMobile>
      <PageContent heightReducedBy={60}>
        <HeaderNavMobile />

        <PageHeader>Orders</PageHeader>

        <PageSection>
          <BannerAd />
        </PageSection>

        <PageSection>
          <OrderList {...props} />
        </PageSection>
      </PageContent>

      <TabBar activeItem={Routes.orders} />
    </PageMobile>
  </Screen>
  </>
);

const OrderListPageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.orders} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <OrderList {...props} />

      <HelpCenter
        title="Accept Customer Payments"
        link="https://intercom.help/gemsby/selling-on-gemsby/handling-orders/how-to-accept-payment-from-customers-through-gemsby"
        center
      />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const OrderListPage = ({ isBigScreen }) => {
  const [state, setState] = useState({
    loading: true,
    orders: null,
    currentPage: 0,
    totalPages: 0,
  });

  const setLoading = (loading: boolean) =>
    setState({
      ...state,
      loading,
    });

  const setCurrentPage = (currentPage: number) =>
    setState({
      ...state,
      currentPage,
    });

  useEffect(() => {
    const { currentPage } = state;
    setLoading(true);
    api.getOrderList(currentPage + 1).then(orders => {
      setState({
        ...state,
        loading: false,
        orders: orders.toJSON(),
        totalPages: orders.getMeta('total-pages'),
      });
    });
  }, [state.currentPage]);

  // if (state.totalPages < 1) {
  //   if (state.loading) {
  //     return <SpinningDiamond fullScreen />;
  //   }
  // }

  const ListPage = isBigScreen ? OrderListPageDesktop : OrderListPageMobile;

  return (
    <ListPage
      isBigScreen={isBigScreen}
      state={state}
      setCurrentPage={setCurrentPage}
    />
  );
};

const mapState = ({ device }) => ({
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(OrderListPage);
