import React from 'react';
import Button from 'common/components/common/Button';
import styles from './PromoteFooter.css'
import NoThanksButton from 'seller/app/components/inventory/promote/NoThanksButton/NoThanksButton';

const PromoteFooter = () => {
  return (

    <div className={styles.promoteFooter}>

      <div className={styles.orContainer}>
        <h4>OR</h4>
      </div>

      <div className={styles.footerButtonContainer}>
          <NoThanksButton/>
      </div>

    </div>
  );
};

export default PromoteFooter;
