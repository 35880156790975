// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-Pagination__pagination{width:100%;margin-top:5px;display:flex;align-items:center;justify-content:center;text-transform:uppercase}.projects-seller-app-components-common-Pagination__pageButton{padding:10px;color:var(--mainColor);font-size:18px;border:none;background:none}.projects-seller-app-components-common-Pagination__pageButton:disabled{color:#ddd}.projects-seller-app-components-common-Pagination__pageInfo{margin:0 5px;font-size:12px;position:relative;top:-1px}", ""]);
// Exports
exports.locals = {
	"pagination": "projects-seller-app-components-common-Pagination__pagination",
	"pageButton": "projects-seller-app-components-common-Pagination__pageButton",
	"pageInfo": "projects-seller-app-components-common-Pagination__pageInfo"
};
module.exports = exports;
