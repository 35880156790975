import classNames from 'classnames';
import React from 'react';

import logoGoogle from 'common/assets/images/logo-google.svg';

import styles from './PoweredByGoogle.css';

const PoweredByGoogle = ({ className }: Gemsby.IComponentProps) => (
  <div className={classNames(styles.poweredByGoogle, className)}>
    <span className={styles.text}>Powered by</span>
    <img className={styles.logo} src={logoGoogle} alt="logo-google" />
  </div>
);

export default PoweredByGoogle;
