// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-BannerAd__bannerAd{width:100%;padding-bottom:15px;text-align:center;border-bottom:1px solid #ddd}.projects-seller-app-components-common-BannerAd__bannerAd img{height:90px}@media (max-width:768px){.projects-seller-app-components-common-BannerAd__bannerAd a{display:block}.projects-seller-app-components-common-BannerAd__bannerAd a img{width:100%;height:auto}}", ""]);
// Exports
exports.locals = {
	"bannerAd": "projects-seller-app-components-common-BannerAd__bannerAd"
};
module.exports = exports;
