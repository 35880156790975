// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-promote-priceCards-TextWithTick-TextWithTick__tickIconContainer{margin-right:1.5rem}.projects-seller-app-components-inventory-promote-priceCards-TextWithTick-TextWithTick__tickIcon{color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"tickIconContainer": "projects-seller-app-components-inventory-promote-priceCards-TextWithTick-TextWithTick__tickIconContainer",
	"tickIcon": "projects-seller-app-components-inventory-promote-priceCards-TextWithTick-TextWithTick__tickIcon"
};
module.exports = exports;
