import React from 'react';
import { connect } from 'react-redux';

import Page from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import Header from 'seller/app/components/common/header/Header';
import HeaderButton from 'seller/app/components/common/header/HeaderButton';
import Footer from 'seller/app/components/landing/Footer';
import HeaderNav from 'seller/app/components/landing/HeaderNav';
import SignIn from 'seller/app/components/landing/SignIn';
import { Routes } from 'seller/app/constants';


import styles from './Landing.css';

const SignInPageMobile = ({ goToSignUp, ...props }) => (
  <Screen>
    <Page>
      <PageContent>
        <Header>
          <div className={styles.header}>
            <HeaderButton onClick={goToSignUp}>Sign Up</HeaderButton>
          </div>
        </Header>

        <SignIn {...props} />

        <Footer />
      </PageContent>
    </Page>
  </Screen>
);

const SignInPageDesktop = ({ goToSignUp, ...props }) => (
  <div>
    <HeaderNav activeItem={Routes.signIn} />
    <SignIn {...props} />
    <Footer />
  </div>
);

const SignInPage = ({ history, ...props }) => {
  props.goToSignUp = () => history.push('/');
  props.goToDashboard = () => history.push('/dashboard');

  return props.isBigScreen ? (
    <SignInPageDesktop {...props} />
  ) : (
    <SignInPageMobile {...props} />
  );
};

const mapState = ({ device }) => ({
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(SignInPage);
