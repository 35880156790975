import { isNaOption, isNullOption } from 'common/utils/form';
import { SaleMarkupTypes, Settings } from 'common/utils/inventory';
import * as retailerUtils from 'common/utils/retailer';
import _ from 'lodash';
import { ReturnPolicies } from 'common/utils/retailer';

export const VALIDATION_ERROR_TYPE = 'validation';

const emailRe = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const isValidEmail = data => emailRe.test(data);

interface IResult {
  valid: boolean;
  error: Error;
}
type Data = Record<string, any>;
type Error = Record<string, string>;
type FieldValidator = (
  data: Data,
  field: string,
  error?: Error,
  displayName?: string
) => boolean;
type NamedFieldValidator = (data: Data, error?: Error) => boolean;
type DataValidator = (data: Data, ...rest: any[]) => IResult;
type DataValidatorWrapper = (validator: (error: Error) => boolean) => IResult;

const validateRequired: FieldValidator = (data, field, error, displayName) => {
  if (!data[field] || isNullOption(data[field])) {
    if (error) {
      error[field] = `${displayName || _.capitalize(field)} is required`;
    }
    return false;
  }
  return true;
};

const validateNumber: FieldValidator = (data, field, error, displayName) => {
  if (data[field] === 0) {
    return true;
  }
  if (!validateRequired(data, field, error, displayName)) {
    return false;
  }
  if (!_.isNumber(data[field])) {
    if (error) {
      error[field] = `Invalid ${_.lowerCase(displayName || field)}`;
    }
    return false;
  }
  return true;
};

const validatePositiveNumber: FieldValidator = (
  data,
  field,
  error,
  displayName
) => {
  if (!validateNumber(data, field, error, displayName)) {
    return false;
  }
  if (data[field] <= 0) {
    if (error) {
      error[field] = `${displayName ||
        _.capitalize(field)} must be greater than 0`;
    }
    return false;
  }
  return true;
};

const validateEmail: NamedFieldValidator = (data, error) => {
  const field = 'email';
  if (!validateRequired(data, field, error)) {
    return false;
  }
  if (!isValidEmail(data.email)) {
    if (error) {
      error[field] = 'Invalid email';
    }
    return false;
  }
  return true;
};

const validate: DataValidatorWrapper = validator => {
  const error: Error = {};
  const valid = validator(error);
  if (!valid) {
    error.type = VALIDATION_ERROR_TYPE;
  }
  return { valid, error };
};

export const validateLogin: DataValidator = data =>
  validate(error => {
    let valid = true;

    if (data.email !== 'admin' && !validateEmail(data)) {
      valid = false;
    }
    if (!validateRequired(data, 'password')) {
      valid = false;
    }

    return valid;
  });

export const validateRegister: DataValidator = (
  data: Gemsby.SellerRegisterData
) =>
  validate(error => {
    let valid = true;

    if (!validateEmail(data, error)) {
      valid = false;
    }
    if (!validateRequired(data, 'password', error)) {
      valid = false;
    }
    if (!validateRequired(data, 'name', error, 'Seller Name / Store Name')) {
      valid = false;
    }
    if (!validateRequired(data, 'storeAddress', error, 'Location')) {
      valid = false;
    }

    return valid;
  });

const validateInventoryRetailer: NamedFieldValidator = (data, error) => {
  if (!validateRequired(data, 'retailerId')) {
    error.retailer = 'Inventory must be attached to a retailer';
    return false;
  }
  return true;
};

const validateCarat: NamedFieldValidator = (data, error) => {
  if (validatePositiveNumber(data, 'carat', error)) {
    return true;
  }
  if (isNaOption(`${data.carat}`)) {
    delete error.carat;
    return true;
  }
  return false;
};

const validatePrice: NamedFieldValidator = (data, error) =>
  validatePositiveNumber(data, 'price', error);

const validateSaleMarkup: NamedFieldValidator = (data, error) => {
  if (data.saleMarkupType === SaleMarkupTypes.SALE) {
    if (data.price < data.salePrice) {
      error.salePrice = 'Sale price must be less than the retail price';
      return false;
    } else if (
      !validatePositiveNumber(data, 'salePrice', error, 'sale price')
    ) {
      return false;
    }
  }
  if (data.saleMarkupType === SaleMarkupTypes.MARKUP) {
    if (!validatePositiveNumber(data, 'markup', error)) {
      return false;
    }
  }
  return true;
};

const validateProportions: NamedFieldValidator = (data, error) => {
  const { propLength = '', propWidth = '', propDepth = '' } = data;

  if (propLength === '' && propWidth === '' && propDepth === '') {
    return true;
  }

  let valid = true;

  if (!validatePositiveNumber(data, 'propLength', error, 'Length')) {
    valid = false;
  }
  if (!validatePositiveNumber(data, 'propWidth', error, 'Width')) {
    valid = false;
  }
  if (!validatePositiveNumber(data, 'propDepth', error, 'Depth')) {
    valid = false;
  }

  return valid;
};

const validateInventoryCommon: DataValidator = data =>
  validate(error => {
    let valid = true;

    [
      ['certificate'],
      ['clarity'],
      ['color'],
      ['colorType'],
      ['cut'],
      ['ringDescription', 'Item description'],
      ['saleMarkupType'],
      ['shape'],
    ].forEach(([field, displayName]) => {
      if (!validateRequired(data, field, error, displayName)) {
        valid = false;
      }
    });

    if (!validateInventoryRetailer(data, error)) {
      valid = false;
    }
    if (!validateCarat(data, error)) {
      valid = false;
    }
    if (!validatePrice(data, error)) {
      valid = false;
    }
    if (!validateSaleMarkup(data, error)) {
      valid = false;
    }
    if (data.recurring && !validatePositiveNumber(data, 'quantity', error)) {
      valid = false;
    }
    if (!validateProportions(data, error)) {
      valid = false;
    }
    if (data.linkToCert && !/https?:\/\//.test(data.linkToCert)) {
      valid = false;
      error.linkToCert = 'URL must start with https:// or http://';
    }

    return valid;
  });

const validateInventoryGemstone: DataValidator = data =>
  validate(error => {
    // tslint:disable-next-line:prefer-const
    let { valid, error: commonError } = validateInventoryCommon(data);
    Object.assign(error, commonError);

    return validateRequired(data, 'gemstone', error);
  });

const validateInventoryLooseStone = validateInventoryCommon;

const inventoryValidators: Record<Settings, DataValidator> = {
  [Settings.LOOSE_DIAMOND]: validateInventoryCommon,
  [Settings.LOOSE_GEMSTONE]: validateInventoryGemstone,
  [Settings.DIAMOND_JEWELRY]: validateInventoryCommon,
  [Settings.JEWELRY]: validateInventoryCommon,
  [Settings.CRAFT_JEWELRY]: validateInventoryCommon,
  [Settings.WATCH]: validateInventoryCommon,
  [Settings.SERVICE]: validateInventoryCommon,
};

export const validateInventory = (
  data: Data,
  images: Gemsby.IImagePickerImage[]
) =>
  validate(error => {
    // tslint:disable-next-line:prefer-const
    let { valid, error: inventoryError } = inventoryValidators[
      data.setting as Settings
    ](data);
    Object.assign(error, inventoryError);

    if (!images || images.length < 1) {
      error.images = 'Please attach at least one image to the item';
      valid = false;
    }

    return valid;
  });

export const validateRetailer: DataValidator = data =>
  validate(error => {
    let valid = true;

    if (!validateEmail(data, error)) {
      valid = false;
    }
    if (!validateRequired(data, 'name', error, 'Store name')) {
      valid = false;
    }
    if (!validateRequired(data, 'storeAddress', error, 'Store address')) {
      valid = false;
    }
    if (data.statusCode === retailerUtils.statusCodes.PRIVATE) {
      if (!validateRequired(data, 'privatePassword', error, 'Access code')) {
        valid = false;
      }
    }

    return valid;
  });

const validatePasswordInput: NamedFieldValidator = (data, error) => {
  let valid = true;

  if (data.password !== data.verifyPassword) {
    error.password = error.verifyPassword = "Password doesn't match";
    valid = false;
  }
  if (!data.password) {
    error.password = 'Password is required';
    valid = false;
  }
  if (!data.verifyPassword) {
    error.verifyPassword = 'Confirm password is required';
    valid = false;
  }

  return valid;
};

export const validateNewAccount: DataValidator = data =>
  validate(error => {
    let valid = true;

    if (!data.username) {
      error.username = 'Username is required';
      valid = false;
    }
    if (!validatePasswordInput(data, error)) {
      valid = false;
    }

    return valid;
  });

export const validatePassword: DataValidator = (data, withOldPassword) =>
  validate(error => {
    let valid = true;

    if (!validatePasswordInput(data, error)) {
      valid = false;
    }
    if (withOldPassword && !data.oldPassword) {
      error.oldPassword = 'Old password is required';
      valid = false;
    }

    return valid;
  });

export const validateRetailerAccount: DataValidator = data =>
  validate(error => {
    let valid = true;

    const { password, verifyPassword, oldPassword } = data;
    if (password || verifyPassword || oldPassword) {
      const { valid: passwordValid, error: passwordError } = validatePassword(
        data,
        true
      );
      if (!passwordValid) {
        valid = false;
        Object.assign(error, passwordError);
      }
    }
    if (
      data.returnPolicy === ReturnPolicies.RETURN_ACCEPTED &&
      !validatePositiveNumber(data, 'returnPeriod', error, 'Return period')
    ) {
      valid = false;
    }

    return valid;
  });

export const validateTrackingDetails: DataValidator = data =>
  validate(error => {
    let valid = true;

    if (
      !validateRequired(data, 'shippingTrackingId', error, 'Tracking number')
    ) {
      valid = false;
    }
    if (!validateRequired(data, 'shippingService', error, 'Shipping service')) {
      valid = false;
    }

    return valid;
  });

export const validatePayPalClientId: DataValidator = data =>
  validate(error =>
    validateRequired(data, 'paypalClientId', error, 'PayPal Client ID')
  );

export const validateCustomUrl: DataValidator = data =>
  validate(error => {
    if (!validateRequired(data, 'publicLink', error, 'Custom URL')) {
      return false;
    }
    if (!/^[\w-+]+$/.test(data.publicLink)) {
      error.publicLink = 'Invalid custom URL';
      return false;
    }

    return true;
  });

export default {
  login: validateLogin,
  register: validateRegister,
  inventory: validateInventory,
  retailer: validateRetailer,
  newAccount: validateNewAccount,
  password: validatePassword,
  trackingDetails: validateTrackingDetails,
  paypalClientId: validatePayPalClientId,
};
