import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import FormErrorMessage from 'common/components/common/form/FormErrorMessage';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import { scrollToFormError, useFormState } from 'common/utils/form';
import { getRetailerImageFile } from 'common/utils/image';
import { isPrivateRetailer, statusCodes } from 'common/utils/retailer';
import {
  validateRetailer,
  VALIDATION_ERROR_TYPE,
} from 'common/utils/validators';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from 'seller/app/actions/form';
import { saveRetailer, setRetailerError } from 'seller/app/actions/retailer';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import LastWeekViews from 'seller/app/components/common/LastWeekView';
import TabBar from 'seller/app/components/common/TabBar';
import ProfileForm from 'seller/app/components/profile/form/Form';
import { Routes } from 'seller/app/constants';
import onboarding from 'seller/app/services/onboarding';
import Modal from 'common/components/common/mobile/MobileModal';

const formErrorRef = React.createRef();

const PageTitle = () => <PageHeader>Profile</PageHeader>;

const ProfilePageMobile = props => (
  <>
  <Modal />
  <Screen>
    <PageMobile>
      <PageContent heightReducedBy={60}>
        <HeaderNavMobile />

        <PageTitle />

        <PageSection>
          <BannerAd />
        </PageSection>

        <PageSection>
          <LastWeekViews />
          <FormErrorMessage error={props.error} ref={formErrorRef} />
          <ProfileForm {...props} />
        </PageSection>
      </PageContent>

      <TabBar activeItem={Routes.profile} />
    </PageMobile>
  </Screen>
  </>
);

const ProfilePageDesktop = props => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.profile} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <PageTitle />

      <LastWeekViews />
      <FormErrorMessage error={props.error} ref={formErrorRef} />
      <ProfileForm {...props} />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const ProfilePage = props => {
  const {
    history,
    isBigScreen,
    retailer,
    error,
    // tslint:disable:no-shadowed-variable
    saveRetailer,
    setRetailerError,
    showFullscreenLoader,
    hideFullscreenLoader,
    // tslint:enable:no-shadowed-variable
  } = props;
  const retailerData = retailer.toJSON().attributes;
  const galleryImages = retailer.getRelated('images');
  const repImage = retailer.getRelated('imageRep');
  const mastheadImage = retailer.getRelated('imageMastHead');

  const [state, handleFieldChange, setState] = useFormState(
    Object.assign(retailerData, {
      isPrivate: isPrivateRetailer(retailer),
      galleryImages: galleryImages
        ? galleryImages.map(getRetailerImageFile)
        : [],
      repImage: repImage ? [getRetailerImageFile(repImage)] : [],
      mastheadImage: mastheadImage ? [getRetailerImageFile(mastheadImage)] : [],
    })
  );

  useEffect(() => {
    setState({
      ...state,
      ...state.geo,
    });
  }, [state.geo]);

  useEffect(() => {
    if (!error || error.type !== VALIDATION_ERROR_TYPE) {
      return;
    }
    const { error: validationError } = validateRetailer(state);
    setRetailerError(validationError);
  }, [state]);

  const onImagesChange = (imageField: string) => imageFiles => {
    setState({
      ...state,
      [imageField]: imageFiles,
    });
  };
  const onGalleryImagesChange = onImagesChange('galleryImages');
  const onRepImageChange = onImagesChange('repImage');
  const onMastheadImageChange = onImagesChange('mastheadImage');

  const onCancel: React.MouseEventHandler = event => {
    event.preventDefault();
    history.goBack();
  };

  const onSubmit: React.MouseEventHandler = event => {
    event.preventDefault();

    showFullscreenLoader();

    const {
      galleryImages,
      repImage,
      mastheadImage,
      geo,
      ...attributes
    } = state;

    attributes.statusCode = attributes.isPrivate
      ? statusCodes.PRIVATE
      : statusCodes.AVAILABLE;
    delete attributes.isPrivate;

    saveRetailer(
      {
        type: 'retailer',
        id: retailer.id,
        attributes,
      },
      galleryImages,
      repImage,
      mastheadImage
    )
      .then(
        () => {
          onboarding.setProfileUpdated();
        },
        () => {
          scrollToFormError(formErrorRef);
        }
      )
      .finally(hideFullscreenLoader);
  };

  props = {
    ...props,
    state,
    handleFieldChange,
    onGalleryImagesChange,
    onRepImageChange,
    onMastheadImageChange,
    onCancel,
    onSubmit,
  };

  return isBigScreen ? (
    <ProfilePageDesktop {...props} />
  ) : (
    <ProfilePageMobile {...props} />
  );
};

const mapState = ({ login, device, retailer }) => ({
  isBigScreen: device.isBigScreen,
  retailer: login.currentUser,
  error: retailer.error,
});

const mapDispatch = {
  saveRetailer,
  setRetailerError,
  showFullscreenLoader,
  hideFullscreenLoader,
};

export default connect(
  mapState,
  mapDispatch
)(ProfilePage);
