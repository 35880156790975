import classNames from 'classnames';
import React from 'react';

import styles from './OrderField.css';

interface IOrderFieldProps extends Gemsby.IComponentProps {
  label: string;
  value: string | JSX.Element;
}

const OrderField: React.FC<IOrderFieldProps> = ({
  className,
  label,
  value,
}) => {
  return (
    <div className={classNames(styles.orderField, className)}>
      <span className={styles.label}>{label}:</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

export default OrderField;
