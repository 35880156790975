import produce from 'immer';
import { parseErrorResponse } from 'common/utils/errors';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SET_NEXT_URL,
  SET_CURRENT_USER,
  CLEAR_LOGIN_ERROR,
  SAVE_ACCOUNT_REQUEST,
  SAVE_ACCOUNT_SUCCESS,
  SAVE_ACCOUNT_ERROR
} from 'seller/app/actions/login';

const initialState = {
  currentUser: null,
  isAdminUser: false,
  nextUrl: '/',
  inProgress: false,
  error: null,
  registered: false
};

export default (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case REGISTER_REQUEST:
      draft.currentUser = null;
      draft.inProgress = true;
      draft.error = null;
      break;
    case SAVE_ACCOUNT_REQUEST:
      draft.inProgress = true;
      draft.error = null;
      break;
    case LOGIN_SUCCESS:
      draft.currentUser = action.currentUser;
      draft.isAdminUser = action.currentUser.type === 'admin';
      draft.inProgress = false;
      break;
    case REGISTER_SUCCESS:
      draft.inProgress = false;
      draft.registered = true;
      break;
    case SAVE_ACCOUNT_SUCCESS:
      draft.inProgress = false;
      break;
    case LOGIN_ERROR:
    case LOGOUT_ERROR:
    case REGISTER_ERROR:
    case SAVE_ACCOUNT_ERROR:
      draft.inProgress = false;
      draft.error = parseErrorResponse(action.error);
      break;
    case LOGOUT_SUCCESS:
      window.location = '/'; // Deadly
      break;
    case SET_NEXT_URL:
      draft.nextUrl = action.url;
      break;
    case SET_CURRENT_USER:
      draft.currentUser = action.currentUser;
      draft.isAdminUser = action.currentUser && action.currentUser.type === 'admin';
      break;
    case CLEAR_LOGIN_ERROR:
      draft.error = null;
      break;
  }
});
