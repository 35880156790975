// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-LastWeekViews__lastWeekViews{margin-bottom:20px}", ""]);
// Exports
exports.locals = {
	"lastWeekViews": "projects-seller-app-components-common-LastWeekViews__lastWeekViews"
};
module.exports = exports;
