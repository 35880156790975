import React, { MouseEventHandler } from 'react';

import Fa from 'common/components/common/Fa';

import styles from './RefreshButton.css';

interface IRefreshButtonProps {
  onClick: MouseEventHandler;
}

const RefreshButton = ({ onClick }: IRefreshButtonProps) => (
  <div className={styles.container}>
    <div className={styles.button} onClick={onClick}>
      <Fa icon="sync" />
      <span className={styles.text}>Refresh Inventory</span>
    </div>
  </div>
);

export default RefreshButton;
