// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-ContentPageHeader__contentPageHeader{display:flex;justify-content:space-between;align-items:center;margin-bottom:20px}.projects-seller-app-components-common-ContentPageHeader__contentPageHeader h3{font-size:22px}.projects-seller-app-components-common-ContentPageHeader__contentPageHeaderActions{display:flex}", ""]);
// Exports
exports.locals = {
	"contentPageHeader": "projects-seller-app-components-common-ContentPageHeader__contentPageHeader",
	"contentPageHeaderActions": "projects-seller-app-components-common-ContentPageHeader__contentPageHeaderActions"
};
module.exports = exports;
