import React from 'react';

import classNames from 'classnames';

import { isNaOption } from 'common/utils/form';
import { Settings, JewelryTypes } from 'common/utils/inventory';
import valueFormatter from 'common/utils/valueFormatter';

const isEmpty = value => !value || isNaOption(value) || value === 'other';

const renderLooseDiamond = (inventory, textOnly) => {
  const { carat, shape } = inventory;

  const boldText = carat > 0 ? `${valueFormatter.carat(carat)} Carat` : '';
  const regularText = [
    !isEmpty(shape) && valueFormatter.shape(shape),
    'Diamond',
  ]
    .filter(part => part)
    .join(' ');

  if (textOnly) {
    return boldText ? `${boldText} ${regularText}` : regularText;
  }

  return (
    <div>
      {boldText && <b>{boldText}</b>}
      <span>{regularText}</span>
    </div>
  );
};

const renderLooseGemstone = (inventory, textOnly) => {
  const { carat, shape, gemstone } = inventory;

  const boldText = carat > 0 ? `${valueFormatter.carat(carat)} Carat` : '';
  let regularText = [
    !isEmpty(shape) && valueFormatter.shape(shape),
    !isEmpty(gemstone) && valueFormatter.gemstone(gemstone) || 'Gemstone',
  ].filter(part => part);
  if (regularText.length === 2 && regularText[0] === regularText[1]) {
    regularText[1] = 'Gemstone';
  }
  regularText = regularText.join(' ');

  if (textOnly) {
    return boldText ? `${boldText} ${regularText}` : regularText;
  }

  return (
    <div>
      {boldText && <b>{boldText}</b>}
      <span>{regularText}</span>
    </div>
  );
};

const renderDiamondJewelry = (inventory, textOnly) => {
  const { metalPurity, metalType, carat, brand, jewelryType } = inventory;

  const boldText = [
    !isEmpty(metalPurity) && valueFormatter.metalPurity(metalPurity),
    !isEmpty(metalType) && valueFormatter.metalType(metalType),
    carat > 0 ? `${valueFormatter.carat(carat)} Carat` : '',
  ]
    .filter(part => part)
    .join(' ');
  const regularText = [
    !isEmpty(brand) && valueFormatter.brand(brand),
    'Diamond',
    !isEmpty(jewelryType) && valueFormatter.jewelryType(jewelryType) || 'Jewelry',
  ]
    .filter(part => part)
    .join(' ');

  if (textOnly) {
    return boldText ? `${boldText} ${regularText}` : regularText;
  }

  return (
    <div>
      {boldText && <b>{boldText}</b>}
      <span>{regularText}</span>
    </div>
  );
};

const renderJewelry = (inventory, textOnly) => {
  const { metalPurity, metalType, carat, brand, gemstone, jewelryType } = inventory;

  const boldText = [
    !isEmpty(metalPurity) && valueFormatter.metalPurity(metalPurity),
    !isEmpty(metalType) && valueFormatter.metalType(metalType),
    carat > 0 ? `${valueFormatter.carat(carat)} Carat` : '',
  ]
    .filter(part => part)
    .join(' ');
  const regularText = [
    !isEmpty(brand) && valueFormatter.brand(brand),
    !isEmpty(gemstone) && valueFormatter.gemstone(gemstone) ||
      !isEmpty(gemstone) && jewelryType && jewelryType !== JewelryTypes.WATCH && 'Gemstone',
    !isEmpty(jewelryType) && valueFormatter.jewelryType(jewelryType) || 'Jewelry',
  ]
    .filter(part => part)
    .join(' ');

  if (textOnly) {
    return boldText ? `${boldText} ${regularText}` : regularText;
  }

  return (
    <div>
      {boldText && <b>{boldText}</b>}
      <span>{regularText}</span>
    </div>
  );
};

const renderService = (inventory, textOnly) => {
  const regularText = 'Service';
  return textOnly ? regularText : (
    <div>
      <span>{regularText}</span>
    </div>
  );
};

const renderers = {
  [Settings.LOOSE_DIAMOND]: renderLooseDiamond,
  [Settings.LOOSE_GEMSTONE]: renderLooseGemstone,
  [Settings.DIAMOND_JEWELRY]: renderDiamondJewelry,
  [Settings.JEWELRY]: renderJewelry,
  [Settings.CRAFT_JEWELRY]: renderJewelry,
  [Settings.WATCH]: renderJewelry,
  [Settings.SERVICE]: renderService,
}

export const renderText = (inventory) => {
  const { customTitle, setting } = inventory;
  if (customTitle) {
    return customTitle;
  }
  return renderers[setting](inventory, true);
};

const ItemShortDescription = ({ className, inventory }) => {
  const { customTitle, setting } = inventory;
  let content = null;
  if (customTitle) {
    content = customTitle;
  } else {
    content = renderers[setting](inventory);
  }
  return <div className={classNames(className)}>{content}</div>;
};

export default ItemShortDescription;
