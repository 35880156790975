import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import Fa from 'common/components/common/Fa';
import OutLink from 'common/components/common/OutLink';
import { marketplaceUrl } from 'common/utils/inventory';
import { NA } from 'common/utils/valueFormatter';
import DataTableItem from 'seller/app/components/common/datatable/DataTableItem';
import Customer from 'seller/app/components/order/list/Customer';
import TrackingNumber from 'seller/app/components/order/list/TrackingNumber';

import styles from './List.css';

const TableItem = ({ item, notifications, onClickMessage }) => {
  const { createdDay, orderId, paymentType } = item.attributes;
  let showNotification = notifications.includes(orderId) ? '(NEW)' : '';
  return (
    <DataTableItem
      items={[
        <div className={styles.orderDate}>
          {moment(createdDay * 1000).format('ll')}
        </div>,
        <div>{orderId}</div>,
        <Customer order={item} />,
        <div>{paymentType || NA}</div>,
        <TrackingNumber order={item} />,
        <div className={styles.itemActionContainer}>
          <Link
            className={styles.itemAction}
            to={`/orders/${encodeURIComponent(item.id)}`}
          >
            <Fa icon="pen" /> Manage
          </Link>
          <Link
            className={styles.itemAction}
            to={{
              pathname: `/chat/${encodeURIComponent(item.id)}?anonymous=false`,
              state: { item },
            }}
            onClick={() => {
              !!showNotification && onClickMessage(orderId);
            }}
          >
            <Fa icon="comment" /> Message{' '}
            {showNotification && (
              <span className={styles.noTracking}>{showNotification}</span>
            )}
          </Link>
          <OutLink className={styles.itemAction} to={marketplaceUrl(item)}>
            <Fa icon="external-link-alt" /> Shared Link
          </OutLink>
        </div>,
      ]}
    />
  );
};

export default TableItem;
