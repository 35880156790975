export const parseErrorResponse = error => {
  if (!error || !error.response) {
    return error;
  }
  return Object.values(error.response.errors || {}).reduce((error, item) => {
    const { source = {}, title, detail } = item;
    const { parameter } = source;
    const msg = detail ? `${title}: ${detail}` : title;
    if (parameter) {
      error[parameter] = msg;
    }
    error.errors = (error.errors || []).concat(msg);
    return error;
  }, {});
};
