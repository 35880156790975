import React from 'react';
import MessageField from 'common/components/common/MessageField';
import localstore from 'common/utils/localstore';
import Received from './Received';
import Sent from './Sent';
import styles from './Form.css';

const Form = props => {
  const { username } = localstore.get('currentUser');
  return (
    <>
      <div className={styles.chatContainer}>
        <div
          className={styles.messageContain}
          ref={el => {
            props.messageContain.current = el;
          }}
        >
          {props.messages.map((item, index) => {
            return item.data.userEmail === username ? (
              <Sent {...item.data} key={index} />
            ) : (
              <Received {...item.data} key={index} />
            );
          })}
        </div>
        <div className={styles.fieldContain}>
          <MessageField {...props} />
        </div>
      </div>
    </>
  );
};

export default Form;
