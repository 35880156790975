import produce from 'immer';
import _ from 'lodash';

import { parseErrorResponse } from 'common/utils/errors';
import {
  CLEAR_SELECTED_INVENTORY,
  CREATE_INVENTORY,
  CSV_UPLOAD_ERROR,
  CSV_UPLOAD_REQUEST,
  CSV_UPLOAD_SUCCESS,
  DELETE_INVENTORY_ERROR,
  DELETE_INVENTORY_IMAGE_SUCCESS,
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  FETCH_INVENTORY_LIST_ERROR,
  FETCH_INVENTORY_LIST_REQUEST,
  FETCH_INVENTORY_LIST_SUCCESS,
  GET_INVENTORY_ERROR,
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  SAVE_INVENTORY_ERROR,
  SAVE_INVENTORY_REQUEST,
  SAVE_INVENTORY_SUCCESS,
  SET_INVENTORY_ERROR,
} from 'seller/app/actions/inventory';
import { createNewInventory } from 'seller/app/utils/inventory';

const initialState = {
  inProgress: false,
  inventoryList: null,
  selectedInventory: null,
  error: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_INVENTORY_REQUEST:
        draft.selectedInventory = null;
        draft.inProgress = true;
        draft.error = {};
        break;
      case FETCH_INVENTORY_LIST_REQUEST:
      case SAVE_INVENTORY_REQUEST:
      case DELETE_INVENTORY_REQUEST:
      case CSV_UPLOAD_REQUEST:
        draft.inProgress = true;
        draft.error = {};
        break;
      case FETCH_INVENTORY_LIST_ERROR:
      case GET_INVENTORY_ERROR:
      case SAVE_INVENTORY_ERROR:
      case DELETE_INVENTORY_ERROR:
      case CSV_UPLOAD_ERROR:
        draft.inProgress = false;
        draft.error = parseErrorResponse(action.error);
        break;
      case FETCH_INVENTORY_LIST_SUCCESS:
        if (_.isFunction(action.callback)) {
          action.callback(action.inventoryList);
        }
        draft.inProgress = false;
        draft.inventoryList = action.inventoryList;
        break;
      case GET_INVENTORY_SUCCESS:
        draft.inProgress = false;
        draft.selectedInventory = action.inventory;
        break;
      case SAVE_INVENTORY_SUCCESS:
        draft.inProgress = false;
        draft.inventoryList = null;
        draft.selectedInventory = action.inventory;
        break;
      case DELETE_INVENTORY_SUCCESS:
      case CSV_UPLOAD_SUCCESS:
        draft.inProgress = false;
        draft.inventoryList = null;
        draft.selectedInventory = null;
        break;
      case CREATE_INVENTORY:
        draft.selectedInventory = createNewInventory();
        break;
      case DELETE_INVENTORY_IMAGE_SUCCESS:
        const inventory = draft.selectedInventory.clone();
        inventory.removeRelated('images', action.image.id);
        draft.selectedInventory = inventory;
        break;
      case CLEAR_SELECTED_INVENTORY:
        draft.selectedInventory = null;
        break;
      case SET_INVENTORY_ERROR:
        draft.error = parseErrorResponse(action.error);
        break;
    }
  });
