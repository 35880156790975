// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__footerButtonContainer,.projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__orContainer{text-align:center}.projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__footerButton{margin:2rem;font-size:32px;font-weight:700}", ""]);
// Exports
exports.locals = {
	"footerButtonContainer": "projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__footerButtonContainer",
	"orContainer": "projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__orContainer",
	"footerButton": "projects-seller-app-components-inventory-promote-PromoteFooter-PromoteFooter__footerButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + ""
};
module.exports = exports;
