import React from 'react';

import OutLink from 'common/components/common/OutLink';
import {
  GEMSBY_BLOG_URL,
  GEMSBY_HELP_URL,
  MARKETPLACE_URL,
  MOBILE_APP_URL,
  PRIVACY_URL,
  SUPPORT_EMAIL,
  TOS_URL,
} from 'common/constants';

import abcImage from 'common/assets/images/landing/landing-webfoot-abc.png';
import cbsImage from 'common/assets/images/landing/landing-webfoot-cbs.png';
import foxImage from 'common/assets/images/landing/landing-webfoot-fox.png';
import nbcImage from 'common/assets/images/landing/landing-webfoot-nbc.png';
import styles from './Footer.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.seenOn}>
        <h3>AS SEEN ON</h3>
        <img src={abcImage} alt="abc logo" />
        <img src={cbsImage} alt="cbs logo" />
        <img src={foxImage} alt="fox logo" />
        <img src={nbcImage} alt="nbc logo" />
      </div>
      <div>
        ©{new Date().getFullYear()} C.G. Diam Corp. &nbsp;All Rights Reserved.
      </div>
      <div>
        <ul className="list-unstyled list-inline">
          <li>
            <OutLink to={MARKETPLACE_URL}>Gemsby</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={MOBILE_APP_URL}>Download</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={PRIVACY_URL}>Privacy</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={TOS_URL}>Terms</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={GEMSBY_BLOG_URL}>Magazine</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={GEMSBY_HELP_URL}>Help</OutLink>
          </li>
          <li>·</li>
          <li>
            <OutLink to={`mailto:${SUPPORT_EMAIL}`}>Contact Us</OutLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
