// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-Card__card{padding:20px;background-color:#fff;border:1px solid #dbdbdb;box-shadow:0 3px 0 0 #aaa;border-radius:4px}.projects-common-components-common-Card__card .cardIcon{font-size:36px;color:#aaa;margin-bottom:10px}.projects-common-components-common-Card__card .cardIcon.icon{display:inline-block}.projects-common-components-common-Card__card .cardHeader{text-align:center;color:var(--textColor);font-size:14px;font-weight:300;text-transform:uppercase;margin-bottom:15px}", ""]);
// Exports
exports.locals = {
	"card": "projects-common-components-common-Card__card"
};
module.exports = exports;
