import classNames from 'classnames';
import React from 'react';

import styles from './DataTable.css';

interface IDataTableItemProps {
  className?: string;
  items: JSX.Element[];
  onClick?: React.EventHandler<React.MouseEvent>;
}

const DataTableItem = ({
  className,
  items,
  onClick,
}: IDataTableItemProps) => (
  <tr className={classNames(styles.item, className)} onClick={onClick}>
    {items.map((item, i) => (
      <td key={i} className={styles.itemCell}>
        {item}
      </td>
    ))}
  </tr>
);

export default DataTableItem;
