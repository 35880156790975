export const VERSION = '2.1.6';

export enum Routes {
  signOn = '/signon',
  signIn = '/signin',
  inventory = '/inventory',
  profile = '/profile',
  orders = '/orders',
  account = '/account',
  settings = '/settings',
}

export default {
  Routes,
  VERSION,
};
