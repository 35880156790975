import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import Modal from 'common/components/common/mobile/MobileModal';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import { useFormState } from 'common/utils/form';
import { getParameterByName } from 'common/utils/getParameters';
import localstore from 'common/utils/localstore';
import { validateTrackingDetails } from 'common/utils/validators';
import { createChatRoom, getAllMessages } from 'seller/app/actions/chat';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import { Routes } from 'seller/app/constants';
import api from 'seller/app/services/api';
import {
  deleteNotifiction,
  sendMessageToDb,
  setImagesToDb,
} from 'seller/app/services/firebase';
import Chat from './Chat';
import ChatModal from './ChatModal';

const ChatPageHeader = () => <PageHeader>Message</PageHeader>;

const ChatPageMobile = props => {
  return (
    <>
      <Modal />
      <ChatModal
        open={props.open}
        onClose={props.toggle}
        onImageChange={props.onImageChange}
        onUpload={props.handleUpload}
        images={props.images}
        upload={props.upload}
      />
      <Screen>
        <PageMobile>
          <PageContent heightReducedBy={60}>
            <HeaderNavMobile />

            <ChatPageHeader />

            <PageSection>
              <BannerAd />
            </PageSection>

            <PageSection>
              {/* add component here */}
              <Chat {...props} />
            </PageSection>
          </PageContent>

          <TabBar activeItem={Routes.orders} />
        </PageMobile>
      </Screen>
    </>
  );
};
const ChatPageDesktop = props => (
  <>
    <ChatModal
      open={props.open}
      onClose={props.toggle}
      onImageChange={props.onImageChange}
      onUpload={props.handleUpload}
      images={props.images}
      upload={props.upload}
    />
    <PageDesktop>
      <HeaderNavDesktop activeItem={Routes.orders} />

      <PageSection>
        <BannerAd />
      </PageSection>

      <PageSection compact>
        <ChatPageHeader />
        {/* add component here */}
        <Chat {...props} />
      </PageSection>

      <Footer />
    </PageDesktop>
  </>
);

const ChatPage = ({
  match,
  history,
  location,
  isBigScreen,
  createChatRoom,
  chatRoom,
  messageList,
  getAllMessages,
  notificationList,
}) => {
  const orderId = match.params.id;
  
  const anonymous = location.search.slice(location.search.indexOf('=') + 1);
    
  const messageContain = useRef(null);
  const [order, setOrder] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomId, setRoomId] = useState(null);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [scrollTop, setScrollTop] = useState(null);
  const [messageInput, setMessageInput] = useState('');
  const [state, handleFieldChange] = useFormState({
    shippingTrackingId: '',
    shippingService: '',
  });
  const [images, setImages] = useState([]);
  const [upload, setUpload] = useState(false);
  const [error, setError] = useState(null);
  console.log(history.location.state.item.id)

  const validateTrackingForm = () => {
    // tslint:disable-next-line: no-shadowed-variable
    const { valid, error } = validateTrackingDetails(state);
    setError(error);
    return valid;
  };

  const handleMessage = e => {
    setMessageInput(e.target.value);
  };

  const handleChatModalToggle = () => {
    setOpen(!open);
  };

  const onImageChange = image => {
    const filteredImages = image.filter(item => item.url);
    setImages(filteredImages);
  };

  const submitMessageOnEnter = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = async () => {
    const { username } = localstore.get('currentUser');
    const anonymous = location.search.slice(location.search.indexOf('='))
    console.log('anon',anonymous)
    if (messageInput.trim() !== '') {
      const obj = {
        message: messageInput,
        userEmail: username,
        roomId: chatRoom.roomId,
      };
      const trackData = {
        data: {
          type: 'chat',
          id: history.location.state.item.id,
          attributes: {
            channelId: chatRoom.roomId,
            retailerEmail: chatRoom.data.users.retailerEmail,
            customerEmail: '',
            anonymous: isAnonymous,
            address_to: chatRoom.data.users.consumerEmail,
            retailer_id: chatRoom.data.retailerId
          },
        },
      };
      try {
        setMessageInput('');
        const res = await sendMessageToDb(obj);
        const track = await api.trackChat(trackData);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUpload = async () => {
    setUpload(true);
    const { username } = localstore.get('currentUser');

    const obj = {
      images,
      userEmail: username,
      roomId: chatRoom.roomId,
    };
    const trackData = {
      data: {
        type: 'chat',
        id: history.location.state.item.id,
        attributes: {
          channelId: chatRoom.roomId,
          retailerEmail: chatRoom.data.users.retailerEmail,
          customerEmail: '',
          anonymous: isAnonymous,
          address_to: chatRoom.data.users.consumerEmail,
          retailer_id: chatRoom.data.retailerId
        },
      },
    };
    try {
      const res = await setImagesToDb(obj);
      setImages([]);
      setUpload(false);
      setOpen(false);
      const track = await api.trackChat(trackData);
    } catch (e) {
      console.log(e);
      setUpload(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const value = getParameterByName('anonymous');
    if (value === 'false') {
      api.getOrder(orderId).then(orderEntity => {
        setOrder(orderEntity.toJSON());
        const { orderId, email,retailerId } = orderEntity.toJSON().attributes;
        const { username } = localstore.get('currentUser');        
        const data = { orderId, consumerEmail: email, retailerEmail: username ,retailerId};
        createChatRoom(data);
      });
    } else {
      setIsAnonymous(true);
    }

    const timer = setInterval(async () => {
      if (notificationList.data && notificationList.data.includes(orderId)) {
        try {
          const res = await deleteNotifiction({
            roomId: notificationList.id,
            orderId,
          });
        } catch (e) {
          console.log(e);
        }
      }
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (
      !isAnonymous &&
      chatRoom.hasOwnProperty('roomId') &&
      roomId !== chatRoom.roomId
    ) {
      setRoomId(chatRoom.roomId);
      getAllMessages(chatRoom.roomId);
    }

    if (messages.length && messageContain.current) {
      const scrollHeight = messageContain.current.scrollHeight;
      messageContain.current.scrollTop = scrollHeight;
      setScrollTop(messageContain.current.scrollTop);
    }
  });

  useEffect(() => {
    if (isAnonymous) { getAllMessages(orderId); }
  }, [isAnonymous]);

  useEffect(() => {
    setMessages(messageList);
  }, [messageList.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (error) {
      validateTrackingForm();
    }
  }, [state.shippingTrackingId, state.shippingService]);

  if (loading) {
    return <SpinningDiamond fullScreen />;
  }

  const props = {
    history,
    order,
    state,
    open,
    images,
    upload,
    error: error || {},
    handleFieldChange,
    messageContain,
    messageInput,
    messages,
    handleMessage,
    handleClick,
    onImageChange,
    submitMessageOnEnter,
    toggle: handleChatModalToggle,
    handleUpload,
  };

  const ChatPage = isBigScreen ? ChatPageDesktop : ChatPageMobile;
  return <ChatPage {...props} />;
};

const mapState = ({ device, chat }) => ({
  isBigScreen: device.isBigScreen,
  chatRoom: chat.chatRoom,
  messageList: chat.messages,
  notificationList: chat.notifications,
});

const mapDispatch = dispatch => {
  return {
    createChatRoom: data => dispatch(createChatRoom(data)),
    getAllMessages: roomId => dispatch(getAllMessages(roomId)),
  };
};

export default connect(
  mapState,
  mapDispatch
)(ChatPage);
