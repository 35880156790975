// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-Well__well{border:1px solid #ddd;border-radius:4px;text-align:center;padding:20px;font-weight:400}.projects-common-components-common-Well__well a,.projects-common-components-common-Well__well strong{color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"well": "projects-common-components-common-Well__well"
};
module.exports = exports;
