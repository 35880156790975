// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-header-HeaderNav__header{display:flex;align-items:center;justify-content:flex-end}.projects-seller-app-components-common-header-HeaderNav__link{margin-right:3%;text-transform:uppercase;position:relative}.projects-seller-app-components-common-header-HeaderNav__link:hover{color:var(--mainColor);cursor:pointer}.projects-seller-app-components-common-header-HeaderNav__badge{position:absolute;top:0;right:-6px;height:7px;width:6px;border-radius:50%;background-color:#b80000}.projects-seller-app-components-common-header-HeaderNav__activeLink{color:var(--mainColor)}.projects-seller-app-components-common-header-HeaderNav__logoutLink{}.projects-seller-app-components-common-header-HeaderNav__divider{display:inline-block;width:1px;height:20px;margin-right:3%;position:relative;top:5px;background-color:var(--sectionSepColor)}", ""]);
// Exports
exports.locals = {
	"header": "projects-seller-app-components-common-header-HeaderNav__header",
	"link": "projects-seller-app-components-common-header-HeaderNav__link",
	"badge": "projects-seller-app-components-common-header-HeaderNav__badge",
	"activeLink": "projects-seller-app-components-common-header-HeaderNav__activeLink",
	"logoutLink": "projects-seller-app-components-common-header-HeaderNav__logoutLink projects-seller-app-components-common-header-HeaderNav__link",
	"divider": "projects-seller-app-components-common-header-HeaderNav__divider"
};
module.exports = exports;
