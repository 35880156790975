import classNames from 'classnames';
import React from 'react';

import Button from 'common/components/common/Button';
import Fa from 'common/components/common/Fa';

import styles from './DeleteButton.css';

interface IDeleteButton extends Gemsby.IComponentProps {
  onClick: React.MouseEventHandler;
}

const DeleteButton = ({ className, onClick }: IDeleteButton) => (
  <Button className={classNames(styles.deleteButton, className)} onClick={onClick}>
    <Fa icon="trash-alt" />
    <span className={styles.label}>Delete</span>
  </Button>
);

export default DeleteButton;
