// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!seller/app/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-payment-Payment__paymentPage{display:flex;align-items:stretch;justify-content:space-between}.projects-seller-app-components-payment-Payment__paymentCard{width:calc(50% - 5px)}.projects-seller-app-components-payment-Payment__paymentCard a{color:var(--mainColor)}@media (max-width:768px){.projects-seller-app-components-payment-Payment__paymentCard{width:100%}}.projects-seller-app-components-payment-Payment__paymentCardContent{height:100%;display:flex;flex-direction:column}.projects-seller-app-components-payment-Payment__logo{height:32px;text-align:center}.projects-seller-app-components-payment-Payment__logo img{width:auto;height:100%}.projects-seller-app-components-payment-Payment__instruction{flex:1;line-height:1.35em}@media (max-width:768px){.projects-seller-app-components-payment-Payment__instruction{font-size:12px}}.projects-seller-app-components-payment-Payment__steps{list-style:disc;padding-left:40px;margin-bottom:20px}.projects-seller-app-components-payment-Payment__step{margin-bottom:5px}.projects-seller-app-components-payment-Payment__buttons{display:flex;width:100%;margin-top:10px}.projects-seller-app-components-payment-Payment__button{flex:1}a.projects-seller-app-components-payment-Payment__button{color:#fff;text-align:center}.projects-seller-app-components-payment-Payment__secondaryButton{flex:1;color:var(--textColor)}.projects-seller-app-components-payment-Payment__manageOrdersButton{}@media (max-width:768px){.projects-seller-app-components-payment-Payment__manageOrdersButton{margin-bottom:20px}}", ""]);
// Exports
exports.locals = {
	"paymentPage": "projects-seller-app-components-payment-Payment__paymentPage",
	"paymentCard": "projects-seller-app-components-payment-Payment__paymentCard",
	"paymentCardContent": "projects-seller-app-components-payment-Payment__paymentCardContent",
	"logo": "projects-seller-app-components-payment-Payment__logo",
	"instruction": "projects-seller-app-components-payment-Payment__instruction",
	"steps": "projects-seller-app-components-payment-Payment__steps",
	"step": "projects-seller-app-components-payment-Payment__step",
	"buttons": "projects-seller-app-components-payment-Payment__buttons",
	"button": "projects-seller-app-components-payment-Payment__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"secondaryButton": "projects-seller-app-components-payment-Payment__secondaryButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["button"] + "",
	"manageOrdersButton": "projects-seller-app-components-payment-Payment__manageOrdersButton " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["regularActionButton"] + ""
};
module.exports = exports;
