import classNames from 'classnames';
import React from 'react';

interface IFaProps extends Gemsby.IComponentProps {
  icon: string;
  spin?: boolean;
  brand?: boolean;
  onClick?: React.MouseEventHandler<React.ReactNode>;
}

const Fa: React.FC<IFaProps> = ({
  className = '',
  icon,
  spin = false,
  brand = false,
  onClick,
}) => {
  className = classNames(
    brand ? 'fab' : 'fa',
    `fa-${icon}`,
    { 'fa-spin': spin },
    className
  );
  return <i className={className} onClick={onClick} />;
};

export default Fa;
