import classNames from 'classnames';
import React from 'react';

import Checkbox from 'common/components/common/form/Checkbox';
import Field, { IFieldProps } from 'common/components/common/form/Field';

import styles from './Form.css';

interface ICheckboxFieldProps extends IFieldProps {
  checked: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler;
}

const CheckboxField: React.FC<ICheckboxFieldProps> = ({
  className,
  label,
  checked,
  disabled,
  error,
  onChange,
  children
}) => {
  return (
    <Field error={error}>
      <div className={classNames(styles.checkboxField, className)}>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={styles.checkboxLabel}>{label}</span>
      </div>
      {children}
    </Field>
  );
};

export default CheckboxField;
