// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-promote-AboveCardsButtonMobile-AboveCardsButtonMobile__buttonContainer{text-align:center;margin-top:4rem;margin-bottom:-2rem}", ""]);
// Exports
exports.locals = {
	"buttonContainer": "projects-seller-app-components-inventory-promote-AboveCardsButtonMobile-AboveCardsButtonMobile__buttonContainer"
};
module.exports = exports;
