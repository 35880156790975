// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-StatusBarFill__statusBarFill{width:100%;height:15px;padding-top:env(safe-area-inset-top);background-color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"statusBarFill": "projects-common-components-common-mobile-StatusBarFill__statusBarFill"
};
module.exports = exports;
