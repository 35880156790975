// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-mobile-Screen__screen{height:100%;overflow-y:hidden}", ""]);
// Exports
exports.locals = {
	"screen": "projects-common-components-common-mobile-Screen__screen"
};
module.exports = exports;
