import React from 'react';
import { Link } from 'react-router-dom';

import Fa from 'common/components/common/Fa';
import { renderText as getInventoryDescription } from 'common/components/common/InventoryShortDescription';
import OutLink from 'common/components/common/OutLink';
import { marketplaceUrl, settingIcons } from 'common/utils/inventory';
import { cutText } from 'common/utils/misc';
import { formatPrice, formatSetting, NA } from 'common/utils/valueFormatter';
import DataTableItem from 'seller/app/components/common/datatable/DataTableItem';
import Thumbnail from 'seller/app/components/inventory/list/Thumbnail';

import styles from './List.css';

const TableItem = ({ item }) => (
  <DataTableItem
    className={styles.dataTableItem}
    items={[
      <div className={styles.itemImage}>
        <Thumbnail item={item} />
      </div>,
      <div className={styles.itemType}>
        <span className={styles.itemInfoValue}>
          {settingIcons[item.setting]}
        </span>
        <span className={styles.itemTypeLabel}>
          {formatSetting(item.setting)}
        </span>
      </div>,
      <div>{item.internalId || NA}</div>,
      <div>{getInventoryDescription(item)}</div>,
      <div className={styles.itemDescription}>
        {cutText(item.ringDescription, 90) || 'No description available'}
      </div>,
      <span className={styles.itemInfoValue}>{formatPrice(item.price)}</span>,
      <span className={styles.itemInfoValue}>{item?.viewCount}</span>,
      <div className={styles.itemActionContainer}>
        <Link
          className={styles.itemAction}
          to={`/inventory/edit/${encodeURIComponent(item.id)}`}
        >
          <Fa icon="pen" /> Edit
        </Link>
        <OutLink className={styles.itemAction} to={marketplaceUrl(item)}>
          <Fa icon="external-link-alt" /> View on Gemsby
        </OutLink>
      </div>,
    ]}
  />
);

export default TableItem;
