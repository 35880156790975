import React from 'react';

import Card from 'common/components/common/Card';
import EmptyMessage from 'common/components/common/EmptyMessage';
import Fa from 'common/components/common/Fa';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import ContentPageHeader from 'seller/app/components/common/ContentPageHeader';
import DataTable from 'seller/app/components/common/datatable/DataTable';
import Pagination from 'seller/app/components/common/Pagination';
import AddButton from 'seller/app/components/inventory/list/AddButton';
import AddMultipleButton from 'seller/app/components/inventory/list/AddMultipleButton';
import CreateFirstItemMessage from 'seller/app/components/inventory/list/CreateFirstItemMessage';
import ListFilters from 'seller/app/components/inventory/list/ListFilters';
import ListItem from 'seller/app/components/inventory/list/ListItem';
import RefreshButton from 'seller/app/components/inventory/list/RefreshButton';
import TableItem from 'seller/app/components/inventory/list/TableItem';


import styles from './List.css';

const SearchEmptyMessage = ({ className, onFilterChange }) => (
  <EmptyMessage className={className} message="No items found">
    <a className={styles.clearQueryLink} onClick={() => onFilterChange()}>
      Clear query
    </a>
  </EmptyMessage>
);

const ListMobile = props => {
  const { state, setCurrentPage, onFilterChange } = props;
  const { items, currentPage, totalPages, loading, query, type } = state;
  return (
    <>
      <ListFilters
        initialQuery={query}
        initialType={type}
        onChange={onFilterChange}
      />
      {loading ? (
        <SpinningDiamond smallIcon />
      ) : items.length > 0 ? (
        <ul className={styles.list}>
          {items.map((item, i) => (
            <ListItem item={item} key={i} />
          ))}
        </ul>
      ) : (
        <SearchEmptyMessage
          className={styles.listEmptyMessage}
          onFilterChange={onFilterChange}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  );
};

const ListContentMobile = props => {
  const { state, setCurrentPage } = props;
  const { items, query, type, loading } = state;
  return (
    <>
      <AddButton />
      <RefreshButton onClick={() => setCurrentPage(-1)} />
      <Card className={styles.listCard}>
        {(items && items.length > 0) || loading || query || type ? (
          <ListMobile {...props} />
        ) : (
          <CreateFirstItemMessage />
        )}
      </Card>
      <AddMultipleButton />
    </>
  );
};

const dataTableHeader = [
  {
    label: '',
    thCls: styles.dataTableHeaderImage,
  },
  {
    label: 'Type',
    className: styles.dataTableHeaderTypeContent,
    thCls: styles.dataTableHeaderType,
  },
  {
    label: 'Internal ID',
  },
  {
    label: 'Title',
    thCls: styles.dataTableHeaderTitle,
  },
  {
    label: 'Description',
    thCls: styles.dataTableHeaderDescription,
  },
  {
    label: 'Price',
    thCls: styles.dataTableHeaderPrice,
  },
  {
    label: 'Views',
    thCls: styles.dataTableHeaderPrice,
  },
  {
    label: 'Actions',
    thCls: styles.dataTableHeaderActions,
  },
];

const ListDesktop = props => {
  const { state, setCurrentPage, onFilterChange } = props;
  const { items, currentPage, totalPages, loading, query, type } = state;
  const pagination = {
    currentPage,
    totalPages,
    onPageChange: setCurrentPage,
  };
  return (
    <>
      <ListFilters
        initialQuery={query}
        initialType={type}
        onChange={onFilterChange}
      />
      {loading ? (
        <SpinningDiamond smallIcon />
      ) : (
        <DataTable
          headers={dataTableHeader}
          pagination={pagination}
          items={items.map((item, i) => (
            <TableItem key={i} item={item} />
          ))}
          emptyMessage={
            <SearchEmptyMessage
              className={styles.dataTableEmptyMessage}
              onFilterChange={onFilterChange}
            />
          }
        />
      )}
    </>
  );
};

const ListContentDesktop = props => {
  const { state, setCurrentPage } = props;
  const { items, query, type, loading } = state;
  return (
    <>
      <ContentPageHeader
        title={
          <>
            <span>Inventory</span>
            <Fa
              icon="sync"
              className={styles.listRefreshButton}
              onClick={() => setCurrentPage(-1)}
            />
          </>
        }
        renderActions={() => (
          <>
            <AddMultipleButton />
            <AddButton />
          </>
        )}
      />
      <Card className={styles.tableCard}>
        {(items && items.length > 0) || loading || query || type ? (
          <ListDesktop {...props} />
        ) : (
          <CreateFirstItemMessage />
        )}
      </Card>
    </>
  );
};

const List = ({ isBigScreen, ...props }) => {
  const ListContent = isBigScreen ? ListContentDesktop : ListContentMobile;
  return (
    <div className={styles.inventoryList}>
      <ListContent {...props} />
    </div>
  );
};

export default List;
