// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/postcss-loader/src/index.js!common/components/common/TitleCard.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-onboarding-Card__card{position:relative;width:calc(25% - 10px)}.projects-seller-app-components-onboarding-Card__card,.projects-seller-app-components-onboarding-Card__card:not(:first-of-type){margin:10px 0 0}.projects-seller-app-components-onboarding-Card__card .title{color:var(--mainColor);text-align:center;font-size:16px}.projects-seller-app-components-onboarding-Card__card .titleSeparator{width:60px}@media (max-width:768px){.projects-seller-app-components-onboarding-Card__card{width:calc(50% - 5px)}}.projects-seller-app-components-onboarding-Card__content{display:flex;flex-direction:column;align-items:center;text-align:center;font-size:12px}.projects-seller-app-components-onboarding-Card__iconContainer{font-size:25px;margin-bottom:10px}.projects-seller-app-components-onboarding-Card__iconContainer img{height:27px}.projects-seller-app-components-onboarding-Card__checkedIcon{position:absolute;top:-5px;right:-5px;font-size:28px;color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"card": "projects-seller-app-components-onboarding-Card__card " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["titleCard"] + "",
	"content": "projects-seller-app-components-onboarding-Card__content",
	"iconContainer": "projects-seller-app-components-onboarding-Card__iconContainer",
	"checkedIcon": "projects-seller-app-components-onboarding-Card__checkedIcon"
};
module.exports = exports;
