import React from 'react';
import Button from 'common/components/common/Button';
import styles from './NoThanksButton.css';
import { Link } from 'react-router-dom';


const NoThanksButton = () => {

  return (
    <Link to={'/inventory'}>
      <Button className={styles.noThanksButton}>
        NO THANKS
      </Button>
    </Link>
  );
};

export default NoThanksButton;
