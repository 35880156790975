// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-FullscreenLoader__fullscreenLoader{position:fixed;top:0;bottom:0;left:0;right:0;z-index:999;display:flex;align-items:center;justify-content:center;color:var(--textColor);background-color:hsla(0,0%,100%,.85)}.projects-seller-app-components-common-FullscreenLoader__content{max-width:500px;padding:20px;text-align:center}.projects-seller-app-components-common-FullscreenLoader__message{margin-top:20px}", ""]);
// Exports
exports.locals = {
	"fullscreenLoader": "projects-seller-app-components-common-FullscreenLoader__fullscreenLoader",
	"content": "projects-seller-app-components-common-FullscreenLoader__content",
	"message": "projects-seller-app-components-common-FullscreenLoader__message"
};
module.exports = exports;
