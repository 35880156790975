// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-DeleteButton__deleteButton{color:#aaa;text-transform:uppercase;font-size:12px;cursor:pointer;padding:0;margin:0;background:none;border:none;outline:none}.projects-seller-app-components-common-DeleteButton__label{display:inline-block;margin-left:5px}", ""]);
// Exports
exports.locals = {
	"deleteButton": "projects-seller-app-components-common-DeleteButton__deleteButton",
	"label": "projects-seller-app-components-common-DeleteButton__label"
};
module.exports = exports;
