import _ from 'lodash';

export const firstOption: (
  options: string | string[] | Record<string, string>
) => string = options => {
  if (_.isObject(options)) {
    return Object.keys(options)[0];
  }
  if (_.isArray(options)) {
    return firstOption(options[0]);
  }
  return options;
};

export const cutText = (text: string, limit: number): string => {
  if (!text) {
    return '';
  }
  if (text.length <= limit) {
    return text;
  }
  return `${text.substring(0, limit)}...`;
};

export const parseFormNumberField = (value: string) => {
  if (!value) {
    return '';
  }
  const parsed = parseFloat(value);
  return isNaN(parsed) ? '' : parsed;
};

export const isUrl = (url: string) => /^https?:\/\/.+/.test(url);
