import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from 'common/components/common/Card';
import EmptyMessage from 'common/components/common/EmptyMessage';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import ContentPageHeader from 'seller/app/components/common/ContentPageHeader';
import DataTable from 'seller/app/components/common/datatable/DataTable';
import Pagination from 'seller/app/components/common/Pagination';
import CollectPaymentsButton from 'seller/app/components/order/list/CollectPaymentsButton';
import ListItem from 'seller/app/components/order/list/ListItem';
import TableItem from 'seller/app/components/order/list/TableItem';
import { deleteNotifiction } from 'seller/app/services/firebase';

import styles from './List.css';

const Instruction = () => (
  <p className={styles.instruction}>
    View and manage orders by selecting <strong>Manage</strong>, where you can
    add a tracking number once you ship an item. Your customer will be notified.
  </p>
);

const ListContentMobile = ({
  orders,
  loading,
  pagination,
  notifications,
  roomId,
  onClickMessage,
}) => (
  <>
    {loading ? (
      <SpinningDiamond smallIcon />
    ) : orders.length > 0 ? (
      <Card className={styles.listCard}>
        <Instruction />
        <ul className={styles.list}>
          {orders.map((order, i) => (
            <ListItem
              item={order}
              key={i}
              notifications={notifications}
              roomId={roomId}
              onClickMessage={onClickMessage}
            />
          ))}
        </ul>
        <Pagination {...pagination} />
      </Card>
    ) : (
      <EmptyMessage
        className={styles.listEmptyMessage}
        message="No orders found"
      />
    )}
    <CollectPaymentsButton />
  </>
);

const dataTableHeader = [
  {
    label: 'Order Date',
  },
  {
    label: 'Order Number',
  },
  {
    label: 'Customer',
  },
  {
    label: 'Payment',
    thCls: styles.dataTableHeaderPayment,
  },
  {
    label: 'Tracking Number',
  },
  {
    label: 'Actions',
    thCls: styles.dataTableHeaderActions,
  },
];

const ListContentDesktop = ({
  orders,
  loading,
  pagination,
  notifications,
  roomId,
  onClickMessage,
}) => {
  return (
    <>
      <ContentPageHeader
        title="Orders"
        renderActions={() => <CollectPaymentsButton />}
      />
      {loading ? (
        <SpinningDiamond smallIcon />
      ) : (
        <Card className={styles.tableCard}>
          <Instruction />
          <DataTable
            headers={dataTableHeader}
            items={orders.map((order, i) => (
              <TableItem
                key={i}
                item={order}
                notifications={notifications}
                roomId={roomId}
                onClickMessage={onClickMessage}
              />
            ))}
            emptyMessage="No orders found"
            pagination={pagination}
          />
        </Card>
      )}
    </>
  );
};
const List = ({ isBigScreen, ...props }) => {
  const ListContent = isBigScreen ? ListContentDesktop : ListContentMobile;
  const { state, setCurrentPage, notificationList } = props;
  const { orders, currentPage, totalPages, loading } = state;
  const [notifications, setNotifications] = useState([]);
  const [roomId, setRoomId] = useState(null);
  useEffect(() => {
    if (
      notificationList.hasOwnProperty('data') &&
      notificationList.data.length !== notifications.length
    ) {
      setNotifications(notificationList.data);
      if (!roomId) {
        setRoomId(notificationList.id);
      }
    }
  });

  const onClickMessage = async orderId => {
    try {
      let res = await deleteNotifiction({ roomId, orderId });
    } catch (e) {
      console.log(e);
    }
  };

  props = {
    orders,
    loading,
    notifications,
    roomId,
    onClickMessage,
    pagination: {
      currentPage,
      totalPages,
      onPageChange: setCurrentPage,
    },
  };

  return (
    <div className={styles.orderList}>
      <ListContent {...props} />
    </div>
  );
};

const mapStateToProps = ({ chat }) => ({
  notificationList: chat.notifications,
});

export default connect(mapStateToProps)(List);
