import React from 'react';

import Field from 'common/components/common/form/Field';
import ImagePicker from 'common/components/common/form/ImagePicker';
import SectionCard from 'common/components/common/SectionCard';

import placementSampleImage from 'common/assets/images/profile/seller-masthead-placement.jpg';
import styles from './Form.css';

const MastheadCard = ({ state, onMastheadImageChange }) => {
  return (
    <SectionCard title="Profile Masthead / Top Banner">
      <p className={styles.sectionIntro}>
        Customize your profile with a masthead image that displays across the
        top (large screens). Recommended image dimensions: 2500px wide by 500px
        high, or 5:1 size ratio. 15MB image upload limit.
      </p>
      <Field label="Masthead Image">
        <ImagePicker
          className={styles.imagePicker}
          max={1}
          images={state.mastheadImage}
          onChange={onMastheadImageChange}
        />
      </Field>

      <div className={styles.mastheadPlacementSample}>
        <h4>Placement Sample</h4>
        <img src={placementSampleImage} alt="banner placement sample" />
      </div>
    </SectionCard>
  );
};

export default MastheadCard;
