import classNames from 'classnames';
import React, { useState } from 'react';

import Card from 'common/components/common/Card';
import Fa from 'common/components/common/Fa';

import styles from './SectionCard.css';

export const SectionIntro: React.FC<Gemsby.ISimpleWrapperProps> = ({
  className,
  children,
}) => <p className={classNames(styles.sectionIntro, className)}>{children}</p>;

interface ISectionCardProps extends Gemsby.IComponentProps {
  title: string;
  initialCollapsed?: boolean;
  alwaysOpen?: boolean;
}

const SectionCard: React.FC<ISectionCardProps> = ({
  className = '',
  title,
  initialCollapsed = false,
  alwaysOpen = false,
  children,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const toggleCollapsed = event => {
    if (alwaysOpen) {
      return;
    }
    event.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <Card className={classNames(styles.sectionCard, className, { collapsed })}>
      <div className={styles.header} onClick={toggleCollapsed}>
        {!alwaysOpen && <Fa icon={`chevron-${collapsed ? 'right' : 'down'}`} />}
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.content}>{children}</div>
    </Card>
  );
};

export default SectionCard;
