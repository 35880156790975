import Fa from 'common/components/common/Fa';
import React from 'react';
import styles from './TextWithTick.css';

const TextWithTick: React.FC = ({ children }) => {
  return (
    <div style={{display:'flex'}}>
            <div className={styles.tickIconContainer}>
              <Fa className={styles.tickIcon} icon={'check'} />
            </div>
      <span>
        {children}
      </span>
    </div>
  );
};

export default TextWithTick;
