import React from 'react';

import TitleCard from 'common/components/common/TitleCard';
import GemsbyIcon from 'common/components/common/GemsbyIcon';


import styles from './Card.css';

interface IOnboardingCardProps extends Gemsby.IComponentProps {
  title: string;
  tagline: string;
  icon: React.ReactNode;
  desc: string;
  isChecked: boolean;
}

const Card: React.FC<IOnboardingCardProps> = ({
  title,
  tagline,
  icon,
  desc,
  isChecked,
  ...props
}) => (
  <TitleCard className={styles.card} title={title} tagline={tagline} {...props}>
    <div className={styles.content}>
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.descContainer}>{desc}</div>
    </div>
    {isChecked && <GemsbyIcon className={styles.checkedIcon} icon="check-alt" />}
  </TitleCard>
);

export default Card;
