// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-form-Toggle__toggleContainer *>{pointer-events:none}.projects-common-components-common-form-Toggle__toggleContainer input[type=checkbox]{display:none}.projects-common-components-common-form-Toggle__toggle{width:50px;height:30px;background-color:#ccc;border-radius:4px;position:relative}input[type=checkbox]:checked~.projects-common-components-common-form-Toggle__toggle{background-color:var(--mainColor)}.projects-common-components-common-form-Toggle__slider{width:26px;height:26px;top:2px;left:2px;border-radius:4px;background-color:#fff;box-shadow:0 0 1px #888;transition:left .3s}.projects-common-components-common-form-Toggle__slider,.projects-common-components-common-form-Toggle__slider:after,.projects-common-components-common-form-Toggle__slider:before{display:inline-block;position:absolute}.projects-common-components-common-form-Toggle__slider:after,.projects-common-components-common-form-Toggle__slider:before{content:\"\";width:1px;height:20px;left:11.5px;top:4px;background-color:#333}.projects-common-components-common-form-Toggle__slider:before{transform:rotate(45deg)}.projects-common-components-common-form-Toggle__slider:after{transform:rotate(-45deg)}input[type=checkbox]:checked~.projects-common-components-common-form-Toggle__toggle .projects-common-components-common-form-Toggle__slider{left:22px}input[type=checkbox]:checked~.projects-common-components-common-form-Toggle__toggle .projects-common-components-common-form-Toggle__slider:before{height:16px;top:6px;left:16px}input[type=checkbox]:checked~.projects-common-components-common-form-Toggle__toggle .projects-common-components-common-form-Toggle__slider:after{height:8px;top:13px;left:8px}", ""]);
// Exports
exports.locals = {
	"toggleContainer": "projects-common-components-common-form-Toggle__toggleContainer",
	"toggle": "projects-common-components-common-form-Toggle__toggle",
	"slider": "projects-common-components-common-form-Toggle__slider"
};
module.exports = exports;
