import localstore from 'common/utils/localstore';
import store from 'seller/app/store';

const KEY_PREFIX = 'onboarding';

enum OnboardingItems {
  profileUpdated = 'profileUpdated',
  inventoryUploaded = 'inventoryUploaded',
  paymentConnected = 'paymentConnected',
  accountUpdated = 'accountUpdated',
}

type OnboardingData = Record<OnboardingItems, boolean>;

let onboardingData: OnboardingData;

const getStoreKey = () => {
  const retailerId = store.getState().login.currentUser.id;
  return `${KEY_PREFIX}-${retailerId}`;
};

const getOnboardingData: () => OnboardingData = () => {
  if (onboardingData) {
    return onboardingData;
  }
  onboardingData = localstore.get(getStoreKey()) || {
    [OnboardingItems.profileUpdated]: false,
    [OnboardingItems.inventoryUploaded]: false,
    [OnboardingItems.paymentConnected]: false,
    [OnboardingItems.accountUpdated]: false,
  };
  return onboardingData;
};

const getField: (field: OnboardingItems) => boolean = field => {
  try {
    return getOnboardingData()[field];
  } catch {
    return false;
  }
};

const setField: (field: OnboardingItems) => void = field => {
  try {
    const data = getOnboardingData();
    data[field] = true;
    localstore.set(getStoreKey(), data);
  } catch {
    // do nothing
  }
};

export const isProfileUpdated = getField.bind(
  null,
  OnboardingItems.profileUpdated
);
export const isInventoryUploaded = getField.bind(
  null,
  OnboardingItems.inventoryUploaded
);
export const isPaymentConnected = getField.bind(
  null,
  OnboardingItems.paymentConnected
);
export const isAccountUpdated = getField.bind(
  null,
  OnboardingItems.accountUpdated
);

export const setProfileUpdated = setField.bind(
  null,
  OnboardingItems.profileUpdated
);
export const setInventoryUploaded = setField.bind(
  null,
  OnboardingItems.inventoryUploaded
);
export const setPaymentConnected = setField.bind(
  null,
  OnboardingItems.paymentConnected
);
export const setAccountUpdated = setField.bind(
  null,
  OnboardingItems.accountUpdated
);

export const isCompleted = () =>
  isProfileUpdated() &&
  isInventoryUploaded() &&
  isPaymentConnected() &&
  isAccountUpdated();

export default {
  isProfileUpdated,
  isInventoryUploaded,
  isPaymentConnected,
  isAccountUpdated,
  setProfileUpdated,
  setInventoryUploaded,
  setPaymentConnected,
  setAccountUpdated,
  isCompleted,
};
