import { Action, Dispatch } from 'redux';

import {
  validateLogin,
  validatePassword,
  validateRegister,
} from 'common/utils/validators';
import api from 'seller/app/services/api';
import auth from 'seller/app/services/auth';
import { getNotifications } from './chat';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
const loginRequest = () => ({ type: LOGIN_REQUEST });

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const loginSuccess = currentUser => ({ type: LOGIN_SUCCESS, currentUser });

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = error => ({ type: LOGIN_ERROR, error });

export const login = (email, password, saveLogin = false) => dispatch => {
  const { valid, error } = validateLogin({ email, password });
  if (!valid) {
    dispatch(loginError(error));
    return Promise.reject(error);
  }
  email = email.toLowerCase();
  dispatch(loginRequest());
  return auth.login(email, password).then(
    data => {
      const { token, currentUser } = data;
      dispatch(loginSuccess(currentUser));
      dispatch(getNotifications(currentUser.data.attributes.email));
      return data;
    },
    error => {
      dispatch(loginError(error));
      throw error;
    }
  );
};

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
function logoutRequest() {
  return { type: LOGOUT_REQUEST };
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
function logoutSuccess() {
  return { type: LOGOUT_SUCCESS };
}

export const LOGOUT_ERROR = 'LOGOUT_ERROR';
function logoutError(error) {
  return { type: LOGOUT_ERROR, error };
}

export function logout() {
  return dispatch => {
    dispatch(logoutRequest());
    return auth.logout().then(
      () => {
        dispatch(logoutSuccess());
        dispatch(unsubsribeNotifications());
      },
      error => {
        dispatch(logoutError(error));
      }
    );
  };
}

interface IRegisterRquestAction extends Action<string> {}
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
const registerRequest = () => ({ type: REGISTER_REQUEST });

interface IRegisterSuccessAction extends Action<string> {}
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const registerSuccess = () => ({ type: REGISTER_SUCCESS });

interface IRegisterErrorAction extends Action<string> {
  error: any;
}
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const registerError = (error: any) => ({ type: REGISTER_ERROR, error });

type RegisterAction =
  | IRegisterRquestAction
  | IRegisterSuccessAction
  | IRegisterErrorAction;

export const register = (data: Gemsby.SellerRegisterData) => (
  dispatch: Dispatch<RegisterAction>
) => {
  const { valid, error } = validateRegister(data);
  if (!valid) {
    dispatch(registerError(error));
    return Promise.reject(error);
  }
  data.email = data.email.toLowerCase();
  dispatch(registerRequest());
  return auth.register(data).then(
    () => {
      dispatch(registerSuccess());
    },
    error => {
      dispatch(registerError(error));
    }
  );
};

export const SET_NEXT_URL = 'SET_NEXT_URL';
export function setNextUrl(url) {
  return { type: SET_NEXT_URL, url };
}

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export function setCurrentUser(currentUser) {
  return { type: SET_CURRENT_USER, currentUser };
}

export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export function clearLoginError() {
  return { type: CLEAR_LOGIN_ERROR };
}

export const SAVE_ACCOUNT_REQUEST = 'SAVE_ACCOUNT_REQUEST';
function saveAccountRequest() {
  return { type: SAVE_ACCOUNT_REQUEST };
}

export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS';
function saveAccountSuccess() {
  return { type: SAVE_ACCOUNT_SUCCESS };
}

export const SAVE_ACCOUNT_ERROR = 'SAVE_ACCOUNT_ERROR';
function saveAccountError(error) {
  return { type: SAVE_ACCOUNT_ERROR, error };
}

export function saveAccount(data) {
  return dispatch => {
    dispatch(saveAccountRequest());
    const { valid, error } = validatePassword(data.attributes, true);
    if (!valid) {
      dispatch(saveAccountError(error));
      return;
    }
    if (!data.attributes.password) {
      dispatch(saveAccountSuccess());
      return;
    }
    // XXX: handle admin change password
    return api.updateRetailer(data.id, data).then(
      () => {
        dispatch(saveAccountSuccess());
        dispatch(logout());
      },
      error => {
        dispatch(saveAccountError(error));
      }
    );
  };
}
