// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-HelpCenter__helpCenter{display:flex;align-items:center;border:2px dashed #ddd;border-radius:15px;padding:15px;margin-top:20px}.projects-common-components-common-HelpCenter__questionIcon{font-size:30px;color:#ccc}.projects-common-components-common-HelpCenter__blurb{margin-left:15px;font-size:12px;font-weight:700}.projects-common-components-common-HelpCenter__blurb .projects-common-components-common-HelpCenter__helpCenterLink{color:#888;text-transform:uppercase}.projects-common-components-common-HelpCenter__helpCenterLink{display:block;margin-bottom:2px}@media (max-width:768px){.projects-common-components-common-HelpCenter__helpCenterLink{font-size:9px;margin-bottom:5px}}.projects-common-components-common-HelpCenter__articleLink{display:block;font-size:16px}@media (max-width:768px){.projects-common-components-common-HelpCenter__articleLink{font-size:12px}}.projects-common-components-common-HelpCenter__center{margin-top:0;border:1px solid #ddd;border-radius:4px}.projects-common-components-common-HelpCenter__center .projects-common-components-common-HelpCenter__blurb{width:100%;text-align:center}.projects-common-components-common-HelpCenter__center .projects-common-components-common-HelpCenter__articleLink{font-weight:400;text-transform:uppercase;color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"helpCenter": "projects-common-components-common-HelpCenter__helpCenter",
	"questionIcon": "projects-common-components-common-HelpCenter__questionIcon",
	"blurb": "projects-common-components-common-HelpCenter__blurb",
	"helpCenterLink": "projects-common-components-common-HelpCenter__helpCenterLink",
	"articleLink": "projects-common-components-common-HelpCenter__articleLink",
	"center": "projects-common-components-common-HelpCenter__center"
};
module.exports = exports;
