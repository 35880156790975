export const API_HOST = SERVER;
export const API_ROOT = `${window.cordova ? API_HOST : ''}/api/v1`;
export const API_LOGIN_PATH = 'login';
export const API_INVENTORY_PATH = 'inventory';
export const API_RETAILER_PATH = 'retailer';
export const API_BOOKING_PATH = 'booking';
export const API_ADS_PATH = 'curated';
export const API_STATS_PATH = 'stats';
export const API_ORDERS_PATH = 'orders';
export const API_ORDER_PATH = 'order';
export const API_CHAT_PATH = 'chat';

const marketplaceUrls: Record<string, string> = {
  development: 'https://app-a01m.gemsby.com',
  production: 'https://gemsby.com',
};
export const MARKETPLACE_URL = marketplaceUrls[process.env.NODE_ENV];

const retailerUrls: Record<string, string> = {
  development: 'https://retailer-a01w.gemsby.com',
  production: 'https://retailer.gemsby.com',
};
export const RETAILER_URL = retailerUrls[process.env.NODE_ENV];

export const algoliaIndices: Record<string, Record<string, string>> = {
  development: {
    retailers: 'retailers_dev',
    inventory: 'inventory_dev',
    inventoryPriceAsc: 'inventory_dev_price_asc',
    inventoryPriceDesc: 'inventory_dev_price_desc',
    inventoryRecentUpdate: 'inventory_dev_recent_update',
  },
  production: {
    retailers: 'retailers',
    inventory: 'inventory',
    inventoryPriceAsc: 'inventory_price_asc',
    inventoryPriceDesc: 'inventory_price_desc',
    inventoryRecentUpdate: 'inventory_recent_update',
  },
};
export const ALGOLIA_INDICES = algoliaIndices[process.env.NODE_ENV];

export const LIST_PAGE_LENGTH = 20;
export const BIG_SCREEN_SIZE = 768;
export const DEFAULT_COUNTRY_CODE = 'US';
export const ACCESS_TOKENS_KEY = 'accessTokens';
export const APP_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE0NzQ1OTUxMzEsImp0aSI6ImdBTnNZZkY2VjZHdUdDQ01uNzdNRVNBenNwT2J2Z1BUcExjbVJaNE1oREk9IiwiaXNzIjoibXlkaWFtb25kYXBwLmNvbSIsIm5iZiI6MTQ3NDU5NTEzMSwiZXhwIjo0NjI4NTk1MTMxLCJkYXRhIjp7InVzZXJOYW1lIjoiYXBwIiwibG9nZ2VkSW5BcyI6ImFwcCJ9fQ.kZscs_8on0VguhWUFnxgOgNFIPv4_H-HqvkT7usuMXl2xlNIV9ENQWoa712WD_7ROnStY96gu-wpPMy0gXJatQ';

export const IMGIX_URL = 'https://mydiamondapp.imgix.net';
export const CLOUDINARY_URL = 'https://res.cloudinary.com/basecase/image/fetch';
export const YOTPO_WIDGET_JS = `https://staticw2.yotpo.com/${process.env.YOTPO_APIKEY}/widget.js`;

export const SUPPORT_EMAIL = 'support@gemsby.com';
export const PRIVACY_URL =
  'http://www.gemsby.club/legal/privacypolicy.html';
export const TOS_URL =
  'http://www.gemsby.club/legal/termsofservice.html';
export const SELLER_SIGN_IN_URL = `${RETAILER_URL}/signin`;
export const SELLER_SIGN_UP_URL = `${RETAILER_URL}/signup`;
export const MOBILE_APP_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.mydiamondapp.mobile&hl=en';
export const MOBILE_APP_IOS_URL =
  'https://itunes.apple.com/ca/app/mydiamond-browse-local-jewelry-stores/id1172211235?mt=8';
export const MOBILE_APP_URL = 'https://mydiamond.app.link/app-install-page';
export const GEMSBY_BLOG_URL = 'https://medium.com/gemsbymagazine';
export const GEMSBY_HELP_URL = 'https://intercom.help/gemsby';
export const GEMSBY_INSTAGRAM_URL = 'https://www.instagram.com/gemsbyofficial';
export const GEMSBY_FACEBOOK_URL = 'https://www.facebook.com/gemsby';
export const GEMSBY_TWITTER_URL = 'https://twitter.com/gemsbyofficial';

export default {
  API_ROOT,
  API_LOGIN_PATH,
  API_RETAILER_PATH,
  API_INVENTORY_PATH,
  API_BOOKING_PATH,
  API_ADS_PATH,
  API_STATS_PATH,
  API_ORDERS_PATH,
  API_ORDER_PATH,
  API_CHAT_PATH,
  APP_TOKEN,
  DB_SIZE: 50 * 1024 * 1024,
  IMGIX_URL,
  CLOUDINARY_URL,
  SELLER_SIGN_IN_URL,
  SELLER_SIGN_UP_URL,
  BIG_SCREEN_SIZE,
  GEMSBY_BLOG_URL,
  GEMSBY_INSTAGRAM_URL,
  GEMSBY_FACEBOOK_URL,
  GEMSBY_TWITTER_URL,
  ACCESS_TOKENS_KEY,
  YOTPO_WIDGET_JS,
  SERVER: MARKETPLACE_URL,
};
