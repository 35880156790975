// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-common-components-common-form-Checkbox__checkboxContainer{display:flex}.projects-common-components-common-form-Checkbox__checkboxContainer>*{pointer-events:none}.projects-common-components-common-form-Checkbox__checkboxContainer input[type=checkbox]{display:none}.projects-common-components-common-form-Checkbox__checkboxContainer input[type=checkbox]~.icon{font-size:18px;background-color:#f6f6f6}.projects-common-components-common-form-Checkbox__checkboxContainer input[type=checkbox]:checked~.icon{color:var(--mainColor)}", ""]);
// Exports
exports.locals = {
	"checkboxContainer": "projects-common-components-common-form-Checkbox__checkboxContainer"
};
module.exports = exports;
