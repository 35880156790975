import React from 'react';
import SectionCard from 'common/components/common/SectionCard';
import PageSection from 'common/components/common/PageSection';
import ChatForm from "./Form"
import Button from 'common/components/common/Button';

import styles from './Form.css';

const Chat = (props) => {
    return (
        <div>
            <SectionCard title="CUSTOMER SUMMARY" initialCollapsed={true} ></SectionCard>
            <SectionCard title="CHAT WITH CUSTOMER">
                <PageSection>
                    <ChatForm {...props} />
                </PageSection>
            </SectionCard>
            <div className={styles.buttons}>
                <Button
                    className={styles.backButton}
                    onClick={event => {
                        event.preventDefault();
                        props.history.goBack();
                    }}
                >
                    Back To Orders
                </Button>
            </div>
        </div>
    );
};

export default Chat;