import React from 'react';
import Modal from 'common/components/common/mobile/Modal';
import ImagePicker from 'common/components/common/form/ImagePicker';
import Button from 'common/components/common/Button';
import spinningDiamondGif from 'common/assets/images/spinning-diamond.gif';
import styles from './Form.css';

const ChatModal = ({
  open,
  onClose,
  images,
  onImageChange,
  onUpload,
  upload,
}) => {
  return (
    <Modal open={open} onClose={onClose} modalClass={styles.chatModal}>
      {upload ? (
        <div className={styles.loaderContainer}>
          <img src={spinningDiamondGif} height="120px" width="120px" />
        </div>
      ) : (
        <>
          <h3>Upload Image</h3>
          <ImagePicker
            className={styles.chatImagePicker}
            type="retailer"
            max={5}
            size={1}
            onChange={onImageChange}
          />
          <Button
            className={styles.uploadButton}
            disabled={!images.length}
            onClick={() => onUpload()}
          >
            Upload
          </Button>
        </>
      )}
    </Modal>
  );
};

export default ChatModal;
