import produce from 'immer';
import {
  HIDE_FULLSCREEN_LOADER,
  SET_FULLSCREEN_LOADER_MESSAGE,
  SHOW_FULLSCREEN_LOADER,
} from 'seller/app/actions/form';

const initialState = {
  isFullscreenLoaderShown: false,
  fullscreenLoaderMessage: ''
};

export default (state = initialState, action: Gemsby.Action) => produce(state, (draft) => {
  switch (action.type) {
    case SHOW_FULLSCREEN_LOADER:
      draft.isFullscreenLoaderShown = true;
      draft.fullscreenLoaderMessage = action.message;
      break;
    case HIDE_FULLSCREEN_LOADER:
      draft.isFullscreenLoaderShown = false;
      draft.fullscreenLoaderMessage = '';
      break;
    case SET_FULLSCREEN_LOADER_MESSAGE:
      draft.fullscreenLoaderMessage = action.message;
      break;
  }
});
