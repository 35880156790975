import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import OutLink from 'common/components/common/OutLink';

import styles from './TabBarItem.css';

interface ITabBarItemProps extends Gemsby.IComponentProps {
  extraCls?: string;
  active?: boolean;
  path?: string;
  icon: JSX.Element;
  external?: boolean;
}

const TabBarItem: React.FC<ITabBarItemProps> = ({
  extraCls,
  active,
  path,
  icon,
  external = false,
  hasBadges = false,
  showBadge = false
}) => {
  const itemCls = active ? styles.activeItem : styles.item;
  const LinkComponent = external ? OutLink : Link;
  return (
    <div className={classNames(itemCls, extraCls)}>
      <LinkComponent to={path}>{icon}</LinkComponent>
      {showBadge && hasBadges && (
        <span className={styles.badges}></span>
      )}
    </div>
  );
};

export default TabBarItem;
