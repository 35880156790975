import classNames from 'classnames';
import React from 'react';

import Card from 'common/components/common/Card';

import styles from './TitleCard.css';

interface ITitleCardProps extends Gemsby.ISimpleWrapperProps {
  title?: string;
  tagline?: string;
}

const TitleCard: React.FC<ITitleCardProps> = ({
  className,
  title,
  tagline,
  children,
  ...props
}) => (
  <Card className={classNames(styles.titleCard, className)} {...props}>
    {title && (
      <div className={classNames('header', styles.header)}>
        {tagline && (
          <p className={classNames('tagline', styles.tagline)}>{tagline}</p>
        )}
        <h2 className={classNames('title', styles.title)}>{title}</h2>
        <div className={classNames('titleSeparator', styles.titleSeparator)} />
      </div>
    )}
    {children}
  </Card>
);

export default TitleCard;
