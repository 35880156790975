export const SHOW_FULLSCREEN_LOADER = 'SHOW_FULLSCREEN_LOADER';
export const showFullscreenLoader = message => ({
  type: SHOW_FULLSCREEN_LOADER,
  message
});

export const HIDE_FULLSCREEN_LOADER = 'HIDE_FULLSCREEN_LOADER';
export const hideFullscreenLoader = () => ({ type: HIDE_FULLSCREEN_LOADER });

export const SET_FULLSCREEN_LOADER_MESSAGE = 'SET_FULLSCREEN_LOADER_MESSAGE';
export const setFullscreenLoaderMessage = (message) => ({
  type: SET_FULLSCREEN_LOADER_MESSAGE,
  message
});
