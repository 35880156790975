import React from 'react';

import Header from 'seller/app/components/common/header/Header';
import HeaderLink from 'seller/app/components/common/header/HeaderLink';

import { MARKETPLACE_URL } from 'common/constants';
import { renderLink } from 'seller/app/components/common/header/HeaderNavDesktop';
import { Routes } from 'seller/app/constants';
import styles from './Landing.css';

interface IHeaderNavProps {
  activeItem: Routes;
}

const HeaderNav = ({ activeItem }: IHeaderNavProps) => (
  <Header className={styles.desktopHeaderContainer}>
    <div className={styles.header}>
      {renderLink(Routes.signOn, 'Create Account', activeItem)}
      {renderLink(Routes.signIn, 'Sign In', activeItem)}
      <HeaderLink external to={MARKETPLACE_URL}>Marketplace</HeaderLink>
    </div>
  </Header>
);

export default HeaderNav;
