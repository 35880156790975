import React from 'react';
import { connect } from 'react-redux';

import Page from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import Header from 'seller/app/components/common/header/Header';
import HeaderLink from 'seller/app/components/common/header/HeaderLink';
import HeaderNav from 'seller/app/components/landing/HeaderNav';
import Landing from 'seller/app/components/landing/Landing';
import { Routes } from 'seller/app/constants';

import styles from './Landing.css';

const LandingPageMobile = (props) => (
  <Screen>
    <Page>
      <PageContent>
        <Header>
          <div className={styles.header}>
            <HeaderLink to="/signin">Sign In</HeaderLink>
          </div>
        </Header>

        <Landing {...props} />
      </PageContent>
    </Page>
  </Screen>
);

const LandingPageDesktop = (props) => (
  <>
    <HeaderNav activeItem={Routes.signOn} {...props} />
    <Landing {...props} />
  </>
);

const LandingPage = ({ history, ...props }) => {
  props.goToDashboard = () => history.push('/dashboard');
  
  return props.isBigScreen ? (
    <LandingPageDesktop {...props} />
  ) : (
    <LandingPageMobile {...props} />
  );
};

const mapState = ({ device }) => ({
  isBigScreen: device.isBigScreen,
});

export default connect(mapState)(LandingPage);
