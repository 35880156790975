import { isNaOption, isNullOption, NA_OPTION } from 'common/utils/form';
import {
  colorTypes,
  ColorTypes,
  JewelryConditions,
  MountTypes,
  ParcelSizes,
  SaleMarkupTypes,
  Settings,
  statusCodes,
} from 'common/utils/inventory';
import { firstOption } from 'common/utils/misc';
import { Entity } from 'fetch.jsonapi';

export * from 'common/utils/inventory';

export const createNewInventory = () =>
  new Entity({
    id: '',
    type: 'inventory',
    attributes: {
      brand: '',
      carat: '',
      centerStoneWeight: '',
      certGrade: '',
      certNum: '',
      certificate: '',
      clarity: '',
      color: '',
      colorType: ColorTypes.REGULAR,
      containsGemstones: false,
      currency: '',
      customTitle: '',
      cut: '',
      enhancement: '',
      extraDescription: '',
      firstImagePath: '',
      fluorescence: '',
      gemstone: '',
      gender: '',
      hidePrice: false,
      hue: '',
      intensity: '',
      internalId: '',
      itemAvailability: '',
      jewelryCondition: JewelryConditions.NEW,
      jewelryMaterial: '',
      jewelryType: '',
      laserInscription: '',
      linkToCert: '',
      markup: '',
      metalPurity: '',
      metalType: '',
      mountSize: '',
      mountType: MountTypes.CENTER_STONE,
      parcelSize: ParcelSizes.SINGLE,
      polish: '',
      price: '',
      propDepth: '',
      propLength: '',
      propWidth: '',
      quantity: '',
      recurring: false,
      ringDescription: '',
      saleMarkupType: '',
      salePrice: '',
      segomaLink: '',
      setting: Settings.LOOSE_DIAMOND,
      shape: '',
      statusCode: statusCodes.AVAILABLE,
      symmetry: '',
      synthetic: false,
      totalWeightCarat: '',
      totalWeightGrams: '',
      watchBraceletMaterial: '',
      watchCaseMaterial: '',
      watchModel: '',
      watchMovement: '',
      year: '',
      youtubeUrl: '',
    },
  });

interface IInventoryData {
  setting: Settings;
  markup: string;
  salePrice: string;
  [field: string]: string | number | boolean;
}

type DataFields = string[];

const toNumber = (value: string) => {
  const n = parseFloat(value);
  return isNaN(n) ? value : n;
};

const prepareFields = (converter: (value: any) => any, data: IInventoryData, fields: DataFields) =>
  fields.reduce(
    (result, field) =>
      Object.assign(result, {
        [field]: converter(data[field]),
      }),
    {}
  );

const prepareNumberFields = prepareFields.bind(null, toNumber);
const prepareNAFields = prepareFields.bind(null, () => NA_OPTION);

const excludeFields = (data: IInventoryData, fields: DataFields, predicate: (value: any) => boolean = () => true) => {
  fields.forEach(field => {
    if (predicate(data[field])) {
      delete data[field];
    }
  });
  return data;
};

const fourCsFields = [
  'carat',
  'certificate',
  'clarity',
  'color',
  'cut',
  'shape',
];

const prepareCommonData = (data: IInventoryData) => {
  data = Object.assign({}, data, {
    statusCode: data.isHidden ? statusCodes.HIDDEN : statusCodes.AVAILABLE,
    markup:
      data.saleMarkupType === SaleMarkupTypes.MARKUP
        ? toNumber(data.markup)
        : 0,
    salePrice:
      data.saleMarkupType === SaleMarkupTypes.SALE
        ? toNumber(data.salePrice)
        : 0,
    ...prepareNumberFields(data, [
      'carat',
      'centerStoneWeight',
      'mountSize',
      'price',
      'propDepth',
      'propLength',
      'propWidth',
      'quantity',
      'totalWeightCarat',
      'totalWeightGrams',
    ]),
  });

  delete data.isHidden;

  return excludeFields(
    data,
    ['propDepth', 'propLength', 'propWidth'],
    isNullOption
  );
};

const prepareJewelryData = (data: IInventoryData) => {
  data = Object.assign(prepareCommonData(data));
  if (!data.containsGemstones) {
    Object.assign(data, {
      ...prepareNAFields(data, fourCsFields.concat('colorType')),
    });
    excludeFields(data, ['propDepth', 'propLength', 'propWidth']);
  }
  return data;
};

const dataPreparers = {
  [Settings.LOOSE_DIAMOND]: prepareCommonData,
  [Settings.LOOSE_GEMSTONE]: prepareCommonData,
  [Settings.DIAMOND_JEWELRY]: prepareCommonData,
  [Settings.JEWELRY]: prepareJewelryData,
  [Settings.CRAFT_JEWELRY]: prepareJewelryData,
  [Settings.WATCH]: prepareJewelryData,
  [Settings.SERVICE]: prepareJewelryData,
};

export const prepareInventoryData = (data: IInventoryData) => {
  return dataPreparers[data.setting](data);
};

const resetNAFields = (data: IInventoryData, fields: DataFields) =>
  fields.reduce(
    (result, field) =>
      Object.assign(result, {
        [field]: isNaOption(data[field] as string) ? '' : data[field],
      }),
    {} as IInventoryData
  );

const setBlankFieldsToNA = (data: IInventoryData, fields: DataFields) =>
  fields.reduce(
    (result, field) =>
      Object.assign(result, {
        [field]: data[field] === '' ? NA_OPTION : data[field],
      }),
    {} as IInventoryData
  );

const resetLooseDiamondData = (data: IInventoryData) => ({
  ...resetNAFields(data, fourCsFields),
  colorType: firstOption(colorTypes),
});

const resetLooseGemstoneData = (data: IInventoryData) => ({
  ...setBlankFieldsToNA(data, fourCsFields),
  colorType: NA_OPTION,
});

const resetDiamondJewelryData = (data: IInventoryData) => ({
  ...resetLooseDiamondData(data),
  containsDiamonds: true,
});

const resetJewelryData = (data: IInventoryData) => ({
  ...prepareNAFields(data, fourCsFields.concat('colorType')),
  containsDiamonds: false,
});

const dataResetters = {
  [Settings.LOOSE_DIAMOND]: resetLooseDiamondData,
  [Settings.LOOSE_GEMSTONE]: resetLooseGemstoneData,
  [Settings.DIAMOND_JEWELRY]: resetDiamondJewelryData,
  [Settings.JEWELRY]: resetJewelryData,
  [Settings.CRAFT_JEWELRY]: resetJewelryData,
  [Settings.WATCH]: resetJewelryData,
  [Settings.SERVICE]: resetJewelryData,
};

export const resetInventoryData = (data: IInventoryData) =>
  Object.assign({}, data, dataResetters[data.setting](data));
