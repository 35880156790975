import React from 'react';
import StatusBarFill from 'common/components/common/mobile/StatusBarFill';

import styles from './Screen.css';

const Screen = ({
  className = styles.screen,
  statusBarFillColor = '',
  children
}) => (
  <div className={className}>
    <StatusBarFill color={statusBarFillColor} />
    {children}
  </div>
);

export default Screen;
