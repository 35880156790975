import React from 'react';

import Button from 'common/components/common/Button';
import CurrencyCard from 'seller/app/components/account/form/CurrencyCard';
import ChangePasswordCard from 'seller/app/components/account/form/ChangePasswordCard';
import FeedsCard from 'seller/app/components/account/form/FeesCard';

import styles from './Form.css';

const FormMobile = (props) => (
  <>
    <CurrencyCard {...props} />
    <FeedsCard {...props} />
    <ChangePasswordCard {...props} />
  </>
);

const FormDesktop = (props) => (
  <div className={styles.formContent}>
    <div className={styles.formContentLeftColumn}>
      <CurrencyCard {...props} />
      <FeedsCard {...props} />
    </div>
    <div className={styles.formContentRightColumn}>
      <ChangePasswordCard {...props} />
    </div>
  </div>
);

const Form = (props) => {
  const { isBigScreen, onCancel, onSubmit } = props;

  return (
    <form className={styles.form}>
      {isBigScreen ? <FormDesktop {...props} /> : <FormMobile {...props} />}

      <div className={styles.buttons}>
        <Button className={styles.cancelButton} onClick={onCancel}>
          Cancel
        </Button>
        <Button className={styles.saveButton} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
