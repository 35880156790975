import produce from 'immer';
import device from 'common/services/device';
import { SET_IS_BIG_SCREEN } from 'seller/app/actions/device';

const initialState = {
  isBigScreen: device.isBigScreen(),
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_IS_BIG_SCREEN:
        draft.isBigScreen = action.isBigScreen;
        break;
    }
  });
