// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("common/assets/fonts/Montserrat/Montserrat-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("common/assets/fonts/Montserrat/Montserrat-Hairline.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("common/assets/fonts/Montserrat/Montserrat-Light.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("common/assets/fonts/Montserrat/Montserrat-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("common/assets/fonts/Montserrat/Montserrat-Black.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-Black.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("common/assets/fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-weight: 100;\n}\n@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-weight: 300;\n}\n@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  font-weight: 700;\n}\n@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  font-weight: 900;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n  font-weight: 400;\n  font-style: italic;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n  font-weight: 700;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n  font-weight: 700;\n  font-style: italic;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ");\n  font-weight: 900;\n}\n@font-face {\n  font-family: 'Playfair Display';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('truetype');\n  font-weight: 900;\n  font-style: italic;\n}\n", ""]);
// Exports
module.exports = exports;
