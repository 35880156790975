import uuid from 'uuid';

import { BIG_SCREEN_SIZE } from 'common/constants';
import localstore from 'common/utils/localstore';

const key = 'device';
const data = localstore.get(key) || {};

let platform: string;
let resizeTimeout: number;

export const randomString = (): string =>
  `${Math.floor(Math.random() * 90000 + 10000)}`;

const onWindowResize = (handler: EventListener) => (event: Event) => {
  if (resizeTimeout) {
    return;
  }
  resizeTimeout = window.setTimeout(() => {
    handler(event);
    resizeTimeout = null;
  }, 66);
};

const device = {
  platform(): string {
    if (!platform) {
      platform = window.cordova
        ? window.device.platform.toLowerCase()
        : 'browser';
    }
    return platform;
  },
  isIos(): boolean {
    return this.platform() === 'ios';
  },
  isAndroid(): boolean {
    return this.platform() === 'android';
  },
  isBrowser(): boolean {
    return this.platform() === 'browser';
  },
  uuid(): string {
    if (data.uuid) {
      return data.uuid;
    }
    const deviceId = window.cordova ? window.device.uuid : uuid.v4();
    data.uuid = deviceId;
    localstore.set(key, data);
    return deviceId;
  },
  isBigScreen(): boolean {
    return window.innerWidth >= BIG_SCREEN_SIZE;
  },
  listenWindowResize(handler: EventListener): void {
    window.addEventListener('resize', onWindowResize(handler), false);
  },
  hasNotch(): boolean {
    if (!window.cordova) {
      return false;
    }
    const model = window.device.model;
    return !![
      'iPhone10,3',
      'iPhone10,6',
      'iPhone11,2',
      'iPhone11,4',
      'iPhone11,6',
      'iPhone11,8',
      'iPhone12,1',
      'iPhone12,2',
      'iPhone12,3',
    ].find(m => m === model);
  },
  randomString,
};

export default device;
