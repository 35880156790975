import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import Modal from 'common/components/common/mobile/MobileModal';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageSection from 'common/components/common/PageSection';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import Onboarding from 'seller/app/components/onboarding/Onboarding';
import { Routes } from 'seller/app/constants';
import PromoteNowView from 'seller/app/components/common/PromoteNowView';
import PromoteBannerDesktop from 'seller/app/components/inventory/promote/promoteBannerDesktop/PromoteBannerDesktop';
import PriceCards from 'seller/app/components/inventory/promote/priceCards/PriceCards';
import PromoteBannerMobile from 'seller/app/components/inventory/promote/PromoteBannerMobile/PromoteBannerMobile';
import PromoteFooter from 'seller/app/components/inventory/promote/PromoteFooter/PromoteFooter';
import AboveCardsButtonMobile
  from 'seller/app/components/inventory/promote/AboveCardsButtonMobile/AboveCardsButtonMobile';
import { getInventory } from 'seller/app/actions/inventory';
import { firstOption } from 'common/utils/misc';
import { saleMarkupTypes, settings, statusCodes } from 'common/utils/inventory';
import { currencies } from 'common/utils/retailer';
import { getInventoryImageFile } from 'common/utils/image';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import { hideFullscreenLoader, showFullscreenLoader } from 'seller/app/actions/form';
import CompletePurchase from 'seller/app/components/inventory/promote/CompletePurchase/CompletePurchase';

const PromotePromptPageMobile = ({ state,completePurchaseOpen, completePurchaseOpenValueHandler,promotionType,setPromotionType}) => (

  <>
    <Modal />
    <Screen>
      <PageMobile>
        <PageContent>
          <HeaderNavMobile />

          <PageSection>
            <PromoteBannerMobile />
          </PageSection>

          <PageSection>
            <AboveCardsButtonMobile />
          </PageSection>

          <PageSection>
            <PriceCards setPromotionType={setPromotionType} completePurchaseOpenValueHandler={completePurchaseOpenValueHandler}/>
          </PageSection>

          <PageSection>
            <PromoteFooter />
          </PageSection>

          <PageSection>
            <CompletePurchase
              promotionType={promotionType}
              state={state}
              completePurchaseOpen={completePurchaseOpen}
            />
          </PageSection>

        </PageContent>

        <TabBar activeItem={Routes.inventory} />
      </PageMobile>
    </Screen>
  </>
);

const PromotePromptPageDesktop = ({ state,completePurchaseOpen, completePurchaseOpenValueHandler,promotionType,setPromotionType}) => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.inventory} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <Onboarding />
    </PageSection>

    <PageSection>
      <PromoteNowView />
    </PageSection>

    <PageSection>
      <PromoteBannerDesktop />
    </PageSection>

    <PageSection>
      <PriceCards setPromotionType={setPromotionType}  completePurchaseOpenValueHandler={completePurchaseOpenValueHandler}/>
    </PageSection>


    <PageSection>
      <PromoteFooter />
    </PageSection>

        <PageSection>
          <CompletePurchase
            state={state}
            promotionType={promotionType}
            completePurchaseOpen={completePurchaseOpen}
          />
        </PageSection>

    <Footer />
  </PageDesktop>
);

const PromotePromptPage = (props) => {

  const {
    isBigScreen,
    match,
    getInventory,
    showFullscreenLoader,
    hideFullscreenLoader,
    selectedInventory,
  } = props;

  const [completePurchaseOpen,setCompletePurchaseOpen] = useState(false);

  const completePurchaseOpenValueHandler = (value:boolean) => {
    setCompletePurchaseOpen(value)
  }

  const [state,setState] = useState();

  const { id: inventoryId } = match.params;

  const [promotionType,setPromotionType] = useState({
    value: 'basic',
    price: 0
  });

  const [loading, setLoading] = useState(true);




  useEffect(() => {
    getInventory(inventoryId);
  }, []);

  useEffect(() => {
    if (!selectedInventory) {
      return;
    }

    const data = selectedInventory.toJSON().attributes;
    data.setting = data.setting || firstOption(settings);
    data.saleMarkupType = data.saleMarkupType || firstOption(saleMarkupTypes);
    data.isHidden = data.statusCode === statusCodes.HIDDEN;
    data.currency =
      data.currency || retailer.get('currency') || firstOption(currencies);

    data.imageFiles = (selectedInventory.getRelated('images') || []).map(
      getInventoryImageFile,
    );

    setState(data);

    let timeoutId = setTimeout(
      () => {
        setLoading(false);
        timeoutId = null;
      },
      inventoryId ? 300 : 0,
    );

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [selectedInventory]);

  if (loading) {
    return <SpinningDiamond fullScreen />;
  }

  console.log(state);



  const ListPage = isBigScreen
    ? PromotePromptPageDesktop
    : PromotePromptPageMobile;

  return (

    <ListPage
      isBigScreen={isBigScreen}
      state={state}
      promotionType={promotionType}
      setPromotionType={setPromotionType}
      completePurchaseOpenValueHandler ={completePurchaseOpenValueHandler}
      completePurchaseOpen={completePurchaseOpen}

    />

  );
};

const mapState = ({ device, inventory }) => ({
  isBigScreen: device.isBigScreen,
  selectedInventory: inventory.selectedInventory,
});

const mapDispatch = {
  getInventory,
  showFullscreenLoader,
  hideFullscreenLoader,
};

export default connect(mapState, mapDispatch)(PromotePromptPage);
