import classNames from 'classnames';
import React from 'react';

import styles from './SubSection.css';

interface ISubSectionTitleProps extends Gemsby.IComponentProps {
  title: string;
}

export const SubSectionTitle: React.FC<ISubSectionTitleProps> = ({
  className,
  title,
}) => <h4 className={classNames(styles.title, className)}>{title}</h4>;

interface ISubSectionProps extends Gemsby.ISimpleWrapperProps {
  title: string;
}

export const SubSection: React.FC<ISubSectionProps> = ({
  className,
  title,
  children,
}) => {
  return (
    <div className={classNames(styles.subSection, className)}>
      <SubSectionTitle title={title} />
      {children}
    </div>
  );
};

export default SubSection;
