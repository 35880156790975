import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';

import Field, { IFieldProps } from 'common/components/common/form/Field';
import { NA_OPTION } from 'common/utils/form';

import styles from './Form.css';

export interface ISelectOptions {
  [value: string]: string;
}

interface ISelectFieldProps extends IFieldProps {
  options: ISelectOptions;
  selectedOption: string;
  nullOption?: string;
  naOption?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const SelectField: React.FC<ISelectFieldProps> = ({
  className,
  label,
  options,
  selectedOption,
  nullOption,
  naOption,
  disabled,
  error,
  onChange,
}) => {
  return (
    <Field
      className={classNames(styles.selectField, className)}
      containerClassName={styles.selectFieldContainer}
      error={error}
    >
      {label && <span className={styles.selectFieldLabel}>{label}</span>}
      <select value={selectedOption} onChange={onChange} disabled={disabled}>
        {nullOption && (
          <>
            <option value="">{nullOption}</option>
            <option value="-" disabled>
              -----
            </option>
          </>
        )}
        {Object.entries(options).map(([value, name]) => (
          <option value={value} key={value} disabled={value === '-'}>
            {name || '--'}
          </option>
        ))}
        {naOption && <option value={NA_OPTION}>Not Applicable</option>}
      </select>
    </Field>
  );
};

export default SelectField;
