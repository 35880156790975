import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RedirectRoute = ({ component: Component, shouldRedirect, redirectTo, ...rest }) => (
  <Route
    {...rest}
    render={props => shouldRedirect()
      ? <Redirect to={{
          pathname: redirectTo,
          state: { from: props.location }
        }} />
      : <Component {...props} />
    }
  />
);

export default RedirectRoute;
