import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/common/Button';
import HelpCenter from 'common/components/common/HelpCenter';
import OutLink from 'common/components/common/OutLink';
import SectionCard from 'common/components/common/SectionCard';
import AcceptPaymentHelpCenter from 'seller/app/components/payment/AcceptPaymentHelpCenter';
import { connectStripe } from 'seller/app/services/stripe';


import logoStripeImage from 'common/assets/images/logo-stripe.png';
import styles from './Payment.css';

const ConnectStripe = () => (
  <div className={styles.instruction}>
    <p>
      <strong>To start selling on Gemsby, follow these 4 simple steps:</strong>
    </p>
    <ul className={styles.steps}>
      <li className={styles.step}>
        Tap the "Connect with Stripe" button below
      </li>
      <li className={styles.step}>
        In the next screen, create or sign in to your Stripe account.{' '}
        <OutLink to="https://stripe.com/en-ca/about">(What is Stripe?)</OutLink>
      </li>
      <li className={styles.step}>
        Once you have completed all of the steps in the form, you will be
        redirected back here. If everything was successful, you will see a
        confirmation message. We will work on verifying your store in the next
        few days.
      </li>
    </ul>
  </div>
);

const StripeEnabled = () => (
  <div className={styles.instruction}>
    <p>
      <strong>Online Payment is Enabled</strong>
    </p>
    <p>You have successfully connected your Gemsby account with Stripe.</p>
    <p>
      To confirm a payment from any Gemsby consumer that has chosen to purchase
      your listed inventory, tap the <strong>"Your Stripe Account"</strong>{' '}
      button below. You may also manage payment settings there.
    </p>
  </div>
);

const StripeAccountButton = () => (
  <OutLink className={styles.button} to="https://dashboard.stripe.com/">
    Your Stripe Account
  </OutLink>
);

const ConnectStripeButton = ({ onClick }) => (
  <Button className={styles.button} onClick={onClick}>
    Connect with Stripe
  </Button>
);

const ConnectStripeHelpCenter = () => (
  <HelpCenter
    title="How to Connect with Stripe"
    link="https://intercom.help/gemsby/selling-on-gemsby/getting-started/how-to-connect-your-account-with-stripe"
  />
);

interface IStripeProps extends Gemsby.IComponentProps {
  retailerId: string;
  hasStripe: boolean;
}

const Stripe = ({ retailerId, hasStripe, showHelpCenter }: IStripeProps) => {
  const onConnectStripeButtonClick = event => {
    event.preventDefault();
    connectStripe(retailerId);
  };

  return (
    <SectionCard
      className={styles.paymentCard}
      title="Stripe (Major Credit Cards)"
      alwaysOpen
    >
      <div className={styles.paymentCardContent}>
        <div className={styles.logo}>
          <img src={logoStripeImage} alt="stripe logo" />
        </div>
        {hasStripe ? (
          <StripeEnabled />
        ) : (
          <ConnectStripe retailerId={retailerId} />
        )}
        <div className={styles.buttons}>
          {hasStripe ? (
            <StripeAccountButton />
          ) : (
            <ConnectStripeButton onClick={onConnectStripeButtonClick} />
          )}
        </div>
        {showHelpCenter && (hasStripe ? <AcceptPaymentHelpCenter /> : <ConnectStripeHelpCenter />)}
      </div>
    </SectionCard>
  );
};

const mapState = ({ login }) => ({
  retailerId: login.currentUser.id,
  hasStripe: !!login.currentUser.get('stripeAccount'),
});

export default connect(mapState)(Stripe);
