// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".spinningDiamond{display:flex;align-items:center;justify-content:center}.spinningDiamond.loadingFullScreen{position:absolute;top:0;bottom:0;left:0;right:0;background-color:#fff;z-index:5}.spinningDiamond.loadingFullScreen img{width:30%}.spinningDiamond.loadingFullScreen.transparent{background:none}.projects-common-components-common-SpinningDiamond__smallIcon img{width:80px}", ""]);
// Exports
exports.locals = {
	"smallIcon": "projects-common-components-common-SpinningDiamond__smallIcon"
};
module.exports = exports;
