import classNames from 'classnames';
import React from 'react';

import Field from 'common/components/common/form/Field';
import Toggle from 'common/components/common/form/Toggle';

import styles from './Form.css';

const ToggleField = ({
  className,
  label,
  checked,
  disabled,
  error,
  children,
  onChange,
}) => (
  <Field error={error}>
    <div className={classNames(styles.toggleField, className)}>
      <span className={styles.toggleFieldLabel}>{label}</span>
      <Toggle checked={checked} disabled={disabled} onChange={onChange} />
    </div>
    {children}
  </Field>
);

export default ToggleField;
