import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PageDesktop from 'common/components/common/desktop/Page';
import FormErrorMessage from 'common/components/common/form/FormErrorMessage';
import PageMobile from 'common/components/common/mobile/Page';
import PageContent from 'common/components/common/mobile/PageContent';
import Screen from 'common/components/common/mobile/Screen';
import PageHeader from 'common/components/common/PageHeader';
import PageSection from 'common/components/common/PageSection';
import SpinningDiamond from 'common/components/common/SpinningDiamond';
import { clearCache } from 'common/services/algolia';
import { scrollToFormError, useFormState } from 'common/utils/form';
import { getInventoryImageFile } from 'common/utils/image';
import { firstOption } from 'common/utils/misc';
import { currencies } from 'common/utils/retailer';
import { showPrompt } from 'seller/app/actions/dialog';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from 'seller/app/actions/form';
import {
  deleteInventory,
  getInventory,
  saveInventory,
  validateInventory,
} from 'seller/app/actions/inventory';
import BannerAd from 'seller/app/components/common/BannerAd';
import Footer from 'seller/app/components/common/Footer';
import HeaderNavDesktop from 'seller/app/components/common/header/HeaderNavDesktop';
import HeaderNavMobile from 'seller/app/components/common/header/HeaderNavMobile';
import TabBar from 'seller/app/components/common/TabBar';
import InventoryForm from 'seller/app/components/inventory/form/Form';
import { Routes } from 'seller/app/constants';
import onboarding from 'seller/app/services/onboarding';
import {
  prepareInventoryData,
  resetInventoryData,
  saleMarkupTypes,
  settings,
  statusCodes,
} from 'seller/app/utils/inventory';
import Modal from 'common/components/common/mobile/MobileModal';

const formErrorRef = React.createRef();

const InventoryFormPageMobile = ({ title, ...props }) => (
  <>
    <Modal />
    <Screen>
      <PageMobile>
        <PageContent heightReducedBy={60}>
          <HeaderNavMobile />

          <PageHeader>{title}</PageHeader>

          <PageSection>
            <BannerAd />
          </PageSection>

          <PageSection>
            <FormErrorMessage error={props.error} ref={formErrorRef} />
            <InventoryForm {...props} />
          </PageSection>
        </PageContent>

        <TabBar activeItem={Routes.inventory} />
      </PageMobile>
    </Screen>
  </>
);

const InventoryFormPageDesktop = ({ title, ...props }) => (
  <PageDesktop>
    <HeaderNavDesktop activeItem={Routes.inventory} />

    <PageSection>
      <BannerAd />
    </PageSection>

    <PageSection>
      <PageHeader>{title}</PageHeader>

      <FormErrorMessage error={props.error} ref={formErrorRef} />
      <InventoryForm {...props} />
    </PageSection>

    <Footer />
  </PageDesktop>
);

const InventoryFormPage = props => {
  /* tslint:disable:no-shadowed-variable */
  const {
    isBigScreen,
    match,
    history,
    selectedInventory,
    error,
    retailer,
    getInventory,
    saveInventory,
    validateInventory,
    deleteInventory,
    showFullscreenLoader,
    hideFullscreenLoader,
    showPrompt,
  } = props;
  /* tslint:enable:no-shadowed-variable */

  const { id: inventoryId } = match.params;
  const title = inventoryId ? 'Edit Item' : 'Add an Item';

  const [state, handleFieldChange, setState] = useFormState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInventory(inventoryId);
  }, []);

  useEffect(() => {
    if (!selectedInventory) {
      return;
    }

    const data = selectedInventory.toJSON().attributes;
    data.setting = data.setting || firstOption(settings);
    data.saleMarkupType = data.saleMarkupType || firstOption(saleMarkupTypes);
    data.isHidden = data.statusCode === statusCodes.HIDDEN;
    data.currency =
      data.currency || retailer.get('currency') || firstOption(currencies);

    data.imageFiles = (selectedInventory.getRelated('images') || []).map(
      getInventoryImageFile,
    );

    setState(data);

    let timeoutId = setTimeout(
      () => {
        setLoading(false);
        timeoutId = null;
      },
      inventoryId ? 300 : 0,
    );

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [selectedInventory]);

  useEffect(() => {
    if (state.setting) {
      setState(resetInventoryData(state));
    }
  }, [state.setting]);

  useEffect(() => {
    if (!_.isEmpty(error) && state.setting) {
      validateInventory(prepareInventoryData(state), state.imageFiles);
    }
  }, [state]);

  if (loading) {
    return <SpinningDiamond fullScreen />;
  }

  const onImagesChange = imageFiles => {
    setState({
      ...state,
      imageFiles,
    });
  };

  const onCancel: React.MouseEventHandler = event => {
    event.preventDefault();
    history.goBack();
  };

  const onSubmit: React.MouseEventHandler = event => {
    event.preventDefault();
    showFullscreenLoader();

    const { imageFiles, ...data } = state;

    saveInventory(
      {
        type: 'inventory',
        id: selectedInventory.id,
        attributes: prepareInventoryData(data),
      },
      imageFiles,
    )
      .then((item: any) => {
          onboarding.setInventoryUploaded();
          clearCache();

          history.push(`promote/${encodeURIComponent(item.id)}`);
          // history.goBack();
        },
        // () => {
        //   scrollToFormError(formErrorRef);
        // }
      )
      .finally(hideFullscreenLoader);
  };

  const onDelete: React.MouseEventHandler = event => {
    event.preventDefault();

    showPrompt('Are you sure you want to delete this item?').then(() => {
      showFullscreenLoader();
      deleteInventory(selectedInventory)
        .then(() => {
          history.goBack();
        }, () => {
          scrollToFormError(formErrorRef);
        })
        .finally(hideFullscreenLoader);
    });
  };

  const FormPage = isBigScreen
    ? InventoryFormPageDesktop
    : InventoryFormPageMobile;

  props = {
    ...props,
    state,
    error,
    handleFieldChange,
    setState,
    onImagesChange,
    onCancel,
    onSubmit,
    onDelete,
  };

  return <FormPage {...props} title={title} formErrorRef={formErrorRef} />;
};

const mapState = ({ device, inventory, login }) => ({
  isBigScreen: device.isBigScreen,
  selectedInventory: inventory.selectedInventory,
  error: inventory.error,
  retailer: login.currentUser,
});

const mapDispatch = {
  getInventory,
  saveInventory,
  validateInventory,
  deleteInventory,
  showFullscreenLoader,
  hideFullscreenLoader,
  showPrompt,
};

export default connect(
  mapState,
  mapDispatch,
)(InventoryFormPage);
