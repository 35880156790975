// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".projects-seller-app-components-common-Footer__footer{display:flex;flex-direction:column;align-items:center;padding:2% 10%;background-color:var(--footerBgColor)}.projects-seller-app-components-common-Footer__footer a:hover{opacity:.7}.projects-seller-app-components-common-Footer__section{width:100%;height:150px;display:flex;align-items:center;padding:0 20px;border-bottom:1px solid #52546e}.projects-seller-app-components-common-Footer__sectionTitle{width:20%;color:#fff;font-size:18px;text-align:center}.projects-seller-app-components-common-Footer__sectionContent{flex:1;display:flex;justify-content:center}.projects-seller-app-components-common-Footer__socialLink{margin:0 15px;font-size:36px;color:var(--mainColor)}.projects-seller-app-components-common-Footer__image{height:50px;margin:0 15px}.projects-seller-app-components-common-Footer__links{display:flex;align-items:center;justify-content:center;margin-top:80px}.projects-seller-app-components-common-Footer__links a{color:var(--mainColor)}.projects-seller-app-components-common-Footer__dot{margin:0 10px;color:#fff;font-size:24px}.projects-seller-app-components-common-Footer__copyright{margin-top:15px;text-align:center;color:#fff}", ""]);
// Exports
exports.locals = {
	"footer": "projects-seller-app-components-common-Footer__footer",
	"section": "projects-seller-app-components-common-Footer__section",
	"sectionTitle": "projects-seller-app-components-common-Footer__sectionTitle",
	"sectionContent": "projects-seller-app-components-common-Footer__sectionContent",
	"socialLink": "projects-seller-app-components-common-Footer__socialLink",
	"image": "projects-seller-app-components-common-Footer__image",
	"links": "projects-seller-app-components-common-Footer__links",
	"dot": "projects-seller-app-components-common-Footer__dot",
	"copyright": "projects-seller-app-components-common-Footer__copyright"
};
module.exports = exports;
