import React from 'react';
import styles from './PriceCards.css';
// import Fa from 'common/components/common/Fa';
// import TextWithTick from 'seller/app/components/inventory/promote/priceCards/TextWithTick/TextWithTick';
// import Button from 'common/components/common/Button';
import PriceCard from 'seller/app/components/inventory/promote/priceCards/PriceCard';

interface IPriceCards {
  completePurchaseOpenValueHandler: (v:boolean) => void;
  setPromotionType: any;
}

const PriceCards: React.FC<IPriceCards> = ({completePurchaseOpenValueHandler,setPromotionType}) => {

  const cardOneOptions = [
    'Item Displayed in Top Row of search results' ,'Viewed by thousands daily' ,
    'Remain active for one week', '10x Increased item views'
  ]




  const cardTwoOptions = [
    'Item Displayed On Home Page',
    'Viewed by thousands daily',
    'Remain active for one week',
    '20x Increased item views'
  ]
  const cardThreeOptions = [
    'Item Displayed in Top Row of search results & Home Page',
    'Viewed by thousands daily',
    'Remain active for one week',
    '40 x Increased item views'
  ]

  return (
    <div className={styles.priceCards}>
      {/* price card one*/}

        <PriceCard
          completePurchaseOpenValueHandler={completePurchaseOpenValueHandler}
          heading={'Basic'} options={cardOneOptions}
          setPromotionType={setPromotionType}
          price={18}/>

      {/*  Price card two*/}

        <PriceCard
          completePurchaseOpenValueHandler={completePurchaseOpenValueHandler}
                   heading={'Standard'} options={cardTwoOptions}
          setPromotionType={setPromotionType}
          price={30}/>

      {/*  Price card three*/}

        <PriceCard
          completePurchaseOpenValueHandler={completePurchaseOpenValueHandler}
          heading={'Premium'}
          options={cardThreeOptions}
          price={40}
          setPromotionType={setPromotionType}
        />

    </div>
  );
};

export default PriceCards;
