// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../../node_modules/postcss-loader/src/index.js!common/components/common/Button.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".projects-seller-app-components-inventory-form-Form__formContent{display:flex}.projects-seller-app-components-inventory-form-Form__formContentLeftColumn{flex:2;margin-right:20px}.projects-seller-app-components-inventory-form-Form__formContentRightColumn{flex:3}.projects-seller-app-components-inventory-form-Form__multipleItemsForm p:first-of-type{margin-top:0}.projects-seller-app-components-inventory-form-Form__multipleItemsForm a{color:var(--mainColor)}.projects-seller-app-components-inventory-form-Form__button{}.projects-seller-app-components-inventory-form-Form__sectionIntro{margin:0 0 20px;font-size:14px}.projects-seller-app-components-inventory-form-Form__selectImageButton{width:100%}.projects-seller-app-components-inventory-form-Form__propsContainer{display:flex;justify-content:space-between;width:calc(100% - 20px)}.projects-seller-app-components-inventory-form-Form__propsContainer input{width:30%;font-size:12px}.projects-seller-app-components-inventory-form-Form__buttons{display:flex;justify-content:space-between;align-items:flex-end;margin-top:30px}.projects-seller-app-components-inventory-form-Form__cancelButton{width:49%}.projects-seller-app-components-inventory-form-Form__submitButton{width:49%}a.projects-seller-app-components-inventory-form-Form__submitButton{color:#fff;text-align:center;margin:2px 0}.projects-seller-app-components-inventory-form-Form__deleteButtonContainer{display:flex;justify-content:center;margin-top:15px}.projects-seller-app-components-inventory-form-Form__settingItem{margin-top:20px}.projects-seller-app-components-inventory-form-Form__settingItemLabel{display:flex;align-items:center}.projects-seller-app-components-inventory-form-Form__settingItemLabel .icon{display:inline-block;width:25px;margin-right:10px;font-size:22px;text-align:center}.projects-seller-app-components-inventory-form-Form__videoSectionTitle{margin-top:20px}", ""]);
// Exports
exports.locals = {
	"formContent": "projects-seller-app-components-inventory-form-Form__formContent",
	"formContentLeftColumn": "projects-seller-app-components-inventory-form-Form__formContentLeftColumn",
	"formContentRightColumn": "projects-seller-app-components-inventory-form-Form__formContentRightColumn",
	"multipleItemsForm": "projects-seller-app-components-inventory-form-Form__multipleItemsForm",
	"button": "projects-seller-app-components-inventory-form-Form__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"sectionIntro": "projects-seller-app-components-inventory-form-Form__sectionIntro",
	"selectImageButton": "projects-seller-app-components-inventory-form-Form__selectImageButton projects-seller-app-components-inventory-form-Form__button " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"propsContainer": "projects-seller-app-components-inventory-form-Form__propsContainer",
	"buttons": "projects-seller-app-components-inventory-form-Form__buttons",
	"cancelButton": "projects-seller-app-components-inventory-form-Form__cancelButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainButton"] + "",
	"submitButton": "projects-seller-app-components-inventory-form-Form__submitButton " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["plainPrimaryButton"] + "",
	"deleteButtonContainer": "projects-seller-app-components-inventory-form-Form__deleteButtonContainer",
	"settingItem": "projects-seller-app-components-inventory-form-Form__settingItem",
	"settingItemLabel": "projects-seller-app-components-inventory-form-Form__settingItemLabel",
	"videoSectionTitle": "projects-seller-app-components-inventory-form-Form__videoSectionTitle"
};
module.exports = exports;
